import { Dropdown, Menu } from "antd";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import AppIcon from "assets/images/icon.svg";
import { logOutProvider } from "redux/reducers/auth";
import { profileSelector } from "redux/reducers/profile";
import { UpdateSubContext } from "context/UpdateSubscriptionContext";
import { useAppDispatch, useAppSelector } from "redux/store";

import AvatarImg from "../Avatar";
import UpdateSubscriptionModal from "component/UpdateSubModal";

import { MenuLink, NavbarWrapper } from "./styles";

import { ReactComponent as LightningIcon } from "assets/icons/lightning.svg";

const Navbar = ({ setOpenedMenu }: any) => {
  const dispatch = useAppDispatch();
  const { profile } = useSelector(profileSelector);
  const { plan } = useAppSelector((state) => state.subscription);
  const { handleShowModal } = useContext(UpdateSubContext);

  const { logo_path, business_name, id } = profile || {};

  const handleLogout = () => {
    dispatch(logOutProvider());
  };

  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <Link to='/manage-account'>Manage account</Link>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key='1' onClick={handleLogout}>
        Log out
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <NavbarWrapper>
        <span style={{ display: "flex", alignItems: "center" }}>
          <i
            className='lni lni-menu menu-icon'
            style={{ cursor: "pointer" }}
            onClick={() => setOpenedMenu(true)}
          ></i>
          <img src={AppIcon} alt='icon' />
        </span>
        <div className='menu'>
          {plan === "trial" || plan === "freemium" ? (
            <span
              className='upgrade-plan'
              onClick={() => handleShowModal(true)}
            >
              <LightningIcon />
              Upgrade Plan
            </span>
          ) : null}

          <Dropdown overlay={menu} trigger={["click"]}>
            <MenuLink>
              <span className='username' style={{ marginRight: 10 }}>
                {business_name}
                <span
                  style={{
                    textTransform: "uppercase",
                    fontSize: "12px",
                    display: "block"
                  }}
                >
                  {id && <b>ID:</b>} {id}
                </span>
              </span>
              <AvatarImg img={logo_path} fallbackText={`${business_name}`} />
            </MenuLink>
          </Dropdown>
        </div>
      </NavbarWrapper>

      <UpdateSubscriptionModal />
    </>
  );
};

export default Navbar;
