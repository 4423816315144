import React from "react";
import {
  ChoiceFieldContainer,
  FieldContainer,
  QuestionsFormWrapper,
} from "./styles";
import { ReactComponent as NoteIcon } from "assets/icons/note-2.svg";
import { SingleFormType } from "../../constants/types";
import { Checkbox, Input, Progress, Radio } from "antd";

type QuestionsFormProps = {
  handleQuestionFormChange: any;
  normalizedQuestions: any;
  currentQuestionId: any;
  form: any;
  responses: Record<any, any>;
};
export type QuestionResponse = {
  question: number;
  question_text: string;
  response: number | number[];
  value: any;
  next_question: number;
};
const QuestionsForm = ({
  handleQuestionFormChange,
  form,
  normalizedQuestions,
  currentQuestionId,
  responses,
}: QuestionsFormProps) => {
  const service_form: SingleFormType = form;

  return (
    <QuestionsFormWrapper>
      <div className="question">
        <div className="question__header">
          <NoteIcon />
          <h1>{service_form?.name}</h1>
        </div>
        <div className="question__progress">
          <h4>
            Question {currentQuestionId} of {service_form.number_of_questions}
          </h4>
          <Progress
            strokeColor="#0FAFF0"
            size="small"
            showInfo={false}
            trailColor="#D9D9D9"
            percent={
              // @ts-ignore
              (100 / service_form?.number_of_questions) * currentQuestionId
            }
          />
        </div>
        <Questions
          question={normalizedQuestions[currentQuestionId]}
          value={responses[currentQuestionId]}
          onChange={handleQuestionFormChange}
        />
      </div>
    </QuestionsFormWrapper>
  );
};

export default QuestionsForm;

export type QuestionFormProps = {
  question: any;
  value: QuestionResponse;
  onChange: (value: QuestionResponse) => void;
};
const Questions = (props: QuestionFormProps) => {
  const hasHint = props?.question?.hint && props?.question?.hint !== "null";
  const labelProps = { style: { marginBottom: "12px" } };

  return (
    <div className="question__form-body">
      <h3 {...(hasHint ? {} : labelProps)}>{props?.question?.text}</h3>
      {hasHint ? <span>{props?.question?.hint}</span> : null}
      {getInputs(props)}
    </div>
  );
};

function getInputs(props: QuestionFormProps) {
  const { question, value, onChange } = props;
  switch (question?.type) {
    case "text":
    default:
      return (
        <FieldContainer>
          <Input.TextArea
            autoSize={{ minRows: 3, maxRows: 4 }}
            value={value?.value}
            onChange={(e) =>
              onChange({
                question: question?.id,
                question_text: question?.text,
                response: question.response?.id,
                value: e.target?.value,
                next_question: question?.next_question,
              })
            }
            placeholder={"Write your answer here..."}
          />
        </FieldContainer>
      );
    case "select_one":
      return (
        <Radio.Group
          style={{ display: "block" }}
          value={value?.response}
          onChange={(e) => {
            const option = question.response.find(
              (option: any) => option.id === e.target?.value,
            );
            onChange({
              question: question?.id,
              question_text: question?.text,
              response: e.target.value,
              value: option?.value,
              next_question: !option?.next_question
                ? question?.next_question
                : option?.next_question,
            });
          }}
        >
          {question?.response.map((option: any) => (
            <ChoiceFieldContainer
              active={value?.response === option?.id}
              key={option?.id}
            >
              <Radio value={option?.id}>{option?.value}</Radio>
            </ChoiceFieldContainer>
          ))}
        </Radio.Group>
      );
    case "select_multiple":
      return (
        <>
          {question?.response.map((option: any) => {
            const response = value?.response as number[];
            const responseValue = value?.value as string[];
            const checked = response?.includes(option.id);
            return (
              <ChoiceFieldContainer active={checked} key={option?.id}>
                <Checkbox
                  checked={checked}
                  onChange={(e) =>
                    onChange({
                      question: question?.id,
                      question_text: question?.text,
                      response: e.target.checked
                        ? [...(response || []), option?.id]
                        : (() => {
                            const newResponse = [...response];
                            newResponse.splice(response.indexOf(option?.id), 1);
                            return newResponse;
                          })(),
                      value: e.target.checked
                        ? [...(responseValue || []), option?.value]
                        : (() => {
                            const newResponseValue = [...responseValue];
                            newResponseValue.splice(
                              response.indexOf(option?.value),
                              1,
                            );
                            return newResponseValue;
                          })(),
                      next_question: question?.next_question,
                    })
                  }
                >
                  {option?.value}
                </Checkbox>
              </ChoiceFieldContainer>
            );
          })}
        </>
      );
  }
}
