import moment from "moment";

import { AppDispatch } from "redux/store";
import { invalidatesList } from "./redux-toolkit-query";
import { BookingsRTKQueryTagEnum } from "constants/types";

export const getAge = (dob: any) => {
  const ageInYears = dob
    ? moment().diff(moment(dob, "DD/MM/YYYY"), "years")
    : null;
  if (ageInYears || ageInYears === 0) {
    return ageInYears === 0
      ? `${moment().diff(moment(dob, "DD/MM/YYYY"), "months")} mo`
      : ageInYears > 0
      ? `${ageInYears}yrs`
      : "—";
  }
  return "—";
};

export const capitaliseFirstLetter = (word: string) =>
  word.charAt(0)?.toUpperCase() + word.toLowerCase()?.slice(1);

export const capitaliseWords = (words: string): string => {
  return words
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * Returns an array containing disabled hours based on if todays date was selected in the `DatePicker`. If `today` was selected, return an array combining both outside work hours and below current hour hours. Else, for non `today` future dates return just outside work hours.
 * @param isTodaySelected boolean
 * @returns number[]
 */
export const getDisabledHours = (isTodaySelected: boolean) => {
  var hours = [18, 19, 20, 21, 22, 23, 24, 0, 1, 2, 3, 4, 5, 6, 7];

  if (!isTodaySelected) {
    return hours;
  }

  for (let i = 0; i < moment().hour(); i++) {
    if (!hours.includes(i)) {
      hours.push(i);
    }
  }
  return hours;
};

/**
 * Returns an array containing disabled minutes. If `isTodaySelected` is true, and `selectedHour` is outside of work hours or -1 (no selection), then return all numbers from 1 to 60. Else only disable minutes less than current time.
 * @param selectedHour number
 * @param isTodaySelected boolean
 * @returns number[]
 */
export const getDisabledMinutes = (
  selectedHour: number,
  isTodaySelected: boolean
) => {
  const minutes = [];
  if (
    isTodaySelected &&
    (selectedHour > 19 || selectedHour < 7 || selectedHour === -1)
  ) {
    for (let i = 0; i < 60; i++) {
      minutes.push(i);
    }
  }

  if (selectedHour === moment().hour()) {
    for (let i = 0; i < moment().minute(); i++) {
      minutes.push(i);
    }
  }
  return minutes;
};

export const insuranceCompanies = [
  "Avon Healthcare Limited",
  "AXA Mansard",
  "Healthcare International (HCI)",
  "Hygeia HMO",
  "Leadway Health",
  "ProHealth HMO",
  "Redcare HMO",
  "Reliance HMO",
  "Total Health Trust",
  "Venus Medicare",
  "SUNU Health",
  "United Health Care",
  "Novo Health Africa",
  "Precious Health Care",
  "Bastion Health",
  "Oceanic Health Management Limited",
  "Fountain Healthcare Limited",
  "Metro Health",
  "Salus Trust",
  "Greenfield HMO",
  "Police HMO",
  "Life Action Plus",
  "Ultimate HMO",
  "Eagle HMO",
  "Ronsberger HMO",
  "Health Partners HMO",
  "Roding HMO",
  "Mediplan HMO",
  "Wellness HMO",
  "Songhai HMO",
  "Medical Partners HMO",
  "Greenbay HMO",
  "Life Care Partners HMO",
  "Life Worth HMO",
  "Markfema HMO",
  "Nonsuch HMO",
  "Prepaid Medicare",
  "NHIS",
  "ClearLine HMO",
  "Veritas HMO"
];

/**
 * handles notification and invalidation / refetching of bookings
 *
 * @param data Array
 * @param dispatch AppDispatch
 * @param api bookingsApi
 * @param title String
 */
export function handleBinding(
  data: any,
  dispatch: AppDispatch,
  api: any,
  title: string
) {
  function showNotification() {
    if (data && data.metadata.source === "pneumapage") {
      new Notification(title, {
        body: `New booking for ${data.plan.name}`,
        icon: process.env.PUBLIC_URL + "/logo.png"
      });

      // trigger automated invalidation & refetching cause of added booking
      dispatch(
        api.util.invalidateTags(
          invalidatesList(BookingsRTKQueryTagEnum.BOOKINGS) as any
        )
      );
    }
  }

  // show notification if notification access is granted, else ask for access & if granted show notification
  if (window.Notification) {
    if (Notification.permission === "granted") {
      showNotification();
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission()
        .then((permission) => {
          if (permission === "granted") {
            showNotification();
          }
        })
        .catch((reason) => console.log("Notification", reason));
    }
  }
}

/**
 * @returns a debounced function that takes in a callback and a timer, and delays the callback's execution if invoked before the specified timer
 */
export function debounce(func: (val: any) => void, wait: number) {
  let timeoutId: any;

  return (arg: any) => {
    // clear existing timeout
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      func(arg);
    }, wait);
  };
}

/**
 * returns properly formatted phone number containing the specific country code and the user number, with adjustments made for removing unnecessary digits.
 * @param number
 * @param dialCode
 * @returns string
 */
export function handlePhoneNumberFormat(number: string, dialCode: string) {
  // prepend `+` to inputted number to account for cases where inputted number contains country code. this ensures uniqueness cause users can only input numbers and no special characters.
  const _number = "+" + number;
  const _dialCode = "+" + dialCode;

  let userPhone = _number.split(_dialCode)[1];
  userPhone = userPhone.charAt(0) === "0" ? userPhone.slice(1) : userPhone;

  return `${_dialCode}${userPhone}`;
}

export type daysOfWeek =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

export async function triggerPushNotifications(title: string, message: string) {
  if ("serviceWorker" in navigator) {
    try {
      const register = await navigator.serviceWorker.register(
        `${process.env.PUBLIC_URL}/service-worker.js`,
        {
          scope: "/"
        }
      );

      const subscription = await register.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(
          process.env.REACT_APP_PUBLIC_VAPID_KEY!
        )
      });

      await fetch(`${process.env.REACT_APP_BASE_URL}/subscribe`, {
        method: "POST",
        body: JSON.stringify({
          subscription,
          payload: {
            title,
            body: message
          }
        }),
        headers: {
          "Content-Type": "application/json"
        }
      });
    } catch (err) {
      console.log(err);
    }
  } else {
    console.error("Service workers are not supported in this browser");
  }
}

function urlBase64ToUint8Array(base64String: string) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}
