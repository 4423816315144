import styled from "styled-components";

export const TimePickerWrapper = styled.div`
  .ant-form-item-label {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
    margin-bottom: 5px;

    label {
      color: #002e46;
      font-size: 13px;
    }
  }

  div.ant-picker {
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    border: 1px solid #bfcad0;
    border-radius: 5px;
  }
`;
