import { useState, useEffect } from "react";
import {
  ArrowLeftOutlined,
  CalendarOutlined,
  MailOutlined,
  UserOutlined
} from "@ant-design/icons";
import { PatientDetails, ReturnLink, Wrapper, PatientInfo } from "./style";
import { generatePath, NavLink, useParams } from "react-router-dom";
import { Tabs } from "antd";

//Redux
import { userSelector } from "redux/reducers/users";
import { useAppSelector, useAppDispatch } from "redux/store";
import { useGetSpecificPatientNoteQuery } from "../../../../redux/queries/notes";
//components
import Booking from "component/Bookings";
import Notes from "component/Notes";
import FollowUp from "component/FollowUps";
//svg
import { ReactComponent as PhoneIcon } from "assets/icons/Phone.svg";
import { ReactComponent as MapPinIcon } from "assets/icons/MapPin.svg";
import { ReactComponent as HouseIcon } from "assets/icons/House.svg";

//Modal
import UpdateUserAppointment from "component/UpdateUserAppointmentModal";
import { fetchUserBookings } from "redux/reducers/servicePlans";
import { fetchSingleUser } from "redux/reducers/users";
import { ROUTES } from "constants/routes";
import { useGetSpecificPatientFollowUpsQuery } from "redux/queries/follow-ups";
import { EmptyStateWrapper } from "../../../Messages/styles";
import Loader from "../../../../component/Loader";
import { capitaliseFirstLetter } from "utils/utils";

const PatientsDetail = () => {
  const dispatch = useAppDispatch();
  const { patientId }: { patientId: string } = useParams();
  const { users, usersError, isUsersLoading } = useAppSelector(userSelector);
  const { data: patient, isLoading: isPatientNotesLoading } =
    useGetSpecificPatientNoteQuery({
      patientId
    });
  const { data, isLoading: isPatientFollowUpsLoading } =
    useGetSpecificPatientFollowUpsQuery({
      patientId
    });
  const selectUser = users.find((user) => user.id === patientId);
  const [activeTab, setActiveTab] = useState<
    "notes" | "bookings" | "follow-up"
  >("notes");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [patientBooking, setPatientBooking] = useState<any>();
  const [getBookingLoading, setGetBookingLoading] = useState(false);
  const [patientInfo, setPatientInfo] = useState<any>("");
  const [isUserLoading, setIsUserLoading] = useState(false);
  const notes: any = patient?.data;
  const followUps: any = data?.data;

  useEffect(() => {
    async function getBooking() {
      setGetBookingLoading(true);
      try {
        const res: any = await dispatch(fetchUserBookings(patientId));
        setPatientBooking(res);
      } catch (err) {
        console.log(err);
      } finally {
        setGetBookingLoading(false);
      }
    }
    async function getUser() {
      setIsUserLoading(true);
      try {
        const res: any = await dispatch(fetchSingleUser(patientId));
        setPatientInfo(res);
      } catch (err) {
        console.log(err);
      } finally {
        setIsUserLoading(false);
      }
    }
    getUser();
    getBooking();
  }, [patientId, dispatch]);

  const user = patientInfo;

  if (isPatientNotesLoading || isPatientFollowUpsLoading || isUserLoading) {
    return <Loader />;
  }
  // if (usersError) {
  //   return (
  //     <EmptyStateWrapper>
  //       <h2>Error Loading page...</h2>
  //       <p>Please try again!!</p>
  //     </EmptyStateWrapper>
  //   );
  // }

  return selectUser ? (
    <>
      <Wrapper>
        <ReturnLink to='/patients'>
          <ArrowLeftOutlined />
          <span>Back to patients list</span>
        </ReturnLink>

        <PatientInfo>
          <div className='name-wrapper'>
            <p className='img'>
              <span>
                {user?.first_name?.charAt(0)}
                {user?.last_name?.charAt(0)}
              </span>
            </p>

            <h1>
              {user?.first_name} {user?.last_name}
            </h1>
          </div>

          <div className='contact-wrapper'>
            <p>
              <PhoneIcon /> <span className='details'>{user?.phone}</span>
            </p>
            <p>
              <MailOutlined />
              <span className='details'>{user?.email}</span>
            </p>
          </div>

          <div className='about-wrapper'>
            <p>
              <UserOutlined />
              {user.gender ? (
                <span className='details'>
                  {capitaliseFirstLetter(user?.gender)}
                </span>
              ) : null}
            </p>
            <p>
              <CalendarOutlined />
              {user && (
                <span className='details'>
                  {Number(new Date().getFullYear()) -
                    Number(user?.dob.split("/")[2]) +
                    ` years`}
                </span>
              )}
            </p>
          </div>

          {user.address ? (
            <div className='address-wrapper'>
              <p>
                <HouseIcon />
                <span className='details'>{user.address?.city}</span>
              </p>
              <p>
                <MapPinIcon />{" "}
                <span className='details'>
                  {user.address?.state ? `${user.address.state}, ` : null}{" "}
                  {user.address?.country}
                </span>
              </p>
            </div>
          ) : null}

          {/* <div className='btn'>
            <NavLink
              exact
              to={generatePath(ROUTES.MORE_DETAILS, {
                patientId: selectUser?.id as string
              })}
            >
              More details
            </NavLink>{" "}
          </div> */}
        </PatientInfo>

        <PatientDetails>
          <Tabs style={{ width: "100%" }}>
            <Tabs.TabPane
              tab={
                <p style={{ margin: 0 }} onClick={() => setActiveTab("notes")}>
                  Notes{" "}
                  {notes?.length ? (
                    <span
                      className='count'
                      style={{
                        background:
                          activeTab === "notes" ? "#E6F7FF" : "#f0f0f0"
                      }}
                    >
                      {notes?.length}
                    </span>
                  ) : null}
                </p>
              }
              key={1}
            >
              <Notes handleOpen={() => setIsModalOpen(true)} notes={notes} />
            </Tabs.TabPane>

            {/*<Tabs.TabPane*/}
            {/*  tab={*/}
            {/*    <p*/}
            {/*      style={{ margin: 0 }}*/}
            {/*      onClick={() => setActiveTab("bookings")}*/}
            {/*    >*/}
            {/*      Booking{" "}*/}
            {/*      {patientBooking?.length ? (*/}
            {/*        <span*/}
            {/*          className="count"*/}
            {/*          style={{*/}
            {/*            background:*/}
            {/*              activeTab === "bookings" ? "#E6F7FF" : "#f0f0f0",*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {patientBooking?.length}*/}
            {/*        </span>*/}
            {/*      ) : null}*/}
            {/*    </p>*/}
            {/*  }*/}
            {/*  key={2}*/}
            {/*>*/}
            {/*  <Booking*/}
            {/*    handleOpen={() => setIsModalOpen(true)}*/}
            {/*    patientBooking={patientBooking}*/}
            {/*    getBookingLoading={getBookingLoading}*/}
            {/*  />*/}
            {/*</Tabs.TabPane>*/}
            {/*<Tabs.TabPane*/}
            {/*  tab={*/}
            {/*    <p*/}
            {/*      style={{ margin: 0 }}*/}
            {/*      onClick={() => setActiveTab("follow-up")}*/}
            {/*    >*/}
            {/*      Follow Up{" "}*/}
            {/*      {followUps?.length ? (*/}
            {/*        <span*/}
            {/*          className="count"*/}
            {/*          style={{*/}
            {/*            background:*/}
            {/*              activeTab === "follow-up" ? "#E6F7FF" : "#f0f0f0",*/}
            {/*          }}*/}
            {/*        >*/}
            {/*          {followUps?.length}*/}
            {/*        </span>*/}
            {/*      ) : null}*/}
            {/*    </p>*/}
            {/*  }*/}
            {/*  key={3}*/}
            {/*>*/}
            {/*  <FollowUp*/}
            {/*    handleOpen={() => setIsModalOpen(true)}*/}
            {/*    followUps={followUps}*/}
            {/*  />*/}
            {/*</Tabs.TabPane>*/}
          </Tabs>
        </PatientDetails>
      </Wrapper>

      <UpdateUserAppointment
        isModalOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </>
  ) : null;
};

export default PatientsDetail;
