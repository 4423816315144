import { createApi } from "@reduxjs/toolkit/query/react";

import { axiosBaseQuery } from "utils/redux-toolkit-query";
import http from "utils/api";

const connectApi = createApi({
  reducerPath: "connectApi",
  keepUnusedDataFor: 60 * 3,
  baseQuery: axiosBaseQuery(undefined, http),
  endpoints: (builder) => ({}),
});

export default connectApi;
