import { Col, Row, Table } from "antd";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../component/Button";
import Card from "../../../../component/Card";
import DatePicker from "../../../../component/DatePicker";
import Search from "../../../../component/Search";
import { profileSelector } from "../../../../redux/reducers/profile";
import {
  fetchAllServiceOrders,
  requestsSelector
} from "../../../../redux/reducers/requests";
import {
  ClaimsTableBody,
  ClaimsTableWrapper,
  DownloadCSVBtn,
  TypeFormModalWrapper
} from "./styles";
import { capitaliseFirstLetter } from "utils/utils";

interface ClaimsTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
  dashboardMode?: string;
  investigations?: any;
  isInvestgationsLoading: any;
  setSelectedMonth?: any;
}

const ClaimsTable = ({
  count,
  dashboardMode,
  investigations,
  isInvestgationsLoading,
  setSelectedMonth
}: ClaimsTableProps) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const { profile } = useSelector(profileSelector);
  const { email, phone, business_name, id } = profile || {};
  const [labRequestsState, setClaimsState] = useState<any[]>([]);

  useEffect(() => {
    dispatch(fetchAllServiceOrders());
  }, [dispatch]);

  useEffect(() => {
    if (investigations) {
      console.log(
        investigations.filter(
          (item: any) => item?.metadata?.payment_method === "financed"
        )
      );

      setClaimsState(
        investigations.filter(
          (item: any) =>
            (item.status === "completed" &&
              (item.metadata.payment_method === "pneumapay" ||
                item.metadata.payment_method === "search")) ||
            (item?.metadata && item.metadata?.payment_method === "financed")
        )
      );
    }
  }, [investigations]);

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return <p>{id}</p>;
      }
    },
    {
      title: "Patient",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.user?.first_name.toLowerCase() < b?.user?.first_name.toLowerCase()
          ? -1
          : a?.user?.first_name.toLowerCase() >
            b?.user?.first_name.toLowerCase()
          ? 1
          : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <p
            style={{
              minWidth: "240px"
            }}
          >
            {capitaliseFirstLetter(first_name)}{" "}
            {capitaliseFirstLetter(last_name)}
          </p>
        );
      }
    },
    {
      title: "Partner",
      dataIndex: "partner",
      key: "partner",
      render: (_: any, record: any) => {
        const { business_name } = record?.partner || {};
        return (
          <p
            style={{
              minWidth: "120px",
              maxWidth: "max-content",
              lineHeight: "1.7"
            }}
          >
            {business_name}
          </p>
        );
      }
    },
    {
      title: "Amount",
      dataIndex: "total_price",
      key: "total_price",
      render: (total_price: number) => {
        return (
          <p
            className='tags'
            style={{
              textTransform: "capitalize",
              width: "max-content",
              lineHeight: "1.7"
            }}
          >
            NGN{" "}
            {`${Number(total_price) / 100}`.replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            )}
          </p>
        );
      }
    },
    {
      title: "Created on",
      dataIndex: "schedule",
      key: "schedule",
      render: (_: Date, record: any) => {
        return (
          <>
            <p
              style={{
                minWidth: "120px",
                maxWidth: "max-content",
                lineHeight: "1.7"
              }}
            >
              {moment(record.created_at).format("LL")}
            </p>
            <span className='subtext'>
              {moment(record.created_at).format("hh:mm a")}
            </span>
          </>
        );
      }
    }

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   align: "center" as "center",
    //   render: (_: string, record: any) => {
    //     return (
    //       <Button type="secondary" onClick={() => handleOpenDrawer(record)}>
    //         View details
    //       </Button>
    //     );
    //   },
    // },
  ];

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _labRequests = [...investigations];
    setClaimsState(
      _labRequests
        .filter(
          (item) =>
            item.status === "completed" &&
            item.metadata.payment_method === "pneumapay"
        )
        .filter((item) => {
          const { user } = item;
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(keyword.toLowerCase());
        })
    );
  };

  const onChange = (date: any, dateString: string) => {
    if (date && dateString) {
      const _labRequests = [...investigations];
      setSelectedMonth(dateString);
      setClaimsState(
        _labRequests
          .filter(
            (item) =>
              item.status === "completed" &&
              item.metadata.payment_method === "pneumapay"
          )
          .filter((item) => {
            const { created_at } = item;
            return (
              moment(created_at).isSame(moment(date), "month") &&
              moment(created_at).isSame(moment(date), "year")
            );
          })
      );
    } else {
      setSelectedMonth("");
      setClaimsState(
        investigations.filter(
          (item: any) =>
            item.status === "completed" &&
            item.metadata.payment_method === "pneumapay"
        )
      );
    }
  };

  const dataSource = count
    ? labRequestsState.slice(0, count)
    : labRequestsState;

  return (
    <>
      <Row
        gutter={10}
        style={{ marginTop: "20px", marginBottom: "0px" }}
        justify='space-between'
        align='middle'
      >
        <Col xs={24} md={4} style={{ marginBottom: 10 }}>
          <DatePicker
            marginBottom={0}
            mode='normal'
            width='auto'
            format='MMM YYYY'
            onChange={onChange}
            picker='month'
          />
        </Col>
        <Col xs={24} md={12}>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Search placeholder='Search by patient' onChange={handleSearch} />
            </div>
          </div>
        </Col>
      </Row>
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <ClaimsTableWrapper>
          <ClaimsTableBody>
            <Table
              dataSource={labRequestsState}
              columns={columns}
              scroll={
                !isInvestgationsLoading && dataSource.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 565px)").matches
                  ? { x: 700 }
                  : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isInvestgationsLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true
              }}
            />
          </ClaimsTableBody>
        </ClaimsTableWrapper>
      </Card>
    </>
  );
};

export default ClaimsTable;
