import { message } from "antd";
import Swal from "sweetalert2";
import axios, { AxiosInstance, AxiosResponse, AxiosError } from "axios";

import { Auth as firebaseAuth } from "../utils/firebase";

const accessToken = window.localStorage.getItem("accessToken");
const authorizationKey = window.localStorage.getItem("accessKey");
const integrationKey =
  process.env.REACT_APP_INSTANCE_ENV === "development"
    ? process.env.REACT_APP_PNEUMAPAGE_DEV_KEY
    : process.env.REACT_APP_PNEUMAPAGE_PROD_KEY;

const northIntegrationKey = process.env.REACT_APP_NORTH_PROD_KEY;

const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${authorizationKey}`
  }
});

http.defaults.headers.post["Content-Type"] = "application/json";

http.interceptors.request.use(
  async (config) => {
    config.headers.Authorization = `Bearer ${window.localStorage.getItem(
      "accessKey"
    )}`;

    return config;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
      }
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
      }
    } else if (request) {
      message.error("Request failed. Please try again.");
    }
    return Promise.reject(error);
  }
);

export const httpWithIntegration = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${integrationKey}`
  }
});

httpWithIntegration.defaults.headers.post["Content-Type"] = "application/json";

export const httpWithNorthIntegration = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Authorization: `Bearer ${northIntegrationKey}`
  }
});

export const httpWithJWT: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { Authorization: `Bearer ${accessToken}` }
});

httpWithJWT.defaults.headers.post["Content-Type"] = "application/json";

httpWithJWT.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

export const httpWithJWT2: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { Authorization: `Bearer ${""}` }
});

httpWithJWT2.defaults.headers.post["Content-Type"] = "application/json";

httpWithJWT2.interceptors.response.use(
  async (response: AxiosResponse) => {
    return response;
  },
  (error: AxiosError) => {
    const {
      response,
      request
    }: {
      response?: AxiosResponse;
      request?: XMLHttpRequest;
    } = error;
    if (response) {
      if (
        response?.status === 400 &&
        response?.data?.error === "Token expired"
      ) {
        Swal.fire({
          icon: "error",
          title: "Session Expired!",
          text: "Your session token has expired, please login again!",
          confirmButtonColor: "#0f9af0"
        }).then(() => {
          logOutProvider();
        });
      }
      // return null;
    } else if (request) {
      message.error("Request failed. Please try again.");
      // return null;
    }
    return Promise.reject(error);
  }
);

export const logOutProvider = () => {
  firebaseAuth
    .signOut()
    .then(() => {
      window.localStorage.clear();
      window.location.replace("/");
    })
    .catch((err) => {
      message.error("Request failed. Please try again.");
    });
};

export default http;
