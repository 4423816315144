import React, { createContext, useState } from "react";

export const UpdateSubContext = createContext(
  {} as {
    showUpdateSubModal: boolean;
    handleShowModal: (val: boolean, attemptedFeature?: string) => void;
    attemptedFeature?: string;
  }
);

export const UpdateSubContextProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [showUpdateSubModal, setShowUpdateSubModal] = useState(false);
  const [attemptedFeature, setAttemptedFeature] = useState<
    string | undefined
  >();

  function handleShowModal(val: boolean, attemptedFeature?: string) {
    setShowUpdateSubModal(val);
    setAttemptedFeature(attemptedFeature);
  }

  return (
    <UpdateSubContext.Provider
      value={{ showUpdateSubModal, handleShowModal, attemptedFeature }}
    >
      {children}
    </UpdateSubContext.Provider>
  );
};
