import styled, { css } from "styled-components";

interface ButtonProps {
  type: string;
  pMR: any;
  pAlign: any;
}

const styles = (key: string) => {
  return key === "primary"
    ? css`
        background: #0f9af0;
        color: #fff;
        font-size: 13px;
        border: 1px solid #0f9af0;
        &:hover:not(:disabled) {
          background: #fff;
          color: #0f9af0;
          border: 1px solid #0f9af0;
        }
      `
    : key === "secondary"
    ? css`
        background: #fff;
        text-align: left;
        color: #0f9af0;
        border: 1px solid #0f9af0;
        &:hover:not(:disabled) {
          background: #0f9af0;
          color: #fff;
          border: 1px solid #fff;
        }
        &.ant-btn[disabled]:hover,
        &:hover(:disabled) {
          background: #fff;
          color: #0f9af0;
          border: 1px solid #0f9af0;
        }
      `
    : key === "tertiary"
    ? css`
        background: #fff;
        text-align: left;
        color: #000;
        border: 1px solid #000;

        &:hover:not(:disabled) {
          background: transparent;
          color: #0f9af0;
          border: 1px solid #0f9af0;
        }

        &.ant-btn[disabled]:hover,
        &:hover(:disabled) {
          background: #fff;
          color: #000;
          border: 1px solid #000;
        }
      `
    : key === "danger"
    ? css`
        background: #fb5555;
        text-align: center;
        color: #fff;
        border: 1px solid #fb5555;

        &:hover:not(:disabled) {
          background: #fb5555;
          color: #0f9af0;
          //border: 1px solid #0f9af0;
        }

        &.ant-btn[disabled]:hover,
        &:hover(:disabled) {
          background: #fff;
          color: #000;
          border: 1px solid #000;
        }
      `
    : null;
};

const ButtonWrapper = styled.div<ButtonProps>`
  display: inline-block;
  ${({ pMR }) =>
    pMR &&
    css`
      margin-right: auto;
    `};
  ${({ pAlign }) =>
    pAlign &&
    css`
      align-self: flex-end;
    `};
  button.ant-btn {
    height: 35px;
    border-radius: 4px;
    font-family: "DM Sans";
    font-weight: 400;
    border: none;
    line-height: 9px;
    text-align: center;
    transition: all 0.01s ease-in;
    ${(props) => (props.type ? styles(props.type) : "")};
    &:disabled {
      opacity: 0.5;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
  &.plan-add {
    display: flex;
    .ant-btn {
      margin-left: 10px;
      height: 40px;
      @media only screen and (max-width: 767px) {
        margin: 16px 0 0 auto;
      }
    }
  }
  ${(props) =>
    props.type === "primary" &&
    `
        .ant-btn[disabled]:hover {
            background: #0f9af0 !important;
            color: #fff;
            border: 1px solid #0f9af0;
        }
    `}
`;

export { ButtonWrapper };
