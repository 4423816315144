import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpWithJWT } from "../../utils/api";
import { message as AntdMessage } from "antd";
import { Auth as firebaseAuth, db } from "../../utils/firebase";
import { setProviderProfile, updateProviderProfile } from "./profile";
import { ROUTES } from "../../constants/routes";
import {
  ErrorTypeAPI,
  RegisteredReturnType,
  RegisterUserValues
} from "../../constants/types";
import moment from "moment";

export interface AuthError {
  message: string;
}

export interface AuthState {
  isAuth: boolean;
  currentUser: any;
  currentIndex: number;
  authorizationKey: string;
  providerId: string;
  hasbeenAuthorized: boolean;
  isResettingPassword: boolean;
  hasResetPassword: boolean;
  resetPasswordError: AuthError;
  confirmPasswordLoading: boolean;
  confirmPasswordSuccess: boolean;
  confirmPasswordError: AuthError;
  integrationDocuments: [];
  apiKeys: any[];
  isLoading: boolean;
  isProfileLoading: boolean;
  updateProfileLoading: boolean;
  isIntegrationDocumentsLoading: boolean;
  updateIntegrationDocumentsLoading: boolean;
  isAccountActivationLoading: boolean;
  isAPIKeysLoading: boolean;
  error: AuthError;
  profileError: AuthError;
  updateProfileError: AuthError;
  apiKeysError: AuthError;
  integrationDocumentsError: AuthError;
  updateIntegrationDocumentsError: AuthError;
  isAccountActivationError: AuthError;
}

export const initialState: AuthState = {
  isAuth: false,
  isLoading: false,
  currentUser: null,
  authorizationKey: "",
  providerId: "",
  hasbeenAuthorized: false,
  currentIndex: 0,
  isResettingPassword: false,
  hasResetPassword: false,
  resetPasswordError: { message: "" },
  confirmPasswordLoading: false,
  confirmPasswordSuccess: false,
  confirmPasswordError: { message: "" },
  integrationDocuments: [],
  apiKeys: [],
  isProfileLoading: false,
  updateProfileLoading: false,
  isIntegrationDocumentsLoading: false,
  updateIntegrationDocumentsLoading: false,
  isAccountActivationLoading: false,
  isAPIKeysLoading: false,
  error: { message: "" },
  profileError: { message: "" },
  updateProfileError: { message: "" },
  apiKeysError: { message: "" },
  integrationDocumentsError: { message: "" },
  updateIntegrationDocumentsError: { message: "" },
  isAccountActivationError: { message: "" }
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setAuthSuccess: (state, payload: PayloadAction<any>) => {
      state.currentUser = payload;
      state.isAuth = true;
    },
    setAuthorizedSatus: (state, { payload }: PayloadAction<boolean>) => {
      state.hasbeenAuthorized = payload;
    },
    setCreateAccountCredentials: (state, { payload }: PayloadAction<any>) => {
      state.authorizationKey = payload.authenticationKey;
      state.providerId = payload.providerID;
    },
    setCurrentIndex: (state, { payload }: PayloadAction<string>) => {
      switch (payload) {
        case "add":
          state.currentIndex = state.currentIndex + 1;
          break;

        default:
          state.currentIndex = state.currentIndex - 1;
          break;
      }
    },
    setLogOut: (state) => {
      state.isAuth = false;
      state.hasbeenAuthorized = false;
      state.currentUser = undefined;
    },
    setAuthFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.error = payload;
      state.isAuth = false;
    },
    setPasswordResetLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isResettingPassword = payload;
    },
    hasResetPassword: (state, { payload }: PayloadAction<boolean>) => {
      state.hasResetPassword = payload;
    },
    resetPasswordFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.resetPasswordError = payload;
    },
    setConfirmPasswordLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.confirmPasswordLoading = payload;
    },
    setConfirmPasswordSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.confirmPasswordSuccess = payload;
    },
    setConfirmPasswordError: (state, { payload }: PayloadAction<AuthError>) => {
      state.confirmPasswordError = payload;
    },
    fetchProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isProfileLoading = payload;
    },
    fetchProfileSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    fetchProfileFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.profileError = payload;
    },
    updateProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.updateProfileLoading = payload;
    },
    updateProfileSuccess: (state, { payload }: PayloadAction<any>) => {
      state.currentUser = payload;
    },
    updateProfileFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.updateProfileError = payload;
    },
    updateIntegrationDocumentsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.updateIntegrationDocumentsLoading = payload;
    },
    updateIntegrationDocumentsSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      // state.integrationDocuments = payload;
    },
    updateIntegrationDocumentsFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.updateIntegrationDocumentsError = payload;
    },
    fetchIntegrationDocumentsLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isIntegrationDocumentsLoading = payload;
    },
    fetchIntegrationDocumentsSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {
      state.integrationDocuments = payload;
    },
    fetchIntegrationDocumentsFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.integrationDocumentsError = payload;
    },
    fetchAPIKeysLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isAPIKeysLoading = payload;
    },
    fetchAPIKeysSuccess: (state, { payload }: PayloadAction<any>) => {
      state.apiKeys = payload;
    },
    fetchAPIKeysFailed: (state, { payload }: PayloadAction<AuthError>) => {
      state.apiKeysError = payload;
    },
    requestAccountActivationLoading: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAccountActivationLoading = payload;
    },
    requestAccountActivationSuccess: (
      state,
      { payload }: PayloadAction<any>
    ) => {},
    requestAccountActivationFailed: (
      state,
      { payload }: PayloadAction<AuthError>
    ) => {
      state.isAccountActivationError = payload;
    }
  }
});

export const {
  setAuthSuccess,
  setLogOut,
  setLoading,
  setCurrentIndex,
  setAuthFailed,
  setPasswordResetLoading,
  resetPasswordFailed,
  hasResetPassword,
  setConfirmPasswordLoading,
  setConfirmPasswordSuccess,
  setConfirmPasswordError,
  fetchProfileLoading,
  fetchProfileSuccess,
  setAuthorizedSatus,
  setCreateAccountCredentials,
  fetchProfileFailed,
  updateProfileLoading,
  updateProfileSuccess,
  updateProfileFailed,
  fetchAPIKeysLoading,
  fetchAPIKeysSuccess,
  fetchAPIKeysFailed,
  updateIntegrationDocumentsLoading,
  updateIntegrationDocumentsSuccess,
  updateIntegrationDocumentsFailed,
  fetchIntegrationDocumentsLoading,
  fetchIntegrationDocumentsSuccess,
  fetchIntegrationDocumentsFailed,
  requestAccountActivationLoading,
  requestAccountActivationSuccess,
  requestAccountActivationFailed
} = authSlice.actions;
export const authSelector = (state: { auth: AuthState }) => state.auth;
export default authSlice.reducer;

/** Actions */

export const authoriseProvider = (): AppThunk => async (dispatch) => {
  const user = JSON.parse(window.localStorage.getItem("user")!);
  const accessKey = window.localStorage.getItem("accessKey") || "";

  firebaseAuth
    .signInAnonymously()
    .then(() => {
      db.collection("users")
        .add({
          id: user.id,
          accessKey,
          email: user.email
        })
        .then(() => {
          window.localStorage.setItem("isAuthorized", "true");
          AntdMessage.success("Registration successful!");
          window.location.href = ROUTES.MANAGE_ACCOUNT;
        })
        .catch((err) => {
          const message = { message: err?.message };
          AntdMessage.error(err.message);
          dispatch(setAuthFailed(message));
        });
    })
    .catch((error: any) => {
      AntdMessage.error("Request failed. Please try again.");
    });

  dispatch(setLoading(false));
};

export const loginProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    firebaseAuth
      .signInWithEmailAndPassword(
        `${payload.email}`.toLowerCase(),
        payload.password
      )
      .then(async (userCredential: any) => {
        const query = await db
          .collection("users")
          .where("email", "==", payload.email)
          .get();

        if (!query.empty) {
          AntdMessage.success("Login successful!");
          const snapshot = query.docs[0];
          const data = snapshot.data();
          window.localStorage.setItem("providerID", data.id);
          window.localStorage.setItem("accessKey", data.accessKey);
          window.localStorage.setItem("isAuthorized", "true");
          window.location.href = "/overview";
          dispatch(setLoading(false));
        }
      })
      .catch((err) => {
        const message = {
          message: err?.message
        };
        dispatch(setAuthFailed(message));
        dispatch(setLoading(false));
      });
  };

export const verifyProvider = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  await http
    .get("/services/providers/profile")
    .then(async (res) => {
      const data = res.data.data;

      firebaseAuth
        .signInAnonymously()
        .then(async () => {
          const providerStoredId = await db
            .collection("users")
            .where("id", "==", data.id)
            .get();
          if (!providerStoredId.empty) {
            dispatch(setLoading(false));
            AntdMessage.error("Provider already exists!");
            firebaseAuth.signOut();
          } else {
            firebaseAuth.signOut();
            dispatch(setLoading(false));
            AntdMessage.success("Verification successful!");
            dispatch(setCurrentIndex("add"));
            dispatch(setProviderProfile(data));
            window.localStorage.setItem("user", JSON.stringify(data));
          }
        })
        .catch((error: any) => {
          AntdMessage.error("Request failed. Please try again.");
        });
    })
    .catch((err) => {
      console.log(err);
      err?.response?.status === 401 &&
        AntdMessage.error("Invalid authentication key!");
      dispatch(setLoading(false));
    });
};

export const registerProvider =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    const user = JSON.parse(window.localStorage.getItem("user")!);
    const accessKey = window.localStorage.getItem("accessKey")!;
    firebaseAuth
      .createUserWithEmailAndPassword(
        `${payload.email}`.toLowerCase(),
        payload.password
      )
      .then(() => {
        db.collection("users")
          .add({
            id: user.id,
            accessKey,
            email: user.email,
            subscription: {
              plan: "trial",
              duration: "monthly",
              due_date: moment().add(14, "days").format("MMM Do, YYYY")
            }
          })
          .then(() => {
            dispatch(setAuthSuccess(user));
            dispatch(setLoading(false));
            window.localStorage.setItem("isAuthorized", "true");
            AntdMessage.success("Registration successful!");
            window.location.href = ROUTES.MANAGE_ACCOUNT;
          })
          .catch((err) => {
            const message = { message: err?.message };
            AntdMessage.error(err?.message);
            dispatch(setAuthFailed(message));
          });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        const message = { message: err?.message };
        AntdMessage.error(err?.message);
        dispatch(setAuthFailed(message));
      });
  };

export const resetPassword =
  (payload: string): AppThunk =>
  async (dispatch) => {
    dispatch(setPasswordResetLoading(true));
    firebaseAuth
      .sendPasswordResetEmail(payload)
      .then(() => {
        dispatch(hasResetPassword(true));
        dispatch(setPasswordResetLoading(false));
      })
      .catch((err) => {
        const message = { message: err?.message };
        AntdMessage.error(err?.message);
        dispatch(resetPasswordFailed(message));
        dispatch(setPasswordResetLoading(false));
      });
  };

export const updateAuthEmail =
  (payload: object | any): AppThunk =>
  async (dispatch) => {
    dispatch(setConfirmPasswordLoading(true));
    const { password, ...restOfResponse } = payload;
    const user = firebaseAuth.currentUser!;

    const { email: oldEmail } = user;
    firebaseAuth
      .signInWithEmailAndPassword(oldEmail!, password)
      .then(() => user.updateEmail(payload.email))
      .then(async () => {
        const providerEmail = await db
          .collection("users")
          .where("email", "==", oldEmail)
          .get();
        if (!providerEmail.empty) {
          const snapshot = providerEmail.docs[0];
          snapshot.ref.update({ email: payload.email });
        }
      })
      .then(() => {
        dispatch(setConfirmPasswordLoading(false));
        dispatch(setConfirmPasswordSuccess(true));
        dispatch(updateProviderProfile(restOfResponse));
      })
      .catch((err: object | any) => {
        dispatch(setConfirmPasswordLoading(false));
        const errMessage = { message: err.message };
        AntdMessage.error(
          err.code === "auth/wrong-password"
            ? "Invalid password!"
            : err.code === "auth/too-many-requests"
            ? "Too many failed attempts! Update your profile without the email field or try again later."
            : err.message
        );
        dispatch(setConfirmPasswordError(errMessage));
      })
      .finally(() => {
        dispatch(setConfirmPasswordLoading(false));
      });
  };

export const logOutProvider = (): AppThunk => async (dispatch) => {
  firebaseAuth
    .signOut()
    .then(() => {
      dispatch(setLogOut());
      window.localStorage.clear();
      window.location.replace("/");
    })
    .catch((err) => {
      AntdMessage.error("Network error. Please try again.");
    });
};

/*** UNUSED BUT BREAKS ALOT OF CODE IF DELETED... For now ***/

export const registerLaboratory =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(setLoading(true));
    await http
      .post("/laboratories/register", payload)
      .then((res) => {
        AntdMessage.success("Registration successful!");
        // window.location.href = "/overview";
        dispatch(setAuthorizedSatus(true));
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = { message: err?.response?.data?.message };
        dispatch(setAuthFailed(message));
      });
    dispatch(setLoading(false));
  };

export const getAPIKeys =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchAPIKeysLoading(true));
    const accessToken = window.localStorage.getItem("accessToken");
    const currentUser = JSON.parse(
      window.localStorage.getItem("pneumaCurrentLab") || ""
    );
    if (currentUser) {
      await http
        .post(
          `/integration/${currentUser?.integration}/keys`,
          { password: payload?.password },
          {
            headers: { Authorization: `Bearer ${accessToken}` }
          }
        )
        .then((keysRes) => {
          const keys = keysRes?.data?.data;
          dispatch(fetchAPIKeysSuccess(keys));
        })
        .catch((err) => {
          const message = {
            message: err?.response?.data?.message || err?.response?.data?.error
          };
          dispatch(fetchAPIKeysFailed(message));
        });
    }
    dispatch(fetchAPIKeysLoading(false));
  };

export const fetchProfile = (): AppThunk => async (dispatch) => {
  dispatch(fetchProfileLoading(true));
  await httpWithJWT
    .get("/laboratories/profile")
    .then((res) => {
      const currentUser = res?.data?.data;
      dispatch(fetchProfileSuccess(currentUser));
      window.localStorage.setItem(
        "pneumaCurrentLab",
        JSON.stringify(currentUser)
      );
    })
    .catch((err) => {
      console.log("err..", err?.response?.data);
      const message = {
        message: err?.response?.data?.message || err?.response?.data?.error
      };
      dispatch(fetchProfileFailed(message));
    });
  dispatch(fetchProfileLoading(false));
};

export const updateIntegrationProfile =
  (id: string | number, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateProfileLoading(true));
    await httpWithJWT
      .post(`/integrations/${id}`, payload)
      .then((res) => {
        const currentUser = res?.data?.data || {};
        dispatch(updateProfileSuccess(currentUser));
        AntdMessage.success("Business profile updated");
        dispatch(fetchProfile());
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const uploadIntegrationDocument =
  (integrationId: any, payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(updateIntegrationDocumentsLoading(true));
    await httpWithJWT
      .post(`/integrations/${integrationId}/upload-document`, payload)
      .then((res) => {
        const document = res?.data?.data || [];
        dispatch(updateIntegrationDocumentsSuccess(document));
        AntdMessage.success("Document Uploaded Successfully");
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(updateProfileFailed(message));
      });
    dispatch(updateProfileLoading(false));
  };

export const fetchIntegrationDocuments =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchIntegrationDocumentsLoading(true));
    await httpWithJWT
      .get(`/integrations/${id}/documents`)
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        dispatch(fetchIntegrationDocumentsSuccess(documents));
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        dispatch(fetchIntegrationDocumentsFailed(message));
      });
    dispatch(fetchIntegrationDocumentsLoading(false));
  };

export const requestActivation =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(requestAccountActivationLoading(true));
    await httpWithJWT
      .post(`/integrations/${id}/request-activation`)
      .then((res) => {
        const documents = res?.data?.data?.documents || [];
        dispatch(requestAccountActivationSuccess(documents));
        AntdMessage.success("Account Activation Requested");
      })
      .catch((err) => {
        console.log("err..", err?.response?.data);
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        AntdMessage.error(
          err?.response?.data?.message || err?.response?.data?.error
        );
        dispatch(requestAccountActivationFailed(message));
      });
    dispatch(requestAccountActivationLoading(false));
  };
