import { useContext, useEffect, useState } from "react";
import { Col, Row, Radio } from "antd";

import { UpdateSubContext } from "context/UpdateSubscriptionContext";
import { RadioGroup } from "screens/Profile/styles";
import Subscriptions, { prices, paymentPages } from "constants/subscriptions";
import Button from "component/Button";
import { useAppSelector } from "redux/store";

import { ModalWrapper } from "./styles";

import { ReactComponent as CheckIcon } from "assets/icons/check-icon.svg";
import { capitaliseFirstLetter } from "utils/utils";

const UpdateSubscriptionModal = () => {
  const { duration, plan } = useAppSelector((state) => state.subscription);
  const { handleShowModal, showUpdateSubModal, attemptedFeature } =
    useContext(UpdateSubContext);

  const savedDuration = localStorage.getItem("subscriptionDuration")!;

  const [selectedPricing, setSelectedPricing] = useState<
    "monthly" | "quarterly"
  >("monthly");

  useEffect(() => {
    if (duration) {
      setSelectedPricing(duration as "monthly" | "quarterly");
    }
  }, [duration]);

  return (
    <ModalWrapper
      title={null}
      visible={showUpdateSubModal}
      footer={null}
      onCancel={() => handleShowModal(false)}
      okText='Create'
      className='subscriptions-modal'
    >
      {attemptedFeature ? (
        <>
          <h2>
            Unfortunately! you can't access this feature on the{" "}
            <span>{capitaliseFirstLetter(plan)}</span> plan
          </h2>
          <p>
            You need to upgrade to get access to the {attemptedFeature} feature
          </p>
        </>
      ) : (
        <h2>Welcome! Upgrade your Frontdesk Account</h2>
      )}{" "}
      <div className='plans-wrapper'>
        <Row gutter={24} justify='center'>
          <Col
            xs={24}
            lg={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <RadioGroup
              name='radiogroup'
              defaultValue={savedDuration || selectedPricing}
              onChange={(e) => {
                setSelectedPricing(e.target.value);
              }}
            >
              <Radio value={"monthly"}>Monthly</Radio>

              <Radio value={"quarterly"}>Quarterly</Radio>
            </RadioGroup>
          </Col>
        </Row>

        <div className='plans'>
          {Subscriptions.map((singleSub) => (
            <div
              className={`single-plan ${
                plan === singleSub.name.toLowerCase() ? "is-active" : ""
              }`}
              key={singleSub.name}
            >
              <h5>
                <img src={singleSub.icon} alt={singleSub.name} />{" "}
                {singleSub.name}
              </h5>
              {selectedPricing ? (
                <h4>
                  NGN
                  {new Intl.NumberFormat("en-US").format(
                    prices[selectedPricing][
                      singleSub.name.toLowerCase() as
                        | "start"
                        | "grow"
                        | "engage"
                    ]
                  )}
                  <span>
                    /{selectedPricing === "monthly" ? "month" : "quarter"}
                  </span>
                </h4>
              ) : null}
              <p>{singleSub.description}</p>

              <Button
                type='primary'
                className='btn-wrapper'
                onClick={() => {
                  localStorage.setItem("subscriptionDuration", selectedPricing);
                  window.location.replace(
                    paymentPages[selectedPricing][
                      singleSub.name.toLowerCase() as
                        | "start"
                        | "grow"
                        | "engage"
                    ]
                  );
                }}
                disabled={plan === singleSub.name.toLowerCase()}
              >
                {plan === singleSub.name.toLowerCase()
                  ? "Current plan"
                  : "Upgrade"}
              </Button>

              <div className='benefits'>
                <h6>This plan includes</h6>
                <ul>
                  {singleSub.benefits.map((item, idx) => (
                    <li key={item + idx}>
                      <CheckIcon />
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalWrapper>
  );
};

export default UpdateSubscriptionModal;
