import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Form, message as AntdMessage } from "antd";
import Button from "../Button";
import { TextArea, StyleModal } from "./style";
import { useCreateNoteMutation } from "redux/queries/notes";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../redux/store";
import { userSelector } from "../../redux/reducers/users";
type CreateNote = {
  isModalOpen: boolean;
  onClose: () => void;
};

const CreateNoteModal = ({ isModalOpen, onClose }: CreateNote) => {
  const { patientId }: { patientId: string } = useParams();
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [noteVal, setNoteVal] = useState("");
  const { users } = useAppSelector(userSelector);
  const [addNote] = useCreateNoteMutation();
  const [sendMsgForm] = Form.useForm();
  const selectedUser = users.find((user) => user.id === patientId);

  async function createNote() {
    setIsUpdateLoading(true);
    try {
      await addNote({
        user: patientId,
        note: noteVal,
      }).unwrap();
      onClose();
      AntdMessage.success("Note(s) saved");
      sendMsgForm.resetFields();
    } catch (err) {
      AntdMessage.error("An error occurred. Please try again...");
    } finally {
      setIsUpdateLoading(false);
    }
  }
  useEffect(() => {
    if (selectedUser) {
      setNoteVal("");
    }
  }, [selectedUser]);
  return (
    <StyleModal
      title="Create note"
      okText="Submit"
      footer={null}
      visible={isModalOpen}
      onCancel={onClose}
      style={{ borderRadius: "120px" }}
    >
      <Form
        name="basicForm"
        layout="vertical"
        form={sendMsgForm}
        onFinish={createNote}
      >
        <Row gutter={24}>
          <Col span={24}>
            <TextArea
              formItem={{
                name: "notes",

                rules: [
                  {
                    required: true,
                    message: "Please ........",
                  },
                ],
              }}
              mode="normal"
              autoSize={{ minRows: 6, maxRows: 5 }}
              placeholder={"Write note here..."}
              onChange={(e: any) => {
                setNoteVal(e.target.value);
              }}
              initialvalue={noteVal}
            />
          </Col>
        </Row>

        <Row
          justify="space-between"
          style={{
            marginTop: "20px",

            width: "100%",
            display: "flex",
          }}
        >
          <Button
            type="secondary"
            style={{
              width: 198,
              height: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            disabled={isUpdateLoading}
            style={{ width: 198, height: 48 }}
          >
            {isUpdateLoading ? "Creating" : "Create note"}
          </Button>
        </Row>
      </Form>
    </StyleModal>
  );
};

export default CreateNoteModal;
