import React, { useEffect } from "react";
import { ContentWrapper, FormsWrapper } from "./styles";
import FormsTable from "./container/FormsTable";
const Forms = () => {
  useEffect(() => {
    document.title = "FrontDesk | Forms";
  }, []);

  return (
    <FormsWrapper>
      <ContentWrapper>
        <FormsTable />
      </ContentWrapper>
    </FormsWrapper>
  );
};

export default Forms;
