import { Modal, message as AntdMessage, Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { UnsplashRes } from "constants/types";
import { useEffect, useState } from "react";

import { useAppSelector } from "redux/store";
import { profileSelector } from "redux/reducers/profile";

import { getHealthCarePhotos } from "utils/connect-unsplash";

interface GalleryModalProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  setCoverURL: (url: string) => void;
}

const GalleryModal = ({
  isModalVisible,
  handleCancel,
  setCoverURL
}: GalleryModalProps) => {
  const { profile: providerProfile } = useAppSelector(profileSelector);

  const [isLoading, setIsLoading] = useState(false);
  const [photos, setPhotos] = useState<UnsplashRes["results"]>();

  useEffect(() => {
    async function fetch() {
      setIsLoading(true);
      try {
        const res = await getHealthCarePhotos(providerProfile?.type);
        const typedRes = res.data as UnsplashRes;

        setPhotos(typedRes.results);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    }

    fetch();
  }, [providerProfile?.type]);

  return (
    <Modal
      title={null}
      footer={null}
      visible={isModalVisible}
      onCancel={handleCancel}
      className='gallery-modal'
    >
      <div className='header'>
        <h2>Gallery</h2>
        <p>Select any image as your PneumaPage cover photo</p>
      </div>

      {isLoading ? (
        <p className='loading'>loading...</p>
      ) : (
        <div className='gallery-grid'>
          {photos?.map((singlePhoto) => (
            <div key={singlePhoto.id} className='single-image-wrapper'>
              <img
                src={singlePhoto.urls.small}
                alt={singlePhoto.alt_description}
                onClick={() => {
                  setCoverURL(singlePhoto.urls.full);
                  AntdMessage.success(
                    "Image successfully selected. Please submit to save changes."
                  );
                  handleCancel();
                }}
              />
              <span>
                <Tooltip
                  title={
                    <small style={{ fontSize: "13px" }}>
                      Photo by{" "}
                      <a
                        href={singlePhoto.user.links.html}
                        target='_blank'
                        rel='noreferrer'
                      >
                        {singlePhoto.user.name}
                      </a>{" "}
                      on{" "}
                      <a
                        href='https://unsplash.com/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Unsplash
                      </a>
                    </small>
                  }
                  placement='topRight'
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
            </div>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default GalleryModal;
