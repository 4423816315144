import React, { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import { ContentWrapper, ResponseWrapper } from "./styles";
import ResponsesTable from "./ResponsesTable";
import { ROUTES } from "../../../../constants/routes";
import {
  useGetAllServiceFormsResponseQuery,
  useGetSingleServiceFormQuery,
} from "../../../../redux/queries/service-forms";
import Loader from "../../../../component/Loader";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";

const Responses = () => {
  const { questionId }: { questionId: string } = useParams();
  const { data, isLoading, error } = useGetAllServiceFormsResponseQuery();
  const responses = (data?.data as any) || [];
  const singleReposnses = responses?.filter(
    (response: any) => response?.form?.id === questionId,
  );

  if (isLoading) {
    return <Loader background="transparent" />;
  }

  return (
    <ResponseWrapper>
      <ContentWrapper>
        <NavLink to={ROUTES.FORMS} className="return-btn">
          <ArrowLeftIcon />
          <span>Back</span>
        </NavLink>
        <ResponsesTable questions={singleReposnses} isLoading={isLoading} />
      </ContentWrapper>
    </ResponseWrapper>
  );
};

export default Responses;
