export const ROUTES = Object.freeze({
  HOMEPAGE: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  TERMS_AND_CONDITIONS: "/terms-conditions",
  RECOVER_PASSWORD: "/password/recover",
  RESET_PASSWORD: "/password/reset",
  REQUEST_OTP: "/otp/request",
  ENTER_OTP: "/otp/enter",
  // Dashboard Screens
  DASHBOARD: "/overview",
  INVESTIGATIONS: "/my-services",
  PATIENT_ENCOUNTERS: "/encounters/:patientId",
  USERS: "/users",
  TRANSACTIONS: "/claims",
  REVIEWS_RATINGS: "/reviews",
  FOLLOW_UPS: "/follow-ups",
  MESSAGES: "/messages",
  MESSAGES_DETAILS: "/messages/:patientId",
  PATIENTS: "/patients",
  PATIENTS_DETAILS: "/patients/:patientId",
  MORE_DETAILS: "/patients/:patientId/more_details",
  EDIT_DETAILS: "/patients/:patientId/edit_details",
  MANAGE_ACCOUNT: "/manage-account",
  PROFILE: "/profile",
  CLAIMS: "/payments",
  LAB_INVESTIGATIONS: "/service-requests",
  PAYMENTS: "/payments",
  PRACTITIONERS: "/practitioners",
  LEADS: "/leads",
  FORMS: "/forms",
  CREATE_FORMS: "/forms/create-form",
  RESPONSES: "/responses/:questionId"
});
