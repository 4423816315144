import React, { useState } from "react";
import { Col, Form, Row, Select as AntdSelect, Typography } from "antd";
import { FormDetailsWrapper, UploadWrapper } from "./styles";
import Card from "../../../../component/Card";
import Input from "../../../../component/Input";
import Select from "../../../../component/Select";
import TextArea from "../../../../component/TextArea";
import Button from "../../../../component/Button";
import { ReactComponent as ImageIcon } from "assets/icons/Image.svg";
import { capitaliseFirstLetter } from "../../../../utils/utils";
import axios, { AxiosResponse } from "axios";

type Props = {
  setIconUrl: (url: string | null) => void;
  iconUrl: string | null;
};

const { Text } = Typography;
function FormDetails({ setIconUrl, iconUrl }: Props) {
  const [textAreaValue, setTextAreaValue] = useState("");

  const handleChange = (e: any) => {
    const inputValue = e.target.value;
    setTextAreaValue(inputValue);
  };

  const getWarningMessage = () => {
    const maxLength = 300;

    if (textAreaValue.length > maxLength) {
      const exceededChars = textAreaValue.length - maxLength;
      return `Warning: ${exceededChars} characters over the limit.`;
    }

    return null;
  };

  const customRequest = async ({
    file,
    onSuccess,
    onError
  }: {
    file: File;
    onSuccess: (response: AxiosResponse, file: File) => void;
    onError: (error: Error | string) => void;
  }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", "my_uploads"); // Replace with your Cloudinary preset name

      const response = await axios.post(
        "https://api.cloudinary.com/v1_1/dtczskyoz/image/upload", // Replace with your Cloudinary cloud name
        formData,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest"
          }
        }
      );

      // Check for successful upload (status code 200)
      if (response.status === 200) {
        const secureUrl = response.data.secure_url;
        setIconUrl(secureUrl);
        onSuccess(response.data, file);
      } else {
        onError("Upload failed");
      }
    } catch (error: any) {
      onError(error.message || "Upload failed");
    }
  };

  return (
    <FormDetailsWrapper>
      <Card padding={35}>
        <Row gutter={18}>
          <Col xs={24} md={12}>
            <Input
              mode='normal'
              formItem={{
                name: "name",
                label: "Form name",
                rules: [
                  {
                    required: true,
                    message: "Please input the form name"
                  }
                ]
              }}
            />
          </Col>
          <Col xs={24} md={6}>
            <Select
              mode='normal'
              placeholder='Select category'
              formItem={{
                name: "category",
                label: "Category",
                rules: [
                  {
                    required: true,
                    message: "Please select a category"
                  }
                ]
              }}
            >
              {Object.keys(Categories).map((key) => (
                <AntdSelect key={Categories[key]} value={Categories[key]}>
                  {capitaliseFirstLetter(key.replace(/_/g, " "))}
                </AntdSelect>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={6}>
            <Select
              mode='normal'
              placeholder='Select specialty'
              formItem={{
                name: "specialty",
                label: "Specialty",
                rules: [
                  {
                    required: true,
                    message: "Please select a specialty"
                  }
                ]
              }}
            >
              {Object.keys(Specialties).map((key) => (
                <AntdSelect.Option
                  key={Specialties[key]}
                  value={Specialties[key]}
                >
                  {capitaliseFirstLetter(key.replace(/_/g, " "))}
                </AntdSelect.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <TextArea
              showCount
              formItem={{
                name: "description",
                label: "Form Description "
              }}
              rows={2}
              onChange={handleChange}
              maxLength={300}
              style={{ resize: "none" }}
            />
            <p className='warning'>
              Maximum character should not be more than 300
            </p>
          </Col>

          <Col xs={24}>
            <Form.Item>
              <UploadWrapper
                //@ts-ignore
                customRequest={customRequest}
                showUploadList={true}
                onRemove={(file: any) => {
                  setIconUrl(null);
                }}
              >
                {iconUrl ? null : (
                  <div className='profile-wrapper'>
                    <ImageIcon />
                    <Button>Click to upload icon/Image</Button>
                  </div>
                )}
              </UploadWrapper>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </FormDetailsWrapper>
  );
}

export default FormDetails;

export const Specialties: any = {
  GENERAL: "general",
  SURGERY: "surgery",
  ANAESTHESIA: "anaesthesia",
  DERMATOLOGY: "dermatology",
  DENTISTRY: "dentistry",
  EMERGENCY_MEDICINE: "emergency_medicine",
  CARDIAC_SURGERY: "cardiac_surgery",
  FAMILY_MEDICINE: "family_medicine",
  INTERNAL_MEDICINE: "internal_medicine",
  NEUROLOGY: "neurology",
  OBSTETRICS_AND_GYNECOLOGY: "o_and_g",
  OPTOMETRY: "optometry",
  OPHTHALMOLOGY: "ophthalmology",
  ORTHOPEDIC_SURGERY: "orthopedic_surgery",
  OTOLARYNGOLOGY: "otolaryngology",
  ORAL_AND_MAXILLOFACIAL_SURGERY: "oral_and_maxillofacial_surgery",
  PAEDIATRICS: "paediatrics",
  PODIATRY: "podiatry",
  PSYCHIATRY: "psychiatry",
  RADIOLOGY: "radiology",
  UROLOGY: "urology",
  NEUROSURGERY: "neurosurgery",
  PLASTIC_SURGERY: "plastic_surgery",
  GASTROENTEROLOGY: "gastroenterology",
  PULMONOLOGY: "pulmonology"
};

export const Categories: any = {
  GENERAL: "general",
  MEN_HEALTH: "men_health",
  WOMEN_HEALTH: "women_health",
  ALLERGIES: "allergies",
  INFECTIONS: "infections",
  MENTAL_HEALTH: "mental_health",
  GASTROINTESTINAL: "gastrointestinal",
  CHRONIC_ILLNESS_MANAGEMENT: "chronic_illness_management",
  SPECIALIST_CARE: "specialist_care",
  SEXUAL_HEALTH: "sexual_health",
  ANTENATAL: "antenatal",
  POSTNATAL: "postnatal",
  SKIN_CARE: "skin_care",
  GERIATRICS: "geriatrics"
};
