import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { uid } from "uid";

import { db as firebaseDb } from "utils/firebase";

export interface SubscriptionState {
  due_date: string;
  plan: string;
  duration: string;
  history: {}[];
}

const initialState: SubscriptionState = {
  due_date: "",
  plan: "",
  duration: "",
  history: []
};

export const fetchSubscription = createAsyncThunk<SubscriptionState, void>(
  "subscription",
  async (req, thunkApi) => {
    try {
      const localStoreProviderID = localStorage.getItem("providerID")!;

      const subscriptionRef = await firebaseDb
        .collection("users")
        .where("id", "==", localStoreProviderID)
        .get();

      const subscriberInfo = subscriptionRef.docs[0].data()
        .subscription as SubscriptionState;

      // handle expired subs & new users here
      if (
        !subscriberInfo ||
        moment().isAfter(moment(subscriberInfo.due_date, "MMM Do, YYYY"), "day")
      ) {
        thunkApi.dispatch(
          updateSubscription({
            providerId: localStoreProviderID,
            due_date: moment().add(30, "days").format("MMM Do, YYYY"),
            plan: "freemium",
            duration: "monthly"
          })
        );
      }
      return subscriberInfo;
    } catch (err: any) {
      console.error(err);

      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

interface UpdateRes {
  due_date: string;
  plan: string;
  duration: string;
}

interface UpdatePayload {
  providerId: string;
  due_date: string;
  plan: string;
  duration: string;
}

export const updateSubscription = createAsyncThunk<UpdateRes, UpdatePayload>(
  "updateSubscription",
  async (req, thunkApi) => {
    try {
      await firebaseDb
        .collection("users")
        .where("id", "==", req.providerId)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            doc.ref.update({
              subscription: {
                due_date: req.due_date,
                plan: req.plan,
                duration: req.duration
              }
            });
          });
        });

      // push to provider's subscriptionHistory collection
      const historyId = uid();

      await firebaseDb
        .collection("subscriptionHistory")
        .doc(req.providerId)
        .collection("subscriptions")
        .doc(historyId)
        .set({
          id: historyId,
          createdAt: moment().format("MMM Do, YYYY"),
          due_date: req.due_date,
          plan: req.plan,
          duration: req.duration
        });

      return {
        due_date: req.due_date,
        plan: req.plan,
        duration: req.duration
      };
    } catch (err: any) {
      console.error(err);

      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

type HistoryRes = {}[];

export const fetchSubscriptionHistory = createAsyncThunk<
  HistoryRes,
  { providerId: string }
>("subscriptionHistory", async (req, thunkApi) => {
  try {
    const historyRef = await firebaseDb
      .collection("subscriptionHistory")
      .doc(req.providerId)
      .collection("subscriptions")
      .orderBy("createdAt", "desc")
      .get();

    const historyDocs: HistoryRes = [];
    historyRef.docs
      .filter(
        (item) =>
          item.data().plan === "grow" ||
          item.data().plan === "engage" ||
          item.data().plan === "start"
      )
      .map((item) => historyDocs.push(item.data()));

    return historyDocs;
  } catch (err: any) {
    console.error(err);

    return thunkApi.rejectWithValue({ error: err.message });
  }
});

export const subscriptionSlice = createSlice({
  name: "subscriptions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSubscription.fulfilled, (state, action) => {
      state.due_date = action.payload.due_date;
      state.duration = action.payload.duration;
      state.plan = action.payload.plan;
    });

    builder.addCase(updateSubscription.fulfilled, (state, action) => {
      state.due_date = action.payload.due_date;
      state.duration = action.payload.duration;
      state.plan = action.payload.plan;
    });

    builder.addCase(fetchSubscriptionHistory.fulfilled, (state, action) => {
      state.history = action.payload;
    });
  }
});
