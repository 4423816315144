import { useEffect, useLayoutEffect } from "react";

import { useAppSelector } from "redux/store";
import FollowUpsTable from "./containers/FollowUpsTable";
import { FollowUpsWrapper, ContentWrapper } from "./styles";

const Patients = () => {
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription,
  );

  // useLayoutEffect(() => {
  //   if (subscribedPlan === "freemium") {
  //     window.location.replace("/payments");
  //   }
  // }, [subscribedPlan]);

  useEffect(() => {
    document.title = "FrontDesk | Follow ups";
  }, []);

  return (
    <FollowUpsWrapper>
      <ContentWrapper>
        <FollowUpsTable />
      </ContentWrapper>
    </FollowUpsWrapper>
  );
};

export default Patients;
