import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 50px 20px;
  position: relative;
  width: 100%;

  @media (min-width: 768px) {
    padding: 40px 40px;
  }

  @media (min-width: 1024px) {
    padding: 40px 40px;
  }
`;

export const ReturnLink = styled(NavLink)`
  color: #0d0d0d;
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 10px 0 0;
  gap: 10px;

  span:first-of-type {
    font-size: 18px;
  }

  span:nth-of-type(2) {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    align-items: center;
  }

  @media (min-width: 1024px) {
    padding-left: 10px;
  }

  :hover {
    text-decoration: underline;
  }
`;

export const PatientInfo = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  margin-top: 32px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  gap: 10px;
  padding: 15px 20px;
  width: 100%;

  @media (min-width: 1024px) {
    flex-direction: row;
    padding: 50px 0px 20px;
    width: 100%;
    align-items: center;
    border: none;
    border-bottom: 1.5px solid #e4e7ec;
    box-shadow: none;
    margin: 0 auto;
    border-radius: 0;
  }

  .name-wrapper {
    display: flex;
    flex-direction: row;
    padding-right: 32px;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    margin-bottom: 16px;

    @media (min-width: 1024px) {
      border-right: 1.5px solid #e4e7ec;
      margin-bottom: 0;
      padding-right: 20px;
    }

    @media (min-width: 1200px) {
      padding-right: 32px;
    }

    @media (min-width: 1600px) {
      padding-right: 42px;
    }

    .img {
      display: flex;
      margin: 0;
      align-items: center;
      justify-content: center;
      height: 42px;
      width: 42px;
      background: #0faff0;
      border-radius: 50%;

      @media (min-width: 1200px) {
        height: 54px;
        width: 54px;
      }

      @media (min-width: 1600px) {
        height: 62px;
        width: 62px;
      }

      span {
        margin: 0px;
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 140%;
        color: #ffffff;
        text-transform: uppercase;

        @media (min-width: 1200px) {
          font-size: 28px;
        }
      }
    }

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 140%;
      color: #344054;
      margin: 0px;
      text-transform: capitalize;

      @media (min-width: 1200px) {
        font-size: 32px;
      }
    }
  }

  .contact-wrapper,
  .about-wrapper,
  .address-wrapper {
    display: flex;
    flex-direction: column;
    padding: 0 0 0 10px;
    margin-bottom: 10px;
    gap: 20px;

    @media (min-width: 1024px) {
      padding: 0 20px 0 10px;
      border-right: 1.5px solid #e4e7ec;
    }

    @media (min-width: 1200px) {
      padding: 0 24px 0 10px;
      border-right: 1.5px solid #e4e7ec;
    }

    @media (min-width: 1600px) {
      padding: 0 80px 0 20px;
      gap: 15px;
    }

    p {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0px;
      gap: 8px;
      margin: 0;

      span.anticon {
        width: 20px;
        font-size: 20px;
      }

      span.details {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 140%;
        color: #344054;
      }
    }
  }

  .address-wrapper {
    border-right: none;
  }

  .btn {
    display: flex;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    width: 100%;
    background: #e7f5fd;
    border-radius: 4px;
    margin-top: 42px;

    @media (min-width: 1024px) {
      justify-content: flex-end;
      align-items: flex-end;
      max-width: 121px;
      margin: 0;
      margin-left: auto;
    }

    @media (min-width: 1200px) {
      max-width: 191px;
    }

    a {
      text-align: center;
      width: 100%;
      font-size: 16px;
      white-space: pre;
      padding: 10px 18px;
      color: #0faff0;

      @media (max-width: 500px) {
        white-space: normal;
      }
    }
  }
`;

export const PatientDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -20px;
  margin-right: -20px;
  padding: 60px 20px;

  @media (min-width: 1024px) {
    margin-left: -20px;
    margin-right: -20px;
  }
  .count {
    width: 32px;
    height: 20px;
    padding: 2px 8px;
    font-size: 14px;
    border-radius: 100%;
  }
`;
