import styled from "styled-components";
import { Modal } from "antd";

export const ModalWrapper = styled(Modal)`
  width: 756px !important;
  height: 367px;
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px;
    border: none;
  }
  .ant-modal-body {
    padding: 0px;
    .forms {
      padding: 16px 24px 0px;
    }
  }

  .ant-modal-title {
    padding-right: 20px;

    h1 {
      color: #344054;
      font-family: DM Sans;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: 38px;
    }
    span {
      color: #667085;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
    }
  }

  .btn-wrapper {
    display: flex;
    width: 756px;
    padding: 21px 41px 20px 486px;
    justify-content: flex-end;
    align-items: center;
    gap: 25px;
    border-radius: 0px 0px 12px 12px;
    border-top: 1px solid #d0d5dd;
    background: #fff;
    height: 89px;

    button:nth-child(1) {
      span {
        color: var(--Pneuma-Blue, #0faff0);
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 125% */
        text-decoration-line: underline;
      }
    }
    button:nth-child(2) {
      display: flex;
      width: 140px;
      height: 48px;
      padding: 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
      flex-shrink: 0;
      border-radius: 4px;
      background: #0faff0;

      span {
        color: #fff;
        font-family: DM Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
      }
    }
  }
`;
