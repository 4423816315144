import { Modal, Form as AntdForm } from "antd";
import styled from "styled-components";

const DashboardWrapper = styled.div``;

const Container = styled.div`
  display: flex;
  z-index: 0;
`;

const MainWrapper = styled.section`
  height: calc(100vh - 57px);
  margin-top: 57px;
  width: calc(100% - 270px);
  margin-left: 270px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    width: calc(100%);
    margin-left: 0px;
  }
`;

const ModalWrapper = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px;
  }
  .ant-modal-title {
    padding-right: 20px;
  }

  .header {
    h3 {
      font-family: "DM Sans";
      font-weight: 700;
      font-size: 24px;
      line-height: 38px;
      color: #344054;
      margin: 0 0 4px;
    }

    p {
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: #667085;
      margin: 0;
    }
  }

  .otp-label {
    width: 100%;
    color: #002e46;
    font-size: 14px;
    span {
      color: #ff4d4f;
      position: relative;
      top: 3px;
    }
  }

  .row-icons {
    margin-bottom: 25px;
    h3 {
      width: 100%;
      margin-left: 11px;
      color: #002e46;
      font-size: 13px;
      span {
        color: #ff4d4f;
        position: relative;
        top: 3px;
      }
    }
    .ant-card-bordered {
      border: 1px solid #f0f0f0;
      box-shadow: none;
      transition: 0.3s;

      &:hover {
        color: #0f9af0;
      }
    }

    .ant-card-body {
      padding: 10px !important;
      cursor: pointer;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      justify-content: start;
    }

    svg {
      height: 30px;
      width: 30px;
      margin-right: 6px;
    }

    .icon-2 {
      margin-right: 12px;
    }

    .isCardActive {
      color: #0f9af0;
      border: 1px solid #f0f0f0 !important;
      border-radius: 8px;

      .ant-card-bordered {
        border: none !important;
        box-shadow: none !important;
      }

      .ant-card-body {
        border-radius: 8px;
        border: 1px solid #0f9af0 !important;
      }
    }
  }
`;

export const FormItem = styled(AntdForm.Item)`
  .ant-form-item-label {
    padding: 0;
    margin-bottom: 3px;
    font-weight: 500;
  }
`;

export { DashboardWrapper, Container, MainWrapper, ModalWrapper };
