import React from "react";
import { Wrapper } from "./style";

type MedicalProps = {
  user: any;
};
const MedicalHistory = ({ user: { medical_record } }: MedicalProps) => {
  console.log(medical_record);
  return (
    <Wrapper>
      <div className={"medical-info"}>
        <h1>Vital Information</h1>
        <div className="vital--info">
          <div className="top">
            <div>
              <h3>Blood Group</h3>
              {medical_record.blood_group ? (
                <p>{medical_record.blood_group}</p>
              ) : (
                <p>N/A</p>
              )}
            </div>
            <div>
              <h3>Genotype</h3>
              {medical_record.genotype ? (
                <p>{medical_record.genotype}</p>
              ) : (
                <p>N/A</p>
              )}
            </div>
            <div>
              <h3>Weight (kg)</h3>
              {medical_record.wieght ? (
                <p>{medical_record.wieght}</p>
              ) : (
                <p>N/A</p>
              )}
            </div>
            <div>
              <h3>Height (ft)</h3>
              {medical_record.height ? (
                <p>{medical_record.height}</p>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
          <div className="bottom">
            <div>
              <h3>How freqeuncy do you drink?</h3>
              {medical_record.drinking_habit ? (
                <p>{medical_record.drinking_habit}</p>
              ) : (
                <p>N/A</p>
              )}
            </div>
            <div>
              <h3>How freqeuncy do you smoke?</h3>
              {medical_record.smoking_habit ? (
                <p>{medical_record.smoking_habit}</p>
              ) : (
                <p>N/A</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"personal-record"}>
        <div className="content">
          <div className="allergies">
            <h1>Allergies</h1>
            <div>
              {medical_record?.allergies?.length ? (
                medical_record.allergies.map((item: any, index: number) => (
                  <span key={index}>{item}</span>
                ))
              ) : (
                <h1>N/A</h1>
              )}
            </div>
          </div>
          <div className="medications">
            <h1>Medications</h1>
            <div>
              {medical_record?.medications?.length ? (
                medical_record.medications.map((item: any, index: number) => (
                  <span key={index}>{item}</span>
                ))
              ) : (
                <h1>N/A</h1>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={"family--history"}>
        <h1>
          Family History {`(${medical_record?.family_medical_history?.length})`}
        </h1>
        {medical_record?.family_medical_history?.length ? (
          medical_record.family_medical_history.map(
            (item: any, index: number) => (
              <div className="conditions--1">
                <div className="conditions">
                  <h1>Conditions</h1>
                  <p>Abdominal pain</p>
                </div>
                <div className="family--members">
                  <h1>Member of Family</h1>
                  <div>
                    <span>Father</span>
                    <span>Mother</span>
                    <span>Sister</span>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <h1>N/A</h1>
        )}
      </div>
      <div className={"surgery-history"}>
        <h1>Surgery{`(${medical_record?.surgeries?.length})`}</h1>

        {medical_record?.surgeries?.map((item: any, index: number) => (
          <div className="surgeries">
            <div>
              <h3>Procedure</h3>
              {item.type ? <p>{item.type}</p> : <p>N/A</p>}
            </div>
            <div>
              <h3>Year</h3>
              {item.period ? <p>{item.period}</p> : <p>N/A</p>}
            </div>
          </div>
        ))}
      </div>
      <div className={"transfusion"}>
        <h1>
          Transfusion {`(${medical_record?.transfusion_history?.length})`}
        </h1>
        {medical_record.transfusion_history?.map((item: any, index: number) => (
          <div className="procedure">
            <div>
              <h3>Procedure</h3>
              {item.type ? <p>{item.type}</p> : <p>N/A</p>}
            </div>
            <div>
              <h3>Year</h3>
              {item.period ? <p>{item.period}</p> : <p>N/A</p>}
            </div>
          </div>
        ))}
      </div>
      <div className={"vaccination"}>
        <h1>Vaccination {`(${medical_record?.vaccinations?.length})`}</h1>
        {medical_record.vaccinations?.map((item: any, index: number) => (
          <div className="vaccine">
            <div>
              <h3>Procedure</h3>
              {item.type ? <p>{item.type}</p> : <p>N/A</p>}
            </div>
            <div>
              <h3>Year</h3>
              {item.period ? <p>{item.period}</p> : <p>N/A</p>}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default MedicalHistory;
