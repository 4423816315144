import { Form, Col, Row, message as AntdMessage } from "antd";
import { useEffect, useState } from "react";

import { ModalWrapper } from "layouts/DashboardLayout/styles";
import { debounce } from "utils/utils";
import TextArea from "component/TextArea";
import Button from "component/Button";
import { MixPanel } from "utils/mixpanel";

import {
  useSendSingleMessageMutation,
  useSendBulkMessagesMutation
} from "redux/queries/messages";
import { profileSelector } from "redux/reducers/profile";
import { useAppSelector } from "redux/store";
import { UserType } from "constants/types";

type SendSMSModalTypes = {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedUserID?: string;
  messageType?: "birthday" | "follow-up" | "reminder";
  selectedUsers?: UserType[];
};

function SendSMSModal({
  isModalVisible,
  handleCancel,
  selectedUserID,
  selectedUsers,
  messageType
}: SendSMSModalTypes) {
  const [isLoading, setIsLoading] = useState(false);

  const { profile: ProviderProfile } = useAppSelector(profileSelector);
  const [sendSingleMessageMutation] = useSendSingleMessageMutation();
  const [sendMessages] = useSendBulkMessagesMutation();

  const [messageVal, setMessageVal] = useState(
    `\n\n - ${ProviderProfile?.business_name}`
  );

  useEffect(() => {
    if (ProviderProfile) {
      setMessageVal(`\n\n - ${ProviderProfile.business_name}`);
    }
  }, [ProviderProfile]);

  const [wordCount, setWordCount] = useState(messageVal.length);

  const [sendMessageForm] = Form.useForm();

  async function sendMessage(values: any) {
    const _values = values as { message: string };

    setIsLoading(true);
    try {
      // run send single message if we have the user id else run bulk messages
      selectedUserID
        ? await sendSingleMessageMutation([
            {
              user: selectedUserID,
              message: _values.message
            }
          ]).unwrap()
        : await sendMessages({
            users: selectedUsers!.map((user) => user.id),
            message: _values.message
          }).unwrap();

      MixPanel.track("MessageSent", {
        messageType: messageType ?? "general",
        wordCount,
        patientId: selectedUserID!
      });
      AntdMessage.success("Message(s) sent successfully!");
      sendMessageForm.resetFields();
      setWordCount(0);
      handleCancel();
    } catch (err) {
      const _err = err as Error;
      AntdMessage.error(_err.message);
    } finally {
      setIsLoading(false);
    }
  }

  const delayWordCount = debounce(setWordCount, 150);

  return (
    <ModalWrapper
      title='Send SMS message'
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className='dashboard-mode-modal'
    >
      <Form
        name='sendMessageForm'
        layout='vertical'
        form={sendMessageForm}
        onFinish={sendMessage}
      >
        <Row gutter={24}>
          <Col span={24}>
            <TextArea
              formItem={{
                name: "message",
                label: `Message (${wordCount} of ${160} characters)`,
                rules: [
                  {
                    required: true,
                    message: "Please enter message"
                  }
                ]
              }}
              label=''
              onChange={(e: any) => {
                delayWordCount(e.target.value.length);
                setMessageVal(e.target.value);
              }}
              defaultValue={messageVal}
              mode='normal'
              maxLength={160}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>

          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button htmlType='submit' type='primary' disabled={isLoading}>
              {isLoading ? "Sending..." : "Send Message"}
            </Button>
          </Col>
        </Row>
      </Form>
    </ModalWrapper>
  );
}

export default SendSMSModal;
