import { useEffect } from "react";

import { ClaimsWrapper, ContentWrapper } from "./styles";
import PaymentTable from "./containers/PaymentTable";

const Claims = () => {
  useEffect(() => {
    document.title = "FrontDesk | Claims";
  }, []);

  return (
    <ClaimsWrapper>
      <ContentWrapper>
        <PaymentTable />
      </ContentWrapper>
    </ClaimsWrapper>
  );
};

export default Claims;
