export const nigerianStateAndCities = {
  Abia: [
    {
      id: 2606,
      name: "Aba"
    },
    {
      id: 2435,
      name: "Amaeke"
    },
    {
      id: 2433,
      name: "Arochukwu"
    },
    {
      id: 139,
      name: "IKWUANO"
    },
    {
      id: 2434,
      name: "Ohafia"
    },
    {
      id: 2608,
      name: "Umuahia"
    },
    {
      id: 303,
      name: "UMUDIKE"
    }
  ],
  Adamawa: [
    {
      id: 100,
      name: "GOMBI"
    },
    {
      id: 107,
      name: "HONG"
    },
    {
      id: 208,
      name: "MAYO BELWA"
    },
    {
      id: 217,
      name: "MUBI"
    },
    {
      id: 222,
      name: "NGURORE"
    },
    {
      id: 227,
      name: "NUMAN"
    },
    {
      id: 294,
      name: "SONG"
    },
    {
      id: 2679,
      name: "Yola"
    }
  ],
  "Akwa Ibom": [
    {
      id: 3,
      name: "ABAK"
    },
    {
      id: 79,
      name: "EKET"
    },
    {
      id: 88,
      name: "ETINAN"
    },
    {
      id: 134,
      name: "IKOT ABASSI"
    },
    {
      id: 135,
      name: "IKOT EKPENE"
    },
    {
      id: 136,
      name: "IKOT OSURA"
    },
    {
      id: 215,
      name: "MKPAT ENIN"
    },
    {
      id: 262,
      name: "ORON"
    },
    {
      id: 2880,
      name: "Uyo"
    }
  ],
  Anambra: [
    {
      id: 1150,
      name: "ABAGANA"
    },
    {
      id: 12,
      name: "AWKA"
    },
    {
      id: 2682,
      name: "Ayamelum"
    },
    {
      id: 2683,
      name: "Dunukofia"
    },
    {
      id: 1151,
      name: "EKWULOBIA"
    },
    {
      id: 2879,
      name: "Ihiala"
    },
    {
      id: 224,
      name: "NKPOR"
    },
    {
      id: 2289,
      name: "Nnewi"
    },
    {
      id: 2684,
      name: "Ogbaru"
    },
    {
      id: 325,
      name: "ONITSHA"
    },
    {
      id: 2685,
      name: "Oyi"
    }
  ],
  Bauchi: [
    {
      id: 36,
      name: "ALKALERI"
    },
    {
      id: 2910,
      name: "Awala"
    },
    {
      id: 2995,
      name: "Bauchi"
    },
    {
      id: 2996,
      name: "Darazo"
    },
    {
      id: 2914,
      name: "Fadamar Mada"
    },
    {
      id: 2913,
      name: "Gida Dubu"
    },
    {
      id: 2916,
      name: "Ibrahim Bako"
    },
    {
      id: 2920,
      name: "Katagum"
    },
    {
      id: 2909,
      name: "New GRA"
    },
    {
      id: 2919,
      name: "Old GRA"
    },
    {
      id: 2912,
      name: "Wunti"
    },
    {
      id: 2918,
      name: "Yan Doka"
    },
    {
      id: 2911,
      name: "Yelwa"
    }
  ],
  Bayelsa: [
    {
      id: 39,
      name: "AMASSOMA"
    },
    {
      id: 67,
      name: "BRASS"
    },
    {
      id: 2687,
      name: "Ekeremor"
    },
    {
      id: 184,
      name: "KIAMA"
    },
    {
      id: 2688,
      name: "Kolokuma"
    },
    {
      id: 2689,
      name: "Nembe"
    },
    {
      id: 2690,
      name: "Ogbia"
    },
    {
      id: 1040,
      name: "OTUOKE"
    },
    {
      id: 282,
      name: "SAGBAMA"
    },
    {
      id: 316,
      name: "WILBERFORCE ISLAND"
    },
    {
      id: 2636,
      name: "Yenagoa"
    }
  ],
  Benue: [
    {
      id: 95,
      name: "GBOKO"
    },
    {
      id: 180,
      name: "KATSINA-ALA"
    },
    {
      id: 2934,
      name: "Markurdi"
    },
    {
      id: 214,
      name: "MKAR"
    },
    {
      id: 2403,
      name: "Otukpo"
    },
    {
      id: 308,
      name: "VANDEKYA"
    },
    {
      id: 319,
      name: "YANDEV"
    }
  ],
  Borno: [
    { id: 298, name: "Abadam" },
    { id: 299, name: "Askira" },
    { id: 300, name: "Bama" },
    { id: 301, name: "Bayo" },
    { id: 302, name: "Biu" },
    { id: 303, name: "Chibok" },
    { id: 304, name: "Damboa" },
    { id: 305, name: "Dikwa" },
    { id: 306, name: "Gubio" },
    { id: 307, name: "Guzamala" },
    { id: 308, name: "Gwoza" },
    { id: 309, name: "Hawul" },
    { id: 310, name: "Jere" },
    { id: 311, name: "Kaga" },
    { id: 312, name: "Kala" },
    { id: 313, name: "Konduga" },
    { id: 314, name: "Kukawa" },
    { id: 315, name: "Kwaya Kusar" },
    { id: 316, name: "Mafa" },
    { id: 317, name: "Magumeri" },
    { id: 297, name: "Maiduguri" },
    { id: 318, name: "Marte" },
    { id: 319, name: "Mobbar" },
    { id: 320, name: "Monguno" },
    { id: 321, name: "Ngala" },
    { id: 322, name: "Nganzai" },
    { id: 323, name: "Shani" }
  ],
  "Cross River": [
    {
      id: 1512,
      name: "Abi"
    },
    {
      id: 1513,
      name: "Akamkpa"
    },
    {
      id: 1514,
      name: "Akpabuyo"
    },
    {
      id: 1515,
      name: "Bakassi"
    },
    {
      id: 1516,
      name: "Bekwarra"
    },
    {
      id: 1517,
      name: "Biase"
    },
    {
      id: 1518,
      name: "Boki"
    },
    {
      id: 2635,
      name: "Calabar"
    },
    {
      id: 1519,
      name: "Etung"
    },
    {
      id: 1520,
      name: "Ikom"
    },
    {
      id: 1521,
      name: "Obanliku"
    },
    {
      id: 1522,
      name: "Obubra"
    },
    {
      id: 1523,
      name: "Obudu"
    },
    {
      id: 1524,
      name: "Odukpani"
    },
    {
      id: 1525,
      name: "Ogoja"
    },
    {
      id: 1526,
      name: "Yakuur"
    },
    {
      id: 1527,
      name: "Yala"
    }
  ],
  Delta: [
    {
      id: 2437,
      name: " Warri"
    },
    {
      id: 1969,
      name: "Abraka"
    },
    {
      id: 2277,
      name: "AGBOR"
    },
    {
      id: 578,
      name: "ALADJA"
    },
    {
      id: 2241,
      name: "ASABA"
    },
    {
      id: 3000,
      name: "Ebrumede"
    },
    {
      id: 1982,
      name: "Eku-Eku"
    },
    {
      id: 2999,
      name: "Fupre"
    },
    {
      id: 2695,
      name: "Isele"
    },
    {
      id: 2052,
      name: "Kwale"
    },
    {
      id: 1985,
      name: "Obiaruku"
    },
    {
      id: 2347,
      name: "Oghara"
    },
    {
      id: 2696,
      name: "Ogwashi"
    },
    {
      id: 2050,
      name: "Oleh"
    },
    {
      id: 2697,
      name: "Otor"
    },
    {
      id: 585,
      name: "OVWIAN"
    },
    {
      id: 1989,
      name: "Ozoro"
    },
    {
      id: 1578,
      name: "SAPELE"
    },
    {
      id: 2100,
      name: "Udu"
    },
    {
      id: 1692,
      name: "Ughelli"
    },
    {
      id: 304,
      name: "UMUNEDE"
    }
  ],
  Ebonyi: [
    {
      id: 4,
      name: "ABAKALIKI"
    },
    {
      id: 2698,
      name: "Afikpo"
    },
    {
      id: 2701,
      name: "Ezza"
    },
    {
      id: 2703,
      name: "Ikwo"
    },
    {
      id: 2704,
      name: "Ishielu"
    },
    {
      id: 2705,
      name: "Ivo"
    },
    {
      id: 2706,
      name: "Izzi"
    },
    {
      id: 2707,
      name: "Ohaozara"
    },
    {
      id: 2708,
      name: "Ohaukwu"
    },
    {
      id: 2709,
      name: "Onicha"
    }
  ],
  Edo: [
    {
      id: 1389,
      name: "Akoko Edo"
    },
    {
      id: 2457,
      name: "Benin"
    },
    {
      id: 1420,
      name: "Egor"
    },
    {
      id: 2973,
      name: "Ekiadolor"
    },
    {
      id: 2974,
      name: "Ekiadoro"
    },
    {
      id: 1380,
      name: "Ekpoma"
    },
    {
      id: 1390,
      name: "Esan"
    },
    {
      id: 1434,
      name: "Etsako"
    },
    {
      id: 1437,
      name: "Igueben"
    },
    {
      id: 1438,
      name: "Ikpoba Okha"
    },
    {
      id: 1423,
      name: "Okada"
    },
    {
      id: 1424,
      name: "Oluku"
    },
    {
      id: 1395,
      name: "Orhionmwon"
    },
    {
      id: 1394,
      name: "Ovia"
    },
    {
      id: 1397,
      name: "Owan"
    },
    {
      id: 1398,
      name: "Ubiaja"
    },
    {
      id: 2657,
      name: "Ugbowo"
    },
    {
      id: 1425,
      name: "Uhen"
    },
    {
      id: 2975,
      name: "Uromi"
    }
  ],
  Ekiti: [
    {
      id: 2667,
      name: "Ado Ekiti"
    },
    {
      id: 3007,
      name: "Afebabalola"
    },
    {
      id: 3001,
      name: "Aramoko"
    },
    {
      id: 2713,
      name: "Efon"
    },
    {
      id: 2714,
      name: "Emure"
    },
    {
      id: 2715,
      name: "Gbonyin"
    },
    {
      id: 2716,
      name: "Ido Osi"
    },
    {
      id: 2717,
      name: "Ijero"
    },
    {
      id: 2718,
      name: "Ikere"
    },
    {
      id: 131,
      name: "IKOLE"
    },
    {
      id: 3015,
      name: "Ilawe"
    },
    {
      id: 2719,
      name: "Ilejemeje"
    },
    {
      id: 2720,
      name: "Irepodun"
    },
    {
      id: 150,
      name: "ISE"
    },
    {
      id: 3016,
      name: "Iworoko"
    },
    {
      id: 2722,
      name: "Moba"
    },
    {
      id: 254,
      name: "OMUO"
    },
    {
      id: 269,
      name: "OTUN"
    },
    {
      id: 2723,
      name: "Oye"
    }
  ],
  Enugu: [
    {
      id: 16,
      name: "AGBANI"
    },
    {
      id: 2724,
      name: "Aninri"
    },
    {
      id: 2725,
      name: "Awgu"
    },
    {
      id: 2881,
      name: "Enugu"
    },
    {
      id: 2726,
      name: "Ezeagu"
    },
    {
      id: 2727,
      name: "Igbo"
    },
    {
      id: 2730,
      name: "Isi Uzo"
    },
    {
      id: 159,
      name: "ITUKU OZALLA"
    },
    {
      id: 2731,
      name: "Nkanu"
    },
    {
      id: 42,
      name: "ANIRI"
    },
    {
      id: 2675,
      name: "Nsukka"
    },
    {
      id: 2733,
      name: "Oji River"
    },
    {
      id: 2734,
      name: "Udenu"
    },
    {
      id: 2735,
      name: "Udi"
    },
    {
      id: 2736,
      name: "Uzo-Uwani"
    }
  ],
  "FCT - Abuja": [
    {
      id: 2,
      name: "ABAJI"
    },
    {
      id: 1764,
      name: "Lugbe"
    },
    {
      id: 1760,
      name: "KUCHINGORO"
    },
    {
      id: 1762,
      name: "PIWOYI"
    },
    {
      id: 1761,
      name: "PYAKASA"
    },
    {
      id: 1496,
      name: "Apo"
    },
    {
      id: 1507,
      name: "DURUMI"
    },
    {
      id: 1474,
      name: "GARKI"
    },
    {
      id: 1485,
      name: "GWARINPA"
    },
    {
      id: 1510,
      name: "KATAMPE"
    },
    {
      id: 1565,
      name: "KUBWA"
    },
    {
      id: 1493,
      name: "MABUSHI"
    },
    {
      id: 1484,
      name: "MAITAMA"
    },
    {
      id: 612,
      name: "ASOKORO"
    },
    {
      id: 613,
      name: "BWARI"
    },
    {
      id: 1684,
      name: "central business district"
    },
    {
      id: 2244,
      name: "Dakibiyu"
    },
    {
      id: 1338,
      name: "DAWAKI"
    },
    {
      id: 615,
      name: "DEI-DEI"
    },
    {
      id: 616,
      name: "DUTSE"
    },
    {
      id: 177,
      name: "EFAB"
    },
    {
      id: 981,
      name: "GALADIMAWA"
    },
    {
      id: 982,
      name: "GAMES VILLAGE"
    },
    {
      id: 1169,
      name: "GUZAPE"
    },
    {
      id: 622,
      name: "JABI"
    },
    {
      id: 1773,
      name: "JAHI"
    },
    {
      id: 983,
      name: "KABUSA"
    },
    {
      id: 624,
      name: "KADO"
    },
    {
      id: 626,
      name: "KARU"
    },
    {
      id: 628,
      name: "KAURA"
    },
    {
      id: 630,
      name: "KUJE"
    },
    {
      id: 632,
      name: "LIFE CAMP"
    },
    {
      id: 633,
      name: "LOKOGOMA"
    },
    {
      id: 636,
      name: "MARARABA"
    },
    {
      id: 638,
      name: "NYANYA"
    },
    {
      id: 639,
      name: "UTAKO"
    },
    {
      id: 1467,
      name: "WUSE"
    },
    {
      id: 2243,
      name: "Wuye"
    },
    {
      id: 2872,
      name: "Dutse"
    },
    {
      id: 97,
      name: "GIDAN MANGORO"
    },
    {
      id: 1159,
      name: "GWAGWALADA"
    },
    {
      id: 112,
      name: "IDU"
    },
    {
      id: 2739,
      name: "Jikowyi"
    },
    {
      id: 2737,
      name: "Karimo"
    },
    {
      id: 1162,
      name: "KWALI"
    },
    {
      id: 2738,
      name: "Mpape"
    },
    {
      id: 2906,
      name: "Tungan Maje"
    },
    {
      id: 2873,
      name: "Zuba"
    },
    {
      id: 821,
      name: "Gudu"
    }
  ],
  Gombe: [
    {
      id: 2740,
      name: "Akko"
    },
    {
      id: 2955,
      name: "Arawa"
    },
    {
      id: 48,
      name: "ASHAKA"
    },
    {
      id: 56,
      name: "BAJOGA"
    },
    {
      id: 2741,
      name: "Balanga"
    },
    {
      id: 2954,
      name: "Barunde"
    },
    {
      id: 2941,
      name: "BCJ"
    },
    {
      id: 2742,
      name: "Billiri"
    },
    {
      id: 2953,
      name: "Bolari"
    },
    {
      id: 2743,
      name: "Dukku"
    },
    {
      id: 2744,
      name: "Funakaye"
    },
    {
      id: 99,
      name: "GOMBE"
    },
    {
      id: 2946,
      name: "GRA"
    },
    {
      id: 2940,
      name: "JekadaFari"
    },
    {
      id: 2745,
      name: "Kaltungo"
    },
    {
      id: 2956,
      name: "Kasuwan mata"
    },
    {
      id: 2746,
      name: "Kwami"
    },
    {
      id: 204,
      name: "MALAMFIDI"
    },
    {
      id: 2747,
      name: "Nafada"
    },
    {
      id: 2950,
      name: "Nayi Nawa"
    },
    {
      id: 2952,
      name: "Pantami"
    },
    {
      id: 2748,
      name: "Shongom"
    },
    {
      id: 2949,
      name: "Tudun Wada"
    },
    {
      id: 2938,
      name: "Tunfere"
    },
    {
      id: 2749,
      name: "Yamaltu"
    }
  ],
  Imo: [
    {
      id: 1023,
      name: "AMAKOHIA"
    },
    {
      id: 2753,
      name: "Ezinihitte"
    },
    {
      id: 119,
      name: "IHIAGWA"
    },
    {
      id: 120,
      name: "IHIALA"
    },
    {
      id: 156,
      name: "ISUOCHI"
    },
    {
      id: 1172,
      name: "MBAISE"
    },
    {
      id: 1171,
      name: "NGOR OKPALA"
    },
    {
      id: 2589,
      name: "Nwangele"
    },
    {
      id: 2592,
      name: "Obowo"
    },
    {
      id: 2594,
      name: "Oguta"
    },
    {
      id: 247,
      name: "OKIGWE"
    },
    {
      id: 261,
      name: "ORLU"
    },
    {
      id: 3010,
      name: "Owerri"
    }
  ],
  Jigawa: [
    { id: 458, name: "Auyo" },
    { id: 459, name: "Babura" },
    { id: 460, name: "Biriniwa" },
    { id: 461, name: "Buji" },
    { id: 456, name: "Dutse" },
    { id: 462, name: "Gagarawa" },
    { id: 457, name: "Garki" },
    { id: 463, name: "Gumel" },
    { id: 464, name: "Guri" },
    { id: 465, name: "Gwaram" },
    { id: 466, name: "Gwiwa" },
    { id: 467, name: "Hadejia" },
    { id: 468, name: "Jahun" },
    { id: 469, name: "Kafin Hausa" },
    { id: 470, name: "Kaugama" },
    { id: 471, name: "Kazaure" },
    { id: 472, name: "Kiri Kasamma" },
    { id: 473, name: "Kiyawa" },
    { id: 474, name: "Maigatari" },
    { id: 476, name: "Miga" },
    { id: 477, name: "Ringim" },
    { id: 478, name: "Roni" },
    { id: 479, name: "Sule-Tankarkar" },
    { id: 480, name: "Taura" },
    { id: 481, name: "Yankwashi" }
  ],
  Kaduna: [
    {
      id: 647,
      name: "ANGWAN SARIKI"
    },
    {
      id: 648,
      name: "BARNAWA"
    },
    {
      id: 652,
      name: "GONI GORA"
    },
    {
      id: 653,
      name: "IBBI"
    },
    {
      id: 166,
      name: "JAJI"
    },
    {
      id: 656,
      name: "KABALA"
    },
    {
      id: 657,
      name: "KACHIA"
    },
    {
      id: 2504,
      name: "Kaduna"
    },
    {
      id: 172,
      name: "KAFANCHAN"
    },
    {
      id: 660,
      name: "KAKURI"
    },
    {
      id: 661,
      name: "KAWO"
    },
    {
      id: 662,
      name: "KIKINU"
    },
    {
      id: 663,
      name: "KURUMASHI"
    },
    {
      id: 202,
      name: "MAKARFI"
    },
    {
      id: 665,
      name: "MALALI"
    },
    {
      id: 666,
      name: "NARAYI"
    },
    {
      id: 669,
      name: "REGACHUKU"
    },
    {
      id: 670,
      name: "SABO"
    },
    {
      id: 673,
      name: "TRIKANIA"
    },
    {
      id: 674,
      name: "TUNDU WADA"
    },
    {
      id: 322,
      name: "ZARIA"
    }
  ],
  Kano: [
    {
      id: 677,
      name: "BADARAWA"
    },
    {
      id: 62,
      name: "BICHI"
    },
    {
      id: 678,
      name: "BRIGADE"
    },
    {
      id: 681,
      name: "FAGGE"
    },
    {
      id: 683,
      name: "GADAN KAYA"
    },
    {
      id: 684,
      name: "GADUN ALBASU"
    },
    {
      id: 93,
      name: "GAYA"
    },
    {
      id: 685,
      name: "GORON DUTSE"
    },
    {
      id: 686,
      name: "GWAMMAJA"
    },
    {
      id: 2510,
      name: "Gyadi-Gyadi"
    },
    {
      id: 689,
      name: "HOTORO"
    },
    {
      id: 690,
      name: "KABUGA"
    },
    {
      id: 2509,
      name: "Nassarawa"
    },
    {
      id: 691,
      name: "KANO"
    },
    {
      id: 174,
      name: "KARKASARA"
    },
    {
      id: 693,
      name: "KAWO"
    },
    {
      id: 698,
      name: "PANSHEKARA"
    },
    {
      id: 699,
      name: "SABON GARI"
    },
    {
      id: 700,
      name: "SALLARI"
    },
    {
      id: 701,
      name: "SHARADA"
    },
    {
      id: 702,
      name: "TARAUNI"
    },
    {
      id: 317,
      name: "WUDIL"
    },
    {
      id: 704,
      name: "YANKABA"
    }
  ],
  Katsina: [
    { id: 552, name: "Bakori" },
    { id: 553, name: "Batagarawa" },
    { id: 554, name: "Batsari" },
    { id: 555, name: "Baure" },
    { id: 556, name: "Bindawa" },
    { id: 557, name: "Charanchi" },
    { id: 558, name: "Dan Musa" },
    { id: 559, name: "Dandume" },
    { id: 548, name: "Danja" },
    { id: 549, name: "Daura" },
    { id: 560, name: "Dutsi" },
    { id: 561, name: "Dutsin-Ma" },
    { id: 562, name: "Faskari" },
    { id: 563, name: "Funtua" },
    { id: 564, name: "Ingawa" },
    { id: 565, name: "Jibia" },
    { id: 566, name: "Kafur" },
    { id: 567, name: "Kaita" },
    { id: 568, name: "Kankara" },
    { id: 569, name: "Kankia" },
    { id: 550, name: "Katsina" },
    { id: 570, name: "Kurfi" },
    { id: 571, name: "Kusada" },
    { id: 572, name: "Mai adua" },
    { id: 573, name: "Malumfashi" },
    { id: 574, name: "Mani" },
    { id: 575, name: "Mashi" },
    { id: 576, name: "Matazu" },
    { id: 577, name: "Musawa" },
    { id: 578, name: "Rimi" },
    { id: 579, name: "Sabuwa" },
    { id: 580, name: "Safana" },
    { id: 581, name: "Sandamu" },
    { id: 551, name: "Zango" }
  ],
  Kebbi: [
    { id: 585, name: "Aleiro" },
    { id: 586, name: "Arewa-Dandi" },
    { id: 587, name: "Argungu" },
    { id: 588, name: "Augie" },
    { id: 589, name: "Bagudo" },
    { id: 582, name: "Birnin Kebbi" },
    { id: 590, name: "Bunza" },
    { id: 591, name: "Dandi" },
    { id: 592, name: "Fakai" },
    { id: 593, name: "Gwandu" },
    { id: 583, name: "Jega" },
    { id: 594, name: "Kalgo" },
    { id: 595, name: "Koko" },
    { id: 596, name: "Maiyama" },
    { id: 597, name: "Ngaski" },
    { id: 598, name: "Sakaba" },
    { id: 599, name: "Shanga" },
    { id: 600, name: "Suru" },
    { id: 601, name: "Wasagu" },
    { id: 602, name: "Yauri" },
    { id: 584, name: "Zuru" }
  ],
  Kogi: [
    { id: 605, name: "Adavi" },
    { id: 606, name: "Ajaokuta" },
    { id: 607, name: "Ankpa" },
    { id: 608, name: "Bassa" },
    { id: 609, name: "Dekina" },
    { id: 610, name: "Ibaji" },
    { id: 611, name: "Idah" },
    { id: 612, name: "Igala Mela" },
    { id: 613, name: "Igalamela-Odolu" },
    { id: 614, name: "Ijumu" },
    { id: 615, name: "Kabba" },
    { id: 617, name: "Koton Karfe" },
    { id: 603, name: "Lokoja" },
    { id: 618, name: "Mopa-Muro" },
    { id: 619, name: "Ofu" },
    { id: 620, name: "Ogori" },
    { id: 621, name: "Okehi" },
    { id: 604, name: "Okene" },
    { id: 622, name: "Olamaboro" },
    { id: 623, name: "Omala" }
  ],
  Kwara: [
    {
      id: 2646,
      name: "Asa"
    },
    {
      id: 2650,
      name: "Baruten"
    },
    {
      id: 2651,
      name: "Edu"
    },
    {
      id: 2652,
      name: "Ekiti"
    },
    {
      id: 2653,
      name: "Ifelodun"
    },
    {
      id: 2530,
      name: "Ilorin"
    },
    {
      id: 2756,
      name: "Irepodun"
    },
    {
      id: 2757,
      name: "Isin"
    },
    {
      id: 2758,
      name: "Kaiama"
    },
    {
      id: 2759,
      name: "Moro"
    },
    {
      id: 232,
      name: "OFFA"
    },
    {
      id: 2760,
      name: "Oke Ero"
    },
    {
      id: 2761,
      name: "Oyun"
    },
    {
      id: 2762,
      name: "Pategi"
    }
  ],
  Lagos: [
    {
      id: 1329,
      name: "Abule Egba"
    },
    {
      id: 2818,
      name: "Agbara"
    },
    {
      id: 1319,
      name: "Agege"
    },
    {
      id: 1211,
      name: "AGILITI"
    },
    {
      id: 1635,
      name: "AMUWO"
    },
    {
      id: 44,
      name: "ANTHONY VILLAGE"
    },
    {
      id: 1197,
      name: "Apapa"
    },
    {
      id: 997,
      name: "AWOYAYA"
    },
    {
      id: 2813,
      name: "Badagry"
    },
    {
      id: 61,
      name: "BERGER"
    },
    {
      id: 2148,
      name: "Bogije"
    },
    {
      id: 1550,
      name: "Coker"
    },
    {
      id: 1552,
      name: "Doyin"
    },
    {
      id: 2024,
      name: "Ejigbo"
    },
    {
      id: 2147,
      name: "Elemoro"
    },
    {
      id: 86,
      name: "EPE"
    },
    {
      id: 3002,
      name: "Fagba"
    },
    {
      id: 1439,
      name: "FESTAC"
    },
    {
      id: 1953,
      name: "Gbagada"
    },
    {
      id: 111,
      name: "IDIMU"
    },
    {
      id: 114,
      name: "IGANDO"
    },
    {
      id: 121,
      name: "IJANIKIN"
    },
    {
      id: 125,
      name: "IJEGUN"
    },
    {
      id: 126,
      name: "IJORA"
    },
    {
      id: 1177,
      name: "Ikeja"
    },
    {
      id: 1351,
      name: "IKORODU"
    },
    {
      id: 993,
      name: "IKOTA"
    },
    {
      id: 137,
      name: "IKOTUN"
    },
    {
      id: 1225,
      name: "Ikoyi"
    },
    {
      id: 1586,
      name: "ILAJE"
    },
    {
      id: 147,
      name: "ILUPEJU"
    },
    {
      id: 941,
      name: "ISHERI"
    },
    {
      id: 155,
      name: "ISOLO"
    },
    {
      id: 2000,
      name: "Iyana Ejigbo"
    },
    {
      id: 162,
      name: "IYANA IBA"
    },
    {
      id: 1334,
      name: "Iyana Ipaja"
    },
    {
      id: 2233,
      name: "Ketu"
    },
    {
      id: 996,
      name: "LAKOWE"
    },
    {
      id: 2145,
      name: "Lakowe"
    },
    {
      id: 22,
      name: "LEKKI"
    },
    {
      id: 2994,
      name: "Magboro"
    },
    {
      id: 200,
      name: "MAGODO"
    },
    {
      id: 1592,
      name: "MARYLAND"
    },
    {
      id: 209,
      name: "MEBANMU"
    },
    {
      id: 2041,
      name: "Mushin"
    },
    {
      id: 1214,
      name: "Odongunyan"
    },
    {
      id: 1877,
      name: "Ogba"
    },
    {
      id: 237,
      name: "OGUDU"
    },
    {
      id: 238,
      name: "OJO"
    },
    {
      id: 2805,
      name: "Ojo"
    },
    {
      id: 242,
      name: "OJODU"
    },
    {
      id: 243,
      name: "OJOKORO"
    },
    {
      id: 244,
      name: "OJOTA"
    },
    {
      id: 248,
      name: "OKOKOMAIKO"
    },
    {
      id: 249,
      name: "OKOTA"
    },
    {
      id: 1185,
      name: "Omole"
    },
    {
      id: 259,
      name: "OREGUN"
    },
    {
      id: 1546,
      name: "Oreyo- Igbe"
    },
    {
      id: 260,
      name: "ORILE"
    },
    {
      id: 1570,
      name: "OSHODI"
    },
    {
      id: 1548,
      name: "Sari-Iganmu"
    },
    {
      id: 293,
      name: "SOMOLU"
    },
    {
      id: 1207,
      name: "Surulere"
    },
    {
      id: 1220,
      name: "Victoria Island"
    },
    {
      id: 1892,
      name: "Yaba"
    }
  ],
  Nasarawa: [
    {
      id: 33,
      name: "AKWANGA"
    },
    {
      id: 2890,
      name: "Awe"
    },
    {
      id: 72,
      name: "DOMA"
    },
    {
      id: 2875,
      name: "Karu"
    },
    {
      id: 2889,
      name: "Keana"
    },
    {
      id: 182,
      name: "KEFFI"
    },
    {
      id: 2969,
      name: "Lafia"
    },
    {
      id: 206,
      name: "MASAKA"
    },
    {
      id: 2876,
      name: "Nasarawa"
    },
    {
      id: 2891,
      name: "Nasarawa Egon"
    },
    {
      id: 221,
      name: "NEW KARU"
    },
    {
      id: 2892,
      name: "Obi"
    },
    {
      id: 2894,
      name: "Toto"
    },
    {
      id: 313,
      name: "WAMBA"
    }
  ],
  Niger: [
    {
      id: 15,
      name: "AGAI"
    },
    {
      id: 54,
      name: "BADDEGI"
    },
    {
      id: 63,
      name: "BIDA"
    },
    {
      id: 186,
      name: "KONTAGORA"
    },
    {
      id: 196,
      name: "LAPAI"
    },
    {
      id: 199,
      name: "MADALA"
    },
    {
      id: 2250,
      name: "Minna"
    },
    {
      id: 295,
      name: "SULEJA"
    },
    {
      id: 326,
      name: "ZUBA"
    }
  ],
  Ogun: [
    {
      id: 708,
      name: "ABEOKUTA"
    },
    {
      id: 2395,
      name: "Agbara"
    },
    {
      id: 21,
      name: "AGO IWOYE"
    },
    {
      id: 32,
      name: "AKUTE"
    },
    {
      id: 2822,
      name: "Ewekoro"
    },
    {
      id: 2841,
      name: "Ifo"
    },
    {
      id: 2396,
      name: "Igbesa"
    },
    {
      id: 122,
      name: "IJEBU IFE"
    },
    {
      id: 1278,
      name: "IJEBU IGBO"
    },
    {
      id: 1295,
      name: "IJEBU Imushin"
    },
    {
      id: 135,
      name: "IJEBU Itele"
    },
    {
      id: 1707,
      name: "Ijebu ode"
    },
    {
      id: 2835,
      name: "Ikenne"
    },
    {
      id: 2378,
      name: "Ilaro"
    },
    {
      id: 1759,
      name: "Ilishan Remo"
    },
    {
      id: 2840,
      name: "Imeko Afon"
    },
    {
      id: 1209,
      name: "ITELE"
    },
    {
      id: 2882,
      name: "Mowe Ibafo"
    },
    {
      id: 764,
      name: "OBA"
    },
    {
      id: 2842,
      name: "Obafemi Owode"
    },
    {
      id: 2769,
      name: "Obantoko"
    },
    {
      id: 766,
      name: "ODEDA"
    },
    {
      id: 2834,
      name: "Odogbolu"
    },
    {
      id: 235,
      name: "OGIJO"
    },
    {
      id: 1170,
      name: "Sagamu-Remo"
    },
    {
      id: 263,
      name: "ORU"
    },
    {
      id: 1612,
      name: "OTTA"
    },
    {
      id: 2833,
      name: "Remo"
    },
    {
      id: 1608,
      name: "SANGO OTTA"
    },
    {
      id: 2839,
      name: "Yewa"
    }
  ],
  Ondo: [
    {
      id: 2493,
      name: "Akoko"
    },
    {
      id: 2983,
      name: "Akure"
    },
    {
      id: 2878,
      name: "Ese Odo"
    },
    {
      id: 2559,
      name: "Idanre"
    },
    {
      id: 2496,
      name: "Ifedore"
    },
    {
      id: 116,
      name: "IGBOKODA"
    },
    {
      id: 2497,
      name: "Illaje"
    },
    {
      id: 2498,
      name: "Irele"
    },
    {
      id: 2499,
      name: "Odigbo"
    },
    {
      id: 2500,
      name: "Okitipipa"
    },
    {
      id: 2992,
      name: "Akungba"
    },
    {
      id: 258,
      name: "ORE"
    },
    {
      id: 2501,
      name: "Ose"
    },
    {
      id: 2503,
      name: "Owo"
    }
  ],
  Osun: [
    {
      id: 1018,
      name: "ARAKEJI"
    },
    {
      id: 75,
      name: "EDE"
    },
    {
      id: 2844,
      name: "Ejigbo"
    },
    {
      id: 1020,
      name: "ESAOKE"
    },
    {
      id: 1019,
      name: "IJEBU-JESA"
    },
    {
      id: 129,
      name: "IKIRE"
    },
    {
      id: 1014,
      name: "IKIRUN"
    },
    {
      id: 140,
      name: "ILA-ORANGUN"
    },
    {
      id: 2846,
      name: "Ile ife"
    },
    {
      id: 142,
      name: "ILESHA"
    },
    {
      id: 144,
      name: "ILOKO"
    },
    {
      id: 149,
      name: "IREE"
    },
    {
      id: 1734,
      name: "Iwo"
    },
    {
      id: 2848,
      name: "Moro"
    },
    {
      id: 1016,
      name: "OKUKU"
    },
    {
      id: 2847,
      name: "Oshogbo"
    }
  ],
  Oyo: [
    {
      id: 2850,
      name: "Afijio"
    },
    {
      id: 768,
      name: "AKINYELE"
    },
    {
      id: 769,
      name: "ALOMOJA"
    },
    {
      id: 2851,
      name: "Atiba"
    },
    {
      id: 2852,
      name: "Atisbo"
    },
    {
      id: 77,
      name: "EGBEDA"
    },
    {
      id: 771,
      name: "ERUWA"
    },
    {
      id: 2556,
      name: "Ibadan"
    },
    {
      id: 2855,
      name: "Ibarapa"
    },
    {
      id: 808,
      name: "IDI IROKO"
    },
    {
      id: 2856,
      name: "Ido"
    },
    {
      id: 809,
      name: "IGBO OLOYIN"
    },
    {
      id: 2857,
      name: "Irepo"
    },
    {
      id: 810,
      name: "ISEBO"
    },
    {
      id: 152,
      name: "ISEYIN"
    },
    {
      id: 2858,
      name: "Itesiwaju"
    },
    {
      id: 2859,
      name: "Iwajowa"
    },
    {
      id: 160,
      name: "IWO"
    },
    {
      id: 2860,
      name: "Kajola"
    },
    {
      id: 2861,
      name: "Lagelu"
    },
    {
      id: 194,
      name: "LALUPON"
    },
    {
      id: 812,
      name: "LANLATE"
    },
    {
      id: 813,
      name: "MONIYA"
    },
    {
      id: 2862,
      name: "Ogbomosho"
    },
    {
      id: 2863,
      name: "Ogo Oluwa"
    },
    {
      id: 814,
      name: "OLOGUN-ERU"
    },
    {
      id: 2893,
      name: "Olorunsogo"
    },
    {
      id: 2866,
      name: "Ona Ara"
    },
    {
      id: 2867,
      name: "Orelope"
    },
    {
      id: 2868,
      name: "Ori Ire"
    },
    {
      id: 2108,
      name: "Saki"
    },
    {
      id: 816,
      name: "SAGBE"
    },
    {
      id: 2561,
      name: "Surulere"
    }
  ],
  Plateau: [
    {
      id: 57,
      name: "BARIKIN LADI"
    },
    {
      id: 2098,
      name: "Bukuru"
    },
    {
      id: 2096,
      name: "Dadin Kowa"
    },
    {
      id: 2088,
      name: "Farin Gada"
    },
    {
      id: 106,
      name: "HAIPANG"
    },
    {
      id: 2095,
      name: "Jos South"
    },
    {
      id: 2082,
      name: "Katako"
    },
    {
      id: 188,
      name: "KURU"
    },
    {
      id: 2092,
      name: "Lamingo"
    },
    {
      id: 2072,
      name: "Mangu"
    },
    {
      id: 2087,
      name: "Nasarawa"
    },
    {
      id: 2091,
      name: "Rayfield"
    },
    {
      id: 311,
      name: "VOM"
    },
    {
      id: 2093,
      name: "Zarmaganda"
    },
    {
      id: 323,
      name: "ZAWAN"
    }
  ],
  Rivers: [
    {
      id: 7,
      name: "ABHONEMA"
    },
    {
      id: 1167,
      name: "AHOADA"
    },
    {
      id: 2459,
      name: "Bonny Island"
    },
    {
      id: 1168,
      name: "ELELE"
    },
    {
      id: 2673,
      name: "Elelewon"
    },
    {
      id: 1248,
      name: "ELEME"
    },
    {
      id: 83,
      name: "EMOUHA"
    },
    {
      id: 87,
      name: "ETCHE"
    },
    {
      id: 154,
      name: "ISIOKPO"
    },
    {
      id: 2461,
      name: "Okrika"
    },
    {
      id: 252,
      name: "OMOKU"
    },
    {
      id: 1262,
      name: "OYIGBO"
    },
    {
      id: 1621,
      name: "Port Harcourt"
    },
    {
      id: 280,
      name: "RUMUAGHOLU"
    },
    {
      id: 281,
      name: "RUMUODUOMAYA"
    }
  ],
  Sokoto: [
    { id: 737, name: "Binji" },
    { id: 738, name: "Bodinga" },
    { id: 739, name: "Dange-Shuni" },
    { id: 740, name: "Gada" },
    { id: 741, name: "Goronyo" },
    { id: 742, name: "Gudu LGA" },
    { id: 743, name: "Gwadabawa" },
    { id: 734, name: "Illela" },
    { id: 744, name: "Isa" },
    { id: 745, name: "Kebbe" },
    { id: 746, name: "Kware" },
    { id: 747, name: "Rabah" },
    { id: 748, name: "Sabon Birni" },
    { id: 749, name: "Shagari" },
    { id: 750, name: "Silame" },
    { id: 751, name: "Tambuwal" },
    { id: 752, name: "Tangaza" },
    { id: 753, name: "Tureta" },
    { id: 754, name: "Wamako" },
    { id: 755, name: "Wurno" },
    { id: 756, name: "Yabo" }
  ],
  Taraba: [
    { id: 760, name: "Ardo-Kola" },
    { id: 761, name: "Bali" },
    { id: 762, name: "Donga" },
    { id: 763, name: "Gashaka" },
    { id: 764, name: "Gassol" },
    { id: 765, name: "Ibi" },
    { id: 757, name: "Jalingo" },
    { id: 766, name: "Karim-Lamido" },
    { id: 767, name: "Kurmi" },
    { id: 768, name: "Lau" },
    { id: 769, name: "Sardauna" },
    { id: 758, name: "Takum" },
    { id: 770, name: "Ussa" },
    { id: 759, name: "Wukari" },
    { id: 771, name: "Yorro" },
    { id: 772, name: "Zing" }
  ],
  Yobe: [
    { id: 775, name: "Bade" },
    { id: 776, name: "Bursari" },
    { id: 773, name: "Damaturu" },
    { id: 777, name: "Fika" },
    { id: 778, name: "Fune" },
    { id: 779, name: "Geidam" },
    { id: 780, name: "Gujba" },
    { id: 781, name: "Gulani" },
    { id: 782, name: "Jakusko" },
    { id: 783, name: "Karasuwa" },
    { id: 784, name: "Machina" },
    { id: 785, name: "Nangere" },
    { id: 786, name: "Nguru" },
    { id: 774, name: "Potiskum" },
    { id: 787, name: "Tarmua" },
    { id: 788, name: "Yunusari" },
    { id: 789, name: "Yusufari" }
  ],
  Zamfara: [
    { id: 791, name: "Anka" },
    { id: 792, name: "Bakura" },
    { id: 793, name: "Birnin Magaji" },
    { id: 794, name: "Bukkuyum" },
    { id: 795, name: "Bungudu" },
    { id: 796, name: "Gummi" },
    { id: 790, name: "Gusau" },
    { id: 797, name: "Kaura Namoda" },
    { id: 798, name: "Maradun" },
    { id: 799, name: "Maru" },
    { id: 800, name: "Shinkafi" },
    { id: 801, name: "Talata Mafara" },
    { id: 802, name: "Tsafe" },
    { id: 803, name: "Zurmi" }
  ]
};
