import { NavLink } from "react-router-dom";
import styled from "styled-components";

export const Wrapper = styled.section`
  height: 100vh;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  padding: 40px 20px 0 0;

  h3 {
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #333333;
    margin: 0;
  }

  p {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 4px;
    cursor: pointer;
    margin: 0;

    :hover {
      svg {
        fill: #0d97cd;
      }

      text-decoration: underline;
    }
  }
`;

export const LinksWrapper = styled.div`
  max-height: calc(100% - 300px);
  overflow-y: auto;
  padding-right: 20px;
`;

export const MessageLink = styled(NavLink)`
  padding: 14px 12px;

  border-bottom: 1px solid #cbc7c7;
  display: flex;
  align-items: center;
  gap: 12px;
  color: #222222;

  &:hover,
  &.active {
    background-color: #fff;
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    p {
      margin-bottom: 0;

      &:first-of-type {
        font-weight: 500;
        font-size: 14px;
        line-height: 120%;
        color: #222222;
      }

      &:nth-of-type(2) {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #6f6b6b;
      }
    }
  }
`;
