import styled from "styled-components";

export const Wrapper = styled.div`
  .grid {
    padding-bottom: 32px;

    @media (min-width: 1024px) {
      flex-direction: row;
    }
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-right: -22px;
    gap: 25px;
    max-height: 100%;
  }

  .loading {
    margin-top: 40px;
    width: 622px;
    max-height: 297px;
    background: #ffffff;
    border-radius: 8px;
    padding: 20px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .empty-bookings,
  .loading {
    @media (min-width: 1024px) {
      max-width: 822px;
      width: 100%;
    }
  }
  .empty-bookings {
    width: 240px;

    background: #ffffff;
    border: 1px dashed #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    border-radius: 8px;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 32px auto 0;

    @media (min-width: 1024px) {
      width: 420px;
      height: 240px;
    }

    h4 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 120%;
      margin: 0;
      color: #344054;
    }
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 10px;
  margin-left: 0;
  @media (min-width: 1024px) {
    margin-left: 330px;
  }
  .ant-input-group-wrapper {
    width: 313px;
  }
  .ant-input {
    border-radius: 6px 0px 0px 6px;
    font-size: 13px;
    height: 40px;
  }
  flex-direction: row;

  .ant-input-search-button {
    width: auto;
    padding: 0px 20px;
    border-radius: 0px 6px 6px 0px !important;

    span {
      color: #b7c2cf;
    }
  }

  margin-bottom: 62px;
`;

export const CardContainer = styled.div`
  padding-left: 12px;
  width: 100%;

  @media (min-width: 1024px) {
    max-width: 822px;
    width: 65%;
  }
  .time {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #667085;
    flex: 2;
    padding-left: 20px;
  }

  .ant-timeline-item-head-custom {
    flex: 2;
  }
  .card {
    margin-top: 24px;
    border: 1px solid #e4e7ec;
    border-radius: 5px;
    width: 290px;
    max-height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;

    @media (min-width: 768px) {
      width: 450px;
    }

    @media (min-width: 1024px) {
      max-height: 198px;
      width: 591px;
    }

    p {
      width: 570px;
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 130%;
      color: #344054;
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 10px 18px;
      gap: 10px;
      color: #0faff0;
      border: none;

      span {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;
