import { Col, Row, Table, Tooltip } from "antd";
import { EyeInvisibleOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../component/Button";
import Card from "../../../../component/Card";
import Search from "../../../../component/Search";
import AddServiceModal from "../../../../component/AddServiceModal";
import {
  InvestigationTableBody,
  InvestigationTableWrapper,
  SearchAndCreateWrapper,
} from "./styles";
import {
  servicePlansSelector,
  fetchAllServicePlans,
  deleteServicePlan,
} from "../../../../redux/reducers/servicePlans";
import { capitaliseFirstLetter } from "../../../../utils/utils";
import { ReactComponent as NoteIcon } from "assets/icons/note-bl.svg";
interface InvestigationTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
}

const InvestigationTable = ({
  count,
  hasPagination = true,
  marginTop = 20,
}: InvestigationTableProps) => {
  const dispatch = useDispatch();
  const {
    servicePlans,
    servicePlansLoading,
    hasDeletedServicePlan,
    deleteServicePlanLoading,
  } = useSelector(servicePlansSelector);
  const [keyword, setKeyword] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [servicePlanId, setServicePlanId] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [investigationsState, setInvestigationsState] = useState<any[]>([]);

  const [isAddServiceModalVisible, setIsAddServiceModalVisible] =
    useState(false);
  const investigations = servicePlans || {};

  useEffect(() => {
    setInvestigationsState(investigations);
  }, [investigations]);

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _investigations = [...investigations];
    setInvestigationsState(
      _investigations.filter((item) => {
        const { name } = item;
        return name.toLowerCase().includes(keyword.toLowerCase());
      }),
    );
  };

  const handleEdit = (id: string) => {
    setServicePlanId(id);
    setIsEdit(true);
    setIsAddServiceModalVisible(true);
  };

  useEffect(() => {
    dispatch(fetchAllServicePlans());
  }, [dispatch]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "left" as "left",
      render: (name: string, record: any) => {
        // get selectedService by filtering for service name
        const selectedPlan = servicePlans.filter(
          (plan) => plan.name === name,
        )[0];
        const service_form = record?.metadata?.service_form;
        return (
          <div className="flex">
            <div>
              <p
                style={{
                  marginBottom: 5,
                  minWidth: "130px",
                  maxWidth: "max-content",
                  lineHeight: "1.7",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                {capitaliseFirstLetter(name)}
                {!selectedPlan?.public && (
                  <Tooltip
                    placement="right"
                    title="This service is private, and will not be shown on your Pneumapage."
                  >
                    <EyeInvisibleOutlined style={{ fontSize: ".7rem" }} />
                  </Tooltip>
                )}
              </p>
              {service_form && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                  }}
                >
                  <NoteIcon />
                  <span
                    style={{
                      fontSize: 14,
                      color: "#0D97CD",
                    }}
                  >
                    Intake form
                  </span>
                </div>
              )}
            </div>
          </div>
        );
      },
    },
    {
      title: "Service Schedule",
      dataIndex: "schedule",
      key: "schedule",
      align: "left" as "left",
      render: (schedule: any) => {
        return (
          <p
            style={{
              maxWidth: "max-content",
              minWidth: "120px",
              lineHeight: "1.7",
            }}
          >
            {capitaliseFirstLetter(schedule)}
          </p>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left" as "left",
      render: (description: string) => {
        return (
          <p
            style={{
              maxWidth: "max-content",
              minWidth: "300px",
              lineHeight: "1.7",
            }}
          >
            {capitaliseFirstLetter(description)}
          </p>
        );
      },
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      align: "left" as "left",
      render: (price: string) => {
        const isFree = !Number(price);
        return (
          <>
            <p style={{ width: "max-content" }}>
              {isFree ? "" : "NGN"}{" "}
              {isFree
                ? "Free"
                : `${Number(price) / 100}`.replace(
                    /\B(?=(\d{3})+(?!\d))/g,
                    ",",
                  )}
            </p>
          </>
        );
      },
    },
    {
      title: "Unit",
      dataIndex: "unit",
      key: "unit",
      align: "left" as "left",
      render: (unit: string) => {
        return (
          <p style={{ width: "max-content", lineHeight: "1.7" }}>
            {capitaliseFirstLetter(unit)}
          </p>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "left" as "left",
      render: (id: string) => (
        <a id={id} onClick={() => handleEdit(id)}>
          Edit
        </a>
      ),
    },
  ];

  const handleDelete = () => {
    selectedRowKeys.forEach((id) => dispatch(deleteServicePlan(id)));
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const dataSource = investigationsState || [];
  const countText = `${investigationsState?.length || 0}`;

  useEffect(() => {
    servicePlansLoading && setSelectedRowKeys([]);
  }, [servicePlansLoading]);

  return (
    <>
      {!count && (
        <Row justify="space-between" align="top">
          <Col xs={24} md={12}>
            <h3 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              <span>{countText}</span> {+countText > 1 ? "Services" : "Service"}
            </h3>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <SearchAndCreateWrapper>
              <Col>
                <Search
                  placeholder="Search by service name"
                  onChange={handleSearch}
                />
              </Col>
              <Col>
                <Button
                  className="plan-add"
                  onClick={() => {
                    setIsAddServiceModalVisible(true);
                    setIsEdit(false);
                  }}
                  type="primary"
                  pAlign
                >
                  Add a new service
                </Button>
              </Col>
            </SearchAndCreateWrapper>
          </Col>
        </Row>
      )}
      <InvestigationTableWrapper>
        <div
          style={{
            margin: "20px 0 0",
            visibility: `${hasSelected ? "visible" : "hidden"}`,
          }}
        >
          <Button
            type="primary"
            onClick={handleDelete}
            disabled={!hasSelected}
            loading={deleteServicePlanLoading}
          >
            Delete selected service plans
          </Button>
          <span style={{ marginLeft: 8 }}>
            {hasSelected
              ? selectedRowKeys.length <= 1
                ? `${selectedRowKeys.length} service selected`
                : `${selectedRowKeys.length} services selected`
              : ""}
          </span>
        </div>
        <Card marginBottom={15} padding={0} style={{ marginTop }}>
          <InvestigationTableBody hasPagination={hasPagination}>
            <Table
              rowSelection={rowSelection}
              dataSource={dataSource}
              rowKey={(dataRow) => dataRow.id}
              columns={columns}
              scroll={
                !servicePlansLoading && dataSource.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 600px)").matches
                  ? { x: 700 }
                  : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={servicePlansLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true,
              }}
            />
          </InvestigationTableBody>
          <AddServiceModal
            isModalVisible={isAddServiceModalVisible}
            isEdit={isEdit}
            servicePlanId={servicePlanId}
            handleCancel={() => {
              setIsAddServiceModalVisible(false);
              setIsEdit(false);
            }}
          />
        </Card>
      </InvestigationTableWrapper>
    </>
  );
};

export default InvestigationTable;
