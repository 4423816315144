import { Route, Switch, useLocation } from "react-router-dom";
import { init } from "emailjs-com";
import { useEffect } from "react";

import "antd/dist/antd.css";
import "react-phone-input-2/lib/style.css";
import "./App.css";
import { ROUTES } from "./constants/routes";
// Layouts
import DashboardLayoutRoute from "./layouts/DashboardLayout";
import OnboardingLayoutRoute from "./layouts/OnboardingLayout";
import Claims from "./screens/Claims";
import Dashboard from "./screens/Dashboard";
import Investigations from "./screens/Investigations";
import LabRequests from "./screens/LabRequests";
import EnterOTP from "./screens/Onboarding/EnterOTP";
// Onboarding Screens
import Login from "./screens/Onboarding/Login";
import RecoverPassword from "./screens/Onboarding/RecoverPassword";
import Register from "./screens/Onboarding/Register";
import RequestOTP from "./screens/Onboarding/RequestOTP";
import ResetPassword from "./screens/Onboarding/ResetPassword";
import TermsAndConditions from "./screens/Onboarding/TermsAndConditions";
import Patients from "./screens/Patients";
import PatientsDetails from "./screens/Patients/containers/PatientsDetails";
import FollowUps from "./screens/FollowUps";
import Messages from "screens/Messages";
import Payments from "screens/Payments";
import Practitioners from "screens/Practitioners";
import Leads from "screens/Leads";

// Dashboard Screens
import Profile from "./screens/Profile";

import { MixPanel } from "utils/mixpanel";
import MoreDetails from "./component/MoreDetails";
import EditDetails from "./component/EditDetails";
import Forms from "./screens/Forms";
import { useAppDispatch } from "redux/store";
import { fetchSubscription } from "redux/slices/subscriptions";
import CreateForm from "./screens/Forms/container/Create";
import Responses from "./screens/Forms/container/Responses";

// initialize EmailJS
init(process.env.REACT_APP_EMAILJS_USER_ID as string);

const Error404Page = () => (
  <>
    <h4>Error 404 Page</h4>
  </>
);

const App = () => {
  const dispatch = useAppDispatch();
  const providerId = localStorage.getItem("providerID")!;
  const location = useLocation();

  useEffect(() => {
    MixPanel.track("PageView", {
      pageName: location.pathname.slice(1)
    });
  }, [location.pathname]);

  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch, providerId]);

  return (
    <Switch>
      <OnboardingLayoutRoute exact path={ROUTES.HOMEPAGE} component={Login} />
      <OnboardingLayoutRoute exact path={ROUTES.LOGIN} component={Login} />
      <OnboardingLayoutRoute
        exact
        path={ROUTES.REGISTER}
        component={Register}
      />
      <OnboardingLayoutRoute
        exact
        path={ROUTES.TERMS_AND_CONDITIONS}
        component={TermsAndConditions}
      />
      <OnboardingLayoutRoute
        exact
        path={ROUTES.RECOVER_PASSWORD}
        component={RecoverPassword}
      />
      <OnboardingLayoutRoute
        exact
        path={ROUTES.RESET_PASSWORD}
        component={ResetPassword}
      />
      <OnboardingLayoutRoute
        exact
        path={ROUTES.REQUEST_OTP}
        component={RequestOTP}
      />
      <OnboardingLayoutRoute
        exact
        path={ROUTES.ENTER_OTP}
        component={EnterOTP}
      />

      {/* Dashboard Screens */}
      <DashboardLayoutRoute
        exact
        path={ROUTES.DASHBOARD}
        component={Dashboard}
      />
      <DashboardLayoutRoute exact path={ROUTES.CLAIMS} component={Claims} />
      <DashboardLayoutRoute
        exact
        path={ROUTES.MANAGE_ACCOUNT}
        component={Profile}
      />
      <DashboardLayoutRoute
        exact
        path={ROUTES.LAB_INVESTIGATIONS}
        component={LabRequests}
      />
      <DashboardLayoutRoute
        exact
        path={ROUTES.INVESTIGATIONS}
        component={Investigations}
      />
      <DashboardLayoutRoute
        exact
        path={ROUTES.FOLLOW_UPS}
        component={FollowUps}
      />
      <DashboardLayoutRoute exact path={ROUTES.MESSAGES} component={Messages} />
      <DashboardLayoutRoute
        exact
        path={ROUTES.MESSAGES_DETAILS}
        component={Messages}
      />
      <DashboardLayoutRoute exact path={ROUTES.PATIENTS} component={Patients} />
      <DashboardLayoutRoute
        exact
        path={ROUTES.PATIENTS_DETAILS}
        component={PatientsDetails}
      />
      <DashboardLayoutRoute
        exact
        path={ROUTES.MORE_DETAILS}
        component={MoreDetails}
      />
      <DashboardLayoutRoute
        exact
        path={ROUTES.EDIT_DETAILS}
        component={EditDetails}
      />

      <DashboardLayoutRoute
        exact
        path={ROUTES.PRACTITIONERS}
        component={Practitioners}
      />

      <DashboardLayoutRoute exact path={ROUTES.FORMS} component={Forms} />
      <DashboardLayoutRoute
        exact
        path={ROUTES.CREATE_FORMS}
        component={CreateForm}
      />
      <DashboardLayoutRoute
        exact
        path={ROUTES.RESPONSES}
        component={Responses}
      />
      <Route exact path={ROUTES.PAYMENTS} component={Payments} />

      <Route component={Error404Page} />
    </Switch>
  );
};

export default App;
