import { Col, Row, Table, Button, Select } from "antd";
import Card from "component/Card";
import DatePicker from "component/DatePicker";
import Search from "component/Search";
import {
  FinanceTableBody,
  FinanceTableWrapper,
  EmptyStateWrapper,
} from "./styles";
import { useEffect, useState } from "react";
import moment from "moment";
import { capitaliseFirstLetter } from "utils/utils";
import {
  useGetFinancialRequestQuery,
  useLazyGetFinancialRequestQuery,
} from "redux/queries/financial-request";
import {
  GetFinancialRequestRes,
  SingleFinancialRequest,
} from "constants/types";

import { ReactComponent as CoinIcon } from "assets/icons/Coins.svg";
type FinaceTableProps = {
  financialRequestData: SingleFinancialRequest[];
  pagination: any;
  isLoading: boolean;
  openModal: any;
};

const FinaceTable = ({
  financialRequestData,
  pagination,
  isLoading,
  openModal,
}: FinaceTableProps) => {
  const [filterRequest, setFilterRequest] = useState<any>();
  const [selectedRange, setSelectedRange] = useState(10);
  const [selectedMonth, setSelectedMonth] = useState<any>();

  const [refetchFinancialRequest, { isLoading: isRefetchLoading }] =
    useLazyGetFinancialRequestQuery();

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: (id: any) => {
        return <p>{id}</p>;
      },
    },
    {
      title: "Patient",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      // sorter: (a: any, b: any) =>
      //   a?.user?.first_name.toLowerCase() < b?.user?.first_name.toLowerCase()
      //     ? -1
      //     : a?.user?.first_name.toLowerCase() >
      //       b?.user?.first_name.toLowerCase()
      //     ? 1
      //     : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <p style={{ minWidth: "240px" }}>
            {capitaliseFirstLetter(first_name)}{" "}
            {capitaliseFirstLetter(last_name)}
          </p>
        );
      },
    },
    {
      title: "Service",
      dataIndex: "partner",
      key: "partner",
      render: (_: any, record: any) => {
        const { name } = record.service_plan;
        return (
          <p
            style={{
              minWidth: "120px",
              maxWidth: "max-content",
              lineHeight: "1.7",
            }}
          >
            {name}
          </p>
        );
      },
    },
    {
      title: "Amount",
      dataIndex: "total_price",
      key: "total_price",
      render: (_: any, record: any) => {
        const { price } = record.service_plan;
        return (
          <p
            className="tags"
            style={{
              textTransform: "capitalize",
              width: "max-content",
              lineHeight: "1.7",
            }}
          >
            {new Intl.NumberFormat("en-NG", {
              style: "currency",
              currency: "NGN",
              maximumFractionDigits: 0,
            }).format(price / 100)}
          </p>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            <span
              style={{
                display: "inline-block",
                padding: ".4em",
                marginRight: ".4em",
                background:
                  record.status === "approved"
                    ? "#0FBA84"
                    : record.status === "pending"
                    ? "#F2994A"
                    : record.status === "completed"
                    ? "green"
                    : "#EB5757",
                borderRadius: "100%",
              }}
            ></span>
            {capitaliseFirstLetter(record.status)}
          </p>
        );
      },
    },
    {
      title: "Created on",
      dataIndex: "schedule",
      key: "schedule",
      render: (_: Date, record: any) => {
        return (
          <>
            <p
              style={{
                minWidth: "120px",
                maxWidth: "max-content",
                lineHeight: "1.7",
              }}
            >
              {moment(record.created_at).format("LL")}
            </p>
            <span className="subtext">
              {moment(record.created_at).format("hh:mm a")}
            </span>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    if (financialRequestData) {
      setFilterRequest([...financialRequestData]);
    }
  }, [financialRequestData]);

  const handleSearch = (e: any) => {
    const keyword = e.target.value;

    const _request = [...financialRequestData];
    setFilterRequest(
      () =>
        _request?.filter((item) => {
          const { user } = item;
          const fullName = `${user.first_name} ${user.last_name}`;
          return fullName.toLowerCase().includes(keyword.toLowerCase());
        }),
    );
  };

  const onChange = (date: any, dateString: string) => {
    if (date && dateString) {
      const _financialRequests = [...financialRequestData];
      setSelectedMonth(dateString);
      setFilterRequest(
        _financialRequests.filter((item) => {
          const { created_at } = item;
          return (
            moment(created_at).isSame(moment(date), "month") &&
            moment(created_at).isSame(moment(date), "year")
          );
        }),
      );
    }
  };

  return (
    <>
      {financialRequestData?.length ? (
        <>
          <Row
            gutter={10}
            style={{ marginTop: "20px", marginBottom: "0px" }}
            justify="space-between"
            align="middle"
          >
            <Col xs={24} md={4} style={{ marginBottom: 10 }}>
              <DatePicker
                marginBottom={0}
                mode="normal"
                width="auto"
                format="MMM YYYY"
                onChange={onChange}
                picker="month"
              />
            </Col>
            <Col xs={24} md={12}>
              <div style={{ display: "flex" }}>
                <div style={{ flexGrow: 1 }}>
                  <Search
                    placeholder="Search by patient"
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
            <FinanceTableWrapper>
              <FinanceTableBody>
                <Table
                  dataSource={filterRequest}
                  columns={columns}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "even-row" : "odd-row"
                  }
                  scroll={
                    !isLoading && financialRequestData.length > 0
                      ? { x: 700 }
                      : window.matchMedia("(max-width: 650xp").matches
                      ? { x: 700 }
                      : {}
                  }
                  loading={isLoading || isRefetchLoading}
                  pagination={{
                    style: { display: "none" },
                    pageSize: selectedRange,
                  }}
                />
                <div
                  className="custom-pagination"
                  style={{ marginRight: "20px" }}
                >
                  <Button
                    disabled={!pagination?.previous}
                    type="primary"
                    onClick={() =>
                      refetchFinancialRequest({
                        size: selectedRange,
                        next: undefined,
                        previous: pagination?.previous as string,
                      })
                    }
                  >
                    Previous
                  </Button>

                  <Select
                    defaultValue={"10 requests / page"}
                    onChange={(val) => {
                      setSelectedRange(() => +val);
                      refetchFinancialRequest({
                        size: +val,
                        next: undefined,
                        previous: undefined,
                      });
                    }}
                  >
                    <Select.Option value={10}>10 requests / page</Select.Option>
                    <Select.Option value={15}>15 requests / page</Select.Option>
                    <Select.Option value={20}>20 requests / page</Select.Option>
                  </Select>

                  <Button
                    disabled={!pagination?.next}
                    type="primary"
                    onClick={() =>
                      refetchFinancialRequest({
                        size: selectedRange,
                        next: pagination?.next as string,
                        previous: undefined,
                      })
                    }
                  >
                    Next
                  </Button>
                </div>
              </FinanceTableBody>
            </FinanceTableWrapper>
          </Card>
        </>
      ) : (
        <EmptyStateWrapper>
          <div>
            <CoinIcon stroke="#000000" />
            <h2>
              You haven’t created <br /> any finance request
            </h2>
            <p>All requests created will show here</p>
            <Button onClick={openModal}>Create finance request</Button>
          </div>
        </EmptyStateWrapper>
      )}
    </>
  );
};

export default FinaceTable;
