import { Col, Modal, Form, Select as AntdSelect, Row, message } from "antd";
import { useEffect, useState } from "react";

import { useAppSelector, useAppDispatch } from "redux/store";
import {
  updateOrderMetadata,
  fetchAllServiceOrders
} from "redux/reducers/requests";
import Select from "component/Select";
import Button from "component/Button";

type Props = {
  selectedAppointment: any;
  isModalVisible: boolean;
  onClose: () => void;
};

function EditAdditionalServicesModal({
  selectedAppointment,
  isModalVisible,
  onClose
}: Props) {
  const dispatch = useAppDispatch();
  const { servicePlans } = useAppSelector((state) => state.servicePlans);
  const [updateForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  // auto-fill additional services
  useEffect(() => {
    updateForm.setFieldsValue({
      additionalServices: selectedAppointment?.metadata?.additionalServices
    });
  }, [selectedAppointment, updateForm]);

  async function handleUpdateAdditionServices(values: any) {
    const _values = values as { additionalServices: string[] };
    setIsLoading(true);
    try {
      await dispatch(
        updateOrderMetadata(selectedAppointment.id, {
          metadata: {
            ...selectedAppointment.metadata,
            additionalServices:
              _values.additionalServices && _values.additionalServices
          }
        })
      );

      await dispatch(fetchAllServiceOrders());
      message.success("Services successfully updated");
      onClose();
    } catch (err: any) {
      message.error(
        err.message || "Unable to update appointment. Please try again."
      );
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title='Update additional services'
      okText='Submit'
      footer={null}
      visible={isModalVisible}
      onCancel={onClose}
      onOk={handleUpdateAdditionServices}
    >
      <Form
        name='basicForm'
        form={updateForm}
        layout='vertical'
        onFinish={handleUpdateAdditionServices}
      >
        <Col xs={24}>
          <Select
            mode='normal'
            multiple
            placeholder='Add other services to this appointment..'
            formItem={{
              label: "Add other services to this appointment",
              name: "additionalServices"
            }}
            filterOption={(_input: string, option: any) => {
              const input = _input.toLowerCase();
              const singleService = servicePlans.find(
                (plan: any) => plan.id === option.value
              )!;
              const booleanValue = singleService.name
                .toLowerCase()
                .includes(input);
              return booleanValue;
            }}
            filterSort={(optionA: any, optionB: any) =>
              optionA.value
                .toLowerCase()
                .localeCompare(optionB.value.toLowerCase())
            }
          >
            {selectedAppointment &&
              servicePlans
                ?.filter((val) => val.id !== selectedAppointment?.plan?.id)
                ?.map((option, index) => {
                  return (
                    <AntdSelect.Option key={index} value={option.id}>
                      <span style={{ textTransform: "capitalize" }}>
                        {option.name}
                      </span>
                    </AntdSelect.Option>
                  );
                })}
          </Select>
        </Col>

        <Row justify='end' style={{ marginTop: "20px" }}>
          <Button
            type='secondary'
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button htmlType='submit' type='primary' disabled={isLoading}>
            {isLoading ? "Updating" : "Update"}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditAdditionalServicesModal;
