import styled from "styled-components";
import { Modal } from "antd";

export const ModalWrapper = styled(Modal)`
  width: 1092px !important;
  .ant-modal-content {
    height: 787px;
  }
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px;
    border: none;
    @media (min-width: 1024px) {
      padding: 20px;
    }
    @media (min-width: 1400px) {
      padding: 24px;
    }
  }
  .ant-modal-title {
    color: #344054;
    font-family: DM Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }

  .ant-modal-body {
    padding: 16px 24px 0px;
  }
  .preview-content {
    position: fixed;
    max-height: 42rem;
    overflow: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-right: 20px;
    width: 377px;
    @media (min-width: 768px) {
      width: 710px;
    }
    @media (min-width: 1024px) {
      width: 962px;
      max-height: 38rem;
    }
    @media (min-width: 1400px) {
      width: 1000px;
    }
    .header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      img {
        height: 60px;
        width: 60px;
        border-radius: 50px;
      }
      h1 {
        color: #101828;
        font-family: DM Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin-bottom: 0;
      }

      span {
        color: #667085;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
`;

export const FormPreview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;

  .question__header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
      display: flex;
      gap: 15px;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin: 0 auto 0;
        gap: 12px;
        border-radius: 68.4px;
        background: #0d97cd;
        color: #fff;
        font-family: DM Sans;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      h4 {
        color: #101828;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin: 0;
      }
    }

    span {
      color: #667085;
      font-family: DM Sans;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 22.465px;
    }
  }

  .ant-input {
    width: 311px;
    @media (min-width: 768px) {
      width: 500px;
    }
    @media (min-width: 1024px) {
      width: 700px;
    }
  }
  .ant-checkbox-group {
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    width: 311px;
    @media (min-width: 768px) {
      width: 500px;
    }
    @media (min-width: 1024px) {
      width: 700px;
    }
    .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
      cursor: pointer;
      .ant-checkbox-disabled {
        cursor: pointer;
        + span {
          color: #64748b;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          cursor: pointer;
        }
      }
    }
    .ant-checkbox-inner {
      background: transparent;
    }
  }

  .ant-radio-group {
    border-radius: 4px;
    border: 1px solid #d0d5dd;
    width: 311px;
    @media (min-width: 768px) {
      width: 500px;
    }
    height: 48px;
    display: flex;
    align-items: center;
    padding-left: 25px;
    @media (min-width: 1024px) {
      width: 700px;
    }
    .ant-radio-wrapper-disabled {
      cursor: pointer;
      .ant-radio-disabled,
      .ant-radio-disabled,
      .ant-radio-input,
      .ant-radio-disabled,
      .ant-radio-inner {
        cursor: pointer;
        background: transparent;
        border-color: #d0d5dd;
        + span {
          cursor: pointer;
          color: #64748b;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
        }
      }
    }
  }
`;
