import {
  Form,
  Row,
  Col,
  Select as AntdSelect,
  message as AntdMessage
} from "antd";
import { useState, useEffect } from "react";
import { generatePath, useHistory } from "react-router-dom";

import { ModalWrapper } from "layouts/DashboardLayout/styles";
import { CustomForm } from "./styles";
import { debounce } from "utils/utils";
import TextArea from "component/TextArea";
import Button from "component/Button";
import Select from "component/Select";
import { MixPanel } from "utils/mixpanel";

import { useAppSelector } from "redux/store";
import { userSelector } from "redux/reducers/users";
import { profileSelector } from "redux/reducers/profile";
import { useSendBulkMessagesMutation } from "redux/queries/messages";

import { UserType } from "constants/types";
import { ROUTES } from "constants/routes";

interface SendMessageModalTypes {
  isModalVisible: boolean;
  handleCancel: () => void;
}

function SendMessageModal({
  isModalVisible,
  handleCancel
}: SendMessageModalTypes) {
  const history = useHistory();
  const [sendMessages] = useSendBulkMessagesMutation();

  const [sendMsgForm] = Form.useForm();

  const { users } = useAppSelector(userSelector);
  const { profile: ProviderProfile } = useAppSelector(profileSelector);

  const [messageVal, setMessageVal] = useState(
    `\n\n - ${ProviderProfile?.business_name}`
  );
  const [wordCount, setWordCount] = useState(messageVal.length);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllPatients, setSelectAllPatients] = useState(false);

  async function sendMsg(values: any) {
    const _values = values as { selectedPatients: string[]; message: string };
    setIsLoading(true);
    try {
      await sendMessages({
        users: selectAllPatients
          ? users.map((user) => user.id)
          : [..._values.selectedPatients],
        message: _values.message
      });
      MixPanel.track("MessageSent", {
        messageType: "general",
        wordCount
      });
      AntdMessage.success("Message(s) sent");
      history.push(
        generatePath(ROUTES.MESSAGES_DETAILS, {
          patientId: selectAllPatients
            ? users.map((user) => user.id)[0]
            : _values.selectedPatients[0]
        })
      );
      sendMsgForm.resetFields();
      setMessageVal(`\n\n - ${ProviderProfile?.business_name}`);
      handleCancel();
    } catch (err) {
      AntdMessage.error("An error occurred. Please try again...");
    } finally {
      setIsLoading(false);
    }
  }

  const delayWordCount = debounce(setWordCount, 150);

  useEffect(() => {
    if (ProviderProfile) {
      setMessageVal(`\n\n - ${ProviderProfile.business_name}`);
    }
  }, [ProviderProfile]);

  function handleSelectAllPatients() {
    setSelectAllPatients((prev) => !prev);
  }

  return (
    <ModalWrapper
      title={
        <div className='header'>
          <h3>Send message</h3>
          <p>You can send message to multiple patients at the same time.</p>
        </div>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className='dashboard-mode-modal'
    >
      <CustomForm
        name='sendMsgForm'
        layout='vertical'
        form={sendMsgForm}
        onFinish={sendMsg}
      >
        <Row>
          <Col xs={24} className='select-wrapper'>
            <Select
              mode='normal'
              placeholder='Select patient(s)'
              multiple
              disabled={selectAllPatients}
              showSearch
              formItem={{
                label: "Add patients",
                name: "selectedPatients",
                rules: [
                  {
                    required: selectAllPatients ? false : true,
                    message: "Please select patient(s)"
                  }
                ]
              }}
              optionFilterProp='children'
              filterOption={(_input: string, option: any) => {
                const input = _input.trim().toLowerCase();
                const singleUser = users.find(
                  (user: UserType) => user.id === option.value
                )!;
                const doesExist =
                  singleUser.email.toLowerCase().includes(input) ||
                  singleUser.first_name?.toLowerCase().includes(input) ||
                  singleUser.last_name?.toLowerCase().includes(input) ||
                  (
                    singleUser.first_name?.toLowerCase() +
                    " " +
                    singleUser.last_name?.toLowerCase()
                  ).includes(input) ||
                  (
                    singleUser.last_name?.toLowerCase() +
                    " " +
                    singleUser.first_name?.toLowerCase()
                  ).includes(input);
                return doesExist;
              }}
              filterSort={(optionA: any, optionB: any) =>
                optionA.value
                  .toLowerCase()
                  .localeCompare(optionB.value.toLowerCase())
              }
            >
              {users?.map((user) => {
                return (
                  <AntdSelect.Option key={user.id} value={user.id}>
                    <span style={{ textTransform: "capitalize" }}>
                      {user?.first_name} {user?.last_name}
                    </span>
                  </AntdSelect.Option>
                );
              })}
            </Select>

            <div className='checkbox'>
              <input
                id='checkbox'
                type='checkbox'
                checked={selectAllPatients}
                onChange={() => handleSelectAllPatients()}
                style={{ cursor: "pointer" }}
              />{" "}
              <label htmlFor='checkbox'>Select all patients?</label>
            </div>
          </Col>

          <Col span={24}>
            <TextArea
              formItem={{
                name: "message",
                label: `Add your message (${wordCount} of ${160} characters)`,
                rules: [
                  {
                    required: true,
                    message: "Please enter message"
                  }
                ]
              }}
              label=''
              onChange={(e: any) => {
                delayWordCount(e.target.value.length);
                setMessageVal(e.target.value);
              }}
              defaultValue={messageVal}
              mode='normal'
              maxLength={160}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>

          <Col span={24} style={{ display: "flex", justifyContent: "center" }}>
            <Button htmlType='submit' type='primary' disabled={isLoading}>
              {isLoading ? "Sending..." : "Send message"}
            </Button>
          </Col>
        </Row>
      </CustomForm>
    </ModalWrapper>
  );
}

export default SendMessageModal;
