import React, { useState } from "react";
import { Row as Rows } from "./style";
import { Col, Row, Select as AntdSelect, Form, Radio, Space } from "antd";
import Button from "component/Button";
import Input from "../Input";
import { FormItem } from "../../layouts/DashboardLayout/styles";
import DatePicker from "../DatePicker";
import Select from "../Select";
import TextArea from "../TextArea";
import { DownloadOutlined, PlusOutlined } from "@ant-design/icons";
import { ReactComponent as DeleteIcon } from "assets/icons/trash.svg";
const MedicalHistoryForm = () => {
  const [addMedicalHistory] = Form.useForm();

  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isTansfusionOpen, setIsTransfusionOpen] = useState(false);
  const [isSurgeryDone, setIsSurgeryDone] = useState(false);
  const [isFamily, setIsFamily] = useState(false);

  return (
    <Form name="addFollowUpForm" layout="vertical" form={addMedicalHistory}>
      <Rows>
        <h1>Vitals Information</h1>
        <Row gutter={24}>
          <Col span={24} md={10}>
            <Select
              formItem={{
                label: "Blood Group",
                name: "blood_group",
                rules: [
                  {
                    required: true,
                    message: "Please select patient blood group",
                  },
                ],
              }}
              mode="normal"
              placeholder="Select blood group"
            >
              {["+A", "-A", "+B", "-B", "AB", "+O", "-O"].map(
                (gender: string, index: number) => (
                  <AntdSelect.Option key={index} value={gender}>
                    <span>{gender}</span>
                  </AntdSelect.Option>
                ),
              )}
            </Select>
          </Col>
          <Col span={24} md={10}>
            <Select
              formItem={{
                label: "Genotype",
                name: "genotype",
                rules: [
                  {
                    required: true,
                    message: "Please select patient genotype",
                  },
                ],
              }}
              mode="normal"
              placeholder="Select genotype"
            >
              {["AA", "AS", "SS", "AC"].map((gender: string, index: number) => (
                <AntdSelect.Option key={index} value={gender}>
                  <span>{gender}</span>
                </AntdSelect.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "Weight (kg)",
                name: "weight",
                rules: [
                  {
                    required: true,
                    message: "Please input weight",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "Height (ft)",
                name: "height",
                rules: [
                  {
                    required: true,
                    message: "Please input Height (ft)",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "How frequently do you drink?",
                name: "drink",
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "How frequently do you smoke?",
                name: "smoking",
              }}
              mode="normal"
              type="text"
            />
          </Col>
        </Row>
      </Rows>
      <Rows>
        <Row gutter={24}>
          <Col xs={20}>
            <TextArea
              formItem={{
                name: "allergies",
                label: "Allergies?",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient allergies",
                  },
                ],
              }}
              placeholder="Input allergies"
              maxLength={300}
              label=""
              mode="normal"
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>
          <Col xs={20}>
            <TextArea
              formItem={{
                name: "mediacations",
                label: "What medications are you on?",
              }}
              placeholder="Input medications"
              maxLength={300}
              label=""
              mode="normal"
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>
        </Row>
      </Rows>
      <Rows>
        <h1>Do you have any family history conditions?</h1>
        <Radio.Group onChange={(e: any) => setIsFamily(e.target.value === 1)}>
          <Radio value={2}>No</Radio>
          <Radio value={1}>Yes</Radio>
        </Radio.Group>
        {isFamily && (
          <Form.List name={"family-history"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row gutter={24} key={field.key}>
                    <Col xs={24} md={8}>
                      <Select
                        {...field}
                        formItem={{
                          label: "State conditions?",
                        }}
                        name={[field.name, "conditions"]}
                        mode="normal"
                      >
                        {[""].map((gender: string, index: number) => (
                          <AntdSelect.Option key={index} value={gender}>
                            <span>{gender}</span>
                          </AntdSelect.Option>
                        ))}
                      </Select>
                    </Col>

                    <Col span={24} md={8}>
                      <Select
                        formItem={{
                          label: "Member of family",
                        }}
                        {...field}
                        name={[field.name, "family-members"]}
                        mode="normal"
                      >
                        {["Father", "Mother", "Sister", "Brother"].map(
                          (family: string, index: number) => (
                            <AntdSelect.Option key={index} value={family}>
                              <span>{family}</span>
                            </AntdSelect.Option>
                          ),
                        )}
                      </Select>
                    </Col>
                    <Col xs={24} md={8}>
                      <Button
                        type="danger"
                        style={{ marginTop: "30px" }}
                        onClick={() => remove(field.name)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="btn">
                  <span className="link">
                    <Button onClick={() => add()}>
                      {" "}
                      <PlusOutlined />
                      Add history
                    </Button>{" "}
                  </span>
                </div>
              </>
            )}
          </Form.List>
        )}
      </Rows>
      <Rows>
        <h1>Have you had any surgical procedures done?</h1>
        <Radio.Group
          onChange={(e: any) => setIsSurgeryDone(e.target.value === 1)}
        >
          <Radio value={2}>No</Radio>
          <Radio value={1}>Yes</Radio>
        </Radio.Group>
        {isSurgeryDone && (
          <Form.List name={"surgery"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row gutter={24} key={field.key}>
                    <Col xs={24} md={8}>
                      <Select
                        {...field}
                        formItem={{
                          label: "Surgical Procedure",
                        }}
                        name={[field.name, "surgery"]}
                        mode="normal"
                      >
                        {[""].map((gender: string, index: number) => (
                          <AntdSelect.Option key={index} value={gender}>
                            <span>{gender}</span>
                          </AntdSelect.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={24} md={8}>
                      <FormItem
                        label="Select Year"
                        {...field}
                        name={[field.name, "year-vaccine"]}
                        rules={[
                          {
                            required: true,
                            message: "Input Patient Date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          marginBottom={0}
                          mode="normal"
                          width="auto"
                          picker="year"
                          placeholder="YYYY"
                          inputReadOnly={true}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} md={8}>
                      <Button
                        type="danger"
                        style={{ marginTop: "30px" }}
                        onClick={() => remove(field.name)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="btn">
                  <span className="link">
                    <Button onClick={() => add()}>
                      {" "}
                      <PlusOutlined />
                      Add history
                    </Button>{" "}
                  </span>
                </div>
              </>
            )}
          </Form.List>
        )}
      </Rows>
      <Rows>
        <h1>Have you had any transfusion procedures done?</h1>
        <Radio.Group
          onChange={(e: any) => setIsTransfusionOpen(e.target.value === 1)}
        >
          <Radio value={2}>No</Radio>
          <Radio value={1}>Yes</Radio>
        </Radio.Group>
        {isTansfusionOpen && (
          <Form.List name={"transfusion"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row gutter={24} key={field.key}>
                    <Col xs={24} md={8}>
                      <Select
                        {...field}
                        formItem={{
                          label: "Transfusion Procedures",
                        }}
                        name={[field.name, "transfusion"]}
                        mode="normal"
                      >
                        {[""].map((gender: string, index: number) => (
                          <AntdSelect.Option key={index} value={gender}>
                            <span>{gender}</span>
                          </AntdSelect.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={24} md={8}>
                      <FormItem
                        label="Select Year"
                        {...field}
                        name={[field.name, "year-vaccine"]}
                        rules={[
                          {
                            required: true,
                            message: "Input Patient Date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          marginBottom={0}
                          mode="normal"
                          width="auto"
                          picker="year"
                          placeholder="YYYY"
                          inputReadOnly={true}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} md={8}>
                      <Button
                        type="danger"
                        style={{ marginTop: "30px" }}
                        onClick={() => remove(field.name)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="btn">
                  <span className="link">
                    <Button onClick={() => add()}>
                      {" "}
                      <PlusOutlined />
                      Add history
                    </Button>{" "}
                  </span>
                </div>
              </>
            )}
          </Form.List>
        )}
      </Rows>
      <Rows>
        <h1>Have you received any vaccination?</h1>
        <Radio.Group onChange={(e: any) => setIsFormOpen(e.target.value === 1)}>
          <Radio value={2}>No</Radio>
          <Radio value={1}>Yes</Radio>
        </Radio.Group>
        {isFormOpen && (
          <Form.List name={"vaccine"}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field) => (
                  <Row gutter={24} key={field.key}>
                    <Col xs={24} md={8}>
                      <Select
                        {...field}
                        formItem={{
                          label: "Vaccination",
                        }}
                        name={[field.name, "vaccine"]}
                        mode="normal"
                      >
                        {[""].map((gender: string, index: number) => (
                          <AntdSelect.Option key={index} value={gender}>
                            <span>{gender}</span>
                          </AntdSelect.Option>
                        ))}
                      </Select>
                    </Col>
                    <Col span={24} md={8}>
                      <FormItem
                        label="Select Year"
                        {...field}
                        name={[field.name, "year-vaccine"]}
                        rules={[
                          {
                            required: true,
                            message: "Input Patient Date of birth",
                          },
                        ]}
                      >
                        <DatePicker
                          marginBottom={0}
                          mode="normal"
                          width="auto"
                          picker="year"
                          placeholder="YYYY"
                          inputReadOnly={true}
                        />
                      </FormItem>
                    </Col>
                    <Col xs={24} md={8}>
                      <Button
                        type="danger"
                        style={{ marginTop: "30px" }}
                        onClick={() => remove(field.name)}
                      >
                        <DeleteIcon />
                      </Button>
                    </Col>
                  </Row>
                ))}
                <div className="btn">
                  <span className="link">
                    <Button onClick={() => add()}>
                      {" "}
                      <PlusOutlined />
                      Add history
                    </Button>{" "}
                  </span>
                </div>
              </>
            )}
          </Form.List>
        )}
      </Rows>
      <Button
        type={"primary"}
        style={{ marginTop: "40px", width: "207px", height: "48px" }}
      >
        Update profile
      </Button>
    </Form>
  );
};

export default MedicalHistoryForm;
