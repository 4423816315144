import { useEffect, useLayoutEffect } from "react";

import { useAppSelector } from "redux/store";
import PractitionersTable from "./containers/PractitionersTable";
import { PractitionersWrapper, ContentWrapper } from "./styles";

const Patients = () => {
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription
  );

  useLayoutEffect(() => {
    if (subscribedPlan === "freemium") {
      window.location.replace("/payments");
    }
  }, [subscribedPlan]);

  useEffect(() => {
    document.title = "FrontDesk | Practitioners";
  }, []);

  return (
    <PractitionersWrapper>
      <ContentWrapper>
        <PractitionersTable />
      </ContentWrapper>
    </PractitionersWrapper>
  );
};

export default Patients;
