import connectApi from "./connect-api";
import {
  CreateFormInput,
  GetServiceFormAnswer,
  GetServiceFormsRes,
  SERVICE_FORMSRTKQueryTagEnum,
  SubmitServiceFormResponseInput,
} from "../../constants/types";
import { axiosBaseQuery, providesList } from "../../utils/redux-toolkit-query";
import { createApi } from "@reduxjs/toolkit/query/react";
import { httpWithIntegration } from "../../utils/api";

export const serviceFormsApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    createServiceForms: builder.mutation<any, CreateFormInput>({
      query: (data) => ({
        url: `/service_forms`,
        method: "POST",
        data,
      }),
    }),
    getAllServiceForms: builder.query<GetServiceFormsRes, void>({
      query: () => ({
        url: "/service_forms",
      }),
      providesTags: (data) =>
        providesList(
          SERVICE_FORMSRTKQueryTagEnum.SERVICE_FORMS,
          data?.data!,
        ) as any,
    }),
    getSingleServiceForm: builder.query<
      GetServiceFormsRes,
      { questionId: string }
    >({
      query: ({ questionId }) => ({
        url: `/service_forms?id=${questionId}`,
      }),
      providesTags: (data) =>
        providesList(
          SERVICE_FORMSRTKQueryTagEnum.SERVICE_FORMS,
          data?.data!,
        ) as any,
    }),
    getAllServiceFormsResponse: builder.query<GetServiceFormAnswer, void>({
      query: () => ({
        url: `/service_forms/answers`,
      }),
      providesTags: (data) =>
        providesList(
          SERVICE_FORMSRTKQueryTagEnum.SERVICE_FORMS,
          data?.data!,
        ) as any,
    }),
    getSingleServiceFormsResponse: builder.query<
      GetServiceFormAnswer,
      { responseId: string }
    >({
      query: ({ responseId }) => ({
        url: `/service_forms/answers?id=${responseId}`,
      }),
      providesTags: (data) =>
        providesList(
          SERVICE_FORMSRTKQueryTagEnum.SERVICE_FORMS,
          data?.data!,
        ) as any,
    }),
  }),
  overrideExisting: true,
});

serviceFormsApi.enhanceEndpoints({
  addTagTypes: [...Object.values(SERVICE_FORMSRTKQueryTagEnum)],
});

export const {
  useCreateServiceFormsMutation,
  useGetAllServiceFormsQuery,
  useGetAllServiceFormsResponseQuery,
  useGetSingleServiceFormQuery,
  useGetSingleServiceFormsResponseQuery,
} = serviceFormsApi;

export const submitFormApi = createApi({
  reducerPath: "submitFormApi",
  baseQuery: axiosBaseQuery(undefined, httpWithIntegration),
  endpoints: (builder) => ({
    handleSubmitForm: builder.mutation<any, SubmitServiceFormResponseInput>({
      query: (data) => ({
        url: "/service_forms/answers",
        method: "POST",
        data,
      }),
    }),
  }),
});

export const { useHandleSubmitFormMutation } = submitFormApi;
