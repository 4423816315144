import {
  Col,
  Form,
  Row,
  Select as AntdSelect,
  message as AntdMessage,
  Tooltip,
  Switch,
  Radio,
  TimePicker,
  Table,
  Spin
} from "antd";
import {
  CopyOutlined,
  CloseCircleOutlined,
  UploadOutlined,
  EditOutlined,
  InfoCircleFilled,
  CheckCircleOutlined,
  CalendarOutlined,
  DownOutlined,
  UpOutlined,
  CloudDownloadOutlined
} from "@ant-design/icons";
import { uid } from "uid";
import { useEffect, useRef, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";
import moment from "moment";

import Button from "component/Button";
import Input from "component/Input";
import Select from "component/Select";
import { useAppSelector, useAppDispatch } from "redux/store";
import { storage } from "utils/firebase";
import {
  profileSelector,
  updateProviderProfile,
  uploadBusinessIcon
} from "redux/reducers/profile";
import {
  fetchSubscription,
  updateSubscription,
  fetchSubscriptionHistory
} from "redux/slices/subscriptions";
import { servicePlansSelector } from "redux/reducers/servicePlans";
import {
  ContentWrapper,
  PasswordConfirmWrapper,
  ProfileWrapper,
  UploadWrapper,
  UploadLarge,
  Tabs,
  GalleryWrapper,
  RadioGroup,
  SubscriptionWrapper
} from "./styles";
import TextArea from "component/TextArea";
import { nigerianStateAndCities } from "constants/nigerianStateAndCities";
import {
  authSelector,
  setConfirmPasswordSuccess,
  updateAuthEmail
} from "redux/reducers/auth";
import {
  capitaliseFirstLetter,
  daysOfWeek,
  handlePhoneNumberFormat,
  insuranceCompanies
} from "utils/utils";
import GalleryModal from "component/GalleryModal";
import Subscriptions, { prices, paymentPages } from "constants/subscriptions";

// svgs
import { ReactComponent as PaystackIcon } from "assets/icons/paystack-icon.svg";
import { ReactComponent as CashIcon } from "assets/icons/cash-icon.svg";
import { ReactComponent as InsuranceIcon } from "assets/icons/insurance-icon.svg";
import { ReactComponent as ProfileIcon } from "assets/icons/profile-pic-icon.svg";
import { ReactComponent as UploadIcon } from "assets/icons/upload-icon.svg";
import { ReactComponent as GalleryIcon } from "assets/icons/gallery-icon.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-icon.svg";
import Invoice from "./components/Invoice";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { MixPanel } from "utils/mixpanel";

type FormValues = {
  name: string;
  phone: string;
  email: string;
  street_line_one: string;
  city: string;
  state: string;
  country: string;
  type: string;
  description: string;
  website_url: string;
  google_review_url: string;
  whatsapp: string;
  twitter: string;
  instagram: string;
  facebook: string;
  business_name: string;
  slug: string;
  cash: boolean;
  insurance: boolean;
  selectedProviders: string[];
  coverImage: string;
  quickService: string;
  actionText: string;
  open_hours: string;
  custom_hours: {};
  keywords: string[];
};

const Profile = (props: any) => {
  const { search } = useLocation();
  const { servicePlans } = useAppSelector(servicePlansSelector);
  const { due_date, duration, plan, history } = useAppSelector(
    (state) => state.subscription
  );
  const [basiform] = Form.useForm();
  const [otherForm] = Form.useForm();
  const [testWebhookForm] = Form.useForm();
  const [liveWebhookForm] = Form.useForm();
  const dispatch = useAppDispatch();
  const handleRef = useRef();
  const [selectedState, setSelectedState] =
    useState<keyof typeof nigerianStateAndCities>();
  const [subDomain, setSubDomain] = useState("");
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isEdit, setEdit] = useState(false);
  const [profileUpdatePayload, setProfileUpdatePayload] = useState<
    object | any
  >({});
  const [formattedPhone, setFormattedPhone] = useState("");
  const { confirmPasswordLoading, confirmPasswordSuccess } =
    useSelector(authSelector);
  const { profile, iconURL, isUpdatingIconLoading } =
    useSelector(profileSelector);

  const {
    name,
    email,
    phone,
    address,
    type,
    description,
    website_url,
    // social_media,
    business_name,
    id,
    slug,
    metadata,
    logo_path
  } = profile || {};
  const { street_line_one, state, city, country } = address || {};
  const {
    social_media,
    payment_options,
    google_review_url,
    whatsapp,
    pneumapage
  } = metadata || {};
  const { twitter, instagram, facebook } = social_media || {};
  const { cash, insurance, selectedProviders } = payment_options || {};
  const {
    coverImage,
    quickService,
    open_hours,
    custom_hours,
    actionText,
    keywords
  } = pneumapage || {};

  const profileDetails = {
    name,
    email,
    phone,
    type,
    street_line_one,
    state,
    city,
    country,
    description,
    website_url,
    google_review_url,
    whatsapp,
    twitter,
    instagram,
    facebook,
    business_name,
    slug,
    logo_path,
    cash,
    insurance,
    selectedProviders,
    coverImage,
    quickService,
    actionText,
    open_hours,
    custom_hours,
    keywords
  };

  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    phone: "",
    email: "",
    street_line_one: "",
    city: "",
    state: "",
    country: "",
    type: "",
    description: "",
    website_url: "",
    google_review_url: "",
    whatsapp: "",
    twitter: "",
    instagram: "",
    facebook: "",
    business_name: "",
    slug: "",
    cash: false,
    insurance: false,
    selectedProviders: [],
    coverImage: "",
    quickService: "",
    actionText: "",
    open_hours: "",
    custom_hours: {},
    keywords: []
  });

  const [editedvalues, setEditedValues] = useState<FormValues>({
    name: "",
    phone: "",
    email: "",
    street_line_one: "",
    city: "",
    state: "",
    country: "",
    type: "",
    description: "",
    website_url: "",
    google_review_url: "",
    whatsapp: "",
    twitter: "",
    instagram: "",
    facebook: "",
    business_name: "",
    slug: "",
    cash: false,
    insurance: false,
    selectedProviders: [],
    coverImage: "",
    quickService: "",
    actionText: "",
    open_hours: "",
    custom_hours: {},
    keywords: []
  });

  const [nonNullFieldValues, setNonNullFieldValues] = useState<any | object>(
    {}
  );

  const [coverImageURL, setCoverImageURL] = useState("");

  const onChange = (currentField: any): void => {
    const currentKey = Object.keys(currentField)[0];
    const currentValue = Object.values(currentField)[0];
    setEditedValues({ ...editedvalues, [currentKey]: currentValue });
  };

  useEffect(() => {
    setSelectedState(state);
  }, [state]);

  useEffect(() => {
    document.title = "FrontDesk | Profile";
  }, []);

  useEffect(() => {
    setFormValues(profileDetails);
    setEditedValues(profileDetails);
  }, [profile]);

  // FILTERING FOR FIELDS CHANGED.
  useEffect(() => {
    const _formValues = { ...formValues } as any;
    const _editValues = { ...editedvalues } as any;
    const changedValues = Object.keys(formValues).filter(
      (key: any) => _formValues[key] !== _editValues[key]
    );
    const newValues = Object.fromEntries(
      changedValues.map((value: any) => [value, _editValues[value]])
    );
    setNonNullFieldValues(newValues);
  }, [editedvalues]);

  const confirmPassword = (values: object | any) => {
    dispatch(
      updateAuthEmail({ ...profileUpdatePayload, password: values.password })
    );
  };

  useEffect(() => {
    basiform.setFieldsValue({
      ...profileDetails,
      street_line_one:
        street_line_one && street_line_one !== "null" ? street_line_one : "",
      state: state && state !== "null" ? state : "",
      city: city && city !== "null" ? city : "",
      country: country && country !== "null" ? country : "",
      description: description && description !== "null" ? description : "",
      website_url: website_url && website_url !== "null" ? website_url : "",
      google_review_url:
        google_review_url && google_review_url !== "null"
          ? google_review_url
          : "",
      whatsapp: whatsapp && whatsapp !== "null" ? whatsapp : "",
      twitter: twitter && twitter !== "null" ? twitter : "",
      instagram: instagram && instagram !== "null" ? instagram : "",
      facebook: facebook && facebook !== "null" ? facebook : "",
      slug: "",
      cash: cash ? true : false,
      insurance: insurance ? true : false,
      selectedProviders:
        selectedProviders && selectedProviders !== null && insurance
          ? selectedProviders
          : [],
      quickService: quickService && quickService !== "null" ? quickService : "",
      actionText:
        actionText && actionText !== "null"
          ? actionText
          : "Book an appointment",
      keywords: keywords && keywords !== null ? keywords : []
    });
  }, [
    subDomain,
    profile,
    basiform,
    otherForm,
    testWebhookForm,
    liveWebhookForm
  ]);

  const definePayload = () => {
    const objData: any = {};

    if (iconURL) {
      objData.logo_path = iconURL;
    }
    if (nonNullFieldValues.name) {
      objData.name = nonNullFieldValues.name;
    }
    if (nonNullFieldValues.business_name) {
      objData.business_name = nonNullFieldValues.business_name;
    }
    if (nonNullFieldValues.email) {
      objData.email = nonNullFieldValues.email;
    }
    if (nonNullFieldValues.slug) {
      objData.slug = `${nonNullFieldValues.slug}`.toLowerCase();
    }
    if (nonNullFieldValues.phone) {
      objData.phone = nonNullFieldValues.phone;
    }
    if (
      nonNullFieldValues.street_line_one ||
      nonNullFieldValues.city ||
      nonNullFieldValues.state ||
      nonNullFieldValues.country
    ) {
      const addressObj: any = {
        street_line_one: nonNullFieldValues.street_line_one,
        city: nonNullFieldValues.city,
        state: nonNullFieldValues.state,
        country: nonNullFieldValues.country
      };

      for (const key in addressObj) {
        const isData = addressObj[key];
        if (!isData) {
          delete addressObj[key];
        }
      }
      objData.address = {
        ...addressObj
      };
    }

    if (nonNullFieldValues.type) {
      objData.type = nonNullFieldValues.type;
    }

    if (nonNullFieldValues.description) {
      objData.description = nonNullFieldValues.description;
    }

    if (nonNullFieldValues.website_url) {
      objData.website_url = nonNullFieldValues.website_url;
    }

    if (nonNullFieldValues.google_review_url) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: nonNullFieldValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: pneumapage?.actionText ?? actionText,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (nonNullFieldValues.whatsapp) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: nonNullFieldValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: pneumapage?.actionText ?? actionText,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (
      nonNullFieldValues.twitter ||
      nonNullFieldValues.instagram ||
      nonNullFieldValues.facebook
    ) {
      const socialMediaObj: any = {
        twitter: nonNullFieldValues.twitter ?? formValues.twitter,
        instagram: nonNullFieldValues.instagram ?? formValues.instagram,
        facebook: nonNullFieldValues.facebook ?? formValues.facebook
      };

      for (const key in socialMediaObj) {
        const handle = socialMediaObj[key];
        if (!handle) {
          delete socialMediaObj[key];
        } else {
          socialMediaObj[key] = handle;
        }
      }

      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: { ...socialMediaObj },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: pneumapage?.actionText ?? actionText,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (
      nonNullFieldValues.selectedProviders ||
      nonNullFieldValues.cash ||
      nonNullFieldValues.insurance
    ) {
      const paymentOptionsObj: any = {
        cash: nonNullFieldValues.cash || basiform.getFieldValue("cash"),
        insurance:
          nonNullFieldValues.insurance || basiform.getFieldValue("insurance"),
        selectedProviders:
          nonNullFieldValues.insurance || basiform.getFieldValue("insurance")
            ? nonNullFieldValues.selectedProviders ||
              basiform.getFieldValue("selectedProviders")
            : []
      };

      for (const key in paymentOptionsObj) {
        const handle = paymentOptionsObj[key];

        paymentOptionsObj[key] = handle;
      }

      objData.metadata = {
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        payment_options: { ...paymentOptionsObj },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: pneumapage?.actionText ?? actionText,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (coverImageURL) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          actionText: actionText ?? formValues.actionText,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (nonNullFieldValues.quickService) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: nonNullFieldValues.quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: pneumapage?.actionText ?? actionText,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (nonNullFieldValues.actionText) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: nonNullFieldValues.actionText,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (updatedOpenHours) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? formValues.quickService,
          actionText: pneumapage?.actionText ?? formValues.actionText,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          open_hours: openHours,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (updatedCustomHours) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? formValues.quickService,
          actionText: pneumapage?.actionText ?? formValues.actionText,
          open_hours: "custom",
          custom_hours: customHours,
          keywords: pneumapage?.keywords ?? keywords
        }
      };
    }

    if (nonNullFieldValues.keywords) {
      objData.metadata = {
        payment_options: {
          cash: formValues.cash,
          insurance: formValues.insurance,
          selectedProviders: formValues.selectedProviders
        },
        social_media: {
          twitter: formValues.twitter,
          facebook: formValues.facebook,
          instagram: formValues.instagram
        },
        google_review_url: formValues.google_review_url,
        whatsapp: formValues.whatsapp,
        pneumapage: {
          coverImage: pneumapage?.coverImage ?? coverImageURL,
          quickService: pneumapage?.quickService ?? quickService,
          open_hours: pneumapage?.open_hours ?? openHours,
          custom_hours: pneumapage?.custom_hours ?? customHours,
          actionText: pneumapage?.actionText ?? actionText,
          keywords: nonNullFieldValues.keywords
        }
      };
    }

    return objData;
  };

  const handleSubmitBasicDetails = async () => {
    if (Object.keys(definePayload()).length) {
      if (definePayload().email) {
        setProfileUpdatePayload(definePayload());
        setIsVisible(true);
      } else {
        setLoading(true);
        try {
          const data = definePayload();
          const onlySlugUpdated =
            Object.keys(data).length === 1 && Object.keys(data)[0] === "slug";
          await dispatch(updateProviderProfile(data));
          AntdMessage.success(
            onlySlugUpdated
              ? "PneumaPage URL successfully updated!"
              : "Profile successfully updated!"
          );
          setEdit(false);
        } catch (error) {
          const err = error as Error;
          AntdMessage.error(err.message);
        } finally {
          setLoading(false);
        }
      }
    } else {
      AntdMessage.error("No field updated!");
    }
  };

  useEffect(() => {
    if (confirmPasswordSuccess) {
      setIsVisible(false);
      setConfirmPasswordSuccess(false);
    }
  }, [confirmPasswordSuccess]);

  useEffect(() => {
    const _handleRef: HTMLInputElement = document.querySelector(
      ".page-input .ant-input"
    )!;
    if (isEdit) {
      basiform.setFields([
        {
          name: ["slug"],
          value: slug ? slug : id
        }
      ]);
      _handleRef.focus();
    } else {
      basiform.setFields([
        {
          name: ["slug"],
          value: ""
        }
      ]);
    }
  }, [isEdit]);

  function formatPhoneNumber(
    number: string,
    numberDetails: { dialCode: string }
  ) {
    const formattedPhone = handlePhoneNumberFormat(
      number,
      numberDetails.dialCode
    );

    setFormattedPhone(formattedPhone);
  }

  // these two 'flag' variables help to keep track of which has been updated to prevent overwrites. they are appropriately adjusted at relevant points in an 'either-or' fashion. they are needed because we cant track the `openHours` & `customHours` using the nonNullValues object.
  const [updatedOpenHours, setUpdatedOpenHours] = useState(false);
  const [updatedCustomHours, setUpdatedCustomHours] = useState(false);

  const [openHours, setOpenHours] = useState<"always-open" | "custom">();

  useEffect(() => {
    setOpenHours(open_hours ?? "custom");
  }, [open_hours]);

  const [customHours, setCustomHours] = useState<{
    [key in daysOfWeek]: { isOpen: boolean; open: string; close: string };
  }>();

  useEffect(() => {
    setCustomHours(
      pneumapage?.custom_hours ?? {
        sunday: { isOpen: false, open: "9:00 am", close: "6:00 pm" },
        monday: { isOpen: true, open: "9:00 am", close: "6:00 pm" },
        tuesday: { isOpen: true, open: "9:00 am", close: "6:00 pm" },
        wednesday: { isOpen: true, open: "9:00 am", close: "6:00 pm" },
        thursday: { isOpen: true, open: "9:00 am", close: "6:00 pm" },
        friday: { isOpen: true, open: "9:00 am", close: "6:00 pm" },
        saturday: { isOpen: false, open: "9:00 am", close: "6:00 pm" }
      }
    );
  }, [pneumapage?.custom_hours]);

  const [isGalleryVisible, setIsGalleryVisible] = useState(false);

  const coverImageName = coverImage?.includes("covers%")
    ? coverImage?.split("covers%")[1].split("?")[0]
    : "un-named_unsplash-file.jpg";

  // tags
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  // pricing

  const [selectedPricing, setSelectedPricing] = useState<
    "monthly" | "quarterly"
  >("monthly");

  useEffect(() => {
    if (duration) {
      setSelectedPricing(duration as "monthly" | "quarterly");
    }
  }, [duration]);

  const [showBillingHistory, setShowBillingHistory] = useState(false);

  const [selectedInvoice, setSelectedInvoice] = useState<any>();
  const [isDownloading, setIsDownloading] = useState(false);
  function savePdf() {
    const domElement = document.querySelector("#printJS-invoice");
    setIsDownloading(true);
    html2canvas(domElement as HTMLElement)
      .then((canvas) => {
        const img = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF();
        pdf.addImage(img, "JPEG", 0, 0, 210, 242);
        pdf.save(`${business_name}-${selectedInvoice.plan} invoice.pdf`);
      })
      .catch((err: any) => console.error(err))
      .finally(() => {
        setIsDownloading(false);
      });
  }

  const billingHistoryCols = [
    {
      title: "Plan",
      dataIndex: "plan",
      render: (id: string, record: any) => (
        <span>{capitaliseFirstLetter(record.plan)} plan</span>
      )
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (id: string, record: any) => (
        <span>
          NGN{" "}
          {new Intl.NumberFormat("en-US").format(
            prices[record.duration as "monthly" | "quarterly"][
              record.plan as "start" | "grow" | "engage"
            ]
          )}{" "}
          / {capitaliseFirstLetter(record.duration)}
        </span>
      )
    },
    { title: "Date", dataIndex: "createdAt" },
    {
      title: "Action",
      dataIndex: "id",
      render: (id: string, record: any) => (
        <p
          className='history-action-btn'
          onClick={async () => {
            await setSelectedInvoice({
              ...record,
              amount:
                prices[record.duration as "monthly" | "quarterly"][
                  record.plan as "start" | "grow" | "engage"
                ]
            });
            savePdf();
          }}
        >
          <CloudDownloadOutlined /> Download invoice
        </p>
      )
    }
  ];

  // subscriptions params
  // format => /manage-account?plan={plan}?token={token}
  const searchParams = useMemo(() => new URLSearchParams(search), [search]);

  const payedTerm = searchParams
    .get("plan")
    ?.slice(0, searchParams.get("plan")?.indexOf("?"));

  const providerId = localStorage.getItem("providerID")!;
  const savedDuration = localStorage.getItem("subscriptionDuration")!;

  useEffect(() => {
    if (payedTerm) {
      dispatch(
        updateSubscription({
          providerId,
          due_date:
            savedDuration === "monthly"
              ? moment().add(30, "days").format("MMM Do, YYYY")
              : moment().add(90, "days").format("MMM Do, YYYY"),
          duration: savedDuration || selectedPricing,
          plan: payedTerm
        })
      );

      MixPanel.track("subcription", {
        subscription_plan: payedTerm,
        provider_name: profile?.business_name,
        duration: savedDuration
      });

      AntdMessage.success(
        `Your ${payedTerm} plan has been activated successfully!`
      );

      window.history.pushState({}, document.title, "/manage-account");
    }
  }, [searchParams, payedTerm]);

  useEffect(() => {
    dispatch(fetchSubscription());
  }, [dispatch]);

  return (
    <ProfileWrapper>
      <Row>
        <PasswordConfirmWrapper
          title='Confirm your password'
          className='confirmPass-modal'
          visible={isVisible}
          onCancel={() => setIsVisible(false)}
          centered
          footer={false}
          width={450}
          destroyOnClose
          bodyStyle={{
            padding: "25px 20px 20px"
          }}
          closeIcon={
            <CloseCircleOutlined
              style={{ fontSize: "1.2rem", color: "#1a90ff" }}
            />
          }
          style={{ borderRadius: "80px !important" }}
        >
          <Form
            name='confirmPasswordForm'
            layout='vertical'
            onFinish={confirmPassword}
          >
            <Row>
              <Col span={24}>
                <Input
                  formItem={{
                    label: "Confirm password",
                    name: "password",
                    rules: [
                      {
                        required: true,
                        message:
                          "Please input your password to update your profile"
                      }
                    ]
                  }}
                  mode='normal'
                  type='password'
                />
              </Col>
              <Col span={24} style={{ display: "flex" }}>
                <div style={{ marginLeft: "auto" }}>
                  <Button
                    htmlType='submit'
                    type='primary'
                    disabled={confirmPasswordLoading || loading}
                  >
                    {confirmPasswordLoading ? "Confirming..." : "Confirm"}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </PasswordConfirmWrapper>
        <Col xs={24} md={24}>
          <ContentWrapper>
            <h2>Manage Account</h2>

            <Row justify='center'>
              <Col xs={24} md={23}>
                <Form
                  name='basicForm'
                  layout='vertical'
                  form={basiform}
                  onFinish={handleSubmitBasicDetails}
                  onValuesChange={(changedValues: any, allValues: any) =>
                    onChange(changedValues)
                  }
                >
                  <Tabs defaultActiveKey={searchParams.get("plan") ? "5" : ""}>
                    {/* basic Information */}
                    <Tabs.TabPane tab='Business details' key={1}>
                      {/* business information */}
                      <div className='single-tab-wrapper'>
                        <h4>Business information</h4>

                        <Row gutter={24}>
                          <Col xs={24} md={8}>
                            <Input
                              formItem={{
                                label: "Business name",
                                name: "business_name",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please input name"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='text'
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <Input
                              formItem={{
                                label: "Phone number",
                                name: "phone",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please enter your phone number"
                                  },
                                  {
                                    pattern: /^([+]?)\d+$/,
                                    message:
                                      "Phone number can only contain digits"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='text'
                              label='Phone number'
                            />
                          </Col>
                          <Col span={24} md={8}>
                            <Input
                              formItem={{
                                label: "Email address",
                                name: "email",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please input your Email address"
                                  },
                                  {
                                    type: "email",
                                    message: "Please input a valid email"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='email'
                              label='Email address'
                            />
                          </Col>
                          <Col xs={24}>
                            <TextArea
                              formItem={{
                                name: "description",
                                label: "Description",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please enter description"
                                  }
                                ]
                              }}
                              placeholder='Enter description'
                              maxLength={300}
                              label=''
                              mode='normal'
                              autoSize={{ minRows: 4, maxRows: 4 }}
                            />
                          </Col>
                        </Row>
                      </div>
                      {/* end of business information */}

                      {/* business contact */}
                      <div className='single-tab-wrapper'>
                        <h4>Business contact</h4>

                        <Row gutter={24}>
                          <Col xs={24} md={8}>
                            <Select
                              formItem={{
                                label: "Country",
                                name: "country",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please input your country"
                                  }
                                ]
                              }}
                              mode='normal'
                              placeholder='Select country'
                            >
                              {["Nigeria"].map(
                                (country: string, index: number) => (
                                  <AntdSelect.Option
                                    key={index}
                                    value={country}
                                  >
                                    <span>{country}</span>
                                  </AntdSelect.Option>
                                )
                              )}
                            </Select>
                          </Col>

                          <Col xs={24} md={8}>
                            <Select
                              formItem={{
                                name: "state",
                                label: "State",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please select your State"
                                  }
                                ]
                              }}
                              placeholder='Select state'
                              mode='normal'
                              showSearch
                              onChange={(
                                state: keyof typeof nigerianStateAndCities
                              ) => {
                                setSelectedState(state);
                                basiform.setFields([
                                  {
                                    name: ["city"],
                                    value: ""
                                  }
                                ]);
                              }}
                            >
                              {Object.keys(nigerianStateAndCities).map(
                                (state: string, index: number) => (
                                  <AntdSelect.Option key={index} value={state}>
                                    <span>{state}</span>
                                  </AntdSelect.Option>
                                )
                              )}
                            </Select>
                          </Col>

                          <Col xs={24} md={8}>
                            <Select
                              formItem={{
                                label: "City/Town/Area",
                                name: "city",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please select your city/town/area"
                                  }
                                ]
                              }}
                              mode='normal'
                              placeholder='Select or input city/town/area'
                              style={{ textTransform: "capitalize" }}
                              showSearch
                            >
                              {selectedState &&
                                nigerianStateAndCities.hasOwnProperty(
                                  selectedState
                                ) &&
                                nigerianStateAndCities[selectedState].map(
                                  (city: object | any, index: number) => (
                                    <AntdSelect.Option
                                      key={index}
                                      value={city.name.toLowerCase()}
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      <span>{city.name.toLowerCase()}</span>
                                    </AntdSelect.Option>
                                  )
                                )}
                            </Select>
                          </Col>

                          <Col span={24}>
                            <TextArea
                              formItem={{
                                label: "Street Address",
                                name: "street_line_one",
                                rules: [
                                  {
                                    required: true,
                                    message: "Please input your Street Address"
                                  }
                                ]
                              }}
                              label=''
                              mode='normal'
                              autoSize={{ minRows: 2, maxRows: 2 }}
                            />
                          </Col>
                        </Row>
                      </div>

                      <Row gutter={24} justify='start'>
                        <Col style={{ textAlign: "center" }}>
                          <Button
                            htmlType='submit'
                            className='update-profile-btn'
                            type='primary'
                            disabled={loading || confirmPasswordLoading}
                          >
                            {loading || confirmPasswordLoading
                              ? "Updating..."
                              : "Update profile"}
                          </Button>
                        </Col>
                      </Row>
                    </Tabs.TabPane>

                    {/* Social  */}
                    <Tabs.TabPane tab='Social links' key={2}>
                      <div className='single-tab-wrapper'>
                        <h4>Social Links</h4>

                        {/* google review link */}
                        {/* tooltip icon is positioned absolutely, & styled using inline styles, (cause the position and styles are specific to its current location & implementation is not duplicated anywhere else in the codebase) */}
                        <Row>
                          <Col xs={24} md={8} style={{ position: "relative" }}>
                            <Input
                              formItem={{
                                label: "Google review link",
                                name: "google_review_url"
                              }}
                              placeHolder='Paste in your google review link'
                              mode='normal'
                              type='text'
                            />

                            <Tooltip
                              placement='topLeft'
                              title={
                                "Your google review link will help patients post positive reviews to your google business page."
                              }
                            >
                              <InfoCircleFilled
                                style={{
                                  fontSize: "18px",
                                  position: "absolute",
                                  top: "1px",
                                  left: "125px",
                                  width: "fit-content",
                                  color: "#0C8ABC",
                                  cursor: "pointer"
                                }}
                              />
                            </Tooltip>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} md={8} style={{ position: "relative" }}>
                            <Input
                              formItem={{
                                label: "WhatsApp phone number",
                                name: "whatsapp",
                                rules: [
                                  {
                                    message:
                                      "Please enter your WhatsApp phone number"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='phone'
                              onChange={formatPhoneNumber}
                            />

                            <Tooltip
                              placement='topLeft'
                              title={
                                "Receive direct WhatsApp messages from new and existing patients directly from your PneumaPage."
                              }
                            >
                              <InfoCircleFilled
                                style={{
                                  fontSize: "18px",
                                  position: "absolute",
                                  top: "1px",
                                  left: "175px",
                                  width: "fit-content",
                                  color: "#0C8ABC",
                                  cursor: "pointer"
                                }}
                              />
                            </Tooltip>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} md={8}>
                            <Input
                              formItem={{
                                label: "Website",
                                name: "website_url"
                              }}
                              placeHolder='https://www.pneuma.care/'
                              mode='normal'
                              type='text'
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} md={8}>
                            <Input
                              formItem={{
                                label: "Facebook handle",
                                name: "facebook",
                                rules: [
                                  {
                                    pattern: /^\S+$/,
                                    message: "Handle name cannot contain spaces"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='text'
                              placeHolder='E.g akinola'
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={24} md={8}>
                            <Input
                              formItem={{
                                label: "Instagram handle",
                                name: "instagram",
                                rules: [
                                  {
                                    pattern: /^\S+$/,
                                    message: "Handle name cannot contain spaces"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='text'
                              placeHolder='E.g akin'
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={24} md={8}>
                            <Input
                              formItem={{
                                label: "Twitter handle",
                                name: "twitter",
                                rules: [
                                  {
                                    pattern: /^\S+$/,
                                    message: "Handle name cannot contain spaces"
                                  }
                                ]
                              }}
                              mode='normal'
                              type='text'
                              placeHolder='E.g mike_01'
                            />
                          </Col>
                        </Row>
                      </div>

                      <Row gutter={24} justify='start'>
                        <Col style={{ textAlign: "right" }}>
                          <Button
                            htmlType='submit'
                            className='update-profile-btn'
                            type='primary'
                            disabled={loading || confirmPasswordLoading}
                          >
                            {loading || confirmPasswordLoading
                              ? "Updating..."
                              : "Update profile"}
                          </Button>
                        </Col>
                      </Row>
                    </Tabs.TabPane>

                    {/* payments */}
                    <Tabs.TabPane tab='Payments' key={3}>
                      <div className='single-tab-wrapper'>
                        <h4>Payments</h4>
                        <p>
                          Choose the way(s) you want to receive payment for your
                          services on pneumapage.
                        </p>

                        <div className='toggle-wrapper'>
                          <span>
                            <PaystackIcon /> Online payment with Paystack
                          </span>
                          <Switch checked={true} disabled={true} />
                        </div>

                        <div className='toggle-wrapper'>
                          <span>
                            <CashIcon /> Cash
                          </span>

                          <Form.Item name='cash'>
                            <Switch checked={basiform.getFieldValue("cash")} />
                          </Form.Item>
                        </div>

                        <div className='insurance-wrapper'>
                          <div className='toggle-wrapper'>
                            <span>
                              <InsuranceIcon /> Insurance
                            </span>
                            <Form.Item name='insurance'>
                              <Switch
                                checked={basiform.getFieldValue("insurance")}
                              />
                            </Form.Item>
                          </div>

                          {basiform.getFieldValue("insurance") && (
                            <Select
                              mode='normal'
                              multiple
                              placeholder='Select covered insurance providers..'
                              value={basiform.getFieldValue(
                                "selectedProviders"
                              )}
                              formItem={{
                                label: "Covered providers",
                                name: "selectedProviders",
                                rules: [
                                  {
                                    required: true,
                                    message:
                                      "Please select your covered providers"
                                  }
                                ]
                              }}
                            >
                              {insuranceCompanies.map((item) => (
                                <AntdSelect.Option key={item} value={item}>
                                  {item.trim()}
                                </AntdSelect.Option>
                              ))}
                            </Select>
                          )}
                        </div>
                      </div>

                      <Row gutter={24} justify='start'>
                        <Col style={{ textAlign: "right" }}>
                          <Button
                            htmlType='submit'
                            className='update-profile-btn'
                            type='primary'
                            disabled={loading || confirmPasswordLoading}
                          >
                            {loading || confirmPasswordLoading
                              ? "Updating..."
                              : "Update profile"}
                          </Button>
                        </Col>
                      </Row>
                    </Tabs.TabPane>

                    {/* pneumapage */}
                    <Tabs.TabPane tab='Configure PneumaPage' key={4}>
                      {/* page settings */}
                      <div className='single-tab-wrapper'>
                        <h4>Page settings</h4>
                        <Row gutter={24}>
                          <div className='profile-wrapper'>
                            {logo_path || iconURL ? (
                              <img
                                src={iconURL !== "" ? iconURL : logo_path}
                                alt={name}
                                className='provider-logo'
                              />
                            ) : (
                              <ProfileIcon />
                            )}

                            <UploadWrapper
                              {...{
                                customRequest: async ({ onError, file }) => {
                                  try {
                                    await dispatch(uploadBusinessIcon(file));
                                  } catch (err: any) {
                                    onError && onError(err);
                                  }
                                },
                                maxCount: 1,
                                size: "large",
                                accept: ".png, .jpg, .jpeg",
                                showUploadList: isUpdatingIconLoading
                              }}
                            >
                              <Button icon={<UploadOutlined />}>
                                Upload your business logo or icon
                              </Button>
                            </UploadWrapper>
                          </div>
                        </Row>

                        <Row className='handle-row'>
                          <div>
                            <span className='sub-heading'>PneumaPage URL</span>

                            <Input
                              isEdit={isEdit}
                              formItem={{
                                name: "slug",
                                rules: [
                                  {
                                    pattern: /^[A-Za-z0-9]+$/,
                                    message:
                                      "Cannot contain spaces or special characters"
                                  },
                                  {
                                    pattern: /^.{3,50}$/,
                                    message:
                                      "Minimum of 3 characters, maximum of 50"
                                  }
                                ]
                              }}
                              className='page-input'
                              mode='normal'
                              style={{ pointerEvents: isEdit ? "all" : "none" }}
                              addonBefore={
                                isEdit
                                  ? "https://pneumapage.com/"
                                  : `https://pneumapage.com/${
                                      !Object.keys(profile).length
                                        ? ""
                                        : slug
                                        ? slug
                                        : id
                                    }`
                              }
                            />
                          </div>

                          <div className='copy-container'>
                            <Tooltip
                              placement='top'
                              title={`${
                                !isEdit ? "Edit" : "Update"
                              } PneumaPage URL`}
                              className='edit-icon'
                            >
                              {isEdit ? (
                                <Button
                                  type='primary'
                                  disabled={loading}
                                  onClick={() => {
                                    if (nonNullFieldValues.slug) {
                                      handleSubmitBasicDetails();
                                    } else {
                                      setEdit(false);
                                    }
                                  }}
                                >
                                  {loading ? "Updating..." : "Update URL"}
                                </Button>
                              ) : (
                                <EditOutlined
                                  style={{ fontSize: "22px" }}
                                  onClick={() =>
                                    setEdit((prev: boolean) => !prev)
                                  }
                                />
                              )}
                            </Tooltip>
                            {!isEdit ? (
                              <Tooltip
                                placement='rightTop'
                                title={"Copy PneumaPage URL"}
                              >
                                <CopyToClipboard
                                  text={`https://pneumapage.com/${
                                    slug ? slug : id
                                  }`}
                                  onCopy={() =>
                                    AntdMessage.success(
                                      "PneumaPage URL copied!"
                                    )
                                  }
                                >
                                  <CopyOutlined style={{ fontSize: "20px" }} />
                                </CopyToClipboard>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                          </div>
                        </Row>

                        <Row className='handle-row'>
                          <div>
                            <span className='sub-heading'>Add cover photo</span>

                            <div className='uploads-flex'>
                              {/* upload */}
                              <UploadLarge
                                name='file'
                                accept='.png, .jpg, .jpeg'
                                customRequest={async ({
                                  onError,
                                  file,
                                  onProgress,
                                  onSuccess
                                }) => {
                                  const _file = file as any;

                                  const storageRef = storage.ref();
                                  const currentFolderRef =
                                    storageRef.child(email);
                                  const currentImageRef = storageRef.child(
                                    `${currentFolderRef}/covers/${_file.name}`
                                  );

                                  const uploadTask = currentImageRef.put(_file);

                                  uploadTask.on(
                                    "state_changed",
                                    (snapshot) => {
                                      const progress =
                                        (snapshot.bytesTransferred /
                                          snapshot.totalBytes) *
                                        100;

                                      onProgress && onProgress(progress as any);
                                    },
                                    (error) => {
                                      AntdMessage.error(
                                        "Unable to upload image. Please try again."
                                      );
                                      onError && onError(error);
                                    },
                                    () => {
                                      uploadTask.snapshot.ref
                                        .getDownloadURL()
                                        .then((downloadURL) => {
                                          AntdMessage.success(
                                            "Image successfully uploaded. Please submit to save changes."
                                          );
                                          setCoverImageURL(downloadURL);
                                          onSuccess && onSuccess(downloadURL);
                                        });
                                    }
                                  );
                                }}
                                maxCount={1}
                                defaultFileList={
                                  coverImage
                                    ? [
                                        {
                                          fileName: coverImageName,
                                          type: "image",
                                          uid: "",
                                          name: coverImageName
                                        }
                                      ]
                                    : []
                                }
                              >
                                <p className='ant-upload-drag-icon'>
                                  <UploadIcon />
                                </p>
                                <p className='ant-upload-text'>
                                  Click to upload cover image
                                </p>
                                <p className='ant-upload-hint'>
                                  Image should be less than 10MB
                                </p>
                              </UploadLarge>

                              {/* choose from gallery */}
                              <GalleryWrapper
                                onClick={() => {
                                  setIsGalleryVisible(true);
                                }}
                              >
                                <p className='upload-icon'>
                                  <GalleryIcon />
                                </p>
                                <p className='upload-text'>
                                  Choose from gallery
                                </p>
                              </GalleryWrapper>
                            </div>
                          </div>
                        </Row>
                      </div>
                      {/* end of page settings */}

                      {/* visit reasons */}
                      <div className='single-tab-wrapper'>
                        <h4 style={{ position: "relative" }}>
                          Why patients visit you
                          <Tooltip
                            placement='topLeft'
                            title={
                              "This will help patients find you faster and easier with search and filters that are based on their current needs."
                            }
                          >
                            <InfoCircleFilled
                              style={{
                                fontSize: "18px",
                                position: "absolute",
                                top: "6px",
                                left: "275px",
                                width: "fit-content",
                                color: "#0C8ABC",
                                cursor: "pointer"
                              }}
                            />
                          </Tooltip>
                        </h4>

                        <Row>
                          <Col xs={24} md={8}>
                            <Select
                              formItem={{
                                name: "keywords",
                                label:
                                  "Enter search keywords for top complaints and procedures"
                              }}
                              tags
                              mode='normal'
                              size='large'
                              placeholder='Type keywords'
                              value={selectedTags}
                              maxTagLength={10}
                              onChange={(val: string[]) => {
                                if (val.length <= 10) {
                                  setSelectedTags(val);
                                } else {
                                  AntdMessage.warn(
                                    "You can only have 10 keywords."
                                  );
                                }
                              }}
                              dropdownStyle={{ display: "none" }}
                            />
                          </Col>
                        </Row>
                      </div>

                      {/* quick appointment */}
                      <div className='single-tab-wrapper'>
                        <h4>Quick appointment</h4>

                        <Row gutter={24}>
                          <Col xs={24} md={6}>
                            <Select
                              formItem={{
                                name: "quickService",
                                label: "Service"
                              }}
                              mode='normal'
                              showSearch
                              placeholder='Select a service'
                              optionFilterProp='children'
                              filterOption={(_input: string, option: any) => {
                                const input = _input.toLowerCase();
                                const singleService = servicePlans.find(
                                  (plan: any) => plan.id === option.value
                                )!;
                                const booleanValue = singleService.name
                                  .toLowerCase()
                                  .includes(input);
                                return booleanValue;
                              }}
                              filterSort={(optionA: any, optionB: any) =>
                                optionA.value
                                  .toLowerCase()
                                  .localeCompare(optionB.value.toLowerCase())
                              }
                            >
                              {servicePlans?.map((option, index) => {
                                return (
                                  <AntdSelect.Option
                                    key={index}
                                    value={option.id}
                                  >
                                    <span
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {option.name}
                                    </span>
                                  </AntdSelect.Option>
                                );
                              })}
                            </Select>
                          </Col>
                        </Row>
                      </div>
                      {/* end of quick appointment */}

                      {/* action button text */}
                      <div className='single-tab-wrapper'>
                        <h4>Call to action</h4>

                        <Row gutter={24}>
                          <Col xs={24} md={6}>
                            <Select
                              formItem={{
                                name: "actionText",
                                label: "Select your call to action"
                              }}
                              mode='normal'
                              showSearch
                              placeholder='Select an option'
                              optionFilterProp='children'
                              defaultValue='Book an appointment'
                            >
                              {[
                                "Book an appointment",
                                "Book a session",
                                "Order plan"
                              ].map((option, i) => (
                                <AntdSelect.Option key={i} value={option}>
                                  <span style={{ textTransform: "capitalize" }}>
                                    {option}
                                  </span>
                                </AntdSelect.Option>
                              ))}
                            </Select>
                          </Col>
                        </Row>
                      </div>
                      {/* end of action button text */}

                      {/* open service hours */}
                      <div className='single-tab-wrapper'>
                        <h4>Open hours</h4>

                        <Row gutter={24}>
                          <Col xs={24} lg={12}>
                            <RadioGroup
                              name='radiogroup'
                              defaultValue={openHours}
                              onChange={(e) => {
                                setOpenHours(e.target.value);
                                setUpdatedOpenHours(true);
                                setUpdatedCustomHours(false);
                              }}
                            >
                              <Radio value={"custom"}>
                                <div>
                                  <p>Custom open hours</p>
                                  <p>Set your hours</p>
                                </div>
                              </Radio>

                              <Radio value={"always-open"}>
                                <div>
                                  <p>Open 24 hours</p>
                                  <p>Everyday of the week</p>
                                </div>
                              </Radio>
                            </RadioGroup>
                          </Col>
                        </Row>

                        <Row gutter={24} style={{ marginTop: "36px" }}>
                          <Col xs={24} lg={12}>
                            {openHours === "always-open" ? (
                              <p className='always-open-tag'>
                                <CheckCircleOutlined /> Open 24 hours everyday
                              </p>
                            ) : (
                              <div className='custom-hours-wrapper'>
                                {customHours
                                  ? [
                                      "monday",
                                      "tuesday",
                                      "wednesday",
                                      "thursday",
                                      "friday",
                                      "saturday",
                                      "sunday"
                                    ].map((item) => {
                                      const selectedDay =
                                        customHours[item as daysOfWeek];
                                      const timeFormat = "hh:mm a";

                                      return (
                                        <div key={item}>
                                          <p>{capitaliseFirstLetter(item)}</p>

                                          <p>
                                            <Switch
                                              checked={selectedDay.isOpen}
                                              onChange={(val) => {
                                                setCustomHours((prev: any) => ({
                                                  ...prev,
                                                  [item as daysOfWeek]: {
                                                    ...prev[item as daysOfWeek],
                                                    isOpen: val
                                                  }
                                                }));

                                                setUpdatedOpenHours(false);
                                                setUpdatedCustomHours(true);
                                              }}
                                            />{" "}
                                            {selectedDay.isOpen
                                              ? "Open"
                                              : "Closed"}
                                          </p>

                                          <p>
                                            {selectedDay.isOpen && (
                                              <TimePicker
                                                defaultValue={moment(
                                                  selectedDay.open,
                                                  timeFormat
                                                )}
                                                format={timeFormat}
                                                inputReadOnly={true}
                                                minuteStep={30}
                                                onChange={(val) => {
                                                  setCustomHours(
                                                    (prev: any) => ({
                                                      ...prev,
                                                      [item as daysOfWeek]: {
                                                        ...prev[
                                                          item as daysOfWeek
                                                        ],
                                                        open: moment(
                                                          val
                                                        ).format(timeFormat)
                                                      }
                                                    })
                                                  );

                                                  setUpdatedOpenHours(false);
                                                  setUpdatedCustomHours(true);
                                                }}
                                              />
                                            )}
                                            {selectedDay.isOpen && (
                                              <span>TO</span>
                                            )}
                                            {selectedDay.isOpen && (
                                              <TimePicker
                                                defaultValue={moment(
                                                  selectedDay.close,
                                                  timeFormat
                                                )}
                                                format={timeFormat}
                                                inputReadOnly={true}
                                                minuteStep={30}
                                                onChange={(val) => {
                                                  setCustomHours(
                                                    (prev: any) => ({
                                                      ...prev,
                                                      [item as daysOfWeek]: {
                                                        ...prev[
                                                          item as daysOfWeek
                                                        ],
                                                        close:
                                                          moment(val).format(
                                                            timeFormat
                                                          )
                                                      }
                                                    })
                                                  );

                                                  setUpdatedOpenHours(false);
                                                  setUpdatedCustomHours(true);
                                                }}
                                              />
                                            )}
                                          </p>
                                        </div>
                                      );
                                    })
                                  : null}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </div>

                      <Row gutter={24} justify='start'>
                        <Col style={{ textAlign: "right" }}>
                          <Button
                            htmlType='submit'
                            className='update-profile-btn'
                            type='primary'
                            disabled={loading || confirmPasswordLoading}
                          >
                            {loading || confirmPasswordLoading
                              ? "Updating..."
                              : "Update profile"}
                          </Button>
                        </Col>
                      </Row>
                    </Tabs.TabPane>

                    {/* subscriptions */}
                    <Tabs.TabPane tab='Plan & Subscription' key={5}>
                      <SubscriptionWrapper>
                        <div className='single-tab-wrapper'>
                          <h4>Your current plan</h4>

                          {plan && (
                            <div className='plan-wrapper'>
                              <h5>
                                {capitaliseFirstLetter(plan)} plan{" "}
                                <span>Active</span>
                              </h5>

                              <h6>Renew date</h6>
                              <p>
                                <CalendarOutlined /> {due_date}
                              </p>
                            </div>
                          )}
                        </div>

                        <div className='single-tab-wrapper'>
                          <h4>Subscriptions</h4>
                          <Row gutter={24}>
                            <Col xs={24} lg={12}>
                              <RadioGroup
                                name='radiogroup'
                                defaultValue={savedDuration || selectedPricing}
                                onChange={(e) => {
                                  setSelectedPricing(e.target.value);
                                }}
                              >
                                <Radio value={"monthly"}>Monthly</Radio>

                                <Radio value={"quarterly"}>Quarterly</Radio>
                              </RadioGroup>
                            </Col>
                          </Row>

                          <div className='plans'>
                            {Subscriptions.map((singleSub) => (
                              <div
                                className={`single-plan ${
                                  plan === singleSub.name.toLowerCase()
                                    ? "is-active"
                                    : ""
                                }`}
                                key={singleSub.name}
                              >
                                <h5>
                                  <img
                                    src={singleSub.icon}
                                    alt={singleSub.name}
                                  />{" "}
                                  {singleSub.name}
                                </h5>
                                {selectedPricing ? (
                                  <h4>
                                    NGN
                                    {new Intl.NumberFormat("en-US").format(
                                      prices[selectedPricing][
                                        singleSub.name.toLowerCase() as
                                          | "start"
                                          | "grow"
                                          | "engage"
                                      ]
                                    )}
                                    <span>
                                      /
                                      {selectedPricing === "monthly"
                                        ? "month"
                                        : "quarter"}
                                    </span>
                                  </h4>
                                ) : null}
                                <p>{singleSub.description}</p>

                                <Button
                                  type='primary'
                                  className='btn-wrapper'
                                  onClick={() => {
                                    localStorage.setItem(
                                      "subscriptionDuration",
                                      selectedPricing
                                    );
                                    window.location.replace(
                                      paymentPages[selectedPricing][
                                        singleSub.name.toLowerCase() as
                                          | "start"
                                          | "grow"
                                          | "engage"
                                      ]
                                    );
                                  }}
                                  disabled={
                                    plan === singleSub.name.toLowerCase()
                                  }
                                >
                                  {plan === singleSub.name.toLowerCase()
                                    ? "Current plan"
                                    : "Upgrade"}
                                </Button>

                                <div className='benefits'>
                                  <h6>This plan includes</h6>
                                  <ul>
                                    {singleSub.benefits.map((item, idx) => (
                                      <li key={item + idx}>
                                        <CheckIcon />
                                        {item}
                                      </li>
                                    ))}
                                  </ul>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className='single-tab-wrapper'>
                          <div className='billing-history-wrapper'>
                            <div className='billing-header'>
                              <div className='text'>
                                <h4>Billing history</h4>
                                <p>See all your invoice here</p>
                              </div>

                              <div className='cta'>
                                <Button
                                  onClick={() => {
                                    if (!history.length) {
                                      dispatch(
                                        fetchSubscriptionHistory({ providerId })
                                      );
                                    }
                                    setShowBillingHistory((prev) => !prev);
                                  }}
                                >
                                  View billing history{" "}
                                  {showBillingHistory ? (
                                    <UpOutlined />
                                  ) : (
                                    <DownOutlined />
                                  )}
                                </Button>
                              </div>
                            </div>

                            {showBillingHistory && history ? (
                              <>
                                <div className='billing-content'>
                                  <Table
                                    dataSource={history}
                                    columns={billingHistoryCols}
                                    rowKey={(dataRow) => {
                                      return uid();
                                    }}
                                    style={{
                                      pointerEvents: loading ? "none" : "all"
                                    }}
                                    scroll={
                                      history.length > 0
                                        ? { x: 700 }
                                        : window.matchMedia(
                                            "(max-width: 650px)"
                                          ).matches
                                        ? { x: 700 }
                                        : {}
                                    }
                                    rowClassName={(_, index) =>
                                      index % 2 === 0 ? "even-row" : "odd-row"
                                    }
                                    pagination={{
                                      defaultCurrent: 1,
                                      position: ["bottomRight"],
                                      showLessItems: true
                                    }}
                                  />
                                </div>

                                {selectedInvoice ? (
                                  <Invoice {...selectedInvoice} />
                                ) : null}
                              </>
                            ) : null}
                          </div>
                        </div>
                      </SubscriptionWrapper>
                    </Tabs.TabPane>
                  </Tabs>
                </Form>
              </Col>
            </Row>
          </ContentWrapper>
        </Col>
      </Row>

      <GalleryModal
        isModalVisible={isGalleryVisible}
        handleCancel={() => setIsGalleryVisible(false)}
        setCoverURL={setCoverImageURL}
      />
    </ProfileWrapper>
  );
};

export default Profile;
