import React from "react";
import { Bottom } from "./style";
import Button from "../Button";

type Props = {
  text: string;
  onClick?: React.MouseEventHandler | string;
};
const BottomBar = ({ text, onClick }: Props) => {
  return (
    <Bottom>
      <Button type={"primary"} onClick={onClick}>
        {text}
      </Button>
    </Bottom>
  );
};

export default BottomBar;
