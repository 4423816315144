import {
  Col,
  Form,
  Modal,
  Row,
  Checkbox,
  Select as AntdSelect,
  InputNumber,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MixPanel } from "utils/mixpanel";

import {
  servicePlansSelector,
  addServicePlan,
  updateServicePlan,
  updateServicePlanSuccess,
  addServicePlanSuccess,
} from "../../redux/reducers/servicePlans";
import Button from "../Button";
import TextArea from "../TextArea";
import Input from "../Input";
import Select from "component/Select";
import { RequestDiagnosisModalWrapper } from "./styles";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useGetAllServiceFormsQuery } from "../../redux/queries/service-forms";
import { SingleFormType } from "../../constants/types";

const AddServiceModal = ({
  isModalVisible,
  handleCancel,
  isEdit,
  servicePlanId,
}: any) => {
  const dispatch = useDispatch();
  const {
    addServicePlanLoading,
    updateServicePlanLoading,
    servicePlans,
    hasAddedServicePlan,
    hasUpdatedServicePlan,
  } = useSelector(servicePlansSelector);
  const [requestForm] = Form.useForm();
  const [checked, setChecked] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [requiresConsultation, setRequiresConsultation] = useState(false);
  const { data, isLoading, error } = useGetAllServiceFormsQuery();
  const service_forms = data?.data as any;
  const servicePlanOfInterest = useMemo(
    () => servicePlans.find((plan) => plan.id === servicePlanId),
    [servicePlanId, servicePlans],
  );

  const [isFormSelected, setIsFormSelected] = useState<string | null>(null);

  const intakeForm = service_forms?.find(
    (form: any) => form.id === servicePlanOfInterest?.metadata?.service_form,
  );
  const switchTags = () => {
    if (isEdit) {
      return updateServicePlanLoading ? "Editing..." : "Edit service";
    } else {
      return addServicePlanLoading ? "Adding..." : "Add a service";
    }
  };

  const handleCreateRequest = async (values: any) => {
    const _values = {
      plans: [
        {
          name: values.name,
          schedule: values.schedule,
          description: values.description,
          unit: values.unit,
          price: checked ? 0 : `${Number(values.price) * 100}`,
          public: !isPrivate,
          metadata: {
            priorConsultation: requiresConsultation
              ? values.priorConsultation
              : false,
            service_form: values?.service_form || null,
          },
        },
      ],
    };

    if (isEdit) {
      dispatch(
        updateServicePlan(servicePlanOfInterest.id, {
          name: values.name,
          schedule: values.schedule,
          description: values.description,
          unit: values.unit,
          price: checked ? 0 : `${Number(values.price) * 100}`,
          public: !isPrivate,
          metadata: {
            priorConsultation: requiresConsultation
              ? values.priorConsultation
              : false,
            service_form: values?.service_form || null,
          },
        }),
      );
    } else {
      dispatch(addServicePlan(_values));
      MixPanel.track("CreateServicePlan", {
        serviceName: values.name,
        price: checked ? "Free" : `${Number(values.price) * 100}`,
        schedule: values.schedule,
        unit: values.unit,
        description: values.description,
      });
    }
  };

  useEffect(() => {
    const isFree = !Number(servicePlanOfInterest?.price);
    if (isEdit) {
      requestForm.setFieldsValue({
        ...servicePlanOfInterest,
        price: isFree ? "" : `${Number(servicePlanOfInterest.price) / 100}`,
        service_form: intakeForm?.name,
      });
      setIsFormSelected(intakeForm?.name);
      isFree ? setPriceAsFree() : setChecked(false);
    } else {
      requestForm.resetFields();
    }
  }, [isEdit, requestForm, servicePlanOfInterest, servicePlans]);

  useEffect(() => {
    const isPrivate = !servicePlanOfInterest?.public;

    setIsPrivate(isPrivate);
  }, [servicePlanOfInterest?.public]);

  useEffect(() => {
    const doesRequirePrior =
      !!servicePlanOfInterest?.metadata?.priorConsultation;

    setRequiresConsultation(Boolean(doesRequirePrior));

    const isFree = !Number(servicePlanOfInterest?.price);
    if (isEdit) {
      requestForm.setFieldsValue({
        ...servicePlanOfInterest,
        price: isFree ? "" : `${Number(servicePlanOfInterest.price) / 100}`,
        priorConsultation:
          servicePlanOfInterest?.metadata?.priorConsultation ?? "",
      });
    }
  }, [
    servicePlanOfInterest?.priorConsultation,
    isEdit,
    requestForm,
    servicePlanOfInterest,
  ]);

  useEffect(() => {
    if (hasAddedServicePlan || hasUpdatedServicePlan) {
      requestForm.resetFields();
      handleCancel();
      dispatch(addServicePlanSuccess(false));
      dispatch(updateServicePlanSuccess(false));
    }
  }, [hasAddedServicePlan, hasUpdatedServicePlan]);

  const setPriceAsFree = () => {
    setChecked(true);
    requestForm.setFields([
      {
        name: ["price"],
        value: "",
      },
    ]);
  };

  return (
    <Modal
      title={isEdit ? "Edit service" : "Add a service"}
      okText="Request"
      footer={null}
      className="dashboard-mode-modal"
      visible={isModalVisible}
      onOk={handleCreateRequest}
      onCancel={handleCancel}
    >
      <RequestDiagnosisModalWrapper>
        <Form
          name="basicForm"
          form={requestForm}
          layout="vertical"
          onFinish={handleCreateRequest}
        >
          <Row gutter={24}>
            <Col span={12}>
              <Input
                formItem={{
                  name: "name",
                  label: "Name of service",
                  rules: [
                    {
                      required: true,
                      message: "Please enter name of service",
                    },
                  ],
                }}
                placeholder="Enter name of service"
                mode="normal"
              />
            </Col>
            <Col span={12}>
              <Input
                formItem={{
                  name: "schedule",
                  label: "Schedule",
                  rules: [
                    {
                      required: true,
                      message: "Please enter service schedule",
                    },
                  ],
                }}
                placeholder="Every Week, Every Day etc"
                label=""
                mode="normal"
              />
            </Col>
            <Col span={24}>
              <Select
                formItem={{
                  name: "service_form",
                  label: "Add intake form (Optional)",
                }}
                mode="normal"
                placeholder="Select a form"
              >
                {service_forms?.map((item: SingleFormType) => (
                  <AntdSelect.Option value={item?.id} key={item.id}>
                    {item.name}
                  </AntdSelect.Option>
                ))}

                <AntdSelect
                  value={""}
                  style={{
                    display: isEdit && isFormSelected ? "block" : "none",
                  }}
                >
                  Remove service form
                </AntdSelect>
              </Select>
            </Col>
            <Col span={24}>
              <TextArea
                formItem={{
                  name: "description",
                  label: "Description",
                  rules: [
                    {
                      required: true,
                      message: "Please enter description",
                    },
                  ],
                }}
                placeholder="Enter description"
                maxLength={300}
                mode="normal"
                autoSize={{ minRows: 4, maxRows: 4 }}
              />
            </Col>
            <Col span={24}>
              <Checkbox
                checked={checked}
                className="check-box"
                onChange={(e: CheckboxChangeEvent) => {
                  e.target.checked ? setPriceAsFree() : setChecked(false);
                }}
              >
                This service does not require payment
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                checked={isPrivate}
                className="check-box"
                onChange={(e: CheckboxChangeEvent) => {
                  setIsPrivate(e.target.checked);
                }}
              >
                This service is private (and will not show on your PneumaPage)
              </Checkbox>
            </Col>

            <Col span={24}>
              <Checkbox
                checked={requiresConsultation}
                className="check-box"
                onChange={(e: CheckboxChangeEvent) => {
                  setRequiresConsultation(e.target.checked);
                }}
              >
                This service requires a prior consultation
              </Checkbox>
            </Col>

            {requiresConsultation && (
              <Col span={24}>
                <Select
                  formItem={{
                    name: "priorConsultation",
                    label: "Prior consultation",
                    rules: [
                      {
                        required: requiresConsultation,
                        message: "Please select a prior consultation",
                      },
                    ],
                  }}
                  mode="normal"
                  showSearch
                  placeholder="Select a service"
                  optionFilterProp="children"
                  filterOption={(_input: string, option: any) => {
                    const input = _input.toLowerCase();
                    const singleService = servicePlans.find(
                      (plan: any) => plan.id === option.value,
                    )!;
                    const booleanValue = singleService.name
                      .toLowerCase()
                      .includes(input);
                    return booleanValue;
                  }}
                  filterSort={(optionA: any, optionB: any) =>
                    optionA.value
                      .toLowerCase()
                      .localeCompare(optionB.value.toLowerCase())
                  }
                >
                  {servicePlans
                    ?.filter((service) => service.id !== servicePlanId)
                    ?.map((option, index) => {
                      return (
                        <AntdSelect.Option key={index} value={option.id}>
                          <span style={{ textTransform: "capitalize" }}>
                            {option.name}
                          </span>
                        </AntdSelect.Option>
                      );
                    })}
                </Select>
              </Col>
            )}

            <Col span={8}>
              <Form.Item
                name="price"
                label="Price"
                rules={[{ required: !checked, message: "Please enter price" }]}
              >
                <InputNumber
                  formatter={(value: any) =>
                    `₦ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }
                  parser={(value: any) => value!.replace(/₦\s?|(,*)/g, "")}
                  disabled={checked}
                  className="price-input"
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Input
                formItem={{
                  name: "unit",
                  label: "Unit",
                  rules: [
                    {
                      required: true,
                      message: "Please enter a unit",
                    },
                  ],
                }}
                placeholder="Per day, Per session, Per appointment, e.t.c"
                label=""
                mode="normal"
              />
            </Col>
          </Row>

          <Row justify="end" style={{ marginTop: "20px" }}>
            <Button
              type="secondary"
              onClick={handleCancel}
              style={{ marginRight: 10 }}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              disabled={addServicePlanLoading || updateServicePlanLoading}
            >
              {switchTags()}
            </Button>
          </Row>
        </Form>
      </RequestDiagnosisModalWrapper>
    </Modal>
  );
};

export default AddServiceModal;
