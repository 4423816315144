import React, { useState } from "react";
import { Col, Modal, Row, Form, message } from "antd";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import { TextArea } from "./style";
import Button from "../Button";

type UserAppointment = {
  isModalOpen: boolean;
  onClose: () => void;
};

const UpdateUserAppointment = ({ isModalOpen, onClose }: UserAppointment) => {
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  return (
    <Modal
      title="Update appointment details"
      okText="Submit"
      footer={null}
      visible={isModalOpen}
      onCancel={onClose}
      style={{ borderRadius: "120px" }}
    >
      <Form name="basicForm" layout="vertical">
        <Row gutter={24}>
          <Col flex="auto">
            <DatePicker
              formItem={{
                label: "Preferred date of appointment",
                name: "preferred_date_of_appointment",
                rules: [
                  {
                    required: true,
                    message: "Please input preferred date",
                  },
                ],
              }}
              disabledDate={(d: any) => {
                // check if currentDate in moment format is less than yesterday & disable accordingly)
                return (
                  !d || d.isBefore(new Date().getTime() - 24 * 60 * 60 * 1000)
                );
              }}
              mode="normal"
              format="DD/MM/yyyy"
              type="text"
            />
          </Col>

          <Col span={12}>
            <TimePicker
              formItem={{
                label: "Preferred time of appointment",
                name: "preferred_time_of_appointment",
                rules: [
                  {
                    required: true,
                    message: "Please input preferred date",
                  },
                ],
              }}
              format="hh:mm"
              mode="normal"
              inputReadOnly={true}
              minuteStep={30}
            />
          </Col>

          <Col span={24}>
            <TextArea
              formItem={{
                name: "additional_notes_or_comments",
                label: "Additional notes or comments",
                rules: [
                  {
                    required: true,
                    message: "Please Additional notes or comments",
                  },
                ],
              }}
              mode="normal"
              autoSize={{ minRows: 4, maxRows: 5 }}
            />
          </Col>
        </Row>
        <Row
          justify="space-between"
          style={{
            marginTop: "20px",
            padding: "20px 20px 0px",
            borderTop: "1px solid  #e4e7ec",
            width: "100%",
          }}
        >
          <Button
            type="secondary"
            style={{
              width: 198,
              height: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            disabled={isUpdateLoading}
            style={{ width: 198, height: 48 }}
          >
            {isUpdateLoading ? "Updating" : "Update"}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default UpdateUserAppointment;
