import { Modal } from "antd";
import styled from "styled-components";

export const PaymentsTableWrapper = styled.div`
  margin: 20px 0 15px;
  padding: 0;

  .header {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

    .text-wrapper {
      h1 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        color: #333333;
      }

      p {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #000000;
      }
    }

    .btn-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 12px;

      @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
    }

      button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 14px;
        gap: 8px;
        height: 48px;
        border: 1.5px solid #0faff0;
        border-radius: 4px;

        &:nth-child(1) {
          width: 219px !important;
          background-color: transparent;
          span {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #0faff0;
          }
        }
        &:nth-child(2) {
          width: 133px !important;
          background-color: transparent;
          span {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #0faff0;
          }
        }
        &:nth-child(3) {
          width: 226px !important;
          background-color: #0faff0;

          span {
            font-family: "DM Sans";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }
  }

  .ant-tabs {
    .ant-tabs-tab {
      p.label {
        font-family: "DM Sans";
        font-size: 16px;
        line-height: 24px;
        display: flex;
        margin: 0;
        gap: 6px;
        color: rgba(0, 0, 0, 0.85);

        span {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px 8px;
          border-radius: 100px;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          text-align: center;
          background: #f0f0f0;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      &-active {
        p.label {
          color: #1890ff;

          span {
            background: #e6f7ff;
            color: #1890ff;
          }
        }
      }
    }
  }
`;





export const TypeFormModalWrapper = styled(Modal)`
.ant-modal-content {
  border-radius: 6px;
}

  @media only screen and (max-width: 375px) {
    .ant-modal-mask .ant-modal {
      width: 97vw !important;
    }

    .ant-modal-body {
  padding: 55px 0px !important;
}

   iframe .ButtonWrapper-sc-__sc-1qu8p4z-0 {
      display: none !important;
    }
  }
`;