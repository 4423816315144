import { Col, Modal, Row, Form, message, Select as AntdSelect } from "antd";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { send } from "emailjs-com";

import Select from "component/Select";
import Button from "../Button";
import DatePicker from "../DatePicker";
import TimePicker from "../TimePicker";
import {
  getDisabledHours,
  getDisabledMinutes,
  capitaliseFirstLetter
} from "utils/utils";
import { TextArea } from "./styles";
import {
  updateOrderMetadata,
  fetchAllServiceOrders
} from "redux/reducers/requests";
import { profileSelector } from "redux/reducers/profile";
import { useAppSelector } from "redux/store";

type EditAppointmentProps = {
  selectedAppointment: any;
  isModalVisible: boolean;
  onClose: () => void;
};

function EditAppointmentModal({
  selectedAppointment,
  isModalVisible,
  onClose
}: EditAppointmentProps) {
  const dispatch = useDispatch();

  const { profile: ProviderProfile } = useSelector(profileSelector);
  const { servicePlans } = useAppSelector((state) => state.servicePlans);

  const [isTodaySelected, setIsTodaySelected] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);

  const [requestForm] = Form.useForm();

  // auto-fill date & time values
  useEffect(() => {
    requestForm.setFieldsValue({
      preferred_date: moment(
        selectedAppointment?.metadata?.preferred_date,
        "DD/MM/YYYY"
      ),
      preferred_time: moment(
        selectedAppointment?.metadata?.preferred_time,
        "HH:mm"
      )
    });
  }, [requestForm, selectedAppointment]);

  async function handleUpdateAppointment(values: any) {
    const templateParams = {
      slug: ProviderProfile.slug || ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      business_email: ProviderProfile.email,
      business_phone: ProviderProfile.phone,
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
      business_address: `${
        ProviderProfile.address?.street_line_one
      }, ${capitaliseFirstLetter(
        ProviderProfile.address.city
      )}, ${capitaliseFirstLetter(ProviderProfile.address.state)}.`,
      to_email: selectedAppointment.user.email,
      to_name: `${capitaliseFirstLetter(selectedAppointment.user.first_name)} ${
        selectedAppointment.user.last_name
      }`,
      service: selectedAppointment.plan.name,
      preferred_date:
        values.preferred_date &&
        moment(values.preferred_date).format("DD/MM/YYYY"),
      preferred_time:
        values.preferred_time && moment(values.preferred_time).format("hh:mm a")
    };

    setIsUpdateLoading(true);

    try {
      // handle invalid date input (comes up when auto-filling date input, & user does not make any change.)
      if (moment(values.preferred_date).isBefore(moment(), "day")) {
        throw new Error("Invalid date. Date cannot be in the past!");
      }

      // handle invalid time inputs for current dates..NOT for future dates.
      if (
        moment(values.preferred_date).isSame(moment(), "day") &&
        moment(values.preferred_time).isBefore(moment(), "hour")
      ) {
        throw new Error("Invalid time. Time cannot be in the past!");
      }

      await dispatch(
        updateOrderMetadata(selectedAppointment.id, {
          metadata: {
            ...selectedAppointment.metadata,
            ...values,
            preferred_date: moment(values.preferred_date).format("DD/MM/YYYY"),
            preferred_time:
              values.preferred_time &&
              moment(values.preferred_time).format("hh:mm a")
          }
        })
      );

      await send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        "template_rescheduled",
        templateParams
      );

      await dispatch(fetchAllServiceOrders());

      message.success("Appointment successfully updated");
      onClose();
    } catch (err: unknown | any) {
      message.error(
        err.message || "Unable to update appointment. Please try again."
      );
    } finally {
      setIsUpdateLoading(false);
    }
  }

  return (
    <Modal
      title='Update appointment details'
      okText='Submit'
      footer={null}
      visible={isModalVisible}
      onCancel={onClose}
      onOk={handleUpdateAppointment}
    >
      <Form
        name='basicForm'
        form={requestForm}
        layout='vertical'
        onFinish={handleUpdateAppointment}
      >
        <Row gutter={24}>
          <Col flex='auto'>
            <DatePicker
              formItem={{
                label: "Preferred Date",
                name: "preferred_date",
                rules: [
                  {
                    required: true,
                    message: "Please input preferred date"
                  }
                ]
              }}
              onChange={(e: any) => {
                if (moment(e) > moment()) {
                  setIsTodaySelected(false);
                } else {
                  setIsTodaySelected(true);
                }
              }}
              disabledDate={(d: any) => {
                // check if currentDate in moment format is less than yesterday & disable accordingly)
                return (
                  !d || d.isBefore(new Date().getTime() - 24 * 60 * 60 * 1000)
                );
              }}
              mode='normal'
              format='DD/MM/yyyy'
              type='text'
            />
          </Col>

          {selectedAppointment?.metadata?.preferred_time && (
            <Col span={12}>
              <TimePicker
                formItem={{
                  label: "Preferred Time",
                  name: "preferred_time",
                  rules: [
                    {
                      required: true,
                      message: "Please input preferred date"
                    }
                  ]
                }}
                format='hh:mm'
                mode='normal'
                inputReadOnly={true}
                minuteStep={30}
                disabledHours={() => getDisabledHours(isTodaySelected)}
                disabledMinutes={(selectedHour: number) =>
                  getDisabledMinutes(selectedHour, isTodaySelected)
                }
              />
            </Col>
          )}

          <Col span={24}>
            <TextArea
              formItem={{
                name: "reason",
                label: "Reason",
                rules: [
                  {
                    required: true,
                    message: "Please enter reason"
                  }
                ]
              }}
              placeholder='Enter reason'
              mode='normal'
            />
          </Col>
        </Row>

        <Row justify='end' style={{ marginTop: "20px" }}>
          <Button
            type='secondary'
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button htmlType='submit' type='primary' disabled={isUpdateLoading}>
            {isUpdateLoading ? "Updating" : "Update"}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

export default EditAppointmentModal;
