import React from "react";
import { Drawer, message as AntdMessage, Spin } from "antd";
import { DrawerBody, DrawerHeader } from "./styles";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";

type Props = {
  isVisible: boolean;
  onClose: any;
  response: any;
};

const ResponseDrawer = ({ onClose, isVisible, response }: Props) => {
  return (
    <Drawer
      title={
        <DrawerHeader>{`${response?.user?.first_name}'s Response`}</DrawerHeader>
      }
      width={450}
      className="lab-request-drawer"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={isVisible}
    >
      <DrawerBody>
        <h3>Responses</h3>
        <div className="responses">
          {response?.responses?.map((item: any, index: number) => (
            <div className="single-response" key={index}>
              <div className="number">
                <span>{item?.question}</span>
              </div>
              <div className="question">
                <h5>{item.question_text}</h5>
                {Array.isArray(item.value) ? (
                  <div className="answer">
                    {item.value.map((response: any, index: number) => (
                      <div key={index}>
                        <CheckIcon />
                        <p>{response}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="answer">
                    <div>
                      <CheckIcon />
                      <p>{item.value}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </DrawerBody>
    </Drawer>
  );
};

export default ResponseDrawer;
