import { Table, Tooltip } from "antd";
import { useState, useEffect } from "react";
import moment from "moment";

import Card from "component/Card";
import Button from "component/Button";
import SendSMSModal from "component/SendSMSModal";
import CreateAppointmentModal from "component/CreateAppointmentModal";
import FollowUpsDrawer from "screens/FollowUps/containers/FollowUpsDrawer";
import { ReactComponent as ViewMoreIcon } from "assets/icons/details-icon.svg";

import {
  FollowUpsTableWrapper,
  FollowUpsTableBody
} from "screens/FollowUps/containers/styles";
import { capitaliseFirstLetter } from "utils/utils";

//redux
import { useGetAllFollowUpsQuery } from "redux/queries/follow-ups";

type FollowUpsTableProps = {
  selectedDate: "today" | "tomorrow";
  updateFollowUpsCount?: (count: number) => void;
};

const FollowUpsTable = ({
  selectedDate,
  updateFollowUpsCount
}: FollowUpsTableProps) => {
  const [filteredFollowUps, setFilteredFollowUps] = useState<any>();
  const [isSendMessageVisible, setIsSendMessageVisible] = useState(false);
  const [isCreateAppointmentVisible, setIsCreateAppointmentVisible] =
    useState(false);

  // follow up drawer
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<any>();

  //followups
  const {
    data: followUps,
    isLoading: followUpsLoading,
    error
  } = useGetAllFollowUpsQuery();

  const columns = [
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            {capitaliseFirstLetter(record.user.first_name)}{" "}
            {capitaliseFirstLetter(record.user.last_name)}
          </p>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            <span
              style={{
                display: "inline-block",
                padding: ".4em",
                marginRight: ".4em",
                background:
                  record.status === "completed"
                    ? "#0FBA84"
                    : record.status === "pending"
                    ? "#F2994A"
                    : "#EB5757",
                borderRadius: "100%"
              }}
            ></span>{" "}
            {capitaliseFirstLetter(record.status)}
          </p>
        );
      }
    },
    {
      title: "Goal",
      dataIndex: "goal",
      key: "goal",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            {capitaliseFirstLetter(record.goal)}
          </p>
        );
      }
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            {record?.service_order?.service_plan}
          </p>
        );
      }
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content", textTransform: "capitalize" }}>
            {moment(record.date).format("ll")}
          </p>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "left" as "left",
      render: (id: string, record: any) => (
        <Tooltip placement='top' title='View details'>
          <Button
            style={{ background: "none" }}
            onClick={() => {
              setSelectedBooking(record);
              setIsDrawerVisible(true);
            }}
          >
            <ViewMoreIcon style={{ fontSize: "1rem", height: "16" }} />
          </Button>
        </Tooltip>
      )
    }
  ];

  useEffect(() => {
    if (followUps?.data.length) {
      setFilteredFollowUps(
        followUps.data.filter((item) => {
          if (item.status === "pending" && selectedDate === "today") {
            return moment(item.date).isSame(moment(), "day");
          } else if (item.status === "pending" && selectedDate === "tomorrow") {
            return moment(item.date).isSame(moment().add(1, "days"), "day");
          }
        })
      );
    }
  }, [followUps, selectedDate]);

  return (
    <>
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <FollowUpsTableWrapper>
          <FollowUpsTableBody>
            <Table
              dataSource={filteredFollowUps}
              columns={columns}
              rowKey={(dataRow) => dataRow.id}
              style={{ pointerEvents: followUpsLoading ? "none" : "all" }}
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              scroll={
                !followUpsLoading && filteredFollowUps?.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 650xp").matches
                  ? { x: 700 }
                  : {}
              }
              loading={followUpsLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true
              }}
            />
          </FollowUpsTableBody>
        </FollowUpsTableWrapper>

        <SendSMSModal
          isModalVisible={isSendMessageVisible}
          handleCancel={() => setIsSendMessageVisible(false)}
          selectedUserID={selectedBooking?.user}
          messageType='follow-up'
        />

        <CreateAppointmentModal
          isModalVisible={isCreateAppointmentVisible}
          handleCancel={() => setIsCreateAppointmentVisible(false)}
          selectedUser={selectedBooking?.user}
        />

        <FollowUpsDrawer
          isVisible={isDrawerVisible}
          onClose={() => setIsDrawerVisible(false)}
          selectedFollowUp={selectedBooking}
        />
      </Card>
    </>
  );
};

export default FollowUpsTable;
