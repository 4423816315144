import React, { useMemo, useState } from "react";
import { Table } from "antd";
import Search from "../../../../component/Search";
import {
  TextWrapper,
  ResponseTableBody,
  ResponseHeader,
  SearchBarWrapper,
  EmptyStateWrapper,
} from "./styles";
import ResponseDrawer from "./ResponseDrawer";
import Button from "../../../../component/Button";

import {
  IResponses,
  ResponseType,
  SingleFormType,
} from "../../../../constants/types";
import type { ColumnsType } from "antd/es/table";
import moment from "moment/moment";
import { capitaliseFirstLetter } from "../../../../utils/utils";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { CSVLink } from "react-csv";
import { ReactComponent as EmptyStateIcon } from "assets/icons/empty-img-default.svg";

type Props = {
  questions?: ResponseType | any;
  isLoading: boolean;
};
const ResponsesTable = ({ questions, isLoading }: Props) => {
  const responses: ResponseType[] = questions !== undefined ? questions : [];
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [selectedResponse, setSelectedResponse] = useState<any>();
  const [filterPatients, setFilterPatients] = useState("");
  const [csvName, setCsvName] = useState("");
  const filteredPatients = responses?.filter(
    (item: ResponseType) =>
      item?.user?.first_name
        ?.toLowerCase()
        .includes(filterPatients?.toLowerCase()) ||
      item?.user?.last_name
        ?.toLowerCase()
        .includes(filterPatients?.toLowerCase()),
  );

  const columns: ColumnsType<any> = [
    {
      title: "Date Created",
      dataIndex: "created_at",
      key: "created_at",
      width: 160,
      fixed: "left",
      render: (created_at) => {
        return (
          <p className="preferred-date">
            <span>{moment(created_at).format("ll")}</span>
            <span>{moment(created_at).format("LT")}</span>
          </p>
        );
      },
    },
    {
      title: "Patient",
      dataIndex: "user",
      key: "patient",
      width: 220,
      fixed: "left",
      render: (user) => (
        <p>
          {capitaliseFirstLetter(user.first_name)} {user.last_name}
        </p>
      ),
    },

    ...(responses[0]?.responses !== undefined
      ? responses[0]?.responses.map((response: IResponses) => ({
          title: response.question_text,
          dataIndex: response.question.toString(),
          key: response.question.toString(),
          width: 420,
          render: (text: any) => (
            <div>
              {Array.isArray(text) ? (
                text.map((item, index) => (
                  <div key={index} className="answer">
                    <CheckIcon />
                    <p>{item}</p>
                  </div>
                ))
              ) : (
                <div className="answer">
                  <CheckIcon />
                  <p>{text}</p>
                </div>
              )}
            </div>
          ),
        }))
      : []),
    {
      title: "",
      dataIndex: "user_responses",
      key: "button",
      width: 160,
      fixed: "right",
      render: (user_responses) => (
        <Button
          type="primary"
          style={{
            backgroundColor: "transparent",
            color: "#0FAFF0",
          }}
          onClick={() => {
            setSelectedResponse(user_responses);
            setIsDrawerVisible(true);
          }}
        >
          View Response
        </Button>
      ),
    },
  ];

  const dataSource = filteredPatients.map((dataItem: ResponseType) => ({
    user_responses: dataItem,
    user: dataItem.user,
    ...dataItem.responses.reduce((acc: any, response: IResponses) => {
      acc[response.question.toString()] = response.value;
      return acc;
    }, {} as any),
    created_at: dataItem.created_at,
  }));

  const downloadUserResponses = useMemo(
    () =>
      responses?.map(({ responses, created_at, form, user }) => {
        setCsvName(form.name);
        return {
          "Form Name": form.name,
          Patient: `${user.first_name} ${user.last_name}`,
          "Created At": moment(created_at).format("MMM Do, YYYY"),
          ...responses.reduce((result: any, response: any) => {
            result[response.question_text] = response.value;
            return result;
          }, {}),
        };
      }),
    [responses],
  );

  return (
    <>
      <ResponseHeader>
        <TextWrapper>
          <h3>Responses</h3>
          <span>{responses?.length}</span>
        </TextWrapper>
        {responses?.length !== 0 && (
          <SearchBarWrapper>
            <Search
              placeholder="Search by patient name"
              onChange={(e: any) => setFilterPatients(e.target.value)}
            />
            <CSVLink
              data={downloadUserResponses}
              filename={`${csvName}__${moment().format("L")}.csv`}
            >
              <Button type={"primary"}>Download responses</Button>
            </CSVLink>
          </SearchBarWrapper>
        )}
      </ResponseHeader>
      {responses?.length !== 0 ? (
        <>
          <ResponseTableBody>
            <Table
              dataSource={dataSource}
              columns={columns}
              scroll={{ x: "100%" }}
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              style={{ pointerEvents: isLoading ? "none" : "all" }}
              loading={isLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true,
              }}
            />
          </ResponseTableBody>
        </>
      ) : (
        <EmptyStateWrapper>
          <div>
            <EmptyStateIcon />
            <h2>You haven't recieved any responses yet.</h2>
            <p>All your responses will show here</p>
          </div>
        </EmptyStateWrapper>
      )}
      <ResponseDrawer
        isVisible={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
        response={selectedResponse}
      />
    </>
  );
};

export default ResponsesTable;
