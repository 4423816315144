import { useEffect, useState } from "react";
import { Table, Row, Col } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Button from "component/Button";

import Card from "component/Card";
import { useAppDispatch, useAppSelector } from "redux/store";
import { fetchPractitioners } from "redux/slices/practitioners";
import Loader from "component/Loader";
import Search from "component/Search";

import {
  EmptyStateWrapper,
  PractitionersTableBody,
  SearchAndCreateWrapper,
  PractitionersTableWrapper
} from "./styles";

// svg
import { ReactComponent as AddIcon } from "assets/icons/add-practitioner.svg";
import AddPractitionerModal from "component/AddPractitionerModal";
import { capitaliseFirstLetter, capitaliseWords } from "utils/utils";
import EditPractitionerModal from "component/EditPractitionerModal";

function Practitioners() {
  const { practitioners } = useAppSelector((state) => state.practitioners);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [selectedPractitioner, setSelectedPractitioner] = useState<any>();

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        await dispatch(fetchPractitioners());
      } catch (err) {
        console.log(err);
        setHasError(true);
      } finally {
        setIsLoading(false);
      }
    }

    fetchData();
  }, [dispatch]);

  if (isLoading) {
    return <Loader background='transparent' />;
  }

  if (hasError) {
    return (
      <EmptyStateWrapper>
        <h2>Error Loading page...</h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      render: (_: string, record: any) => (
        <p>{capitaliseFirstLetter(record.title)}</p>
      )
    },
    {
      title: "Practitioner",
      dataIndex: "name",
      render: (_: string, record: any) => (
        <p>{capitaliseFirstLetter(record.name)}</p>
      )
    },
    {
      title: "Specialty",
      dataIndex: "specialty",
      filters: practitioners
        .map(({ specialty }) => ({
          text: capitaliseWords(specialty),
          value: capitaliseWords(specialty)
        }))
        .filter(function (item, pos, self) {
          return pos === self.findIndex((t) => t.text === item.text);
        }),
      onFilter: (value: any, record: any) =>
        record.specialty.toLowerCase().startsWith(value.toLowerCase()),
      filterSearch: true,
      render: (_: string, record: any) => (
        <p>{capitaliseFirstLetter(record.specialty)}</p>
      )
    },
    {
      title: "Gender",
      dataIndex: "gender",
      render: (_: string, record: any) => (
        <p>{capitaliseFirstLetter(record.gender)}</p>
      )
    },
    { title: "Email", dataIndex: "email" },
    { title: "Phone", dataIndex: "phone" },
    {
      title: "",
      render: (_: string, record: any) => (
        <div>
          <button
            style={{
              color: "#1890ff",
              textDecoration: "none",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              cursor: "pointer",
              padding: ".2em .4em"
            }}
            onClick={() => {
              setIsEditModalVisible(true);
              setSelectedPractitioner(record);
            }}
          >
            <EditOutlined />
          </button>
        </div>
      )
    }
  ];

  const filteredPractitioners = practitioners.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      user.specialty?.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      user.email?.toLowerCase().includes(searchTerm.toLowerCase().trim()) ||
      user.title?.toLowerCase().includes(searchTerm.toLowerCase().trim())
  );

  return (
    <>
      {practitioners.length ? (
        <>
          <Row justify='space-between' align='middle'>
            <Col xs={24} md={12}>
              <h3 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                {filteredPractitioners.length} Practitioner
                {filteredPractitioners.length > 1 ? "s" : ""}
              </h3>
            </Col>
            <Col
              xs={24}
              md={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <SearchAndCreateWrapper>
                <Col>
                  <Search
                    placeholder='Search by practitioner'
                    onChange={(e: any) => setSearchTerm(e.target.value)}
                  />
                </Col>

                <Col>
                  <Button
                    className='plan-add'
                    type='primary'
                    pAlign
                    onClick={() => setIsModalVisible(true)}
                  >
                    Add a practitioner
                  </Button>
                </Col>
              </SearchAndCreateWrapper>
            </Col>
          </Row>

          <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
            <PractitionersTableWrapper>
              <PractitionersTableBody>
                <Table
                  dataSource={filteredPractitioners}
                  columns={columns}
                  style={{ pointerEvents: isLoading ? "none" : "all" }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "even-row" : "odd-row"
                  }
                  scroll={
                    !isLoading && filteredPractitioners.length > 0
                      ? { x: 700 }
                      : window.matchMedia("(max-width: 650xp").matches
                      ? { x: 700 }
                      : {}
                  }
                  loading={isLoading}
                  pagination={{
                    defaultCurrent: 1,
                    position: ["bottomRight"],
                    showLessItems: true
                  }}
                />
              </PractitionersTableBody>
            </PractitionersTableWrapper>
          </Card>
        </>
      ) : (
        <EmptyStateWrapper>
          <div>
            <h2>No practitioners added</h2>
            <p>
              All your medical practitioners will show here and you can manage
              them
            </p>

            <Button type='primary' onClick={() => setIsModalVisible(true)}>
              <AddIcon /> Add practitioner
            </Button>
          </div>
        </EmptyStateWrapper>
      )}

      <AddPractitionerModal
        isModalVisible={isModalVisible}
        handleCancel={() => setIsModalVisible(false)}
      />

      <EditPractitionerModal
        isModalVisible={isEditModalVisible}
        handleCancel={() => {
          setIsEditModalVisible(false);
          setSelectedPractitioner(null);
        }}
        selectedPractitioner={selectedPractitioner}
      />
    </>
  );
}

export default Practitioners;
