import { Form, Row, Col, message as AntdMessage } from "antd";
import { useState, useEffect } from "react";
import Button from "component/Button";
import Input from "component/Input";
import TextArea from "component/TextArea";
import DatePicker from "component/DatePicker";
import { useAppSelector } from "redux/store";
import { profileSelector } from "redux/reducers/profile";
import { ModalWrapper } from "layouts/DashboardLayout/styles";
import { MixPanel } from "utils/mixpanel";
import {
  useCreateFollowUpMutation,
  useUpdateFollowUpMutation
} from "redux/queries/follow-ups";
import moment from "moment";

type AddFollowUpModalProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  isEdit: boolean;
  followUps?: any;
  serviceOrder?: any;
};

function AddFollowUpModal({
  isModalVisible,
  handleCancel,
  isEdit,
  serviceOrder,
  followUps
}: AddFollowUpModalProps) {
  const { profile: ProviderProfile } = useAppSelector(profileSelector);
  const [isLoading, setIsLoading] = useState(false);
  const [addFollowUpForm] = Form.useForm();

  const [updateFollowUp] = useUpdateFollowUpMutation();
  const [addFollowUp] = useCreateFollowUpMutation();

  useEffect(() => {
    if (isEdit && followUps) {
      addFollowUpForm.setFieldsValue({
        goal: followUps.goal,
        note: followUps.note,
        date: moment(new Date(followUps?.date), "MMM Do, YYYY")
      });
    }
  }, [isEdit, addFollowUpForm, followUps]);

  let service_order = serviceOrder?.find((item: any) => item?.id);

  const switchTags = () => {
    if (isEdit) {
      return isLoading ? "Editing..." : "Edit follow up";
    } else {
      return isLoading ? "Scheduling..." : "Schedule follow up";
    }
  };

  async function setupFollowUp(values: any) {
    setIsLoading(true);
    const _values = values as { goal: string; note: string; date: string };

    try {
      if (isEdit) {
        // update the details of that specific follow up
        await updateFollowUp({
          followUpId: followUps.id,
          data: _values
        }).unwrap();

        AntdMessage.success("Follow up updated successfully");
      } else {
        await addFollowUp({
          service_order: service_order.id,
          ..._values
        }).unwrap();

        AntdMessage.success("Follow up added successfully");
      }
      addFollowUpForm.resetFields();
      handleCancel();
      MixPanel.track("FollowUp", {
        service: service_order?.plan?.name ?? "",
        provider: ProviderProfile.business_name,
        "followup-date": _values.date,
        goal: _values.goal
      });
    } catch (err) {
      const _err = err as Error;
      AntdMessage.error(_err.message);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ModalWrapper
      title={`${isEdit ? "Edit" : "Schedule"} a follow up`}
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className='dashboard-mode-modal'
    >
      <Form
        name='addFollowUpForm'
        layout='vertical'
        form={addFollowUpForm}
        onFinish={setupFollowUp}
      >
        <Row gutter={18}>
          <Col xs={24} sm={16}>
            <Input
              formItem={{
                name: "goal",
                label: "Goal of follow up",

                rules: [
                  {
                    required: true,
                    message: "Please enter follow up goal"
                  }
                ]
              }}
              label=''
              mode='normal'
              placeholder='E.g. remind to use medications'
            />
          </Col>

          <Col xs={24} sm={8}>
            <DatePicker
              formItem={{
                label: "Date",
                name: "date",
                rules: [{ required: true, message: "Please input date" }]
              }}
              marginBottom={0}
              mode='normal'
              width='auto'
              format={"MMM Do, YYYY"}
              inputReadOnly={true}
            />
          </Col>

          <Col xs={24}>
            <TextArea
              formItem={{
                name: "note",
                label: "Additional notes/comments"
              }}
              label=''
              mode='normal'
              maxLength={300}
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>

          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button htmlType='submit' type='primary' disabled={isLoading}>
              {switchTags()}
            </Button>
          </Col>
        </Row>
      </Form>
    </ModalWrapper>
  );
}

export default AddFollowUpModal;
