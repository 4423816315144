import React, { useState } from "react";
import { StartPageWrapper } from "./styles";
import QuestionsForm from "./QuestionsForm";
import { SingleFormType } from "../../constants/types";
import Loader from "../Loader";

type QuestionsProps = {
  form: any;
  currentFormStep: number;
  isLoading: boolean;
  handleQuestionFormChange: any;
  normalizedQuestions: any;
  currentQuestionId: any;
  responses: Record<any, any>;
};
const Questions = ({
  form,
  currentFormStep,
  isLoading,
  handleQuestionFormChange,
  normalizedQuestions,
  currentQuestionId,
  responses,
}: QuestionsProps) => {
  const step = [
    <StartPage key={0} form={form} isLoading={isLoading} />,
    <QuestionsForm
      key={1}
      handleQuestionFormChange={handleQuestionFormChange}
      form={form}
      currentQuestionId={currentQuestionId}
      normalizedQuestions={normalizedQuestions}
      responses={responses}
    />,
  ];

  return <> {step[currentFormStep]}</>;
};

export default Questions;

export const StartPage: React.FC<{
  form: SingleFormType;
  isLoading: boolean;
}> = ({ form, isLoading }) => {
  const question: SingleFormType = form;

  return (
    <StartPageWrapper>
      {isLoading ? (
        <Loader background="transparent" />
      ) : (
        <>
          <div className="flex">
            <div className="content">
              {question?.icon ? (
                <img src={question?.icon} alt={question?.name} />
              ) : null}

              <div className="text_wrapper">
                <h2>{question?.name}</h2>
                <span>{question?.description}</span>
              </div>
            </div>
          </div>
        </>
      )}
    </StartPageWrapper>
  );
};
