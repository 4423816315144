import styled from "styled-components";

export const MessagesWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 57px);

  h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
    text-align: left;
    color: #4e667e;
  }
`;

export const ContentWrapper = styled.div`
  padding: 25px;
  background: #f3f8fb;
  height: calc(100vh - 60px);
  overflow: hidden;

  .update-profile-btn {
    margin: 50px 0px 0px 50px;
  }
`;

export const EmptyStateWrapper = styled.div`
  height: calc(100vh - 200px);
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans";

    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      color: #222222;
      margin: 0 0 12px;

      @media (min-width: 768px) {
        font-size: 36px;
      }

      @media (min-width: 768px) {
        font-size: 48px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #667085;
      margin: 0 0 24px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
  }
`;
