import {
  Col,
  Row,
  Select,
  Skeleton,
  Drawer,
  Tabs,
  Button as AntdButton,
  message as AntdMessage,
  Checkbox
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { send } from "emailjs-com";

import Button from "component/Button";
import Card from "component/Card";

// svgs
import { ReactComponent as EncounterIcon } from "assets/icons/doctor.svg";
import { ReactComponent as ServiceIcon } from "assets/icons/healthcare.svg";
import { ReactComponent as PatientsIcon } from "assets/icons/patients.svg";
import { ReactComponent as AlarmIcon } from "assets/icons/alarm.svg";
import { ReactComponent as DarkAlarmIcon } from "assets/icons/alarm-dark.svg";

import { ReactComponent as GiftIcon } from "assets/icons/gift-icon.svg";

import {
  DashboardWrapper,
  ContentWrapper,
  StatsCardWrapper,
  OverviewIntroCardWrapper,
  DrawerBody
} from "./styles";
import { useAppSelector } from "redux/store";
import { profileSelector } from "redux/reducers/profile";
import { requestsSelector } from "redux/reducers/requests";
import { useSendSingleMessageMutation } from "redux/queries/messages";
import {
  servicePlansSelector,
  setBookingModalState
} from "redux/reducers/servicePlans";
import { userSelector } from "redux/reducers/users";
import { capitaliseFirstLetter } from "utils/utils";
import OverviewRequestsTable from "./containers/OverviewRequestsTable";
import OverviewFollowUpsTable from "./containers/OverviewFollowUpsTable";
import { UserType } from "constants/types";
import SendSMSModal from "component/SendSMSModal";
import { ROUTES } from "constants/routes";
import { MixPanel } from "utils/mixpanel";
import { useGetAllFollowUpsQuery } from "redux/queries/follow-ups";

const Dashboard = ({ dashboardMode }: any) => {
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription
  );

  const history = useHistory();
  const dispatch = useDispatch();
  const { profile } = useSelector(profileSelector);
  const { investigations: requests, isInvestgationsLoading } =
    useSelector(requestsSelector);
  const { data: followUps } = useGetAllFollowUpsQuery();

  const { servicePlans, servicePlansLoading } =
    useSelector(servicePlansSelector);
  const { users, isUsersLoading } = useSelector(userSelector);
  const {
    business_name,
    logo_path,
    email,
    phone: provider_phone,
    email: provider_email
  } = profile || {};
  const [sendSingleMessage] = useSendSingleMessageMutation();

  const [selectedDate, setSelectedDate] = useState<"today" | "tomorrow">(
    "today"
  );
  const [selectedFollowDate, setSelectedFollowDate] = useState<
    "today" | "tomorrow"
  >("today");

  const [sendEmailsLoading, setSendEmailsLoading] = useState(false);
  const [sendSMSLoading, setSendSMSLoading] = useState(false);
  const [currentBookings, setCurrentBookings] = useState<any>([]);
  const [totalCurrentBookings, setTotalCurrentBookings] = useState<any>([]);
  const [currentFollowUpsLength, setCurrentFollowUpsLength] =
    useState<number>();
  const [activeTab, setActiveTab] = useState<"bookings" | "follow-ups">(
    "bookings"
  );
  const [openedSidebar, setOpenedSidebar] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedBirthdayUsers, setSelectedBirthdayUsers] = useState<
    UserType[]
  >([]);

  let birthdayUsers = users.filter((user) => {
    return (
      moment(user.dob, "DD/MM/YYYY").date() ===
        moment(moment(), "DD/MM/YYYY").date() &&
      moment(user.dob, "DD/MM/YYYY").month() ===
        moment(moment(), "DD/MM/YYYY").month()
    );
  });

  // users whose birth month is the current month, but birthday is within 7 days of the current date, but greater than the current date.
  let upcomingUsers = users.filter((user) => {
    return (
      moment(user.dob, "DD/MM/YYYY").month() ===
        moment(moment(), "DD/MM/YYYY").month() &&
      moment(user.dob, "DD/MM/YYYY").date() >
        moment(moment(), "DD/MM/YYYY").date() &&
      moment(user.dob, "DD/MM/YYYY").diff(moment(moment(), "DD/MM/YYYY")) < 7
    );
  });

  useEffect(() => {
    document.title = "FrontDesk | Overview";
    const id = localStorage.getItem("providerID")!;
    MixPanel.identify(id);
    MixPanel.people.set({ $name: business_name, $email: email });
  }, [business_name, email]);

  const statsData2 = [
    {
      title: "Patients",
      value: users?.length,
      loading: isUsersLoading,
      actionText:
        subscribedPlan === "freemium" ? "Upgrade now" : "View patients",
      icon: <PatientsIcon />,
      url: subscribedPlan === "freemium" ? "/payments" : "/patients"
    },
    {
      title: "Bookings",
      value: requests?.length,
      loading: isInvestgationsLoading,
      actionText: "View bookings",
      icon: <ServiceIcon />,
      url: "/service-requests"
    }
  ];

  const pendingTasks = requests.length
    ? requests.filter((item: any) => item.status === "pending")
    : 0;

  useEffect(() => {
    setCurrentBookings(() =>
      requests.length
        ? requests?.filter((item: any) => {
            return (
              (item.status === "pending" || item.status === "accepted") &&
              (selectedDate === "today"
                ? moment(item?.metadata?.preferred_date, "DD/MM/YYYY").isSame(
                    moment(),
                    "day"
                  )
                : moment(item?.metadata?.preferred_date, "DD/MM/YYYY").isSame(
                    moment().add(1, "days"),
                    "day"
                  ))
            );
          })
        : []
    );
  }, [requests, selectedDate]);

  // get total bookings for both today & tomorrow
  useEffect(() => {
    setTotalCurrentBookings(() =>
      requests.length
        ? requests?.filter((item: any) => {
            return (
              (item.status === "pending" || item.status === "accepted") &&
              (moment(item?.metadata?.preferred_date, "DD/MM/YYYY").isSame(
                moment(),
                "day"
              ) ||
                moment(item?.metadata?.preferred_date, "DD/MM/YYYY").isSame(
                  moment().add(1, "days"),
                  "day"
                ))
            );
          })
        : []
    );
  }, [requests]);

  // get total follow-ups for today/tomorrow
  useEffect(() => {
    setCurrentFollowUpsLength(() =>
      followUps?.data.length
        ? followUps?.data?.filter((item) => {
            return (
              item.status === "pending" &&
              (moment(item.date).isSame(moment(), "day") ||
                moment(item.date).isSame(moment().add(1, "days"), "day"))
            );
          }).length
        : 0
    );
  }, [followUps?.data]);

  async function sendReminderEmails() {
    const promiseArrs = currentBookings.map((record: any) => {
      const templateParams = {
        patient_email: record.user.email,
        first_name: record.user.first_name,
        preferred_date: record.metadata.preferred_date,
        preferred_time: record.metadata.preferred_time,
        service: record.plan.name,
        business_name,
        business_email: provider_email,
        logo: logo_path
      };

      return send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        "template_reminder",
        templateParams
      );
    });

    try {
      setSendEmailsLoading(true);
      await Promise.all(promiseArrs);
      AntdMessage.success("Reminders successfully sent via emails!");
    } catch (err) {
      AntdMessage.error("Failed to send emails. Try again.");
    } finally {
      setSendEmailsLoading(false);
    }
  }

  async function sendReminderSMS() {
    const promiseArrs = currentBookings.map((record: any) => {
      const _message = `Hello ${
        record.user.first_name
      }, We'll like to remind you of ${selectedDate}'s ${
        record.metadata.preferred_time ?? ""
      } ${
        record.plan.name
      } at ${business_name}. To reschedule, call ${provider_phone}`;

      return sendSingleMessage([
        {
          message: _message,
          user: record.user.id
        }
      ]);
    });
    setSendSMSLoading(true);
    try {
      await Promise.all(promiseArrs);
      MixPanel.track("MessageSent", {
        messageType: "reminders"
      });
      AntdMessage.success("Reminders successfully sent via SMS!");
    } catch (err) {
      AntdMessage.error("Failed to send SMS. Try again.");
    } finally {
      setSendSMSLoading(false);
    }
  }

  const { TabPane } = Tabs;

  return (
    <DashboardWrapper>
      <ContentWrapper>
        <Row gutter={0}>
          <Col span={24} className='overview-intro-pane'>
            <OverviewIntroCardWrapper>
              <div className='circles' />
              <div className='inner'>
                <Row align='middle'>
                  <Col xs={24} md={12}>
                    <h3>Welcome to your dashboard, {business_name}</h3>
                    <p>
                      Message Center: You have{" "}
                      {pendingTasks ? pendingTasks?.length : pendingTasks}{" "}
                      pending{" "}
                      {pendingTasks && pendingTasks?.length > 1
                        ? "bookings"
                        : "booking"}{" "}
                    </p>

                    {subscribedPlan !== "freemium" ? (
                      <Button
                        type='secondary'
                        onClick={() => setOpenedSidebar(true)}
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          alignItems: "center",
                          gap: "4px"
                        }}
                      >
                        {birthdayUsers.length < 1
                          ? "No patients have a birthday today"
                          : birthdayUsers.length > 1
                          ? `${birthdayUsers.length} patients have birthdays today`
                          : `${birthdayUsers.length} patient has a birthday today`}

                        <GiftIcon style={{ fill: "white" }} />
                      </Button>
                    ) : null}
                  </Col>
                  {subscribedPlan !== "freemium" ? (
                    <Col xs={24} md={12} style={{ textAlign: "right" }}>
                      <Button
                        type='secondary'
                        onClick={() => dispatch(setBookingModalState(true))}
                      >
                        Create appointment
                      </Button>
                    </Col>
                  ) : null}
                </Row>
              </div>
            </OverviewIntroCardWrapper>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Row gutter={16} equal-heights={"true"}>
              <Col xs={24} md={8} style={{ height: "100%" }}>
                <StatsCardItem
                  title='Services'
                  icon={<EncounterIcon />}
                  loading={servicePlansLoading}
                  url={
                    subscribedPlan === "freemium" ? "/payments" : "/my-services"
                  }
                  value={`${servicePlans.length.toLocaleString()}`}
                  actionText={
                    subscribedPlan === "freemium"
                      ? "Upgrade now"
                      : "View services"
                  }
                />
              </Col>
              {statsData2.map(
                ({ title, value, loading, actionText, url, icon }, index) => {
                  return (
                    <Col xs={24} md={8} key={index} style={{ height: "100%" }}>
                      <StatsCardItem
                        title={title}
                        value={value}
                        loading={loading}
                        icon={icon}
                        url={url}
                        actionText={actionText}
                      />
                    </Col>
                  );
                }
              )}
            </Row>
          </Col>
        </Row>
        <Row className='encounters-pane'>
          <Tabs>
            <TabPane
              tab={
                <p
                  style={{ margin: 0 }}
                  onClick={() => setActiveTab("bookings")}
                >
                  Bookings{" "}
                  {totalCurrentBookings?.length ? (
                    <span
                      className='bookings-count'
                      style={{
                        background:
                          activeTab === "bookings" ? "#E6F7FF" : "#f0f0f0"
                      }}
                    >
                      {totalCurrentBookings.length}
                    </span>
                  ) : null}
                </p>
              }
              key='1'
            >
              <>
                <Row>
                  <Col xs={0} md={12}>
                    <h4 className='bookings-header'>{`${capitaliseFirstLetter(
                      selectedDate
                    )}'s bookings`}</h4>{" "}
                    <Select
                      defaultValue='today'
                      style={{ minWidth: "100px" }}
                      onChange={(val: any) => setSelectedDate(val)}
                    >
                      <Select.Option value='today'>Today</Select.Option>
                      <Select.Option value='tomorrow'>Tomorrow</Select.Option>
                    </Select>
                  </Col>

                  {currentBookings?.length ? (
                    <Col
                      xs={0}
                      md={12}
                      style={{
                        textAlign: "right",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "10px",
                        justifyContent: "flex-end"
                      }}
                    >
                      <AntdButton
                        className='reminder-btn secondary'
                        onClick={() => sendReminderSMS()}
                        disabled={sendSMSLoading}
                      >
                        {sendSMSLoading ? (
                          <span>Sending...</span>
                        ) : (
                          <span className='wrapper'>
                            <span>Send SMS Reminders</span> <DarkAlarmIcon />
                          </span>
                        )}
                      </AntdButton>

                      <AntdButton
                        className='reminder-btn'
                        onClick={() => sendReminderEmails()}
                        disabled={sendEmailsLoading}
                      >
                        {sendEmailsLoading ? (
                          <span>Sending...</span>
                        ) : (
                          <span className='wrapper'>
                            <span>Send Email Reminders</span> <AlarmIcon />
                          </span>
                        )}
                      </AntdButton>
                    </Col>
                  ) : null}
                </Row>

                <Col xs={0} md={24}>
                  <OverviewRequestsTable
                    status='pending'
                    hasPagination={false}
                    marginTop={10}
                    selectedDate={selectedDate}
                  />
                </Col>
              </>
            </TabPane>
            {subscribedPlan !== "freemium" ? (
              <TabPane
                tab={
                  <p
                    style={{ margin: 0 }}
                    onClick={() => setActiveTab("follow-ups")}
                  >
                    Follow ups{" "}
                    {currentFollowUpsLength ? (
                      <span
                        className='bookings-count'
                        style={{
                          background:
                            activeTab === "follow-ups" ? "#E6F7FF" : "#f0f0f0"
                        }}
                      >
                        {currentFollowUpsLength}
                      </span>
                    ) : null}
                  </p>
                }
                key='2'
              >
                <>
                  <Col xs={0} md={12}>
                    <h4 className='bookings-header'>{`${capitaliseFirstLetter(
                      selectedFollowDate
                    )}'s follow ups`}</h4>{" "}
                    <Select
                      defaultValue='today'
                      style={{ minWidth: "100px" }}
                      onChange={(val) =>
                        setSelectedFollowDate(val as "today" | "tomorrow")
                      }
                    >
                      <Select.Option value='today'>Today</Select.Option>
                      <Select.Option value='tomorrow'>Tomorrow</Select.Option>
                    </Select>
                  </Col>

                  <Col xs={0} md={24}>
                    <OverviewFollowUpsTable
                      selectedDate={selectedFollowDate}
                      updateFollowUpsCount={setCurrentFollowUpsLength}
                    />
                  </Col>
                </>
              </TabPane>
            ) : null}
          </Tabs>
        </Row>
      </ContentWrapper>

      <Drawer
        className='sidebar-drawer'
        title={
          <div>
            <h3>Birthdays</h3>
          </div>
        }
        closable={true}
        placement='right'
        visible={openedSidebar}
        width='450'
        key='right'
        onClose={() => setOpenedSidebar(false)}
      >
        <DrawerBody>
          <Tabs centered>
            <TabPane tab='Today' key='1'>
              {birthdayUsers.length ? (
                <>
                  <label className='select-all'>
                    <Checkbox
                      id='select-all'
                      checked={Boolean(
                        selectedBirthdayUsers.length === birthdayUsers.length
                      )}
                      onChange={(e) => {
                        e.target.checked
                          ? setSelectedBirthdayUsers([...birthdayUsers])
                          : setSelectedBirthdayUsers([]);
                      }}
                    />{" "}
                    Select all birthdays
                  </label>
                  {birthdayUsers.map((user) => (
                    <div className='birthday-user' key={user.id}>
                      <div>
                        <label htmlFor={user.id}>
                          {capitaliseFirstLetter(user.first_name)}{" "}
                          {capitaliseFirstLetter(user.last_name)}
                        </label>
                        <p>
                          {capitaliseFirstLetter(user.gender)},{" "}
                          {moment(moment()).year() -
                            moment(user.dob, "DD/MM/YYYY").year()}{" "}
                          years old today
                        </p>
                      </div>

                      <Checkbox
                        id={user.id}
                        checked={Boolean(
                          selectedBirthdayUsers.find(
                            (item) => item.id === user.id
                          )
                        )}
                        onChange={(e) =>
                          setSelectedBirthdayUsers((prev) => {
                            if (prev.includes(user)) {
                              return prev.filter((item) => item !== user);
                            }

                            return [...prev, user];
                          })
                        }
                      />
                    </div>
                  ))}
                  {selectedBirthdayUsers.length ? (
                    <Button
                      type='primary'
                      className='send-birthday-btn'
                      onClick={() => setIsModalVisible(true)}
                    >
                      Send{" "}
                      {selectedBirthdayUsers.length > 1
                        ? `wishes (${selectedBirthdayUsers.length})`
                        : "wish"}
                    </Button>
                  ) : null}
                </>
              ) : (
                <div className='no-users'>
                  <h4>No patients have birthdays today</h4>
                  <Button
                    type='secondary'
                    onClick={() => history.push(ROUTES.PATIENTS)}
                  >
                    View patients list
                  </Button>
                </div>
              )}
            </TabPane>
            <TabPane tab='Upcoming' key='2'>
              {upcomingUsers.length ? (
                upcomingUsers.map((user) => (
                  <div className='upcoming-user' key={user.id}>
                    <h5>
                      {capitaliseFirstLetter(user.first_name)}{" "}
                      {capitaliseFirstLetter(user.last_name)}
                    </h5>
                    <p>
                      Has birthday on{" "}
                      {moment(user.dob, "DD/MM/YYYY").format("MMMM Do")}
                    </p>
                  </div>
                ))
              ) : (
                <div className='no-users'>
                  <h4>No patients have birthdays over the coming week</h4>
                  <Button
                    type='secondary'
                    onClick={() => history.push(ROUTES.PATIENTS)}
                  >
                    View patients list
                  </Button>
                </div>
              )}
            </TabPane>
          </Tabs>
        </DrawerBody>
      </Drawer>

      <SendSMSModal
        isModalVisible={isModalVisible}
        handleCancel={() => {
          setOpenedSidebar(false);
          setIsModalVisible(false);
          setSelectedBirthdayUsers([]);
        }}
        messageType='birthday'
        selectedUsers={selectedBirthdayUsers}
      />
    </DashboardWrapper>
  );
};

export default Dashboard;

const StatsCardItem = ({
  title,
  value,
  loading,
  actionText,
  url,
  icon
}: any) => {
  return (
    <StatsCardWrapper active={false} isCurrency={title === "Wallet Balance"}>
      <Card>
        {loading ? (
          <Skeleton paragraph={{ rows: 1 }} />
        ) : (
          <div className='content'>
            <div className='flex'>
              <div>
                <p className='title'>{title}</p>
                <h2>
                  {value?.toLocaleString()}
                  {title === "Reviews & Ratings" && <small>/5.0</small>}
                </h2>
              </div>
              <span className='icon'>{icon}</span>
            </div>
            <span className='link'>
              <Link to={url}>
                {actionText} <i className='lni lni-arrow-right'></i>
              </Link>
            </span>
          </div>
        )}
      </Card>
    </StatsCardWrapper>
  );
};
