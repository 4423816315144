import { Button as AntdButton } from "antd"
import { ButtonWrapper } from "./styles";

const Button = (props: any) => {
    const { type, className, pMR, pAlign, ...rest} = props;
    return (
        <ButtonWrapper className={className} type={type} pMR={pMR} pAlign={pAlign}>     
            <AntdButton {...rest}>
                {props.children}
            </AntdButton>
        </ButtonWrapper>
    )
}

export default Button;