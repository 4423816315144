import { Form } from "antd";
import styled from "styled-components";

export const CustomForm = styled(Form)`
  & .ant-select-multiple {
    .ant-select-selection-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 19px;
      gap: 10px;
      background: #0c8abc;
      border-radius: 57px;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #ffffff;
    }

    .ant-select-selection-item-remove {
      color: #ffffff;
    }
  }

  .select-wrapper {
    position: relative;

    .checkbox {
      position: absolute;
      top: 0;
      left: 105px;
      display: flex;
      align-items: center;
      gap: 4px;

      input {
      }

      label {
        cursor: pointer;
        align-self: flex-end;
        font-size: 13px;
        user-select: none;
      }
    }
  }
`;
