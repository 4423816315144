import styled from "styled-components";
export const Wrapper = styled.div`
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 0px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #333333;
  }
`;
