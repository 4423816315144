import { combineReducers } from "@reduxjs/toolkit";
import AuthStateReducer from "./auth";
import ExaminationReducer from "./examinations";
import EncountersReducer from "./encounters";
import SymptomReducer from "./symptoms";
import DiagnosisReducer from "./diagnoses";
import ConsultReducer from "./consults";
import MessageReducer from "./messages";
import InvestigationReducer from "./requests";
import MedicationReducer from "./medications";
import TreatmentsReducer from "./treatments";
import UsersReducer from "./users";
import TransactionsReducer from "./transactions";
import ReviewsReducer from "./reviews";
import FormsReducer from "./forms";
import LabRequestsReducer from "./labRequests";
import ProfileReducer from "./profile";
import servicePlansReducer from "./servicePlans";
import connectApi from "redux/queries/connect-api";
import { subscriptionSlice } from "../slices/subscriptions";
import { practitionersSlice } from "../slices/practitioners";
import { submitFormApi } from "../queries/service-forms";

const rootReducer = combineReducers({
  auth: AuthStateReducer,
  encounters: EncountersReducer,
  examinations: ExaminationReducer,
  symptoms: SymptomReducer,
  medications: MedicationReducer,
  treatments: TreatmentsReducer,
  investigations: InvestigationReducer,
  diagnoses: DiagnosisReducer,
  consults: ConsultReducer,
  messages: MessageReducer,
  users: UsersReducer,
  transactions: TransactionsReducer,
  reviews: ReviewsReducer,
  forms: FormsReducer,
  labRequests: LabRequestsReducer,
  profile: ProfileReducer,
  servicePlans: servicePlansReducer,
  connectApi: connectApi.reducer,
  subscription: subscriptionSlice.reducer,
  practitioners: practitionersSlice.reducer,
  [submitFormApi.reducerPath]: submitFormApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
