import React from "react";
import { Checkbox, Form, FormInstance, Radio } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FormListFieldData, FormListOperation } from "antd/lib/form/FormList";
import Input from "../../../../component/Input";
import Button from "../../../../component/Button";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { OptionsWrapper } from "./styles";

export const SelectOne: React.FC<{
  subField: FormListFieldData;
  form: FormInstance;
  onRemoveNextQuestion: (questionId: number, optionId: number) => void;
  field: FormListFieldData;
  subOpt: FormListOperation;
}> = ({ subField, onRemoveNextQuestion, form, field, subOpt }) => {
  const value = Form.useWatch(
    ["questions", field.name.toString(), "response", subField.name.toString()],
    form
  );

  return (
    <OptionsWrapper key={subField.key}>
      <Form.Item
        name={[subField.name, "id"]}
        style={{ margin: 0, display: "none" }}
        initialValue={subField.name}
      >
        <Input />
      </Form.Item>
      <div className='flex'>
        <Radio />
        <Form.Item name={[subField.name, "value"]} style={{ margin: 0 }}>
          <Input mode='normal' />
        </Form.Item>
      </div>

      <div className='options-btn-wrapper'>
        {value?.next_question && (
          <div className='next_question'>
            <span>Jump to Question {value?.next_question}</span>
            <CloseOutlined
              size={20}
              onClick={() => onRemoveNextQuestion(field.name, subField.name)}
            />
          </div>
        )}
        <Button
          onClick={() => {
            subOpt.remove(subField.name);
          }}
        >
          <DeleteIcon />
        </Button>
      </div>
    </OptionsWrapper>
  );
};

export const SelectMultiple: React.FC<{
  subField: FormListFieldData;
  form: FormInstance;
  onRemoveNextQuestion: (questionId: number, optionId: number) => void;
  field: FormListFieldData;
  subOpt: FormListOperation;
}> = ({ subField, onRemoveNextQuestion, form, field, subOpt }) => {
  const value = Form.useWatch(
    ["questions", field.name.toString(), "response", subField.name.toString()],
    form
  );

  return (
    <OptionsWrapper>
      <Form.Item
        name={[subField.name, "id"]}
        style={{ margin: 0, display: "none" }}
        initialValue={subField.name}
      >
        <Input />
      </Form.Item>

      <div className='flex'>
        <Checkbox />
        <Form.Item name={[subField.name, "value"]}>
          <Input mode='normal' />
        </Form.Item>
      </div>

      <div className='options-btn-wrapper'>
        {value?.next_question && (
          <div className='next_question'>
            <span>Jump to Question {value?.next_question}</span>
            <CloseOutlined
              size={20}
              onClick={() => onRemoveNextQuestion(field.name, subField.name)}
            />
          </div>
        )}
        <Button
          onClick={() => {
            subOpt.remove(subField.name);
          }}
          className='delete-options'
        >
          <DeleteIcon />
        </Button>
      </div>
    </OptionsWrapper>
  );
};
