import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0px 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  h1 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 120%;
    color: #333333;
  }

  .btn {
    display: flex;
    @media (min-width: 1024px) {
      justify-content: flex-start;
      align-items: flex-start;
    }

    span.link {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      gap: 8px;

      @media (max-width: 500px) {
        white-space: normal;
      }

      button {
        text-align: center;
        font-weight: 500;
        background: #ebf7fc;
        padding: 10px 15px;
        border-radius: 5px;
        border: 1px solid #fff;
        color: #0faff0;
        span {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
        }
        @media (max-width: 500px) {
          background: transparent;
          border: none;
          padding: 0px;
        }

        &:hover {
          background: #fff;
          border: 1px solid #0f9af01a;
        }
      }
    }
  }
`;
