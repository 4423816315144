import {
  Modal as AntdModal,
  Form as AntdForm,
  Row,
  Col,
  Select as AntdSelect,
  message as AntdMessage
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

import Input from "component/Input";
import DatePicker from "component/DatePicker";
import Select from "component/Select";
import Button from "component/Button";
import { UserType } from "constants/types";
import {
  updateUserDetails,
  updateUserDetailsWithNorthKey
} from "redux/reducers/users";
import { useAppDispatch } from "redux/store";
import { handlePhoneNumberFormat } from "utils/utils";

type EditUserModalProps = {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedUser: UserType;
};

function EditUser({
  handleCancel,
  isModalVisible,
  selectedUser
}: EditUserModalProps) {
  const dispatch = useAppDispatch();
  const [editPatientForm] = AntdForm.useForm();

  const [updateUserLoading, setUpdateUserLoading] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");

  useEffect(() => {
    editPatientForm.setFieldsValue({
      ...selectedUser,
      dob: moment(selectedUser.dob, "DD/MM/YYYY"),
      phone: selectedUser.phone?.startsWith("+")
        ? selectedUser.phone
        : "+234" + selectedUser.phone?.slice(1)
    });
  }, [editPatientForm, selectedUser]);

  function formatPhoneNumber(
    number: string,
    numberDetails: { dialCode: string }
  ) {
    const { dialCode } = numberDetails;
    const formattedPhone = handlePhoneNumberFormat(number, dialCode);

    setFormattedPhone(formattedPhone);
  }

  async function handleEditPatient(values: any) {
    const _values = values as UserType;
    _values.dob = moment(_values.dob).format("DD/MM/YYYY");
    _values.phone = formattedPhone || _values.phone;

    setUpdateUserLoading(true);
    try {
      await dispatch(updateUserDetails(selectedUser.id, _values));
      AntdMessage.success("Patient details updated successfully!");
      handleCancel();
      editPatientForm.resetFields();
    } catch (err: any) {
      if (err.message.includes("User not found")) {
        // TODO -> remember to fix ASAP.
        // if user is not found (this means that the selected user does not exist on the current integration - FrontDesk/PneumaPage). We would make a hacky workaround to account for erroneous Divine dentals mass record upload (the data was mistakenly uploaded to the wrong integration - Telehealth/North) and use that integration's API key instead to edit the patient record.
        try {
          await dispatch(
            updateUserDetailsWithNorthKey(selectedUser.id, _values)
          );
          AntdMessage.success("Patient details updated successfully!");
          handleCancel();
          editPatientForm.resetFields();
        } catch (err: any) {
          console.log(err);
          AntdMessage.error(err.message);
        }
      } else {
        AntdMessage.error(err.message || "Unable to update patient details!");
      }
    } finally {
      setUpdateUserLoading(false);
    }
  }

  return (
    <AntdModal
      title='Edit patient details'
      footer={null}
      visible={isModalVisible}
      onCancel={handleCancel}
      className='dashboard-mode-modal'
    >
      <AntdForm
        name='basicForm'
        form={editPatientForm}
        layout='vertical'
        onFinish={handleEditPatient}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Input
              formItem={{
                name: "first_name",
                label: "First name",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient's first name"
                  }
                ]
              }}
              placeholder="Patient's first name"
              mode='normal'
            />
          </Col>
          <Col span={12}>
            <Input
              formItem={{
                name: "last_name",
                label: "Last name",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient's last name"
                  }
                ]
              }}
              placeholder="Patient's last name"
              mode='normal'
            />
          </Col>

          <Col span={12}>
            <Input
              formItem={{
                name: "email",
                label: "Email",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient's email address"
                  }
                ]
              }}
              disabled={true}
              placeholder="Patient's email address"
              mode='normal'
            />
          </Col>

          <Col span={12}>
            <Input
              formItem={{
                name: "phone",
                label: "Phone number",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient's phone number"
                  },
                  {
                    required: true,
                    pattern: /[0-9]{11}$/,
                    message: "Please enter a valid phone number"
                  }
                ]
              }}
              placeholder="Patient's phone number"
              mode='normal'
              type='phone'
              onChange={formatPhoneNumber}
            />
          </Col>

          <Col span={12}>
            <DatePicker
              formItem={{
                name: "dob",
                label: "Date of birth",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient's date of birth"
                  }
                ]
              }}
              placeholder="Patient's date of birth"
              format='LL'
              mode='normal'
              inputReadOnly={true}
              disabledDate={(d: any) => {
                const dater = new Date();
                return !d || d.isSameOrAfter(dater);
              }}
            />
          </Col>

          <Col span={12}>
            <Select
              formItem={{
                name: "gender",
                label: "Gender",
                rules: [
                  {
                    required: true,
                    message: "Please select patient's gender"
                  }
                ]
              }}
              placeholder="Patient's gender"
              mode='normal'
            >
              <AntdSelect.Option value='male'>Male</AntdSelect.Option>
              <AntdSelect.Option value='female'>Female</AntdSelect.Option>
            </Select>
          </Col>

          <Col xs={24} sm={12}>
            <Input
              formItem={{
                name: "patientId",
                label: "Patient ID"
              }}
              type='text'
              mode='normal'
              style={{ textTransform: "uppercase" }}
            />
          </Col>
        </Row>

        <Row justify='end' style={{ marginTop: "20px" }}>
          <Button
            type='secondary'
            onClick={handleCancel}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button htmlType='submit' type='primary' disabled={updateUserLoading}>
            {updateUserLoading ? "Updating" : "Update"}
          </Button>
        </Row>
      </AntdForm>
    </AntdModal>
  );
}

export default EditUser;
