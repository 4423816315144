import styled from "styled-components";

export const Bottom = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0px;
  background: #ffffff;
  border-top: 1px solid #e4e7ec;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  padding: 20px 20px;
  display: flex;
  justify-content: center;

  @media (min-width: 1024px) {
    margin-right: 100%;
    width: 100%;
    padding: 20px 320px;
    justify-content: flex-start;
  }

  button {
    width: 212px !important;
    height: 51px !important;
    span {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 20px;
    }
  }
`;
