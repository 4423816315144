import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
  padding: 50px 20px;
  max-height: 400vh;
  position: relative;
  @media (min-width: 768px) {
    padding: 40px 40px;
  }
  @media (min-width: 1024px) {
    padding: 40px 40px;
  }
  width: 100%;
`;
export const ReturnLink = styled(NavLink)`
  color: #0d0d0d;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  gap: 10px;

  span {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 20px;
    align-items: center;
  }
`;

export const PatientDetails = styled.div`
  padding: 50px 20px;
  h3 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    color: #333333;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    //align-items: center;
    justify-content: space-between;
    gap: 60px;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #0ea4df;
      padding-left: 20px;
    }
  }
`;
