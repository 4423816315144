import { toWords } from "number-to-words";

import { useAppSelector } from "redux/store";
import { Wrapper } from "./styles";
import { ReactComponent as PneumaLogo } from "assets/icons/pneuma-logo.svg";
import { capitaliseFirstLetter } from "utils/utils";

interface InvoiceProps {
  createdAt: string;
  due_date: string;
  amount: number;
  plan: string;
}

function Invoice({ createdAt, due_date, amount, plan }: InvoiceProps) {
  const { profile } = useAppSelector((state) => state.profile);

  return (
    <Wrapper id='printJS-invoice'>
      <div className='invoice-details'>
        <div className='pneuma'>
          <PneumaLogo />
          <p>+2348117790867, +2348154827399</p>
          <p>No 50 Ebitu Ekiwe Street, Jabi Abuja.</p>
          <a
            href='mailto:subscription@pneuma.care'
            target='_blank'
            rel='noreferrer'
          >
            subscription@pneuma.care
          </a>
        </div>

        <div className='invoice'>
          <h2>Invoice</h2>
          <div className='basic-info'>
            <p>Invoice Number: 1</p>
            <p>Invoice Date: {createdAt}</p>
            <p>
              Invoice Amount: NGN{" "}
              {new Intl.NumberFormat("en-US").format(amount)}
            </p>
            <p>Provider: {profile.business_name}</p>
            <p>
              Provider ID: <span>{profile.id}</span>
            </p>
          </div>

          <div className='subscription-info'>
            <h3>Subscription Info</h3>
            <p>Plan: {capitaliseFirstLetter(plan)} Plan</p>
            <p>
              Billing period: {createdAt} to {due_date}
            </p>
            <p>Next Billing Date: {due_date}</p>

            <span className='status'>Subscription Paid</span>
          </div>
        </div>
      </div>

      <div className='description-wrapper'>
        <div className='desc'>
          <h4>Description</h4>
          <p>{capitaliseFirstLetter(plan)} plan for Frontdesk</p>
          <p>
            {createdAt} to {due_date}
          </p>
        </div>

        <div className='qty'>
          <h4>QTY</h4>
          <p>1</p>
        </div>

        <div className='price'>
          <h4>Unit Price</h4>
          <p>NGN {new Intl.NumberFormat("en-US").format(amount)}</p>
        </div>

        <div className='amount'>
          <h4>Amount</h4>
          <p>NGN {new Intl.NumberFormat("en-US").format(amount)}</p>
        </div>
      </div>

      <div className='total'>
        <p>Total</p>
        <p>
          <span>NGN {new Intl.NumberFormat("en-US").format(amount)}</span>
          <span>{capitaliseFirstLetter(`${toWords(amount)} naira only`)}</span>
        </p>
      </div>
    </Wrapper>
  );
}

export default Invoice;
