import { Form } from "antd";
import styled from "styled-components";

export const CustomForm = styled(Form)`
  & .ant-select-multiple {
    .ant-select-selection-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 6px 19px;
      gap: 10px;
      background: #0c8abc;
      border-radius: 57px;
      font-weight: 400;
      font-size: 13px;
      line-height: 120%;
      color: #ffffff;
    }

    .ant-select-selection-item-remove {
      color: #ffffff;
    }
  }
`;
