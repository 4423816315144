import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 70px 0px 0px;
  .patient {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-bottom: 50px;

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }
  }

  .patient--name,
  .contact--information,
  .information {
    display: flex;
    gap: 70px;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    h3 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      color: #344054;
    }
    p {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
    }
  }

  .contact,
  .address {
    padding-top: 70px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-bottom: 50px;

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }
  }
`;
