import axios, { AxiosInstance } from "axios";

const http: AxiosInstance = axios.create({
  baseURL: "https://api.unsplash.com",
  headers: {
    Authorization: `Client-ID ${process.env.REACT_APP_UNSPLASH_ACCESS_KEY!}`
  }
});

export const getHealthCarePhotos = (type?: string) =>
  http.get(
    `/search/photos?query=${
      type?.replaceAll("_", " ") || "healthcare"
    }&per_page=50&orientation=landscape`
  );
