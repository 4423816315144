import { useParams, useHistory } from "react-router-dom";
import moment from "moment";
import { useEffect, useState } from "react";
import { message as AntdMessage, Drawer as AntdDrawer } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Spin } from "antd";

import { useAppSelector, useAppDispatch } from "redux/store";
import { userSelector } from "redux/reducers/users";
import { fetchUserBookings } from "redux/reducers/servicePlans";
import {
  useGetSpecificPatientSMSQuery,
  useSendSingleMessageMutation,
} from "redux/queries/messages";

import {
  Header,
  MessagesList,
  Wrapper,
  MessageItem,
  NoPatientSelected,
  MessageInputWrapper,
  DrawerWrapper,
  DrawerHeader,
  DrawerBookings,
} from "./styles";
import { EmptyStateWrapper } from "screens/Messages/styles";
import ChatSendImg from "assets/icons/chat-send.svg";
import Button from "component/Button";
import Loader from "component/Loader";
import { SingleBooking } from "constants/types";
import { ReactComponent as ServiceIcon } from "assets/icons/healthcare.svg";
import { ROUTES } from "constants/routes";
import { MixPanel } from "utils/mixpanel";

type MessageDetailProps = {
  toggleChatList: () => void;
};

function MessageDetail({ toggleChatList }: MessageDetailProps) {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { patientId }: { patientId: string } = useParams();
  const [sendMessage] = useSendSingleMessageMutation();

  const { data, isFetching, error } = useGetSpecificPatientSMSQuery({
    patientId,
  });

  const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);
  const [chatText, setChatText] = useState("");
  const [getBookingsLoading, setGetBookingsLoading] = useState(false);
  const [userBookings, setUserBookings] = useState<SingleBooking[]>();

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  const userDetail = data?.data[0]?.user;
  const { users } = useAppSelector(userSelector);

  const selectUser = users.find((user) => user.id === patientId);

  useEffect(() => {
    async function getBookings() {
      setGetBookingsLoading(true);

      try {
        const res: any = await dispatch(fetchUserBookings(selectUser?.id!));
        setUserBookings(res);
      } catch (err) {
        console.log(err);
      } finally {
        setGetBookingsLoading(false);
      }
    }

    getBookings();
  }, [dispatch, selectUser?.id]);

  async function handleSendMessage(e: any) {
    e.preventDefault();
    setIsSendMessageLoading(true);
    try {
      await sendMessage([
        {
          message: chatText,
          user: selectUser?.id!,
        },
      ]);

      MixPanel.track("MessageSent", {
        messageType: "general",
        wordCount: chatText.length,
        userId: selectUser?.id!,
      });
      AntdMessage.success("Message sent!");
      setChatText("");
    } catch (err) {
      AntdMessage.error("Unable to send message. Please try again!");
    } finally {
      setIsSendMessageLoading(false);
    }
  }

  if (isFetching) {
    return <Loader background={"transparent"} />;
  }

  if (error) {
    return (
      <EmptyStateWrapper>
        <h2>Error Loading page...</h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }

  return (
    <Wrapper>
      {selectUser || userDetail ? (
        <>
          <Header>
            <button onClick={toggleChatList}>
              <ArrowLeftOutlined /> back
            </button>
            <div>
              <h2>
                {selectUser?.first_name || userDetail?.first_name}{" "}
                {selectUser?.last_name || userDetail?.last_name}
              </h2>
              <p>{selectUser?.phone}</p>
            </div>

            {/* TODO setup view patient profile */}
            <Button type="primary" onClick={() => setIsDrawerVisible(true)}>
              View profile
            </Button>
          </Header>

          <MessagesList>
            {data?.data.map((singleMessage) => (
              <MessageItem key={singleMessage.id}>
                <p>{singleMessage.message}</p>
                <small>
                  {moment(singleMessage.created_at).format("LL H:mm a")}
                </small>
              </MessageItem>
            ))}
          </MessagesList>

          {/* send message box */}
          <MessageInputWrapper>
            <textarea
              placeholder="Enter message here..."
              onSubmit={handleSendMessage}
              value={chatText}
              onChange={(e) => setChatText(e.target.value)}
              maxLength={160}
              required
            />
            {isSendMessageLoading ? (
              <span style={{ display: "flex", alignItems: "center" }}>
                <Spin />
              </span>
            ) : (
              <img
                src={ChatSendImg}
                alt="Chat send btn"
                onClick={handleSendMessage}
              />
            )}
          </MessageInputWrapper>
        </>
      ) : (
        <NoPatientSelected>
          <h3>No patient selected.</h3>
          <p>Select a patient to see messages or send a new message.</p>
        </NoPatientSelected>
      )}

      <AntdDrawer
        title={<h2>Profile</h2>}
        width="450"
        className="lab-request-drawer"
        closable={true}
        onClose={() => setIsDrawerVisible(false)}
        visible={isDrawerVisible}
      >
        <DrawerWrapper>
          <DrawerHeader>
            <h3>Patient Details</h3>
            <p>
              <b>Name: </b> {selectUser?.first_name} {selectUser?.last_name}
            </p>
            <p>
              <b>Email address: </b> {selectUser?.email}
            </p>
            <p>
              <b>Phone number</b>: {selectUser?.phone}
            </p>
          </DrawerHeader>

          <DrawerBookings>
            <h3>Recent Bookings</h3>

            <div>
              {getBookingsLoading ? (
                <span
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    minHeight: "10vh",
                  }}
                >
                  <Spin />
                </span>
              ) : userBookings?.length ? (
                userBookings?.slice(0, 5).map((booking) => (
                  <div className="single-booking" key={booking.id}>
                    <h4>{booking.plan.name}</h4>
                    <div>
                      {booking.metadata.preferred_time && (
                        <div>
                          <small>Time</small>
                          <p>{booking.metadata.preferred_time}</p>
                        </div>
                      )}

                      <div>
                        <small>Date</small>
                        <p>
                          {moment(
                            booking.metadata.preferred_date,
                            "DD/MM/YYYY"
                          ).format("LL")}
                        </p>
                      </div>

                      {booking.metadata.rating && (
                        <div>
                          <small>Service rating</small>
                          <p>{booking.metadata.rating} / 5</p>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-bookings">
                  <ServiceIcon fill="#0f9af0" />
                  <h4>
                    No bookings yet for{" "}
                    <span>
                      {selectUser?.first_name} {selectUser?.last_name}
                    </span>
                  </h4>

                  <Button
                    type="primary"
                    onClick={() => history.push(ROUTES.LAB_INVESTIGATIONS)}
                  >
                    View Bookings
                  </Button>
                </div>
              )}
            </div>
          </DrawerBookings>
        </DrawerWrapper>
      </AntdDrawer>
    </Wrapper>
  );
}

export default MessageDetail;
