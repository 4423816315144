import { useEffect, useState, useLayoutEffect } from "react";
import { Row, Col, Divider, Grid } from "antd";

import { MessagesWrapper, EmptyStateWrapper, ContentWrapper } from "./styles";
import Button from "component/Button";
import Loader from "component/Loader";
import MessageList from "./containers/MessageList";
import MessageDetail from "./containers/MessageDetail";
import useToggle from "hooks/useToggle";

import { useGetAllMessagesQuery } from "redux/queries/messages";
import { useAppSelector } from "redux/store";
import SendMessageModal from "component/SendMessageModal";

const Messages = () => {
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription
  );
  const [isChatList, toggleChatList] = useToggle(true);
  const [isSendMessageModalVisible, setIsSendMessageModalVisible] =
    useState(false);

  const { data, isLoading, error } = useGetAllMessagesQuery();

  useLayoutEffect(() => {
    if (subscribedPlan === "freemium") {
      window.location.replace("/payments");
    }
  }, [subscribedPlan]);

  useEffect(() => {
    document.title = "FrontDesk | Messages";
  }, []);

  const breakpoints = Grid.useBreakpoint();

  if (isLoading) {
    return <Loader />;
  }

  if (error) {
    return (
      <EmptyStateWrapper>
        <h2>Error Loading page...</h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }

  // this loops over the entire SMS messages response, to identify unique ids (users) and
  // the associated user objects from that.
  let userId: string[] = [];
  let userData: any[] = [];

  for (let i = 0; i < data?.data.length!; i++) {
    if (!userId.includes(data?.data[i].user.id!)) {
      userId.push(data?.data[i].user.id!);
      userData.push(data?.data[i]);
    }
  }
  return (
    <MessagesWrapper>
      <ContentWrapper>
        {data ? (
          <Row style={{ height: "100%" }} wrap={false} justify="center">
            <Col xs={isChatList ? 24 : 0} md={8} style={{ height: "100%" }}>
              <MessageList
                messages={userData}
                toggleChatList={toggleChatList}
              />
            </Col>

            {breakpoints.md ? (
              <Col>
                <Divider type="vertical" style={{ height: "100%" }} />
              </Col>
            ) : null}

            <Col xs={isChatList ? 0 : 24} md={15}>
              <MessageDetail toggleChatList={toggleChatList} />
            </Col>
          </Row>
        ) : (
          <EmptyStateWrapper>
            <div>
              <h2>No messages yet</h2>
              <p>All your messages will show here</p>
              <Button
                type="primary"
                onClick={() => setIsSendMessageModalVisible(true)}
              >
                Send message to patients
              </Button>
            </div>
          </EmptyStateWrapper>
        )}
      </ContentWrapper>

      <SendMessageModal
        isModalVisible={isSendMessageModalVisible}
        handleCancel={() => setIsSendMessageModalVisible(false)}
      />
    </MessagesWrapper>
  );
};

export default Messages;
