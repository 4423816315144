import StartIcon from "assets/icons/start-icon.svg";
import GrowIcon from "assets/icons/grow-icon.svg";
import EngageIcon from "assets/icons/engage-icon.svg";

const plans = [
  {
    icon: StartIcon,
    name: "Start",
    description:
      "Simple tools that helps you automate your patient interaction.",
    benefits: [
      'Email reminders',
      "Online scheduling",
      "Customised PneumaPage",
      "Credit request for patients",
      "Access to leads",
      "Claims management",
      "1,000 messages per month",
     "1,000 follow-ups",
     "WhatsApp integration",
      "Google reviews "
    ],
    payment_url: "https://paystack.com/pay/mlmy96ox0p"
  },

  {
    icon: EngageIcon,
    name: "Engage",
    description:
      "Advanced engagement and marketing tools that delights your customers.",
    benefits: [
      "Email reminders",
      "Online scheduling",
      "Customised PneumaPage",
      "Credit request for patients",
      "Access to leads",
      "Claims management",
      "2,000 messages per month",
      "2,000 follow-ups",
      "WhatsApp integration", 
      "Google reviews", 
      "Patient portal (coming soon)",
      "Livechat (coming soon)"
    ],
    payment_url: "https://paystack.com/pay/jxo1thstx7"
  },
  {
    icon: GrowIcon,
    name: "Grow",
    description: "Build a loyal customer base that loves your practice.",
    benefits: [
      "Email reminders",	
      "Online scheduling",	
      "Customised PneumaPage",	
      "Credit request for patients",	
      "Access to leads",	
      "Claims management",	
      "10,000 messages per month",	
      "10,000 follow-ups",	
      "WhatsApp integration", 	
      "Google reviews", 	
      "Patient portal (coming soon)",	
      "Livechat (coming soon)"	
        
    ],
    payment_url: "https://paystack.com/pay/v8594ka5b1"
  }
];

export const prices = {
  monthly: { start: 5000, engage: 7500, grow: 12500 },
  quarterly: { start: 15000, engage: 22500, grow: 37500 }
};

export const paymentPages = {
  monthly: {
    start: "https://paystack.com/pay/start-monthly",
    engage: "https://paystack.com/pay/engage-monthly-plan",
    grow: "https://paystack.com/pay/grow-monthly"
  },
  quarterly: {
    start: "https://paystack.com/pay/start-quarterly",
    engage: "https://paystack.com/pay/engage-quarterly",
    grow: "https://paystack.com/pay/grow-quarterly"
  }
};

export const dummyBillingHistory = [
  {
    id: 1,
    plan: "Start plan",
    amount: "25,000/Quarterly",
    date: "Dec 12, 2022"
  },
  {
    id: 2,
    plan: "Start plan",
    amount: "37,000/Quarterly",
    date: "Dec 12, 2022"
  },

  {
    id: 3,
    plan: "Start plan",
    amount: "12,500/Quarterly",
    date: "Dec 12, 2022"
  },

  {
    id: 2,
    plan: "Start plan",
    amount: "12,500/Quarterly",
    date: "Dec 12, 2022"
  },

  {
    id: 2,
    plan: "Start plan",
    amount: "12,500/Quarterly",
    date: "Dec 12, 2022"
  }
];

export default plans;