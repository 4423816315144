import React from "react";
import { Wrapper, ReturnLink, EditContainer } from "./styles";
import { generatePath, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../redux/store";
import { userSelector } from "../../redux/reducers/users";
import { Tabs } from "antd";
import BioForm from "../BioForm";
import MedicalHistoryForm from "../MedicalHistoryForm";
import { EmptyStateWrapper } from "../../screens/Messages/styles";
import Loader from "../Loader";

const EditDetails = () => {
  const { patientId }: { patientId: string } = useParams();
  const { users, usersError, isUsersLoading } = useAppSelector(userSelector);
  const selectUser = users.find((user) => user.id === patientId);

  if (!usersError) {
    return (
      <EmptyStateWrapper>
        <h2>Error Loading page...</h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }
  if (isUsersLoading) {
    return <Loader />;
  }
  return (
    <Wrapper>
      <ReturnLink
        to={generatePath(ROUTES.MORE_DETAILS, {
          patientId: selectUser?.id as any,
        })}
      >
        <ArrowLeftOutlined style={{ fontSize: "32px" }} />
        <span>Back</span>
      </ReturnLink>

      <EditContainer>
        <h3>Edit Patient Details</h3>

        <Tabs>
          <Tabs.TabPane tab={"Bio Date"} key={1}>
            <BioForm />
          </Tabs.TabPane>
          <Tabs.TabPane tab={"Medical History"} key={2}>
            <MedicalHistoryForm />
          </Tabs.TabPane>
        </Tabs>
      </EditContainer>
    </Wrapper>
  );
};

export default EditDetails;
