import firebase from "firebase";

const firebaseConfigDev = {
  apiKey: "AIzaSyD8T84TyoKjCzcroFdJq8LqHR3lahaDbyQ",
  authDomain: "services-connect.firebaseapp.com",
  projectId: "services-connect",
  storageBucket: "services-connect.appspot.com",
  messagingSenderId: "918817969245",
  appId: "1:918817969245:web:02b0478e3aaceab272a9b4",
  measurementId: "G-42TG8MEXSX",
};

const firebaseConfigProd = {
  apiKey: "AIzaSyDzfWutpmwFBe624PF3Etn2BaUgkG9q7QQ",
  authDomain: "service-provider-prod.firebaseapp.com",
  projectId: "service-provider-prod",
  storageBucket: "service-provider-prod.appspot.com",
  messagingSenderId: "732027451982",
  appId: "1:732027451982:web:17e02b76e414d679d5211f",
  measurementId: "G-G4KX710B70",
};

// Initialize Firebase
const app = firebase.initializeApp(
  process.env.REACT_APP_INSTANCE_ENV === "development"
    ? firebaseConfigDev
    : firebaseConfigProd
);
const Auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const analytics = firebase.analytics(app);
const perf = firebase.performance(app);

export { Auth, db, storage, app };
