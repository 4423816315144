import React from "react";
import { FormPreview, ModalWrapper } from "./styles";
import Card from "../Card";
import { SingleFormType } from "../../constants/types";
import TextArea from "../TextArea";
import { Checkbox, Radio } from "antd";

const FormPreviewModal: React.FC<{
  isModalVisible: boolean;
  handleCancel: () => void;
  data: SingleFormType;
}> = ({ isModalVisible, handleCancel, data }) => {
  return (
    <ModalWrapper
      title={"Form preview"}
      visible={isModalVisible}
      footer={null}
      style={{
        top: 20,
      }}
      onCancel={handleCancel}
    >
      <div className="preview-content">
        <Card>
          <div className="header">
            {data?.icon && <img src={data?.icon} alt="" />}
            <h1>{data?.name}</h1>
            {data?.description && <span>{data?.description}</span>}
          </div>
        </Card>
        {data?.questions.map((item) => (
          <Card key={item?.id}>
            <FormPreview>
              <div className="question__header">
                <div>
                  <span>{item?.id}</span>
                  <h4>{item?.text}</h4>
                </div>
                {item?.hint === "null" || null ? "" : <span>{item?.hint}</span>}
              </div>

              {item?.type === "text" && (
                <TextArea
                  autoSize={{ minRows: 3, maxRows: 5 }}
                  placeholder={"Write your answer here..."}
                />
              )}

              {item?.type === "select_one" && (
                <>
                  {item?.response.map((item) => (
                    <Radio.Group disabled value={""}>
                      <Radio
                        key={item?.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.value}
                      </Radio>
                    </Radio.Group>
                  ))}
                </>
              )}

              {item?.type === "select_multiple" && (
                <>
                  {item?.response.map((item) => (
                    <Checkbox.Group disabled>
                      <Checkbox
                        key={item?.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.value}
                      </Checkbox>
                    </Checkbox.Group>
                  ))}
                </>
              )}
            </FormPreview>
          </Card>
        ))}
      </div>
    </ModalWrapper>
  );
};

export default FormPreviewModal;
