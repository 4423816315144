import React, { useEffect, useState } from "react";
import { Wrapper, ReturnLink, PatientDetails } from "./style";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import { Tabs } from "antd";
import BioData from "../BioData";
import Button from "../Button";
import { generatePath, NavLink, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { fetchSingleUser, userSelector } from "../../redux/reducers/users";
import MedicalHistory from "../MedicalHistory";
import { ROUTES } from "../../constants/routes";
import { EmptyStateWrapper } from "../../screens/Messages/styles";
import Loader from "../Loader";
import { fetchUserBookings } from "../../redux/reducers/servicePlans";

const MoreDetails = () => {
  const dispatch = useAppDispatch();
  const { patientId }: { patientId: string } = useParams();
  const { users, usersError, isUsersLoading } = useAppSelector(userSelector);
  const selectUser = users.find((user) => user.id === patientId);
  const [patientInfo, setPatientInfo] = useState<any>("");
  const [isUserLoading, setIsUserLoading] = useState(false);

  useEffect(() => {
    async function getUser() {
      setIsUserLoading(true);
      try {
        const res: any = await dispatch(fetchSingleUser(patientId));
        setPatientInfo(res);
      } catch (err) {
        console.log(err);
      } finally {
        setIsUserLoading(false);
      }
    }
    getUser();
  }, [patientId, dispatch]);

  if (!usersError) {
    return (
      <EmptyStateWrapper>
        <h2>Error Loading page...</h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }
  if (isUsersLoading) {
    return <Loader />;
  }
  const user = patientInfo;
  return (
    <Wrapper>
      <ReturnLink
        to={generatePath(ROUTES.PATIENTS_DETAILS, {
          patientId: selectUser?.id as any,
        })}
      >
        <ArrowLeftOutlined style={{ fontSize: "32px" }} />
        <span>Back</span>
      </ReturnLink>

      <PatientDetails>
        <h3>Patient Details</h3>
        <div className={"tabs"}>
          <Tabs>
            <Tabs.TabPane tab={"Bio Data"} key={1}>
              <BioData user={user} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={"Medical History"} key={2}>
              <MedicalHistory user={user} />
            </Tabs.TabPane>
          </Tabs>

          {/*<div>*/}
          {/*  <NavLink*/}
          {/*    to={generatePath(ROUTES.EDIT_DETAILS, {*/}
          {/*      patientId: selectUser?.id,*/}
          {/*    })}*/}
          {/*  >*/}
          {/*    <EditOutlined style={{ fontSize: "20px" }} />*/}
          {/*    <span>Edit</span>*/}
          {/*  </NavLink>*/}
          {/*</div>*/}
        </div>
      </PatientDetails>
    </Wrapper>
  );
};

export default MoreDetails;
