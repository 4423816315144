import { Table, Row, Col, message as AntdMessage, Tooltip } from "antd";
import { useState, useEffect } from "react";
import { EditOutlined } from "@ant-design/icons";
import moment from "moment";

import Card from "component/Card";
import Search from "component/Search";
import Button from "component/Button";
import DatePicker from "component/DatePicker";
import {
  FollowUpsTableWrapper,
  FollowUpsTableBody,
  SearchAndCreateWrapper
} from "./styles";
import { capitaliseFirstLetter } from "utils/utils";
import AddFollowUpModal from "component/AddFollowUpModal";
import FollowUpsDrawer from "./FollowUpsDrawer";

import { ReactComponent as ViewMoreIcon } from "assets/icons/details-icon.svg";

//redux
import { useGetAllFollowUpsQuery } from "redux/queries/follow-ups";

const FollowUpsTable = () => {
  const [selectedMonth, setSelectedMonth] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filteredFollowUps, setFilteredFollowUps] = useState<any>();
  const [selectedBooking, setSelectedBooking] = useState<any>();
  const [isFollowUpModalVisible, setIsFollowUpModalVisible] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>({
    data: {
      id: "",
      goal: "",
      date: "",
      note: ""
    },
    followUpId: ""
  });

  // drawer
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);

  //follow queries
  const { data, isLoading, error } = useGetAllFollowUpsQuery();

  const follow: any = data?.data;
  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: follow
        ?.map(({ status }: { status: string }) => ({
          text: `${capitaliseFirstLetter(status)} follow ups`,
          value: capitaliseFirstLetter(status)
        }))
        .filter(function (item: any, pos: any, self: any) {
          return pos === self.findIndex((t: any) => t.text === item.text);
        }),
      onFilter: (value: any, record: any) =>
        record.status.toLowerCase().startsWith(value.toLowerCase()),
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            <span
              style={{
                display: "inline-block",
                padding: ".4em",
                marginRight: ".4em",
                background:
                  record.status === "completed"
                    ? "#0FBA84"
                    : record.status === "pending"
                    ? "#F2994A"
                    : "#EB5757",
                borderRadius: "100%"
              }}
            ></span>{" "}
            {capitaliseFirstLetter(record.status)}
          </p>
        );
      }
    },
    {
      title: "Goal",
      dataIndex: "goal",
      key: "goal",
      render: (_: string, record: any) => {
        return (
          <p style={{ maxWidth: "250px" }}>
            {capitaliseFirstLetter(record.goal)}
          </p>
        );
      }
    },
    {
      title: "Patient",
      dataIndex: "patient",
      key: "patient",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            {capitaliseFirstLetter(record.user.first_name)}{" "}
            {capitaliseFirstLetter(record.user.last_name)}
          </p>
        );
      }
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content" }}>
            {record.service_order.service_plan}
          </p>
        );
      }
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_: string, record: any) => {
        return (
          <p style={{ width: "max-content", textTransform: "capitalize" }}>
            {moment(record.date).format("MMM Do, YYYY")}
          </p>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "left" as "left",
      render: (id: string, record: any) => (
        <div style={{ display: "flex", gap: "2px" }}>
          <Tooltip placement='top' title='Edit follow up'>
            <Button
              style={{ background: "none" }}
              onClick={() => {
                setSelectedNote(record);
                setIsEdit(true);
                setIsFollowUpModalVisible(true);
              }}
              disabled={
                record.status === "completed" || record.status === "cancelled"
              }
            >
              <EditOutlined style={{ fontSize: "1rem" }} />
            </Button>
          </Tooltip>

          <Tooltip placement='top' title='View details'>
            <Button
              style={{ background: "none" }}
              onClick={() => {
                setSelectedBooking(record);
                setIsDrawerVisible(true);
              }}
            >
              <ViewMoreIcon style={{ fontSize: "1rem", height: "16" }} />
            </Button>
          </Tooltip>
        </div>
      )
    }
  ];

  useEffect(() => {
    if (follow) {
      setFilteredFollowUps([...follow]);
    }
  }, [follow]);

  const onChange = (date: any, dateString: string) => {
    if (date && dateString) {
      const _followUps = [...follow];
      setSelectedMonth(dateString);

      setFilteredFollowUps(
        _followUps.filter((item) => {
          return moment(item.date).isSame(moment(date), "day");
        })
      );
    }
    if (date === null && dateString === "") {
      setFilteredFollowUps([...follow]);
    }
  };

  const handleSearch = (e: any) => {
    const keyword = e.target.value;

    const _followUps = [...follow];

    setFilteredFollowUps(() =>
      _followUps?.filter(
        (item: any) =>
          item.user.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
          item.user.last_name.toLowerCase().includes(keyword.toLowerCase()) ||
          item.goal.toLowerCase().includes(keyword.toLowerCase()) ||
          item.service_order.service_plan
            .toLowerCase()
            .includes(keyword.toLowerCase())
      )
    );
  };

  return (
    <>
      <Row justify='space-between' align='middle'>
        <Col xs={24} md={12}>
          <h3 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
            {filteredFollowUps?.length} Follow up
            {filteredFollowUps?.length > 1 ? "s" : ""}
          </h3>
        </Col>
        <Col xs={24} md={12}>
          <SearchAndCreateWrapper>
            <Col>
              <Search
                placeholder='Search by patient, goal or service'
                onChange={handleSearch}
              />
            </Col>
          </SearchAndCreateWrapper>
        </Col>

        <Col xs={24} md={4} style={{ margin: "16px 0 10px" }}>
          <DatePicker
            marginBottom={0}
            mode='normal'
            width='auto'
            format='LL'
            onChange={onChange}
            placeholder='Filter by date'
            inputReadOnly={true}
          />
        </Col>
      </Row>

      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <FollowUpsTableWrapper>
          <FollowUpsTableBody>
            <Table
              dataSource={filteredFollowUps}
              columns={columns}
              rowKey={(dataRow) => dataRow.id}
              style={{ pointerEvents: isLoading ? "none" : "all" }}
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              scroll={
                !isLoading && filteredFollowUps?.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 650xp").matches
                  ? { x: 700 }
                  : {}
              }
              loading={isLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true
              }}
            />
          </FollowUpsTableBody>

          <FollowUpsDrawer
            isVisible={isDrawerVisible}
            onClose={() => setIsDrawerVisible(false)}
            selectedFollowUp={selectedBooking}
          />
        </FollowUpsTableWrapper>

        <AddFollowUpModal
          isModalVisible={isFollowUpModalVisible}
          handleCancel={() => {
            setIsFollowUpModalVisible(false);
            setIsEdit(false);
          }}
          followUps={selectedNote}
          isEdit={isEdit}
        />
      </Card>
    </>
  );
};

export default FollowUpsTable;
