import { Link, useLocation } from "react-router-dom";
import { LinkOutlined } from "@ant-design/icons";
import { useContext } from "react";

import { useAppSelector } from "redux/store";
import { UpdateSubContext } from "context/UpdateSubscriptionContext";

import { ReactComponent as PatientsIcon } from "assets/icons/patients.svg";
import { ReactComponent as OverviewIcon } from "assets/icons/dashboard.svg";
import { ReactComponent as FollowUpIcon } from "assets/icons/follow-up-icon2.svg";
import { ReactComponent as PracticeIcon } from "assets/icons/ui_settings.svg";
import { ReactComponent as MessagesIcon } from "assets/icons/messages-icon2.svg";
import { ReactComponent as WalletIcon } from "assets/icons/wallet.svg";
import { ReactComponent as EncounterIcon } from "assets/icons/doctor.svg";
import { ReactComponent as ServiceIcon } from "assets/icons/healthcare.svg";
import { ReactComponent as ExternalLink } from "assets/icons/external-link.svg";
import { ReactComponent as PractitionersIcon } from "assets/icons/practitioners.svg";
import { ReactComponent as LeadsIcon } from "assets/icons/magnet.svg";

import {
  SidebarMenuList,
  SidebarMenuListItem,
  SidebarWrapper,
  ExternalMenuListItem,
  FormIcon,
} from "./styles";

const Sidebar = ({ dashboardMode, setIsDashboardModeModalVisible }: any) => {
  const { plan } = useAppSelector((state) => state.subscription);
  const { handleShowModal } = useContext(UpdateSubContext);
  const location = useLocation();
  const isActivePath = (path: string) => location.pathname.includes(path);

  const sidebarMenu = [
    {
      title: "Overview",
      route: "/overview",
      mode: ["integration", "connect"],
      icon: (
        <OverviewIcon
          fill={isActivePath("/overview") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Service plans",
      route: "/my-services",
      mode: ["integration"],
      icon: (
        <EncounterIcon
          fill={isActivePath("/my-services") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Bookings",
      route: "/service-requests",
      mode: ["integration", "connect"],
      icon: (
        <ServiceIcon
          fill={isActivePath("/service-requests") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Messages",
      route: "/messages",
      mode: ["integration", "connect"],
      icon: (
        <MessagesIcon
          fill={isActivePath("/messages") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Follow ups",
      route: "/follow-ups",
      mode: ["integration", "connect"],
      icon: (
        <FollowUpIcon
          fill={isActivePath("/follow-ups") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Patients",
      route: "/patients",
      mode: ["integration", "connect"],
      icon: (
        <PatientsIcon
          fill={isActivePath("/patients") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Forms",
      route: "/forms",
      mode: ["integration", "connect"],
      icon: (
        <FormIcon stroke={isActivePath("/forms") ? "#0f9af0" : "#002e46"} />
      ),
    },
    {
      title: "Practitioners",
      route: "/practitioners",
      mode: ["integration", "connect"],
      icon: (
        <PractitionersIcon
          stroke={isActivePath("/practitioners") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
    {
      title: "Payments",
      route: "/payments",
      mode: ["integration", "connect"],
      icon: (
        <WalletIcon fill={isActivePath("/claims") ? "#0f9af0" : "#002e46"} />
      ),
    },

    {
      title: "Manage account",
      route: "/manage-account",
      mode: ["integration", "connect"],
      icon: (
        <PracticeIcon
          fill={isActivePath("/manage-account") ? "#0f9af0" : "#002e46"}
        />
      ),
    },
  ];

  const restrictedFreemiumRoutes = [
    "/messages",
    "/follow-ups",
    "/patients",
    "/practitioners",
    "/claims",
  ];

  return (
    <SidebarWrapper>
      <SidebarMenuList>
        {sidebarMenu
          .filter(({ mode }) => mode.includes(dashboardMode))
          .map((item, index) => {
            const { title, route, icon } = item;
            const active = isActivePath(route);

            if (
              plan === "freemium" &&
              restrictedFreemiumRoutes.includes(route)
            ) {
              return (
                <SidebarMenuListItem
                  active={active}
                  key={index}
                  className="upgrade"
                  onClick={() => handleShowModal(true, title)}
                >
                  <span className="blocked">
                    <span className="icon">{icon}</span>
                    {title} <small>Upgrade</small>
                  </span>
                </SidebarMenuListItem>
              );
            }
            return (
              <SidebarMenuListItem active={active} key={index}>
                <Link to={route}>
                  <span className="icon">{icon}</span>
                  {title}
                </Link>
              </SidebarMenuListItem>
            );
          })}
        <ExternalMenuListItem active={false}>
          <a
            href="https://www.youtube.com/channel/UC2rgmm5zrE7QYKFzxylNNiA/videos"
            target="_blank"
            rel="noreferrer"
          >
            <span className="icon">
              <LinkOutlined />
            </span>

            <span className="external-wrapper">
              Learning Resources <ExternalLink className="external-link" />
            </span>
          </a>
        </ExternalMenuListItem>
      </SidebarMenuList>
    </SidebarWrapper>
  );
};

export default Sidebar;

//
// {
//   title: "Leads",
//       route: "/leads",
//     mode: ["integration", "connect"],
//     icon: (
//     <LeadsIcon stroke={isActivePath("/leads") ? "#0f9af0" : "#002e46"} />
// ),
// },
