import styled from "styled-components";

export const FinanceTableBody = styled.div`
  border-radius: 8px;
  border-radius: 8px;

  .ant-card {
    box-shadow: none !important;

    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }

  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }

  .ant-table-wrapper {
    border-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table {
    border-radius: 8px;

    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }

    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }

    tr {
      td:first-child,
      th:first-child {
        padding: 10px 20px;
      }
    }

    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
    }

    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;

      .flex {
        display: flex;

        img {
          margin-right: 10px;
          border: 0.8px solid #0f9af0;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }

      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }

      .action {
        img {
          margin-left: 10px;
        }
      }

      p {
        margin-bottom: 5px;
      }

      span.subtext {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }

  .custom-pagination {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 0 12px 12px;
  }
`;
export const FinanceTableWrapper = styled.div`
  .input-pane {
    padding: 10px 10px;
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    input {
      height: 45px;
      border-radius: 6px;
    }
  }
`;
export const EmptyStateWrapper = styled.div`
  height: calc(100vh - 200px);
  background: transparent;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans";
    align-items: center;

    svg {
      height: 64px;
      width: 64px;
    }

    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      color: #222222;
      margin: 0 0 12px;

      @media (min-width: 768px) {
        font-size: 36px;
      }

      @media (min-width: 768px) {
        font-size: 48px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #667085;
      margin: 0 0 24px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    button {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 14px;
      gap: 8px;
      height: 48px;
      border: 1.5px solid #0faff0;
      border-radius: 4px;
      width: 226px !important;
      background-color: #0faff0;
      span {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
      &:hover,
      &:active{
        background-color: #0faff0 !important;
      }
    }
  }
`;
