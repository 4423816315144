import { useState, useEffect } from "react";
import { Col, Row, Form } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import Input from "../../../component/Input";
import RegisterSvg from "../../../assets/icons/register-icon.svg";
import { InfoCircleOutlined } from "@ant-design/icons";
import ValidCheckSvg from "../../../assets/icons/valid-icon.svg";
import InvalidCheckSvg from "../../../assets/icons/invalid-icon.svg";
import {
  authSelector,
  registerLaboratory,
  verifyProvider,
  setCurrentIndex,
  registerProvider,
  authoriseProvider
} from "../../../redux/reducers/auth";
import { ErrorMsg } from "../../../layouts/OnboardingLayout/styles";
import { profileSelector } from "../../../redux/reducers/profile";
import { MixPanel } from "utils/mixpanel";

const Register = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [payload, setPayload] = useState<any>({});

  const { isLoading, currentIndex, isAuth } = useSelector(authSelector);
  const { profile } = useSelector(profileSelector);

  const steps = [
    [
      <Col span={24}>
        <Input
          formItem={{
            label: "Provider ID",
            name: "providerID",
            rules: [
              { required: true, message: "Please input your provider ID" }
            ],
            tooltip: {
              title: "Check your provider ID in your onboarding email.",
              icon: <QuestionCircleOutlined />,
              trigger: ["click", "hover"],
              placement: ["right"]
            }
          }}
          placeholder='Provider ID'
          type='text'
          marginBottom={16}
        />
      </Col>,

      <Col span={24}>
        <Input
          formItem={{
            label: "Authentication Key",
            name: "authenticationKey",
            rules: [
              {
                required: true,
                message: "Please input your authentication Key"
              }
            ],
            tooltip: {
              title: "Check your authentication key in your onboarding email.",
              icon: <QuestionCircleOutlined />,
              trigger: ["click", "hover"],
              placement: ["right"]
            }
          }}
          placeholder='Authentication Key'
          type='text'
          marginBottom={18}
        />
      </Col>
    ],
    [
      <Col span={24} style={{ marginTop: "20px" }}>
        <Input
          formItem={{
            label: "Email",
            name: "email",
            rules: [{ required: true, message: "Please input email" }]
          }}
          placeholder='email'
          type='email'
          marginBottom={16}
          label='Input email'
          disabled
        />
      </Col>,

      <Col span={24}>
        <Input
          formItem={{
            label: "Input password",
            name: "password",
            rules: [{ required: true, message: "Please input password" }]
          }}
          placeholder='password'
          type='password'
          marginBottom={16}
          label='Input password'
        />
      </Col>,

      <Col span={24}>
        <Input
          formItem={{
            label: "Confirm password",
            name: "confirm_password",
            rules: [
              { required: true, message: "Please confirm password" },
              ({ getFieldValue }: any) => ({
                validator(_: any, value: any) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("Passwords do not match!"));
                }
              })
            ]
          }}
          placeholder='password'
          type='password'
          marginBottom={16}
          label='Confirm your password'
        />
      </Col>
    ]
  ];

  const handleRegister = async (values: any) => {
    if (currentIndex === 0) {
      window.localStorage.setItem("accessKey", values.authenticationKey);
      window.localStorage.setItem("providerID", values.providerID);

      dispatch(verifyProvider());
    } else {
      dispatch(registerProvider(values));
      MixPanel.track("SignUp", { email: values.email });
    }
  };

  useEffect(() => {
    if (currentIndex > 0) {
      form.setFieldsValue({ email: profile?.email });
    }
  }, [currentIndex, form, profile?.email]);

  return (
    <>
      <Col xs={0} md={12}>
        <div className='left-pane'>
          <img className='app-logo' src={AppIcon} alt='logo' />
          <div className='align-center'>
            <img src={RegisterSvg} alt='icon' />
            <h3>Create a profile</h3>
            <p>Enter your details to create a profile</p>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='right-pane'>
          <span className='close-icon' onClick={() => history.goBack()}>
            <i className='lni lni-arrow-left' /> Back
          </span>
          <Form
            name='basic'
            layout='vertical'
            requiredMark={false}
            onFinish={handleRegister}
            form={form}
          >
            <Row gutter={24} align='middle'>
              {steps[currentIndex].map((inputs) => inputs)}
              <Col
                span={24}
                className={`align-center ${
                  currentIndex === 0 ? "justify-end" : "justify-between"
                }`}
                style={{ marginTop: 20 }}
              >
                {currentIndex === steps.length - 1 ? (
                  <Button
                    disabled={isLoading}
                    htmlType='submit'
                    type='primary'
                    className='auth-button'
                  >
                    {isLoading ? "Creating account..." : "Create account"}
                  </Button>
                ) : (
                  <Button
                    disabled={isLoading}
                    htmlType='submit'
                    type='primary'
                    className='auth-button next-button'
                    pMR
                  >
                    {isLoading ? "Verifying profile..." : "Verify profile"}
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default Register;
