import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { uid } from "uid";

import { db as firebaseDb } from "utils/firebase";

export interface PractitionersState {
  practitioners: {
    name: string;
    title: string;
    specialty: string;
    phone: string;
    email: string;
    gender: string;
    id: string;
  }[];
}

const initialState: PractitionersState = { practitioners: [] };
const localStoreProviderID = window.localStorage.getItem("providerID")!;

export const fetchPractitioners = createAsyncThunk<any, void>(
  "practitioners",
  async (req, thunkApi) => {
    try {
      const practitionersRef = await firebaseDb
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("practitioners")
        .get();

      const practitionerDocs: any[] = [];
      practitionersRef.docs.map((item) => practitionerDocs.push(item.data()));

      return practitionerDocs;
    } catch (err: any) {
      console.log(err);

      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

type SinglePractitioner = {
  name: string;
  title: string;
  specialty: string;
  phone: string;
  email: string;
  gender: string;
};
export const createPractitioner = createAsyncThunk<
  any,
  { payload: SinglePractitioner }
>("createPractitioner", async (req, thunkApi) => {
  try {
    const _id = uid();
    await firebaseDb
      .collection("providerInfo")
      .doc(localStoreProviderID)
      .collection("practitioners")
      .doc(_id)
      .set({ ...req.payload, id: _id });
    return req.payload;
  } catch (err: any) {
    console.error(err);

    return thunkApi.rejectWithValue({ error: err.message });
  }
});

export const editPractitioner = createAsyncThunk<any, any>(
  "editPractitioner",
  async (req, thunkApi) => {
    console.log(req);
    try {
      await firebaseDb
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("practitioners")
        .doc(req.id)
        .update({ ...req });

      return { ...req };
    } catch (err: any) {
      console.error(err);

      return thunkApi.rejectWithValue({ error: err.message });
    }
  }
);

export const practitionersSlice = createSlice({
  name: "practitioners",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPractitioners.fulfilled, (state, action) => {
      state.practitioners = action.payload;
    });

    builder.addCase(createPractitioner.fulfilled, (state, action) => {
      state.practitioners.push(action.payload);
    });

    builder.addCase(editPractitioner.fulfilled, (state, action) => {
      state.practitioners = state.practitioners.map((item) => {
        if (item.id === action.payload.id) {
          return { ...item, ...action.payload };
        }

        return item;
      });
    });
  }
});
