import {
  CreateFinancialRequestInput,
  FINANCIALREQUESTRTKQueryTagEnum,
  GetFinancialRequestRes,
} from "constants/types";
import connectApi from "./connect-api";
import { providesList } from "utils/redux-toolkit-query";

const environment =
  process.env.REACT_APP_INSTANCE_ENV === "development" ? "sandbox" : "live";

export const financialApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    createFinancialRequest: builder.mutation<any, CreateFinancialRequestInput>({
      query: (data) => ({
        url: `/finance_requests`,
        data,
        method: "POST",
      }),
      invalidatesTags: [
        FINANCIALREQUESTRTKQueryTagEnum.FINACIAL_REQUEST as any,
      ],
    }),
    getFinancialRequest: builder.query<
      GetFinancialRequestRes,
      { size?: number; next?: string; previous?: string }
    >({
      query: ({ size = 10, next, previous }) => ({
        url: `/finance_requests?mode=${environment}&?size=${size}
          ${previous ? `&prev=${previous}` : ""}
          ${next ? `&next=${next}` : ""}`,
      }),
      providesTags: [
        { type: FINANCIALREQUESTRTKQueryTagEnum.FINACIAL_REQUEST as never },
      ],
    }),
  }),
  overrideExisting: true,
});

financialApi.enhanceEndpoints({
  addTagTypes: [...Object.values(FINANCIALREQUESTRTKQueryTagEnum)],
});

export const {
  useCreateFinancialRequestMutation,
  useGetFinancialRequestQuery,
  useLazyGetFinancialRequestQuery
} = financialApi;
