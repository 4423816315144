import {
  Form,
  Row,
  Col,
  Select as AntdSelect,
  message as AntdMessage
} from "antd";
import { useEffect, useState } from "react";

import { editPractitioner } from "redux/slices/practitioners";
import { ModalWrapper } from "layouts/DashboardLayout/styles";
import { useAppDispatch } from "redux/store";
import { CustomForm } from "./styles";
import Input from "component/Input";
import Button from "component/Button";
import Select from "component/Select";
import { handlePhoneNumberFormat } from "utils/utils";

interface EditPractitionerProps {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedPractitioner: any;
}

function EditPractitionerModal({
  handleCancel,
  isModalVisible,
  selectedPractitioner
}: EditPractitionerProps) {
  const dispatch = useAppDispatch();
  const [editPracForm] = Form.useForm();

  const [formattedPhone, setFormattedPhone] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    editPracForm.setFieldsValue({
      ...selectedPractitioner,
      phone: selectedPractitioner?.phone?.startsWith("+")
        ? selectedPractitioner.phone
        : "+234" + selectedPractitioner?.phone?.slice(1)
    });
  }, [editPracForm, selectedPractitioner]);

  function formatPhoneNumber(
    number: string,
    numberDetails: { dialCode: string }
  ) {
    const { dialCode } = numberDetails;
    const formattedPhone = handlePhoneNumberFormat(number, dialCode);

    setFormattedPhone(formattedPhone);
  }

  async function updatePractitioner(values: any) {
    const _values = values as {
      name: string;
      title: string;
      specialty: string;
      phone: string;
      email: string;
      gender: string;
      id: string;
    };
    _values.phone = formattedPhone
      ? formattedPhone
      : selectedPractitioner.phone;
    _values.id = selectedPractitioner.id;

    setIsLoading(true);

    try {
      await dispatch(editPractitioner(_values));
      AntdMessage.success("Practitioner updated successfully!");
      editPracForm.resetFields();
      handleCancel();
    } catch (err: any) {
      console.log(err);
      AntdMessage.error("Unable to update Practitioner. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <ModalWrapper
      title={
        <div className='header'>
          <h3 style={{ margin: 0 }}>Update practitioner</h3>
        </div>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className='dashboard-mode-modal'
    >
      <CustomForm
        name='addPracForm'
        layout='vertical'
        form={editPracForm}
        onFinish={updatePractitioner}
      >
        <Row>
          <Col xs={24}>
            <Input
              formItem={{
                name: "name",
                label: "Practitioner's name",
                rules: [{ required: true, message: "Please enter name" }]
              }}
              placeholder="Enter practitioner's name"
              mode='normal'
            />
          </Col>

          <Col xs={24} sm={11}>
            <Input
              formItem={{
                name: "title",
                label: "Title",
                rules: [{ required: true, message: "Please enter title" }]
              }}
              placeholder='Dr'
              mode='normal'
            />
          </Col>

          <Col xs={0} sm={1} />

          <Col xs={24} sm={12}>
            <Select
              formItem={{
                name: "specialty",
                label: "Specialty",
                rules: [{ required: true, message: "Please enter specialty" }]
              }}
              placeholder='Select specialty'
              mode='normal'
            >
              <AntdSelect.Option value='audiologist'>
                Audiologist
              </AntdSelect.Option>

              <AntdSelect.Option value='consultants'>
                Consultants
              </AntdSelect.Option>

              <AntdSelect.Option value='dietitians'>
                Dietitians
              </AntdSelect.Option>

              <AntdSelect.Option value='optometrist'>
                Optometrist
              </AntdSelect.Option>

              <AntdSelect.Option value='dentists'>Dentists</AntdSelect.Option>

              <AntdSelect.Option value='psychologist'>
                Psychologist
              </AntdSelect.Option>

              <AntdSelect.Option value='physiotherapists'>
                Physiotherapists
              </AntdSelect.Option>

              <AntdSelect.Option value='general physicians'>
                General physicians
              </AntdSelect.Option>

              <AntdSelect.Option value='speech therapist'>
                Speech therapist
              </AntdSelect.Option>

              <AntdSelect.Option value='orthotist'>Orthotist</AntdSelect.Option>

              <AntdSelect.Option value='occupational therapist'>
                Occupational therapist
              </AntdSelect.Option>

              <AntdSelect.Option value='prosthetist'>
                Prosthetist
              </AntdSelect.Option>
            </Select>
          </Col>

          <Col xs={24} sm={11}>
            <Input
              formItem={{
                name: "email",
                label: "Email",
                rules: [
                  {
                    type: "email",
                    required: true,
                    message: "Please enter a valid email address"
                  }
                ]
              }}
              placeholder='Email address'
              mode='normal'
            />
          </Col>

          <Col xs={0} sm={1}></Col>

          <Col xs={24} sm={12}>
            <Input
              formItem={{
                name: "phone",
                label: "Phone number",
                rules: [
                  {
                    required: true,
                    message: "Please enter patient's phone number"
                  },
                  {
                    required: true,
                    pattern: /[0-9]{11}$/,
                    message: "Please enter a valid phone number"
                  }
                ]
              }}
              placeholder='phone number'
              mode='normal'
              type='phone'
              onChange={formatPhoneNumber}
            />
          </Col>

          <Col xs={24} sm={11}>
            <Select
              formItem={{
                name: "gender",
                label: "Gender",
                rules: [
                  {
                    required: true,
                    message: "Please select gender"
                  }
                ]
              }}
              placeholder='Gender'
              mode='normal'
            >
              <AntdSelect.Option value='male'>Male</AntdSelect.Option>
              <AntdSelect.Option value='female'>Female</AntdSelect.Option>
            </Select>
          </Col>

          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button htmlType='submit' type='primary' disabled={isLoading}>
              {isLoading ? "Updating practitioner" : "Update practitioner"}
            </Button>
          </Col>
        </Row>
      </CustomForm>
    </ModalWrapper>
  );
}

export default EditPractitionerModal;
