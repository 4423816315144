import { useState } from "react";
import { Drawer, message as AntdMessage, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import moment from "moment";

import { capitaliseFirstLetter } from "utils/utils";
import { SingleFollowUpType, UserType } from "constants/types";
import Button from "component/Button";
import SendSMSModal from "component/SendSMSModal";
import CreateAppointmentModal from "component/CreateAppointmentModal";

import { userSelector } from "redux/reducers/users";
import { useAppSelector } from "redux/store";
import { useUpdateFollowUpMutation } from "redux/queries/follow-ups";

import {
  DrawerHeader,
  ActionsWrapper,
  FollowUpInfo,
  LoadingWrapper,
} from "./styles";

// svgs
import { ReactComponent as SendMessageIcon } from "assets/icons/send-message.svg";
import { ReactComponent as AppointmentIcon } from "assets/icons/appointment-new.svg";

type FollowUpsDrawerTypes = {
  onClose: () => void;
  isVisible: boolean;
  selectedFollowUp: SingleFollowUpType;
};

const FollowUpsDrawer = ({
  onClose,
  isVisible,
  selectedFollowUp,
}: FollowUpsDrawerTypes) => {
  const { users } = useAppSelector(userSelector);
  const [isSendMessageVisible, setIsSendMessageVisible] = useState(false);
  const [isAppointmentModalVisible, setIsAppointmentModalVisible] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType>();
  const [isLoading, setIsLoading] = useState(false);
  const [updateFollowUp] = useUpdateFollowUpMutation();
  async function completeFollowUp(id: string) {
    setIsLoading(true);
    try {
      await updateFollowUp({
        data: {
          status: "completed",
        },
        followUpId: id,
      }).unwrap();

      AntdMessage.success("Follow up completed successfully!");
      onClose();
    } catch (err) {
      AntdMessage.error("Unable to complete follow up. Please try again");
    } finally {
      setIsLoading(false);
    }
  }

  const LoadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;
  const userId = selectedFollowUp?.user?.id;
  const user = users.find((user: UserType) => user?.id === userId);

  return (
    <>
      <Drawer
        title={<DrawerHeader>Follow up details</DrawerHeader>}
        width={450}
        className="lab-request-drawer"
        placement="right"
        closable={true}
        onClose={onClose}
        visible={isVisible}
      >
        <ActionsWrapper>
          <div
            onClick={() => {
              setSelectedUser(
                users.find((user: UserType) => user?.id === userId),
              );
              setIsSendMessageVisible(true);
            }}
          >
            <button>
              <SendMessageIcon />
            </button>
            <span>Send SMS Message</span>
          </div>

          <div onClick={() => setIsAppointmentModalVisible(true)}>
            <button>
              <AppointmentIcon />
            </button>
            <span>Create appointment</span>
          </div>
        </ActionsWrapper>

        {selectedFollowUp ? (
          <FollowUpInfo>
            <h4>
              {capitaliseFirstLetter(selectedFollowUp.goal)}{" "}
              {selectedFollowUp.status === "completed" && (
                <span>Completed</span>
              )}
              {selectedFollowUp.status === "cancelled" && (
                <span className="cancelled">Cancelled</span>
              )}
            </h4>

            <p>{moment(selectedFollowUp.date).format("LL")}</p>

            {selectedFollowUp.note ? (
              <p>{capitaliseFirstLetter(selectedFollowUp.note)}</p>
            ) : null}

            {selectedFollowUp.status !== "cancelled" &&
              selectedFollowUp.status !== "completed" && (
                <div className="btn-wrapper">
                  <Button
                    type="primary"
                    onClick={() => completeFollowUp(selectedFollowUp.id)}
                  >
                    Complete follow up
                  </Button>
                </div>
              )}
          </FollowUpInfo>
        ) : null}

        {isLoading && (
          <LoadingWrapper>
            <Spin indicator={LoadingIcon} />
          </LoadingWrapper>
        )}
      </Drawer>

      <SendSMSModal
        isModalVisible={isSendMessageVisible}
        handleCancel={() => setIsSendMessageVisible(false)}
        selectedUserID={selectedUser?.id!}
        messageType="follow-up"
      />

      <CreateAppointmentModal
        isModalVisible={isAppointmentModalVisible}
        handleCancel={() => setIsAppointmentModalVisible(false)}
        selectedUser={user!}
      />
    </>
  );
};

export default FollowUpsDrawer;
