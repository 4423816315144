import styled from "styled-components";

export const FormsWrapper = styled.div`
  width: 100%;
  min-height: calc(100vh - 57px);

  h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 0px;
    text-align: left;
    color: #4e667e;
  }
`;

export const ContentWrapper = styled.div`
  padding: 25px;
  background: #f3f8fb;
  min-height: calc(100vh - 60px);
  padding-bottom: 100px;
`;
