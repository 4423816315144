import React, { useState } from "react";
import { SingleFormType } from "../../../../constants/types";
import { capitaliseFirstLetter } from "../../../../utils/utils";
import moment from "moment/moment";
import { Table, Tooltip } from "antd";
import { FormsTableBody } from "./styles";
import { generatePath, NavLink } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import FormPreviewModal from "../../../../component/FormPreviewModal";
import { ReactComponent as EyeIcon } from "assets/icons/eye.svg";
import Button from "../../../../component/Button";

type Props = {
  filteredForms: any;
  isLoading: boolean;
};
const Forms = ({ filteredForms, isLoading }: Props) => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isFormSelected, setIsFormSelected] = useState<any | null>(null);

  const onModalOpen = (record: SingleFormType) => {
    setIsFormSelected(record);
    setIsModalVisible(true);
  };

  const onModalClose = () => {
    setIsFormSelected(null);
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "Name",
      render: (_: string, record: any) => (
        <p>{capitaliseFirstLetter(record.name)}</p>
      ),
      width: "330px",
    },
    {
      title: "Category",
      render: (_: string, record: any) => (
        <p style={{ textTransform: "capitalize" }}>
          {" "}
          {record.category.replace(/_/g, " ")}
        </p>
      ),
    },
    {
      title: "Specialty",
      render: (_: string, record: any) => (
        <p style={{ textTransform: "capitalize" }}>
          {record.specialty.replace(/_/g, " ")}
        </p>
      ),
    },
    {
      title: "Date Created",
      render: (_: string, record: SingleFormType | any) => (
        <p className="preferred-date">
          <span>{moment(record.created_at).format("LL")}</span>
          <span>{moment(record.created_at).format("LT")}</span>
        </p>
      ),
      width: 200,
    },
    {
      title: "",
      width: "200px",
      render: (_: string, record: SingleFormType | any) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 10,
          }}
        >
          <Tooltip placement="top" title="Preview form">
            <Button
              style={{
                display: "flex",
                padding: 10,
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid #0FAFF0",
                background: "transparent",
              }}
              onClick={() => onModalOpen(record)}
            >
              <EyeIcon
                style={{
                  width: 16,
                }}
              />
            </Button>
          </Tooltip>
          <NavLink
            style={{
              display: "flex",
              padding: 10,
              alignItems: "center",
              justifyContent: "center",
              gap: 4,
              borderRadius: 4,
              border: "1px solid #0FAFF0",
              background: "transparent",
              width: 200,
              height: 39,
            }}
            to={generatePath(ROUTES.RESPONSES, {
              questionId: record.id,
            })}
          >
            <span
              style={{
                color: "#0FAFF0",
                fontFamily: " DM Sans",
                fontSize: 16,
                fontWeight: 500,
                lineHeight: "120%",
              }}
            >
              {" "}
              View responses
            </span>
          </NavLink>
        </div>
      ),
    },
  ];
  //-------------------------------------------------------//

  return (
    <FormsTableBody>
      <Table
        dataSource={filteredForms}
        columns={columns}
        style={{ pointerEvents: isLoading ? "none" : "all" }}
        rowClassName={(_, index) => (index % 2 === 0 ? "even-row" : "odd-row")}
        scroll={
          !isLoading && filteredForms?.length > 0
            ? { x: 700 }
            : window.matchMedia("(max-width: 650xp").matches
            ? { x: 700 }
            : {}
        }
        rowKey={(dataRow) => dataRow.id}
        loading={isLoading}
        pagination={{
          defaultCurrent: 1,
          position: ["bottomRight"],
          showLessItems: true,
        }}
      />

      <FormPreviewModal
        isModalVisible={isModalVisible}
        handleCancel={onModalClose}
        data={isFormSelected}
      />
    </FormsTableBody>
  );
};

export default Forms;
