import styled from "styled-components";

const NavbarWrapper = styled.div`
  width: 100%;
  height: 57px;
  position: fixed;
  padding: 0px 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 100;
  box-shadow: 0px 1px 5px rgba(0, 120, 226, 0.25);
  .menu-icon {
    margin-right: 20px;
    font-size: 19px;
    @media (min-width: 1025px) {
      display: none;
    }
  }
  .menu {
    display: flex;
    gap: 8px;
    align-items: center;

    @media (min-width: 1024px) {
      gap: 24px;
    }

    .mode {
      margin-right: 10px;
    }

    .ant-switch {
      margin-right: 10px;
    }

    .upgrade-plan {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 12px;
      padding: 6px 12px;
      background: #d9efff;
      border-radius: 4px;
      color: #0faff0;
      cursor: pointer;

      &:hover {
        background: #d9efff81;
      }
    }
  }
  .username {
    @media (max-width: 500px) {
      display: none;
    }
  }
`;

const MenuLink = styled.div`
  display: flex;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.02em;
  text-align: right;
  cursor: pointer;
  color: #002e46;

  img {
    margin-left: 15px;
  }
`;

export { NavbarWrapper, MenuLink };
