import React, { useEffect, useState } from "react";
import { QuestionFieldsWrapper, QuestionsWrapper } from "./styles";
import Card from "component/Card";
import {
  Col,
  Form,
  Row,
  Switch,
  Select as AntdSelect,
  FormInstance,
  message,
} from "antd";
import Input from "component/Input";
import Select from "component/Select";
import TextArea from "component/TextArea";
import Button from "component/Button";
import BranchModal from "component/BranchModal";
import FormDetails from "./FormDetails";
import { useCreateServiceFormsMutation } from "redux/queries/service-forms";
import { CreateFormInput } from "constants/types";
import { SelectMultiple, SelectOne } from "./OptionTypes";
import { useHistory } from "react-router";
//svg
import { ReactComponent as PlusCircleIcon } from "assets/icons/plus-circle.svg";
import { ReactComponent as BranchIcon } from "assets/icons/GitBranch.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus-lg.svg";

type Props = {
  setIsPreviewQuestion: any;
};

const Questions = ({ setIsPreviewQuestion }: Props) => {
  const history = useHistory();
  //form instance
  const [form] = Form.useForm();
  const watchQuestions = Form.useWatch("questions", form);
  const watchName = Form.useWatch("name", form);
  const watchDesc = Form.useWatch("description", form);
  //states
  const [iconUrl, setIconUrl] = useState<string | null>(null);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [options, setOptions] = useState<Record<string, any>[]>([]);
  const [currentQuestionId, setCurrentQuestionId] = useState<number>(0);
  //create forms redux
  const [createServiceFrom] = useCreateServiceFormsMutation();

  useEffect(() => {
    setIsPreviewQuestion({
      name: watchName,
      questions: watchQuestions,
      description: watchDesc,
      icon: iconUrl,
    });
  }, [watchQuestions, watchName, watchDesc, iconUrl, setIsPreviewQuestion]);

  console.log(watchQuestions, "questions");

  const onOpenModal = (id: number) => {
    const fieldValue: Record<string, any>[] =
      form.getFieldValue(["questions", id.toString(), "response"]) || [];
    setOptions(fieldValue);
    setCurrentQuestionId(id);
    setIsModalVisible(true);
  };

  const onCloseModal = () => {
    setIsModalVisible(false);
    setOptions([]);
  };

  const onRemoveNextQuestion = (questionId: number, optionId: number) => {
    const fieldValue: Record<string, any>[] = form.getFieldValue([
      "questions",
      questionId.toString(),
      "response",
      optionId.toString(),
    ]);
    if (!fieldValue) return;

    Object.assign(fieldValue, { ...fieldValue, next_question: undefined });

    form.setFieldsValue({ fieldValue });
  };

  const onFinish = async (value: any) => {
    setIsLoading(true);

    const _values = value?.questions?.map(
      (item: any, index: number, array: any[]) => ({
        id: item.id,
        hint: item.hint,
        text: item.text,
        type: item.type,
        response: Array.isArray(item.response)
          ? item.response.map((item: any, index: number) => ({
              id: item.id,
              value: item.value,
              next_question: item.next_question,
            }))
          : {
              id: 0,
              value: item.value,
            },

        required: false,
        next_question: index === array.length - 1 ? null : index + 2,
      })
    );
    const _value: CreateFormInput = {
      category: value?.category,
      questions: _values,
      name: value?.name,
      specialty: value?.specialty,
      description: value?.description,
      icon: iconUrl,
    };

    const filteredValue: CreateFormInput | any = Object.fromEntries(
      Object.entries(_value).filter(([_, val]) => val !== null)
    );

    try {
      const res = await createServiceFrom(filteredValue).unwrap();
      if (res.status === 200 || res.message === "Service form created") {
        history.push("/forms");
        message.success("Form created successfully");
      }

      setIsLoading(false);
    } catch (err) {
      message.error('"An error occurred. Please try again..."');
      setIsLoading(false);
    }
  };
  return (
    <>
      <QuestionsWrapper>
        <h1>Form Details</h1>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          initialValues={{
            questions: [{}],
          }}
        >
          <FormDetails setIconUrl={setIconUrl} iconUrl={iconUrl} />
          <QuestionFieldsWrapper>
            <Form.List
              name="questions"
              rules={[
                {
                  validator: async (_, names) => {
                    if (!names || names.length < 1) {
                      return Promise.reject(
                        new Error("Add at least 1 question")
                      );
                    }
                  },
                },
              ]}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => (
                    <FormCard
                      field={field}
                      remove={remove}
                      onOpen={() => onOpenModal(field.name)}
                      key={field.name}
                      isBranchDisabled={watchQuestions?.length < 2}
                      onRemoveNextQuestion={onRemoveNextQuestion}
                      form={form}
                    />
                  ))}
                  <Button onClick={() => add()} className={"add-question"}>
                    <PlusCircleIcon />
                    <span>Add question</span>
                  </Button>
                  <Form.ErrorList errors={errors} />
                </>
              )}
            </Form.List>
            <Button
              htmlType={"submit"}
              className={"save-btn"}
              type="primary"
              disabled={isLoading}
            >
              {isLoading ? "Saving..." : "Save Form"}
            </Button>
          </QuestionFieldsWrapper>
        </Form>
      </QuestionsWrapper>
      <BranchModal
        isModalVisible={isModalVisible}
        handleCancel={onCloseModal}
        options={options}
        questions={watchQuestions}
        questionId={currentQuestionId}
        form={form}
      />
    </>
  );
};

export default Questions;

const FormCard: React.FC<{
  field: any;
  onOpen: () => void;
  remove: (id: number) => void;
  onRemoveNextQuestion: (questionId: number, optionId: number) => void;
  isBranchDisabled: boolean;
  form: FormInstance;
}> = ({
  field,
  onOpen,
  remove,
  isBranchDisabled,
  onRemoveNextQuestion,
  form,
}) => {
  const [selectedType, setSelectedType] = useState("text");

  return (
    <>
      <Card style={{ marginBottom: 32 }} padding={30}>
        <Form.Item
          name={[field.name, "id"]}
          style={{ margin: 0, display: "none" }}
          initialValue={field.name + 1}
        >
          <Input />
        </Form.Item>

        <Row gutter={12}>
          <Col xs={24} md={18}>
            <Form.Item
              name={[field.name, "text"]}
              label={`${field.name + 1}. Question`}
              rules={[
                { required: true, message: `Please enter your question` },
              ]}
            >
              <Input mode="normal" />
            </Form.Item>
          </Col>
          <Col xs={24} md={6}>
            <Form.Item
              name={[field.name, "type"]}
              label={"Question Type"}
              rules={[
                { required: true, message: `Please select a question type` },
              ]}
            >
              <Select
                mode="normal"
                placeholder="Select category"
                onChange={(value: any) => {
                  setSelectedType(value);
                }}
                value={selectedType}
              >
                <AntdSelect.Option value={"text"}>Text</AntdSelect.Option>
                <AntdSelect.Option value={"select_one"}>
                  Select one
                </AntdSelect.Option>
                <AntdSelect.Option value={"select_multiple"}>
                  Select Multiple
                </AntdSelect.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item
              name={[field.name, "hint"]}
              label={"Hint/Description (Optional)"}
            >
              <Input mode="normal" />
            </Form.Item>
          </Col>

          {selectedType === "text" && (
            <Col xs={24}>
              <Form.Item
                name={[field.name, "value"]}
                style={{ display: "none" }}
                initialValue={null}
              >
                <TextArea placeholder="Enter your answer" initalValue={null} />
              </Form.Item>
            </Col>
          )}

          {selectedType === "select_one" && (
            <Col xs={24}>
              <Form.Item label="Option">
                <Form.List
                  name={[field.name, "response"]}
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 2) {
                          return Promise.reject(
                            new Error("Add at least 2 options")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(subFields, subOpt, { errors }) => (
                    <>
                      {subFields.map((subField) => (
                        <SelectOne
                          subField={subField}
                          onRemoveNextQuestion={onRemoveNextQuestion}
                          form={form}
                          field={field}
                          subOpt={subOpt}
                          key={subField.name}
                        />
                      ))}
                      <Button
                        onClick={() => subOpt.add()}
                        className="add-options"
                      >
                        <PlusIcon />
                        Add option
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          )}
          {selectedType === "select_multiple" && (
            <Col xs={24}>
              <Form.Item label="Option">
                <Form.List
                  name={[field.name, "response"]}
                  rules={[
                    {
                      validator: async (_, names) => {
                        if (!names || names.length < 3) {
                          return Promise.reject(
                            new Error("Add at least 3 options")
                          );
                        }
                      },
                    },
                  ]}
                >
                  {(subFields, subOpt, { errors }) => (
                    <>
                      {subFields.map((subField) => (
                        <SelectMultiple
                          subField={subField}
                          form={form}
                          onRemoveNextQuestion={onRemoveNextQuestion}
                          field={field}
                          subOpt={subOpt}
                          key={subField.name}
                        />
                      ))}
                      <Button
                        onClick={() => subOpt.add()}
                        className="add-options"
                      >
                        <PlusIcon />
                        Add option
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          )}
        </Row>

        <div className="btn-wrapper">
          {selectedType === "text" ||
          selectedType === "select_multiple" ? null : (
            <Button
              className="branch"
              onClick={onOpen}
              disabled={isBranchDisabled}
            >
              <BranchIcon />
              <span>Add Branch</span>
            </Button>
          )}
          <div className="toggle">
            <Form.Item
              name={[field.name, "required"]}
              valuePropName="checked"
              style={{ margin: 0 }}
            >
              <Switch />
            </Form.Item>
            <span>Compulsory</span>
          </div>
          <Button
            className="delete"
            onClick={() => {
              remove(field.name);
            }}
          >
            <DeleteIcon />
            <span>Delete question</span>
          </Button>
        </div>
      </Card>
    </>
  );
};
