import React, { Fragment } from "react";
import { ModalWrapper } from "./styles";
import {
  Button,
  Col,
  Form,
  Row,
  Select as AntdSelect,
  FormInstance,
} from "antd";
import Input from "../Input";
import Select from "../Select";

type Props = {
  isModalVisible: boolean;
  handleCancel: () => void;
  options: Record<string, any>[];
  questions: any[];
  questionId: number;
  form: FormInstance;
};
const BranchModal = ({
  isModalVisible,
  handleCancel,
  options,
  questions,
  questionId,
  form,
}: Props) => {
  const newArray = questions?.filter(
    (question) => question?.id !== questionId + 1
  );
  const [branchForm] = Form.useForm();
  // const onChange = (val: any, optionId: number) => {
  //   const fieldValue: Record<string, any>[] = form.getFieldValue([
  //     "questions",
  //     questionId.toString(),
  //     "response",
  //     optionId.toString(),
  //   ]);
  //   if (!fieldValue) return;

  //   form.setFieldValue(
  //     ["questions", questionId.toString(), "response", optionId.toString()],
  //     { ...fieldValue, next_question: val }
  //   );
  // };
  const onChange = (val: any, optionId: number) => {
    const fieldValue: Record<string, any>[] | any = form.getFieldValue([
      "questions",
      questionId.toString(),
      "response",
      optionId.toString(),
    ]);
    if (!fieldValue) return;

    Object.assign(fieldValue, { ...fieldValue, next_question: val });

    form.setFieldsValue({ fieldValue });
  };
  const onClose = () => {
    branchForm.resetFields();
    handleCancel();
  };

  return (
    <ModalWrapper
      title={
        <>
          <h1>Branch Question</h1>
          <span>Add branch to question</span>
        </>
      }
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className="dashboard-mode-modal"
    >
      <Form layout="vertical" form={branchForm}>
        <Row className="forms" gutter={18}>
          {options.map((option) => (
            <BranchInputs
              option={option}
              newArray={newArray}
              key={option?.id}
              onChange={onChange}
            />
          ))}
        </Row>
        <div className="btn-wrapper">
          <Button type="link" onClick={() => branchForm.resetFields()}>
            Reset all
          </Button>
          <Button onClick={onClose}>Save</Button>
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default BranchModal;

export const BranchInputs: React.FC<{
  option: any;
  newArray: any[];
  onChange: (val: any, optionId: number) => void;
}> = ({ option, newArray, onChange }) => {
  return (
    <Fragment key={option.value}>
      <Col xs={24} md={10}>
        <Form.Item label="Options">
          <Input disabled defaultValue={option.value} mode="normal" />
        </Form.Item>
      </Col>
      <Col xs={24} md={14}>
        <Form.Item name={[option?.id, "next_question"]} label="Go to">
          <Select
            mode="normal"
            placeholder={"Select question"}
            onChange={(val: any) => onChange(val, option?.id)}
          >
            {newArray.map((question) => (
              <AntdSelect.Option key={question?.text} value={question?.id}>
                {question?.text}
              </AntdSelect.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Fragment>
  );
};
