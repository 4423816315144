import React, { useMemo, useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Card,
  Select as AntdSelect,
  Form,
  message as AntdMessage,
  Steps,
  Button,
  message,
} from "antd";
import { send } from "emailjs-com";

import {
  getDisabledHours,
  getDisabledMinutes,
  capitaliseFirstLetter,
} from "utils/utils";
import Select from "component/Select";
import Input from "component/Input";
import TextArea from "component/TextArea";
import TimePicker from "component/TimePicker";
import DatePicker from "component/DatePicker";
import CallInIcon from "assets/icons/call-in-icon.png";
import WalkInIcon from "assets/icons/walk-in-icon.png";
import { servicePlansSelector, bookService } from "redux/reducers/servicePlans";
import { fetchAllServiceOrders } from "redux/reducers/requests";
import { profileSelector } from "redux/reducers/profile";
import { useAppSelector, useAppDispatch } from "redux/store";

import { BookValuesType, UserType } from "constants/types";

import { MixPanel } from "utils/mixpanel";
import { userSelector } from "redux/reducers/users";
import { ModalContent, ModalWrapper } from "./styles";
import { CloseOutlined } from "@ant-design/icons";
import { ReactComponent as CaretLeft } from "assets/icons/caret-left.svg";
import { ReactComponent as CaretRight } from "assets/icons/caret-right.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import {
  useGetSingleServiceFormQuery,
  useHandleSubmitFormMutation,
} from "../../redux/queries/service-forms";
import Questions from "../Questions";
import { QuestionResponse } from "../Questions/QuestionsForm";

type CreateAppointmentModalTypes = {
  isModalVisible: boolean;
  handleCancel: () => void;
  selectedUser: UserType;
};

const { Step } = Steps;
const CreateAppointmentModal = ({
  handleCancel,
  isModalVisible,
  selectedUser,
}: CreateAppointmentModalTypes) => {
  const dispatch = useAppDispatch();
  const { servicePlans } = useAppSelector(servicePlansSelector);
  const { profile: ProviderProfile } = useAppSelector(profileSelector);
  const { user } = useAppSelector(userSelector);
  const [createAppointmentForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isTodaySelected, setIsTodaySelected] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [appointmentType, setAppointmentType] = useState<"walkIn" | "callIn">(
    "walkIn",
  );
  const [selectedServiceId, setSelectedServiceId] = useState<string>();
  const [pageIndex, setPageIndex] = useState<number>(1);
  const [currentFormStep, setCurrentFormStep] = useState<number>(0);
  const [questionIds, setQuestionIds] = useState([1]);
  const currentQuestionId = questionIds[questionIds.length - 1];
  const [responses, setResponses] = useState(
    {} as { [key: string]: QuestionResponse },
  );
  const [getAppointmentInfo, setGetAppointmentInfo] = useState<
    BookValuesType | any
  >(null);

  const [isSubmittingResponse, setIsSubmittingResponse] =
    useState<boolean>(false);
  const [isCreatingAppointment, setIsCreatingAppointment] =
    useState<boolean>(false);

  const [submitFormMutation] = useHandleSubmitFormMutation();

  const handleFormStepChange = (step: "start" | "question") => {
    switch (step) {
      case "start":
        setCurrentFormStep(0);
        break;
      case "question":
        setCurrentFormStep(1);
        break;
    }
  };

  const selectedService = servicePlans?.find(
    (plan) => plan?.id === selectedServiceId,
  );

  const formId = selectedService?.metadata?.service_form;

  const {
    data,
    isLoading: isQuestionLoading,
    error,
  } = useGetSingleServiceFormQuery({ questionId: formId }) as any;

  const forms = data?.data[0] || {};

  const normalizedQuestions = useMemo(
    () =>
      forms?.questions?.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [forms.questions],
  );

  function handleQuestionFormChange(response: QuestionResponse | any) {
    setResponses((p) => ({ ...p, [currentQuestionId]: response }));
  }

  const onClose = () => {
    setResponses({});
    handleFormStepChange("start");
    setQuestionIds([1]);
    setPageIndex(1);
    setSelectedServiceId("");
    setGetAppointmentInfo(null);
    createAppointmentForm.resetFields();
    handleCancel();
  };

  function handleNextQuestion() {
    const currentQuestion = normalizedQuestions[currentQuestionId];

    if (
      responses &&
      !responses[currentQuestionId]?.value &&
      currentQuestion?.required === false
    ) {
      handleQuestionFormChange({
        question: currentQuestion?.id,
        question_text: currentQuestion?.text,
        response: currentQuestion?.response?.id,
        value: "null",
        next_question: currentQuestion?.next_question,
      });

      setQuestionIds((p: any) => {
        return [...p, currentQuestion?.next_question];
      });
    } else {
      setQuestionIds((p: any) => {
        return [...p, responses[currentQuestionId]?.next_question];
      });
    }
  }
  const makeAppointment = async (values: any) => {
    setIsLoading(true);

    try {
      const _values = values as BookValuesType;
      if (!appointmentType) {
        throw new Error("Please choose an appointment type!");
      } else {
        if (formId) {
          setPageIndex(2);
          setGetAppointmentInfo({ ..._values });
        } else {
          await dispatch(
            bookService({
              ..._values,
              source: appointmentType,
              user: selectedUser?.id,
              patientId: user.patientId,
            }),
          );
          MixPanel.track("CreateAppointment", {
            service: ProviderProfile.service_plans.find(
              (plan: any) => plan.id === _values?.plan,
            )?.name,
            preferred_date: moment(_values.preferred_date).format("LL"),
            preferred_time: _values.preferred_time
              ? moment(_values.preferred_time).format("hh:mm a")
              : "Unavailable",
          });

          if (appointmentType === "callIn") {
            // accepted emailjs template
            const acceptedTemplateParams = {
              slug: ProviderProfile.slug || ProviderProfile.id,
              business_name: ProviderProfile.business_name,
              to_name: `${capitaliseFirstLetter(selectedUser?.first_name)}`,
              to_email: selectedUser.email,
              business_phone: ProviderProfile.phone,
              business_email: ProviderProfile.email,
              business_address: `${ProviderProfile.address?.street_line_one}, ${ProviderProfile.address?.street_line_two}, ${ProviderProfile.address?.city}, ${ProviderProfile.address?.state}.`,
              service: ProviderProfile.service_plans.find(
                (plan: any) => plan.id === values?.plan,
              )?.name,
              preferred_date: moment(values?.preferred_date).format("LL"),
              preferred_time: moment(values?.preferred_time).format("hh:mm a"),
              logo:
                ProviderProfile.logo_path ||
                "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
            };

            // send appointment acceptance / confirmed email
            await send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
              "temp_accepted_booking",
              acceptedTemplateParams,
            );
          }

          await dispatch(fetchAllServiceOrders());
          AntdMessage.success("Appointment booked successfully!");
          onClose();
        }
      }
    } catch (err) {
      const _err = err as Error;
      AntdMessage.error(_err.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  // handling the consultation after submitting form response

  async function handleConsult(answers: any) {
    try {
      dispatch(
        bookService({
          ...getAppointmentInfo,
          source: appointmentType!,
          patientId: selectedUser?.id,
          form_response: answers,
          user: user?.id,
        }),
      );
      message.success("Appointment booked successfully!");
      setIsCreatingAppointment(false);
      onClose();
    } catch (e) {
      console.log(error);
      message.error(error?.data?.error);
      setIsCreatingAppointment(false);
    }
  }

  async function handleResponses() {
    setIsSubmittingResponse(true);
    try {
      const answerResponseData = await submitFormMutation({
        service_order: getAppointmentInfo?.plan,
        user: selectedUser?.id,
        form: formId,
        responses: Object.values(responses).map((response: any) => {
          const newResponse = { ...response };
          delete newResponse.next_question;
          delete newResponse.question_text;
          return newResponse;
        }),
      }).unwrap();
      message.success(answerResponseData.message);
      if (answerResponseData.message === "Service form answered") {
        await handleConsult(answerResponseData?.data?.id);
      }
      setIsSubmittingResponse(false);
    } catch (err: any) {
      message.error(err.message || "Something went wrong. Please try again.");
      console.log(err.message);
      setIsSubmittingResponse(false);
    } finally {
      setIsSubmittingResponse(false);
    }
  }

  const ButtonTexts = () => {
    if (!pageIndex) {
      return isLoading ? "processing...." : "Set date and payment options";
    } else if (formId) {
      return "Proceed to questionnaire";
    } else {
      return isLoading ? "Processing..." : "Book Appointment";
    }
  };

  return (
    <ModalWrapper
      visible={isModalVisible}
      closable={false}
      footer={false}
      onCancel={handleCancel}
      okText="Create"
      className="dashboard-mode-modal"
    >
      <header>
        <h1>Create a new appointment</h1>
        <button onClick={onClose}>
          <CloseOutlined style={{ fontSize: "1.2rem" }} />
        </button>
      </header>

      <Form
        name="createAppointmentForm"
        layout="vertical"
        form={createAppointmentForm}
        onFinish={makeAppointment}
      >
        <ModalContent>
          <div className="step">
            <Steps size="default" current={pageIndex} direction={"vertical"}>
              <Step title="Add new or select existing patient" />
              <Step title="Add appointment details" />
              {formId && <Step title="Fill intake form" />}
            </Steps>
          </div>
          <div className="content">
            {pageIndex === 1 ? (
              <>
                <Row gutter={18} className="row-icons">
                  <h3>
                    <span>*</span> Choose appointment method
                  </h3>
                  <Col span={12}>
                    <Card
                      className={
                        appointmentType === "walkIn" ? "isCardActive" : ""
                      }
                      onClick={() => setAppointmentType("walkIn")}
                    >
                      <img
                        src={WalkInIcon}
                        alt="walk in"
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />{" "}
                      Walk in
                    </Card>
                  </Col>

                  <Col span={12}>
                    <Card
                      className={
                        appointmentType === "callIn" ? "isCardActive" : ""
                      }
                      onClick={() => setAppointmentType("callIn")}
                    >
                      <img
                        src={CallInIcon}
                        alt="call in"
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />{" "}
                      Call in
                    </Card>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col xs={24}>
                    <Select
                      formItem={{
                        name: "plan",
                        label: "Select service",
                        rules: [
                          {
                            required: true,
                            message: "Please select a service",
                          },
                        ],
                      }}
                      mode="normal"
                      showSearch
                      optionFilterProp="children"
                      filterOption={(_input: string, option: any) => {
                        const input = _input.toLowerCase();
                        const singleService = servicePlans.find(
                          (plan: any) => plan.id === option.value,
                        )!;
                        const booleanValue = singleService.name
                          .toLowerCase()
                          .includes(input);
                        return booleanValue;
                      }}
                      filterSort={(optionA: any, optionB: any) =>
                        optionA.value
                          .toLowerCase()
                          .localeCompare(optionB.value.toLowerCase())
                      }
                      onChange={(val: string) => setSelectedServiceId(val)}
                    >
                      {servicePlans?.map((option, index) => {
                        return (
                          <AntdSelect.Option key={index} value={option.id}>
                            <span style={{ textTransform: "capitalize" }}>
                              {option.name}
                            </span>
                          </AntdSelect.Option>
                        );
                      })}
                    </Select>
                  </Col>

                  <Col xs={24} sm={12}>
                    <DatePicker
                      formItem={{
                        name: "preferred_date",
                        label: "Preferred appointment date",
                        rules: [
                          {
                            required: true,
                            message: "Please select preferred appointment date",
                          },
                        ],
                      }}
                      disabledDate={(d: any) => {
                        return (
                          !d ||
                          d.isBefore(new Date().getTime() - 24 * 60 * 60 * 1000)
                        );
                      }}
                      placeholder="Preferred appointment date"
                      inputReadOnly={true}
                      mode="normal"
                      format="DD/MM/YYYY"
                      popupStyle={{
                        maxHeight: "400px !important",
                      }}
                      onChange={(e: any) => {
                        setIsDateSelected(true);
                        if (moment(e) > moment()) {
                          setIsTodaySelected(false);
                        } else {
                          setIsTodaySelected(true);
                        }
                      }}
                    />
                  </Col>

                  <Col span={12}>
                    <TimePicker
                      formItem={{
                        label: "Preferred Time",
                        name: "preferred_time",
                        rules: [
                          {
                            required: true,
                            message: "Please input preferred time",
                          },
                        ],
                      }}
                      format="hh:mm"
                      mode="normal"
                      inputReadOnly={true}
                      minuteStep={30}
                      disabled={!isDateSelected}
                      disabledHours={() => getDisabledHours(isTodaySelected)}
                      disabledMinutes={(selectedHour: number) =>
                        getDisabledMinutes(selectedHour, isTodaySelected)
                      }
                    />
                  </Col>

                  <Col xs={24} sm={12}>
                    <Select
                      formItem={{
                        name: "payment_method",
                        label: "Payment method",
                        rules: [
                          {
                            required: true,
                            message: "Please select payment method",
                          },
                        ],
                      }}
                      onChange={(value: string) =>
                        setSelectedPaymentMethod(value)
                      }
                      mode="normal"
                    >
                      <AntdSelect.Option value="cash">Cash</AntdSelect.Option>

                      {ProviderProfile?.metadata?.payment_options
                        ?.insurance && (
                        <AntdSelect.Option value="insurance">
                          Insurance
                        </AntdSelect.Option>
                      )}
                    </Select>
                  </Col>
                  {selectedPaymentMethod === "insurance" && (
                    <>
                      <Col span={16} style={{ marginBottom: "1em" }}>
                        <Select
                          formItem={{
                            name: "insurance_provider",
                            label: "Insurance provider",
                            rules: [
                              {
                                required: true,
                                message: "Please select an insurance provider",
                              },
                            ],
                          }}
                          showSearch={true}
                          mode="normal"
                        >
                          {ProviderProfile?.metadata?.payment_options?.selectedProviders?.map(
                            (item: any) => (
                              <AntdSelect.Option value={item} key={item}>
                                {item}
                              </AntdSelect.Option>
                            ),
                          )}
                        </Select>
                      </Col>

                      <Col span={8}>
                        <Input
                          formItem={{
                            name: "insurance_id",
                            label: "Insurance ID",
                            rules: [
                              {
                                required: true,
                                message: "Please input your insurance id",
                              },
                            ],
                          }}
                          mode="normal"
                          style={{ textTransform: "uppercase" }}
                        />
                      </Col>
                    </>
                  )}

                  <Col span={24}>
                    <TextArea
                      formItem={{
                        name: "note",
                        label: "Additional notes/comments",
                      }}
                      label=""
                      mode="normal"
                      maxLength={300}
                      autoSize={{ minRows: 4, maxRows: 4 }}
                    />
                  </Col>

                  {formId && (
                    <div className="disclaimer">
                      <InfoIcon />
                      <span>
                        The service selected has an intake form, a patient is
                        required to fill in a questionnaire with the help of a
                        healthcare provider before appointment will be booked.
                      </span>
                    </div>
                  )}
                </Row>
              </>
            ) : (
              pageIndex === 2 && (
                <Questions
                  form={data?.data[0]}
                  currentFormStep={currentFormStep}
                  isLoading={isQuestionLoading}
                  handleQuestionFormChange={handleQuestionFormChange}
                  currentQuestionId={currentQuestionId}
                  normalizedQuestions={normalizedQuestions}
                  responses={responses}
                />
              )
            )}
          </div>
        </ModalContent>

        <div className="btn__wrapper">
          {pageIndex === 2 ? (
            <>
              <Button
                onClick={() => {
                  if (currentFormStep === 0) {
                    setPageIndex(1);
                  } else if (currentQuestionId === 1) {
                    handleFormStepChange("start");
                  } else {
                    setQuestionIds((p: any) => {
                      const result = [...p];
                      result.pop();
                      return result;
                    });
                  }
                }}
              >
                <CaretLeft />
                Back
              </Button>

              {normalizedQuestions[currentQuestionId]?.id ===
              forms.number_of_questions ? (
                <Button
                  disabled={
                    (normalizedQuestions[currentQuestionId]?.required &&
                      !responses[currentQuestionId]?.value) ||
                    isSubmittingResponse ||
                    isCreatingAppointment
                  }
                  onClick={async () => {
                    setIsCreatingAppointment(true);
                    try {
                      const res = await handleResponses();
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {isCreatingAppointment || isSubmittingResponse
                    ? "processing..."
                    : "Book appointment"}

                  <CaretRight />
                </Button>
              ) : (
                <Button
                  disabled={
                    currentFormStep === 0
                      ? false
                      : normalizedQuestions[currentQuestionId]?.required &&
                        !responses[currentQuestionId]?.value
                  }
                  onClick={() => {
                    if (currentFormStep === 0) {
                      handleFormStepChange("question");
                    } else {
                      handleNextQuestion();
                    }
                  }}
                >
                  {currentFormStep === 0
                    ? "Start questionnaire"
                    : "Next Question"}{" "}
                  <CaretRight />
                </Button>
              )}
            </>
          ) : (
            <>
              {" "}
              {pageIndex === 1 ? null : (
                <Button
                  className={"left"}
                  onClick={() => setPageIndex(1)}
                  disabled={isLoading}
                >
                  <CaretLeft />
                  Back
                </Button>
              )}
              <Button
                htmlType="submit"
                className={"right"}
                disabled={isLoading}
              >
                {ButtonTexts()} <CaretRight />
              </Button>
            </>
          )}
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default CreateAppointmentModal;
