import {
  Modal,
  Upload,
  Tabs as AntdTabs,
  Select,
  Radio as AntdRadio
} from "antd";
import styled from "styled-components";

const PasswordConfirmWrapper = styled(Modal)`
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 8px;
  }
  .ant-modal-title {
    padding-right: 20px;
  }
`;

const ProfileWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const UploadWrapper = styled(Upload)`
  > .ant-upload .ant-btn {
    border: none;
    background: transparent;
    box-shadow: none;
    border-radius: 8px;
  }
`;

const ContentWrapper = styled.div`
  padding: 15px 15px;
  min-height: calc(100vh - 60px);
  background: #f4f8fe;
  padding-bottom: 100px;

  h2 {
    font-family: "DM Sans";
    margin: 0 0 15px;
    text-align: left;
    color: #4e667e;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #333333;

    @media only screen and (max-width: 767px) {
      margin-left: -19px;
    }
  }

  .single-tab-wrapper {
    padding: 30px 0 50px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    h4 {
      font-weight: 700;
      font-size: 24px;
      line-height: 120%;
      color: #333333;
      margin-bottom: 20px;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      margin: 0;
      max-width: 540px;
    }

    .profile-wrapper {
      display: flex;
      align-items: center;
      padding-left: 10px;

      img {
        width: 50px;
        height: 50px;
        object-fit: cover;
        border-radius: 100%;
      }
    }

    .toggle-wrapper {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      margin-top: 20px;
      padding: 18px 20px;
      width: 90%;
      max-width: 400px;
      display: flex;
      justify-content: space-between;

      &:first-of-type {
        margin-top: 32px;
      }

      span {
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: #667085;
        display: inline-flex;
        align-items: center;
        gap: 8px;
      }

      .ant-form-item {
        margin: 0;
      }
    }

    .insurance-wrapper {
      > .toggle-wrapper {
        margin-top: 20px;
      }

      > div {
        margin-top: 20px;
        width: 90%;
        max-width: 400px;
      }
    }

    .always-open-tag {
      background: #d9efff;
      border-radius: 6px;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      width: 80%;
      max-width: 235px;
    }

    .custom-hours-wrapper {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 20px;

      & > div {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &:not(:first-of-type) {
          margin-top: 16px;

          @media (max-width: 768px) {
            margin-top: 32px;
          }
        }

        > p:first-of-type {
          flex: 1;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #334155;

          @media (max-width: 768px) {
            flex: 0;
            margin-right: 16px;
          }
        }

        > p:nth-of-type(2) {
          flex: 1;
          font-weight: 400;
          font-size: 13px;
          line-height: 20px;
          color: #334155;
          display: flex;
          align-items: flex-end;
          gap: 6px;

          @media (max-width: 768px) {
            flex: 0;
          }
        }

        > p:nth-of-type(3) {
          flex: 2;
          display: flex;
          align-items: center;
          gap: 16px;

          @media (max-width: 768px) {
            min-width: 250px;
            margin-top: 8px;
          }

          span {
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            color: #64748b;
          }
        }
      }
    }

    .plan-wrapper {
      background: #ffffff;
      border: 1.5px solid #e4e7ec;
      border-radius: 8px;
      padding: 18px 24px;
      width: 90%;
      max-width: 365px;

      h5 {
        display: flex;
        gap: 10px;
        align-items: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: #344054;
        margin: 0 0 16px;

        span {
          display: flex;
          align-items: center;
          background: #f6ffed;
          border: 1px solid #5bd066;
          border-radius: 35px;
          font-weight: 400;
          font-size: 10px;
          line-height: 120%;
          color: #5bd066;
          padding: 4px 10px;
        }
      }

      h6 {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #667085;
        margin: 0 0 8px;
      }

      p {
        display: flex;
        align-items: center;
        gap: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        margin: 0;
      }
    }
  }

  .update-profile-btn {
    & > .ant-btn {
      padding: 10px 26px;
      min-height: 45px;
      font-size: 16px;
      margin-top: 30px;
    }
  }

  .btn-add {
    padding: 0px;
    font-size: 13px;
  }
  .ant-tabs-nav {
    background: #fff;
    border: 1px solid #f2f2f2;
    padding: 0px 20px;
    margin: 0px 20px 30px;
    border-radius: 8px;
  }
  .ant-tabs-content {
    padding: 0px 20px;
  }
  .copy-container {
    margin-left: 7px;
    transform: translateY(2px);
    height: max-content;
    @media only screen and (max-width: 431px) {
      margin-left: auto !important;
    }
    .edit-icon {
      margin-right: 10px;
      margin-left: 2px;
    }
  }
  .handle-row {
    margin-top: 16px;
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;

    .sub-heading {
      font-size: 13px;
      display: inline-block;
      margin-bottom: 5px;
      color: #344054;
      font-weight: bold;
    }

    .uploads-flex {
      display: flex;
      flex-wrap: wrap;
      gap: 40px;
    }
  }
`;

const RegularInputWrapper = styled.input`
  height: 45px;
  width: 100%;
  border: 1px solid #f2f2f2;
  outline: none;
`;

const BlankContentWrapper = styled.div`
  background: #fff;
  height: 100%;
  padding: 25px 15px;
  padding-right: 20px;
  box-shadow: 0px 3px 6px rgba(0, 120, 226, 0.1);
  h3 {
    opacity: 0.6;
  }
  p {
    font-size: 13px;
  }
  .ant-alert {
    margin-bottom: 5px;
    .ant-alert-message {
      font-size: 13px;
    }
  }
  .api-section {
    background: #f3f8fb;
    border: 1px solid #91d5ff;
    padding: 15px 15px;
    margin-bottom: 20px;
    p {
      margin-bottom: 10px;
    }
    label {
      font-weight: normal;
      margin-bottom: 5px;
    }
    .ant-input-affix-wrapper.ant-input-password {
      border: 1px solid #bfcad0;
      padding: 5px 10px;
    }
  }
`;

const LicenseRowItemWrapper = styled.div`
  padding: 10px 0px;
  display: flex;
  img {
    width: 70px;
  }
  .certificate-details {
    flex-grow: 1;
    margin-left: 20px;
    .action-btns {
      float: right;
      span {
        margin-left: 10px;
        &.edit {
          color: #0f9af0;
        }
        &.delete {
          color: crimson;
        }
      }
    }
    h3 {
      margin-bottom: 0px;
    }
    p {
      margin-bottom: 0px;
      font-size: 13px;
    }
    span {
      color: #a3a3a3;
      font-size: 13px;
    }
  }
`;

const AddTextWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
  color: #0f9af0;
  img {
    margin-right: 7px;
  }
`;

export const Tabs = styled(AntdTabs)`
  .ant-tabs-nav {
    background: none;
    border: none;
    margin: 0 0 10px;
    padding: 0;
  }

  .ant-tabs-content {
    padding: 0;
  }
`;

export const MultipleSelect = styled(Select)`
  width: 90% !important;
  max-width: 400px !important;

  .ant-select-selector {
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px !important;
    margin-top: 20px;
    padding: 8px 4px;
  }
`;

export const UploadLarge = styled(Upload.Dragger)`
  border-style: solid !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;

  .ant-upload-btn {
    padding: 20px 64px !important;
  }

  .ant-upload-drag-icon {
    margin-bottom: 12px !important;
  }

  .ant-upload-text {
    margin: 0 0 4px;
    font-weight: 500;
    font-size: 14px !important;
    line-height: 20px;
    color: #2d9cdb !important;
  }

  .ant-upload-hint {
    margin: 0;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #667085 !important;
  }
`;

export const GalleryWrapper = styled.div`
  border: 1px solid #d9d9d9;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  background: #fafafa;
  border-radius: 8px;
  padding: 20px 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  cursor: pointer;

  p.upload-icon {
    margin-bottom: 12px;
  }

  p.upload-text {
    margin: 0 0 4px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #2d9cdb;
  }

  &:hover {
    border-color: #2d9cdb;
  }
`;

export const RadioGroup = styled(AntdRadio.Group)`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;

  label {
    background: #ffffff;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 12px;
    display: flex;
    align-items: flex-start;

    > span:nth-of-type(2) > div {
      padding-left: 8px;
      padding-right: 8px;

      p:first-of-type {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #2d9cdb;
        margin: 0 0 4px;
      }

      p:nth-of-type(2) {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #667085;
        margin: 0;
      }
    }

    &:hover {
      border-color: #2d9cdb;
      box-shadow: 0px 2px 3px rgba(16, 24, 40, 0.08);
      transition: all 0.3s ease;
    }

    &.ant-radio-wrapper-checked {
      border-color: #2d9cdb !important;
      box-shadow: 0px 2px 3px rgba(16, 24, 40, 0.08);
    }
  }
`;

export const SubscriptionWrapper = styled.div`
  .single-tab-wrapper {
    border-bottom: none;
  }

  .ant-radio-group {
    gap: 4px;
    margin-bottom: 20px;

    label {
      padding: 10px 24px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
    }
  }

  .plans {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 24px;

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    @media (min-width: 1600px) {
      max-width: 80%;
    }

    .single-plan {
      background: #ffffff;
      border: 0.787312px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 40px 20px;
      display: flex;
      flex-direction: column;

      @media (min-width: 1024px) {
        padding-top: 60px;
      }

      h5 {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        font-size: 24px;
        line-height: 140%;
        color: #0faff0;
        margin: 0 0 6px;
      }

      h4 {
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #0d0d0d;
        margin: 0 0 6px;

        span {
          font-size: 14px;
        }
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #000000;
      }

      .btn-wrapper {
        margin: 24px 0;

        button {
          background-color: #0faff0;
          width: 100%;
          padding-top: 18px;
          padding-bottom: 18px;
          height: auto;
          font-size: 16px;

          &:hover {
            color: #fff;
            background-color: #0faff0;
          }
        }
      }

      .benefits {
        border-top: 0.787312px solid #dcdfe5;
        padding-top: 24px;

        h6 {
          font-weight: 500;
          font-size: 14px;
          line-height: 150%;
          color: #000000;
          margin: 0 0 12px;
        }

        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            display: flex;
            align-items: center;
            gap: 6px;

            &:not(:first-of-type) {
              margin-top: 16px;
            }
          }
        }
      }
    }

    .single-plan.is-active {
      border: 2px solid #0faff0;
      position: relative;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

      .btn-wrapper {
        button {
          background-color: #f5f7f8;
          border: none;
          color: #bbbbbb;

          &:hover {
            background-color: #f5f7f8 !important;
            color: #bbbbbb;
          }
        }
      }

      &::before {
        position: absolute;
        content: "Your current plan";
        background: #0faff0;
        border-radius: 4px 4px 0px 0px;
        top: 0;
        left: 0;
        width: 100%;
        height: 31px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }

  .billing-history-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 900px;

    .billing-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: 1.5px solid #e4e7ec;
      border-radius: 8px;
      padding: 18px 22px;

      .text {
        h4 {
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          color: #344054;
          margin-bottom: 10px;
        }

        p {
          margin: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
          color: #667085;
        }
      }

      .cta {
        button {
          border: 1px solid #000;

          &:hover,
          &:focus,
          &:active {
            background: transparent;
            color: #000;
          }
        }
      }
    }

    .billing-content {
      .history-action-btn {
        color: #0faff0;
        font-weight: 500;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
`;

export {
  PasswordConfirmWrapper,
  ProfileWrapper,
  UploadWrapper,
  ContentWrapper,
  BlankContentWrapper,
  AddTextWrapper,
  LicenseRowItemWrapper,
  RegularInputWrapper
};
