import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpWithIntegration } from "../../utils/api";
import { ErrorTypeAPI } from "../../constants/types";

export interface InvestigationError {
  message: string;
}

export interface requestsState {
  investigations: Array<any>;
  isInvestgationsLoading: boolean;
  investigationsError: InvestigationError;
  isUpdateMetaDataLoading: boolean;
}

export const initialState: requestsState = {
  investigations: [],
  isInvestgationsLoading: false,
  investigationsError: { message: "" },
  isUpdateMetaDataLoading: false,
};

export const requestsSlice = createSlice({
  name: "requests",
  initialState,
  reducers: {
    fetchInvestgationsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isInvestgationsLoading = payload;
    },
    fetchInvestgationsSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>
    ) => {
      state.investigations = payload;
    },
    fetchInvestgationsFailed: (
      state,
      { payload }: PayloadAction<InvestigationError>
    ) => {
      state.investigationsError = payload;
    },
    updateStatusSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.investigations = payload;
    },
    updateMetaDataLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdateMetaDataLoading = payload;
    },
  },
});

export const {
  fetchInvestgationsLoading,
  fetchInvestgationsSuccess,
  fetchInvestgationsFailed,
  updateStatusSuccess,
  updateMetaDataLoading,
} = requestsSlice.actions;
export const requestsSelector = (state: { investigations: requestsState }) =>
  state.investigations;
export default requestsSlice.reducer;

/** Actions */

export const fetchAllServiceOrders = (): AppThunk => async (dispatch) => {
  dispatch(fetchInvestgationsLoading(true));
  await http
    .get(`/provider/services/orders`)
    .then((res) => {
      const serviceOrders = res?.data?.data;
      dispatch(fetchInvestgationsSuccess(serviceOrders));
    })
    .catch((err) => {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchInvestgationsFailed(message));
    });
  dispatch(fetchInvestgationsLoading(false));
};

// UPDATE THE PATIENT DATA: DOB if the PATIENT ORDERED FROM PNEUMAPAGE, then store to firestore then update order status
export const updateOrderStatus =
  (id: string, payload: object): AppThunk =>
  async (dispatch) => {
    await http
      .patch(`/provider/services/orders/${id}`, payload)
      .then((res) => {
        const statusData = res?.data?.data;
        dispatch(updateStatusSuccess(statusData));
      })
      .catch((error) => {
        const err = error as ErrorTypeAPI;
        const message =
          err.response.data.message ||
          err.response.data.error ||
          "An error occurred, please try again.";
        throw new Error(message);
      });
  };

export const updateOrderMetadata =
  (id: string, payload: object): AppThunk =>
  async (dispatch) => {
    dispatch(updateMetaDataLoading(true));

    try {
      await httpWithIntegration.patch(`/services/orders/${id}`, payload);
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response.data.message ||
        err.response.data.error ||
        "An error occurred, please try again.";

      throw new Error(message);
    } finally {
      dispatch(updateMetaDataLoading(false));
    }
  };
