import moment from "moment";
import { Avatar } from "antd";
import { useEffect, useState } from "react";
import { generatePath } from "react-router-dom";

import { Header, Wrapper, MessageLink, LinksWrapper } from "./styles";
import { ReactComponent as PenIcon } from "assets/icons/pen-icon.svg";
import { GetAllMessagesRes, SingleMessageType } from "constants/types";

import Search from "component/Search";
import { capitaliseFirstLetter } from "utils/utils";
import { ROUTES } from "constants/routes";
import SendMessageModal from "component/SendMessageModal";

interface MessageListProps {
  messages: GetAllMessagesRes["data"];
  toggleChatList: () => void;
}

function MessageList({ messages, toggleChatList }: MessageListProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filteredMessages, setFilteredMessages] =
    useState<SingleMessageType[]>();

  useEffect(() => {
    if (messages) {
      setFilteredMessages([...messages]);
    }
  }, [messages]);

  function handleSearch(e: any) {
    const keyword = e.target.value;

    setFilteredMessages(() =>
      messages.filter(
        (message) =>
          message.user.first_name
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          message.user.last_name
            .toLowerCase()
            .includes(keyword.toLowerCase()) ||
          `${message.user.first_name.toLowerCase()} ${message.user.last_name.toLowerCase()}`.includes(
            keyword.toLowerCase()
          )
      )
    );
  }

  return (
    <Wrapper>
      <Header>
        <h3>Messages</h3>

        <p onClick={() => setIsModalOpen(true)}>
          <PenIcon />
          New Message
        </p>
      </Header>

      {/* search box */}
      <Search
        placeholder='Search for patients'
        style={{ marginBottom: "34px", paddingRight: "20px" }}
        onChange={handleSearch}
      />

      {/* message list */}
      <LinksWrapper>
        {filteredMessages?.length ? (
          filteredMessages.map((item) => (
            <MessageLink
              key={item.id}
              to={generatePath(ROUTES.MESSAGES_DETAILS, {
                patientId: item.user.id
              })}
              onClick={toggleChatList}
            >
              <Avatar
                style={{ color: "#fff", backgroundColor: "rgb(24, 144, 255)" }}
                size='large'
              >
                {item.user.first_name.charAt(0).toUpperCase()}
              </Avatar>

              <div>
                <p>
                  {capitaliseFirstLetter(item.user.first_name)}{" "}
                  {capitaliseFirstLetter(item.user.last_name)}
                </p>
                <p>{moment(item.sent_at).startOf("minutes").fromNow()}</p>
              </div>
            </MessageLink>
          ))
        ) : (
          <p>No data</p>
        )}
      </LinksWrapper>

      <SendMessageModal
        isModalVisible={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
      />
    </Wrapper>
  );
}

export default MessageList;
