import { Row, Col, Radio } from "antd";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

import { Nav, Wrapper } from "./styles";
import Button from "component/Button";
import { RadioGroup } from "screens/Profile/styles";
import Subscriptions, { prices, paymentPages } from "constants/subscriptions";
import { ReactComponent as LogoEl } from "assets/icons/pneuma-payment-logo.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check-icon.svg";
import { logOutProvider } from "utils/api";
import { ROUTES } from "constants/routes";

function Payments() {
  const history = useHistory();

  const [activePlan, setActivePlan] = useState("");
  const [selectedPricing, setSelectedPricing] = useState<
    "monthly" | "quarterly"
  >("monthly");

  useEffect(() => {
    document.title = "FrontDesk | Payments";
  }, []);

  return (
    <>
      <Nav>
        <div
          className='logo-wrapper'
          onClick={() => history.push(ROUTES.HOMEPAGE)}
        >
          <LogoEl />
        </div>

        <div className='links-wrapper'>
          <Button type='primary' onClick={() => history.push(ROUTES.HOMEPAGE)}>
            Back Home
          </Button>
          <Button onClick={() => logOutProvider()}>Logout</Button>
        </div>
      </Nav>

      <Wrapper>
        <h2>Take your healthcare practice to the next level</h2>
        <p>Frontdesk help you manage patients and grow your practice</p>

        <div className='plans-wrapper'>
          <Row gutter={24} justify='center'>
            <Col
              xs={24}
              lg={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <RadioGroup
                name='radiogroup'
                defaultValue={selectedPricing}
                onChange={async (e) => {
                  setSelectedPricing(e.target.value);
                }}
              >
                <Radio value={"monthly"}>Monthly</Radio>

                <Radio value={"quarterly"}>Quarterly</Radio>
              </RadioGroup>
            </Col>
          </Row>

          <div className='plans'>
            {Subscriptions.map((singleSub) => (
              <div
                className={`single-plan ${
                  activePlan === singleSub.name.toLowerCase() ? "is-active" : ""
                }`}
                key={singleSub.name}
              >
                <h5>
                  <img src={singleSub.icon} alt={singleSub.name} />{" "}
                  {singleSub.name}
                </h5>
                <h4>
                  NGN
                  {new Intl.NumberFormat("en-US").format(
                    prices[selectedPricing][
                      singleSub.name.toLowerCase() as
                        | "start"
                        | "grow"
                        | "engage"
                    ]
                  )}
                  <span>
                    /{selectedPricing === "monthly" ? "month" : "quarter"}
                  </span>
                </h4>
                <p>{singleSub.description}</p>

                <Button
                  type='primary'
                  className='btn-wrapper'
                  onClick={() => {
                    window.location.replace(
                      paymentPages[selectedPricing][
                        singleSub.name.toLowerCase() as
                          | "start"
                          | "grow"
                          | "engage"
                      ]
                    );
                    setActivePlan(singleSub.name.toLowerCase());
                  }}
                  disabled={activePlan === singleSub.name.toLowerCase()}
                >
                  {activePlan === singleSub.name.toLowerCase()
                    ? "Current plan"
                    : "Upgrade"}
                </Button>

                <div className='benefits'>
                  <h6>This plan includes</h6>
                  <ul>
                    {singleSub.benefits.map((item, idx) => (
                      <li key={item + idx}>
                        <CheckIcon />
                        {item}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default Payments;
