import pusherJs from "pusher-js";

import connectApi from "./connect-api";
import { handleBinding } from "utils/utils";
import { BookingsRTKQueryTagEnum } from "constants/types";
import { providesList } from "utils/redux-toolkit-query";
import {
  fetchInvestgationsSuccess,
  fetchInvestgationsFailed,
} from "redux/reducers/requests";

// this query api was set up in order to enable live updates for new bookings using RTKQ & PusherJS. we update global state accordingly once the query is completed (successfully or failed) using the onQueryStarted method, and we handle notifications once the cache is updated using the onCacheEntryAdded method.
export const bookingsApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getBookings: builder.query({
      query: ({ path }) => ({ url: `/provider/services/orders` }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          const res = await queryFulfilled;
          const serviceOrders = res?.data?.data;
          dispatch(fetchInvestgationsSuccess(serviceOrders));
        } catch (error: object | any) {
          const message = { message: error?.response?.data?.message };
          dispatch(fetchInvestgationsFailed(message));
        }
      },
      async onCacheEntryAdded(
        { path },
        { cacheDataLoaded, cacheEntryRemoved, dispatch }
      ) {
        // init new Pusher instance
        const pusher = new pusherJs(
          process.env.REACT_APP_PUSHER_API_KEY as string,
          {
            cluster: process.env.REACT_APP_PUSHER_CLUSTER as string,
            authEndpoint: process.env.REACT_APP_PUSHER_AUTH_ENDPOINT as string,
          }
        );

        try {
          await cacheDataLoaded;

          // setup pusher subscription & bind events accordingly
          const chanel = pusher.subscribe("service_orders");

          chanel.bind("created", (data: any) => {
            handleBinding(data, dispatch, bookingsApi, "New booking created");
          });
        } catch (err) {
          console.log(err);
        }

        await cacheEntryRemoved;
        pusher.disconnect();
      },
      providesTags: (data) =>
        providesList(BookingsRTKQueryTagEnum.BOOKINGS, data?.data) as any,
    }),
  }),
  overrideExisting: true,
});

bookingsApi.enhanceEndpoints({
  addTagTypes: Object.values(BookingsRTKQueryTagEnum),
});
