import styled from "styled-components";

export const Wrapper = styled.section`
  width: 595px;
  background: #ffffff;
  padding: 45px;
  font-family: "DM Sans";
  position: fixed;
  z-index: -20;

  .invoice-details {
    display: flex;
    justify-content: space-between;

    .pneuma {
      display: flex;
      flex-direction: column;
      gap: 6px;

      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #323d53;
      }

      a {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        text-decoration-line: underline;
        color: #323d53;
      }
    }

    .invoice {
      h2 {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #000000;
        margin: 0 0 12px;
        padding: 0;
      }

      .basic-info {
        display: flex;
        flex-direction: column;
        gap: 6px;
        padding-bottom: 16px;
        border-bottom: 1px dashed #5a6474;

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #323d53;
          margin: 0;

          span {
            text-transform: uppercase;
          }
        }
      }

      .subscription-info {
        padding-top: 16px;

        h3 {
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          color: #000000;
          margin-bottom: 9px;
        }

        p {
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          color: #323d53;
          margin: 0;

          &:not(:first-of-type) {
            margin-top: 6px;
          }
        }

        span.status {
          background: #f6ffed;
          border: 1px solid #3ebb49;
          border-radius: 35px;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #3ebb49;
          padding: 6px 18px;
          display: inline-block;
          margin-top: 12px;
        }
      }
    }
  }

  .description-wrapper {
    margin: 40px 0;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;

    .desc {
      display: flex;
      flex-direction: column;

      h4 {
        font-weight: 700;
        font-size: 10px;
        line-height: 13px;
        margin: 0 0 16px;
      }

      p {
        margin: 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 143.5%;
        color: #1a1a1a;

        &:last-of-type {
          font-weight: 600;
        }
      }
    }

    .qty,
    .price,
    .amount {
      h4 {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #1a1a1a;
        margin: 0 0 16px;
      }

      p {
        margin: 0;
        font-size: 12px;
        line-height: 14px;
        text-align: right;
        color: #1a1a1a;
      }
    }
  }

  .total {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0 40px;
    border-bottom: 1px solid black;

    p:first-of-type {
      font-weight: 700;
      font-size: 12px;
      line-height: 16px;
      margin: 0;
      color: #1a1a1a;
    }

    p:nth-of-type(2) {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin: 0;
      gap: 10px;

      span:first-of-type {
        font-weight: 700;
        font-size: 20px;
        line-height: 26px;
        text-align: right;
        color: #1a1a1a;
      }

      span:nth-of-type(2) {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: right;
        color: #1a1a1a;
      }
    }
  }
`;
