import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, { httpWithIntegration, httpWithNorthIntegration } from "utils/api";
import { message } from "antd";
import { BookValuesType, ErrorTypeAPI, SingleBooking } from "constants/types";
import { updateOrderStatus } from "./requests";

export interface ServicePlansError {
  message: string;
}

export interface servicePlansState {
  servicePlansFetched: boolean;
  servicePlans: Array<any>;
  servicePlansLoading: boolean;
  servicePlansError: ServicePlansError;
  addServicePlanLoading: boolean;
  hasAddedServicePlan: boolean;
  addServicePlanError: ServicePlansError;
  updateServicePlanLoading: boolean;
  hasUpdatedServicePlan: boolean;
  updateServicePlanError: ServicePlansError;
  deleteServicePlanLoading: boolean;
  hasDeletedServicePlan: boolean;
  deleteServicePlanError: ServicePlansError;
  isBookingModalVisible: boolean;
}

export const initialState: servicePlansState = {
  servicePlansFetched: false,
  servicePlans: [],
  servicePlansLoading: false,
  servicePlansError: { message: "" },
  addServicePlanLoading: false,
  hasAddedServicePlan: false,
  addServicePlanError: { message: "" },
  updateServicePlanLoading: false,
  hasUpdatedServicePlan: false,
  updateServicePlanError: { message: "" },
  deleteServicePlanLoading: false,
  hasDeletedServicePlan: false,
  deleteServicePlanError: { message: "" },
  isBookingModalVisible: false,
};

export const servicePlans = createSlice({
  name: "servicePlans",
  initialState,
  reducers: {
    addServicePlanLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.addServicePlanLoading = payload;
    },
    addServicePlanSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.hasAddedServicePlan = payload;
    },
    addServicePlanFailed: (
      state,
      { payload }: PayloadAction<ServicePlansError>,
    ) => {
      state.addServicePlanError = payload;
      state.hasAddedServicePlan = false;
    },
    fetchServicePlansLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.servicePlansLoading = payload;
    },
    fetchServicePlansSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>,
    ) => {
      state.servicePlans = payload;
      state.servicePlansFetched = true;
    },
    fetchServicePlansFailed: (
      state,
      { payload }: PayloadAction<ServicePlansError>,
    ) => {
      state.servicePlansError = payload;
      state.servicePlansFetched = false;
    },
    updateServicePlanLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.updateServicePlanLoading = payload;
    },
    updateServicePlanSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.hasUpdatedServicePlan = payload;
    },
    updateServicePlanFailed: (
      state,
      { payload }: PayloadAction<ServicePlansError>,
    ) => {
      state.updateServicePlanError = payload;
      state.hasUpdatedServicePlan = false;
    },
    deleteServicePlanLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.deleteServicePlanLoading = payload;
    },
    deleteServicePlanSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.hasDeletedServicePlan = payload;
    },
    deleteServicePlanFailed: (
      state,
      { payload }: PayloadAction<ServicePlansError>,
    ) => {
      state.deleteServicePlanError = payload;
      state.hasDeletedServicePlan = false;
    },
    setBookingModalState: (state, { payload }: PayloadAction<boolean>) => {
      state.isBookingModalVisible = payload;
    },
  },
});

export const {
  addServicePlanLoading,
  addServicePlanSuccess,
  addServicePlanFailed,
  fetchServicePlansLoading,
  fetchServicePlansSuccess,
  fetchServicePlansFailed,
  updateServicePlanLoading,
  updateServicePlanSuccess,
  updateServicePlanFailed,
  deleteServicePlanLoading,
  deleteServicePlanSuccess,
  deleteServicePlanFailed,
  setBookingModalState,
} = servicePlans.actions;
export const servicePlansSelector = (state: {
  servicePlans: servicePlansState;
}) => state.servicePlans;
export default servicePlans.reducer;

/** Actions */

export const fetchAllServicePlans = (): AppThunk => async (dispatch) => {
  dispatch(fetchServicePlansLoading(true));
  await http
    .get(`/service_provider/services/plans`)
    .then((res) => {
      const servicePlans = res?.data?.data;
      dispatch(fetchServicePlansSuccess(servicePlans));
    })
    .catch((err) => {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchServicePlansFailed(message));
    });
  dispatch(fetchServicePlansLoading(false));
};

export const fetchUserBookings =
  (userId: string): AppThunk =>
  async () => {
    try {
      const res = await httpWithIntegration.get(
        `/services/orders/user/${userId}`,
      );
      return res.data.data as SingleBooking[];
    } catch (err: any | object) {
      throw new Error(err);
    }
  };

export const addServicePlan =
  (payload: object): AppThunk =>
  async (dispatch) => {
    dispatch(addServicePlanLoading(true));
    await http
      .post(`/service_provider/services/plans`, payload)
      .then((res) => {
        message.success("Service plan added successfully!");
        dispatch(addServicePlanSuccess(true));
        dispatch(fetchAllServicePlans());
      })
      .catch((err) => {
        const _message = { message: err?.response?.data?.message };
        dispatch(addServicePlanFailed(_message));
      });
    dispatch(addServicePlanLoading(false));
  };

export const updateServicePlan =
  (id: string, payload: object): AppThunk =>
  async (dispatch) => {
    dispatch(updateServicePlanLoading(true));
    await http
      .post(`/service_provider/services/plans/${id}`, payload)
      .then((res) => {
        dispatch(updateServicePlanSuccess(true));
        message.success("Service plan updated successfully!");
        dispatch(fetchAllServicePlans());
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(updateServicePlanFailed(_message));
      });
    dispatch(updateServicePlanLoading(false));
  };

export const deleteServicePlan =
  (id: string): AppThunk =>
  async (dispatch) => {
    dispatch(deleteServicePlanLoading(true));
    await http
      .delete(`/service_provider/services/plans/${id}`)
      .then((res) => {
        dispatch(deleteServicePlanSuccess(true));
        message.success("Service plan deleted successfully!");
        dispatch(fetchAllServicePlans());
      })
      .catch((err) => {
        const _message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(deleteServicePlanFailed(_message));
      });
    dispatch(deleteServicePlanLoading(false));
  };

export const bookService =
  (payload: BookValuesType): AppThunk =>
  async (dispatch, getState) => {
    try {
      const requestRes = await httpWithIntegration.post("/services/orders", {
        plan: payload.plan,
        user: payload.user ?? getState().users.user.id,
        number: 1,
        note: payload.note ?? payload.plan,
        metadata: {
          payment_status: "unpaid",
          source: payload.source,
          preferred_date: payload.preferred_date.format("DD/MM/YYYY"),
          preferred_time: payload.preferred_time?.format("h:mm a"),
          payment_method: payload.payment_method,
          insurance_details: {
            user_id: payload?.insurance_id,
            provider: payload?.insurance_provider,
          },
          patientId: payload?.patientId,
          additionalServices: payload?.additionalServices,
          assignedPractitioner: payload?.assignedPractitioner,
          form_response: payload.form_response,
        },
      });

      await dispatch(
        updateOrderStatus(requestRes?.data?.data?.id, { status: "accepted" }),
      );
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response.data.message ||
        err.response.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };

// TODO -> remember to fix ASAP.
// Hacky workaround to account for erroneous Divine dentals mass record upload (the data was mistakenly uploaded to the wrong integration - Telehealth/North) and uses North's API key instead to make bookings.
export const bookServiceWithNorthKey =
  (payload: BookValuesType): AppThunk =>
  async (dispatch, getState) => {
    try {
      const requestRes = await httpWithNorthIntegration.post(
        "/services/orders",
        {
          plan: payload.plan,
          user: payload.user ?? getState().users.user.id,
          number: 1,
          note: payload.note,
          metadata: {
            payment_status: "unpaid",
            source: payload.source,
            preferred_date: payload.preferred_date.format("DD/MM/YYYY"),
            preferred_time: payload.preferred_time?.format("h:mm a"),
            payment_method: payload.payment_method,
            insurance_details: {
              user_id: payload?.insurance_id,
              provider: payload?.insurance_provider,
            },
            patientId: payload?.patientId,
            additionalServices: payload?.additionalServices,
            assignedPractitioner: payload?.assignedPractitioner,
          },
        },
      );

      await dispatch(
        updateOrderStatus(requestRes?.data?.data?.id, { status: "accepted" }),
      );
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response.data.message ||
        err.response.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };
