import styled from "styled-components";

interface StatsCardProps {
  active: boolean;
  isCurrency?: boolean;
}

const DashboardWrapper = styled.div`
  width: 100%;
  h3 {
    font-family: "DM Sans";
    font-weight: 400;
    font-size: 14px;
    text-align: left;
    color: #4e667e;
  }

  .ant-tabs-nav {
    max-width: max-content;
    margin: 0 auto 16px;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
  }
`;

export const DrawerBody = styled.div`
  .ant-tabs-nav {
    max-width: max-content;
    margin: 14px auto 26px;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
  }

  .select-all {
    text-align: right;
    display: block;
    width: auto;
    margin: 0 0 10px 56%;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    display: inline-flex;
    align-items: center;
    gap: 8px;
    justify-content: flex-end;
  }

  .birthday-user,
  .upcoming-user {
    max-width: 80%;
    margin: 0 auto;
    padding: 18px 0 6px;
    box-shadow: inset 0px -1px 0px #f0f0f0;

    display: flex;
    justify-content: space-between;
  }

  .birthday-user {
    align-items: center;

    label {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #344054;
      cursor: pointer;
      margin: 0 0 4px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      margin-top: 4px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .no-users {
    display: flex;
    flex-direction: column;
    align-items: center;

    h4 {
      font-weight: 600;
      font-size: 17px;
      text-align: center;
      max-width: 70%;
      line-height: 20px;
      color: #344054;
      margin: 20px 0 16px;
    }
  }

  .upcoming-user {
    flex-direction: column;
    align-items: flex-start;

    h5 {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #344054;
      cursor: pointer;
      margin: 0 0 4px;
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.45);
    }
  }

  .send-birthday-btn {
    margin: 60px auto 0;
    display: flex;
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  background: #fff;
  min-height: calc(100vh - 60px);
  padding-bottom: 100px;
  padding: 24px 24px 20px;
  position: relative;
  .RHS {
    position: fixed;
    width: inherit;
    max-width: inherit;
    .ant-card {
      min-height: calc(100vh - 57px);
      width: 360px;
      right: 0px;
    }
  }
  .encounters-pane {
    margin-top: 20px;

    .ant-tabs {
      width: 100%;

      .ant-tabs-nav {
        margin: 0 0 16px;
      }
    }

    & > .ant-col:first-of-type {
      display: flex;
      gap: 16px;
      align-items: center;
    }

    h2 {
      margin-bottom: 0px;
      font: normal normal normal 18px/24px DM Sans;
      color: #002e46;
    }

    .reminder-btn {
      padding: 18px 22px;
      display: inline-flex;
      align-items: center;
      background-color: rgb(15, 175, 240) !important;
      color: #fff;

      &:disabled {
        background-color: #f5f5f5 !important;
        color: rgba(0, 0, 0, 0.25) !important;
        border-color: #d9d9d9;
      }

      span.wrapper {
        display: flex;
        gap: 6px;
        align-items: center;
      }

      &.secondary {
        background-color: transparent !important;
        border-color: #333 !important;
        color: #333;
      }
    }

    .bookings-header {
      display: inline-block;
      font-weight: 700;
      font-size: 20px;
      line-height: 38px;
      color: #333;
      margin-right: 40px;
    }

    .bookings-count {
      width: 32px;
      height: 20px;
      padding: 2px 8px;
      font-size: 14px;
      border-radius: 100%;
    }
  }
`;

const StatsCardWrapper = styled.div<StatsCardProps>`
  margin-bottom: 20px;
  .ant-card {
    border-radius: 8px;
    box-shadow: 0px 0px 3px #0f9af01a;
    ${(props) => props.active && `border: 1px solid #0f9af0BF;`}
    .ant-card-body {
      padding: 18px 24px;
    }
  }
  .icon {
    width: 35px;
    fill: #1890ff;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .flex {
      display: flex;
      justify-content: space-between;
    }
  }
  p.title {
    text-align: left;
    text-transform: capitalize;
    font: normal normal medium 13px/14px DM Sans;
    line-height: 18px;
    letter-spacing: 0px;
    color: #002e46;
    word-break: break-word;
    white-space: pre-line;
    opacity: 0.8;
    margin-bottom: 4px;
    @media (max-width: 500px) {
      font-size: 12px;
    }
  }
  h2 {
    text-align: left;
    font: normal normal normal 28px/34px DM Sans;
    letter-spacing: 0px;
    font-weight: 600;
    color: ${(props) => (props.isCurrency ? "#002e46" : "#002e46")};
    opacity: 0.8;
    margin-bottom: 0px;
  }
  span.link {
    /* text-transform: capitalize; */
    text-align: right;
    font-size: 12px;
    margin-top: 15px;
    margin-bottom: 4px;
    width: auto;
    white-space: pre;
    @media (max-width: 500px) {
      white-space: normal;
    }
    a {
      text-align: right;
      font-weight: 600;
      background: #0f9af01a;
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid #fff;
      @media (max-width: 500px) {
        background: transparent;
        border: none;
        padding: 0px;
      }
      &:hover {
        background: #fff;
        border: 1px solid #0f9af01a;
      }
      i {
        margin-left: 5px;
      }
      .lni {
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
  }
`;

const AddTextWrapper = styled.p`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: "DM Sans";
  font-weight: 500;
  font-size: 10px;
  line-height: 24px;
  margin-top: 5px;
  cursor: pointer;
  color: #0f9af0;
  img {
    margin-right: 7px;
  }
`;

const OverviewIntroCardWrapper = styled.div`
  background: #0f9af0;
  width: 100%;
  box-shadow: 0px 0px 3px #0f9af01a;
  border-radius: 8px;
  .inner {
    padding: 50px 40px;
  }
  margin-bottom: 20px;
  overflow: hidden;
  position: relative;
  h3 {
    font-family: "DM Sans";
    font-weight: 600;
    font-size: 28px;
    line-height: 32px;
    text-align: left;
    color: #fff;
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    opacity: 1;
    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }
  .circles {
    background: radial-gradient(
      214px 214px at 60% 90%,
      #0ff0c052 50%,
      rgba(238, 152, 71, 0) 0
    );
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`;

const WalletCardWrapper = styled.div`
  background: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 3px #0f9af01a;
  border-radius: 8px;
  padding: 18px 24px 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  h3 {
    text-align: left;
    font: normal normal normal 28px/34px DM Sans;
    letter-spacing: 0px;
    font-weight: 600;
    color: #002e46;
    opacity: 0.8;
    margin-bottom: 0px;
    small {
      font-size: 18px;
    }
  }
  p {
    margin-bottom: 0px;
    text-transform: capitalize;
  }
  p.link {
    text-transform: capitalize;
    text-align: right;
    font-size: 12px;
    margin-bottom: 4px;
    margin-top: 15px;
    a {
      text-align: right;
      font-weight: 600;
      background: #0f9af01a;
      padding: 10px 15px;
      border-radius: 8px;
      border: 1px solid #fff;
      &:hover {
        background: #fff;
        border: 1px solid #0f9af01a;
      }
      i {
        margin-left: 5px;
      }
    }
  }
`;

export {
  DashboardWrapper,
  ContentWrapper,
  StatsCardWrapper,
  AddTextWrapper,
  OverviewIntroCardWrapper,
  WalletCardWrapper
};
