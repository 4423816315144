import React, { useState } from "react";
import { Col, Row, Select as AntdSelect, Select } from "antd";
import Button from "../../../../component/Button";
import { Container, EmptyStateWrapper } from "./styles";
import Search from "../../../../component/Search";
import { ReactComponent as FormsIcon } from "assets/icons/empty-img-default.svg";
import { generatePath, NavLink } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import Forms from "./Forms";
import { useGetAllServiceFormsQuery } from "../../../../redux/queries/service-forms";
import { SingleFormType } from "../../../../constants/types";
import Loader from "../../../../component/Loader";
import { capitaliseFirstLetter } from "../../../../utils/utils";
import { Categories, Specialties } from "../Create/FormDetails";

const FormsTable = () => {
  const [filterByCategory, setFilterByCategory] = useState("all");
  const [filterBySpecialty, setFilterBySpecialty] = useState("all");
  const [filterByFormName, setFilterByFormName] = useState("");
  const { data, isLoading, error } = useGetAllServiceFormsQuery();
  const service_forms = data?.data as any;

  // Filter items by selected category
  const filteredCategory =
    filterByCategory === "all"
      ? service_forms
      : service_forms?.filter(
          (item: SingleFormType) =>
            item.category
              ?.toLowerCase()
              ?.includes(filterByCategory?.toLowerCase()),
        );

  const filteredSpecialty =
    filterBySpecialty === "all"
      ? filteredCategory
      : filteredCategory?.filter(
          (item: SingleFormType) =>
            item.specialty
              ?.toLowerCase()
              ?.includes(filterBySpecialty?.toLowerCase()),
        );

  const filteredForms = filteredSpecialty?.filter((item: SingleFormType) =>
    item.name.toLowerCase().includes(filterByFormName.toLowerCase()),
  );

  if (isLoading) {
    return <Loader background="transparent" />;
  }

  if (error) {
    return (
      <EmptyStateWrapper>
        <h2>
          {error?.message || "Error Loading page"}
          ...
        </h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }

  return (
    <>
      <Container>
        <Row justify="space-between">
          <Col style={{ display: "flex", gap: 8 }}>
            <h1>Forms</h1>
            <span className="tag">{service_forms?.length}</span>
          </Col>
          <Col>
            <Button type="primary" style={{ padding: "0px 10px" }}>
              <NavLink to={generatePath(ROUTES.CREATE_FORMS)}>
                Create a new form
              </NavLink>
            </Button>
          </Col>
        </Row>
        <div className="search-wrapper" style={{ marginTop: 51 }}>
          <div className="select-wrapper">
            <span>Filter by:</span>
            <Select
              value={filterByCategory}
              onChange={(val) => setFilterByCategory(val)}
            >
              <Select.Option value="all">All categories</Select.Option>
              {Object.keys(Categories).map((key) => (
                <AntdSelect key={Categories[key]} value={Categories[key]}>
                  {capitaliseFirstLetter(key?.replace(/_/g, " "))}
                </AntdSelect>
              ))}
            </Select>
            <Select
              value={filterBySpecialty}
              onChange={(val) => setFilterBySpecialty(val)}
            >
              <Select.Option value="all">All specialty</Select.Option>
              {Object.keys(Specialties).map((key) => (
                <Select.Option key={Specialties[key]} value={Specialties[key]}>
                  {capitaliseFirstLetter(key?.replace(/_/g, " "))}
                </Select.Option>
              ))}
            </Select>
          </div>

          <Search
            placeholder={"Search forms by name"}
            onChange={(e: any) => setFilterByFormName(e.target.value)}
          />
        </div>
      </Container>
      {service_forms && service_forms?.length ? (
        <Forms filteredForms={filteredForms!} isLoading={isLoading} />
      ) : (
        <EmptyStateWrapper>
          <div>
            <FormsIcon />
            <h1>No forms created</h1>
            <p>All created forms will show here</p>
            <Button type="primary">Create a form</Button>
          </div>
        </EmptyStateWrapper>
      )}
    </>
  );
};

export default FormsTable;
