import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import ReactGA from "react-ga";
import { getCLS, getFID, getLCP } from "web-vitals";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";

import { UpdateSubContextProvider } from "context/UpdateSubscriptionContext";
import * as serviceWorker from "./registerServiceWorker";

ReactGA.initialize(
  process.env.REACT_APP_INSTANCE_ENV === "development"
    ? (process.env.REACT_APP_GA_TRACKING_ID_DEV as string)
    : (process.env.REACT_APP_GA_TRACKING_ID_PROD as string)
);
ReactGA.pageview(window.location.pathname + window.location.search);

const sendToGoogleAnalytics = ({ name, delta, id }: any) => {
  ReactGA.ga("send", "event", {
    eventCategory: "Web Vitals",
    eventAction: name,
    eventLabel: id,
    eventValue: Math.round(name === "CLS" ? delta * 1000 : delta),
    nonInteraction: true,
    transport: "beacon"
  });
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <UpdateSubContextProvider>
          <App />
        </UpdateSubContextProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

getCLS(sendToGoogleAnalytics);
getFID(sendToGoogleAnalytics);
getLCP(sendToGoogleAnalytics);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
serviceWorker.register();
