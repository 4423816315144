import React, { useEffect, useMemo, useState } from "react";
import { PaymentsTableWrapper, TypeFormModalWrapper } from "./styles";
import { Button, Row, Tabs } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { CSVLink } from "react-csv";

import ClaimsTable from "./ClaimsTable";
import {
  fetchAllServiceOrders,
  requestsSelector
} from "redux/reducers/requests";
import { profileSelector } from "redux/reducers/profile";
import FinaceTable from "./FinanceTable";
import FinanceRequestModal from "component/FinanceRequestModal";
import { useGetFinancialRequestQuery } from "redux/queries/financial-request";

interface PaymentTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
  dashboardMode?: string;
}

const PaymentTable = ({ count, dashboardMode }: PaymentTableProps) => {
  const [activeTab, setActiveTab] = useState("claims");
  const dispatch = useDispatch();
  const { investigations, isInvestgationsLoading, investigationsError } =
    useSelector(requestsSelector);
  const { profile } = useSelector(profileSelector);
  const { email, phone, business_name, id } = profile || {};
  const [labRequestsState, setClaimsState] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [name, setName] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const { data, isLoading, error } = useGetFinancialRequestQuery({ size: 20 });
  const financialRequestData = data?.data?.data;
  const financialRequestPagination = data?.data?.pagination;
  useEffect(() => {
    dispatch(fetchAllServiceOrders());
  }, [dispatch]);

  useEffect(() => {
    if (investigations) {
      setClaimsState(
        investigations.filter(
          (item) =>
            item.status === "completed" &&
            (item.metadata.payment_method === "pneumapay" ||
              item.metadata.payment_method === "search")
        )
      );
    }
  }, [investigations]);

  const totalAmountPerMonth = labRequestsState.reduce((acc, item) => {
    acc += item.total_price / 100;
    return acc;
  }, 0);
  const downloadableLabRequestsState = useMemo(
    () =>
      labRequestsState.map(
        ({ id, user, partner, status, total_price, currency, created_at }) => {
          setName(`${user?.first_name} ${user?.last_name}`);
          return {
            id,
            patient: `${user?.first_name} ${user?.last_name}`,
            partner: partner?.business_name,
            status,
            total_price,
            currency,
            created_at
          };
        }
      ),
    [labRequestsState]
  );

  return (
    <>
      {!count && (
        <Row justify='space-between' align='middle'>
          <TypeFormModalWrapper
            centered
            visible={openModal}
            onOk={() => setOpenModal(false)}
            onCancel={() => setOpenModal(false)}
            width={"88vw"}
            footer={false}
            destroyOnClose
            bodyStyle={{
              height: "90vh",
              borderRadius: "70px",
              padding: "55px 35px"
            }}
          >
            <iframe
              style={{
                border: "0",
                outline: "none",
                height: "100%",
                width: "100%"
              }}
              title='_'
              src={`https://pneuma.typeform.com/to/Tdt5yGJT#business_name=${business_name}&provider_id=${id}&provider_phone=${phone}&business_email=${email}`}
            ></iframe>
          </TypeFormModalWrapper>
        </Row>
      )}
      <PaymentsTableWrapper>
        <div className='header'>
          <div className='text-wrapper'>
            <h1>Payments</h1>
            <h3>Amount payable: NGN {totalAmountPerMonth.toLocaleString()}</h3>
          </div>
          <div className='btn-wrapper'>
            <CSVLink
              data={downloadableLabRequestsState}
              filename={`${name}_claims_${selectedMonth ?? ""}.csv`}
            >
              <Button>Download claims as CSV</Button>
            </CSVLink>

            <Button onClick={() => setOpenModal(true)}>Submit claims</Button>
            <Button onClick={() => setIsModalOpen(true)}>
              Create finance request
            </Button>
          </div>
        </div>

        <Tabs activeKey={activeTab} onChange={(val) => setActiveTab(val)}>
          <Tabs.TabPane
            key='claims'
            tab={
              <p className='label'>
                Claims <span>{labRequestsState.length}</span>
              </p>
            }
          >
            <ClaimsTable
              investigations={investigations}
              isInvestgationsLoading={isInvestgationsLoading}
              setSelectedMonth={setSelectedMonth}
            />
          </Tabs.TabPane>

          <Tabs.TabPane
            key='finance-requests'
            tab={
              <p className='label'>
                Finance requests <span>{financialRequestData?.length}</span>
              </p>
            }
          >
            <FinaceTable
              financialRequestData={financialRequestData}
              pagination={financialRequestPagination}
              isLoading={isLoading}
              openModal={() => setIsModalOpen(true)}
            />
          </Tabs.TabPane>
        </Tabs>
      </PaymentsTableWrapper>

      <FinanceRequestModal
        handleCancel={() => setIsModalOpen(false)}
        isModalVisible={isModalOpen}
      />
    </>
  );
};

export default PaymentTable;
