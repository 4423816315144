import styled from "styled-components";
import { ReactComponent as FormsIcon } from "assets/icons/note.svg";

interface MenuItemProps {
  active: boolean;
}

const SidebarWrapper = styled.aside`
  height: calc(100vh - 57px);
  margin-top: 57px;
  width: 270px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: left;
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  box-shadow: 2px 0px 5px rgba(0, 120, 226, 0.15);
  .icon {
    width: 45px;
  }
  .dashboard-mode-switch {
    position: absolute;
    bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    cursor: pointer;
    color: #0f9af0;
    i {
      margin-right: 10px;
    }
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const SidebarMenuList = styled.ul`
  list-style-type: none;
  margin-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;
`;

const SidebarMenuListItem = styled.li<MenuItemProps>`
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  margin: 10px 0px;
  background: ${(props) => (props.active ? "#D9EFFF" : "#fff")};
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;

  a,
  span.blocked {
    display: flex;
    align-items: center;
    text-decoration: none;
    line-height: 13px;
    height: 46px;
    width: 100%;
    padding: 10px;
    color: ${(props) => (props.active ? "#0f9af0" : "#002e46")};
  }

  svg {
    margin-right: 15px;
  }

  small {
    background: #0faff0;
    border-radius: 34px;
    font-weight: 400;
    font-size: 10px;
    line-height: 120%;
    color: #ffffff;
    padding: 4px 12px;
    display: inline-block;
    position: absolute;
    top: 12px;
    right: 0;
  }

  &:hover {
    background: #d9efff;
  }

  &.upgrade {
    cursor: pointer;
  }
`;

export const ExternalMenuListItem = styled(SidebarMenuListItem)`
  &:hover {
    a {
      color: #0f9af0;
    }

    .external-wrapper svg {
      fill: #0f9af0;
    }
  }

  span.icon {
    svg {
      font-size: 24px;
    }
  }

  span.external-wrapper {
    font-size: 14px;
    display: flex;

    svg {
      width: 11px;
      height: 11px;
      display: inline-block;
      margin: 0 0 0 2px;
    }
  }
`;

const FormIcon = styled(FormsIcon)<{ stroke: string }>`
  path {
    stroke: ${(props) => props.stroke};
  }
`;

export { SidebarWrapper, SidebarMenuList, SidebarMenuListItem, FormIcon };
