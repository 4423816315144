import styled from "styled-components";

export const EmptyStateWrapper = styled.div`
  margin-top: 200px;
  background: transparent;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans";
    align-items: center;

    svg {
      height: 106px;
      width: 99px;
    }

    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      color: #222222;
      margin: 0 0 12px;

      @media (min-width: 768px) {
        font-size: 36px;
      }

      @media (min-width: 768px) {
        font-size: 48px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #667085;
      margin: 0 0 24px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
    button {
      display: flex;
      width: 227px;
      padding: 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
`;

export const Container = styled.div`
  h1 {
    color: #333;
    font-family: DM Sans;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px; /* 126.667% */
  }

  .tag {
    display: flex;
    width: 32px;
    height: 32px;
    //padding: 6px 19px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 57px;
    background: #0d97cd;
    color: #fff;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 19.2px */
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .ant-form {
      margin-bottom: 0;
    }
    .select-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;
      flex: 1;

      > span {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 120%;
        color: #333333;
      }

      .ant-select {
        min-width: 162px;

        .ant-select-selector {
          height: 100%;
        }
      }
    }
    .ant-input {
      display: flex;
      justify-content: flex-end;

      min-width: 290px;
      @media (min-width: 768px) {
        width: 340px;
      }
      @media (min-width: 1024px) {
        width: 490px;
      }
    }
  }
`;

export const FormsTableBody = styled.div`
  margin-top: 51px;
  border-radius: 8px;
  border-radius: 8px;

  .ant-card {
    box-shadow: none !important;

    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }

  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }

  .ant-table-wrapper {
    border-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table {
    border-radius: 8px;

    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }

    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }

    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }

    tr {
      td:first-child,
      th:first-child {
        padding: 10px 20px;
      }
    }

    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
    }

    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;

      .flex {
        display: flex;
        gap: 10px;

        button {
          background: transparent;
          box-shadow: none;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 20px;
        }
      }

      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }

      .action {
        img {
          margin-left: 10px;
        }
      }

      p {
        margin-bottom: 5px;
      }

      span.subtext {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }

  .preferred-date {
    display: flex;
    flex-direction: column;
    gap: 6px;

    > span:nth-of-type(2) {
      color: #0d97cd;
    }
  }

  .unlock {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #0faff0;
    font-weight: 500;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }

    svg {
      height: 20px;
      width: 20px;
      stroke: #0f9af0;
    }
  }

  .custom-pagination {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 0 12px 12px;
  }
`;
