import styled from "styled-components";

interface SelectProps {
  marginBottom: number;
  mode?: string;
  multiple?: boolean;
}

const SelectWrapper = styled.div<SelectProps>`
  label,
  .ant-form-item-label {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
    margin-bottom: 5px;
  }
  /* .ant-select-selection-overflow {
        display: none;
    } */
  .ant-form-item-has-error
    .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
    .ant-select-selector {
    border-color: #bfcad0 !important;
  }
  .ant-select {
    &.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      box-shadow: none;
    }
    .ant-select-selector {
      border: none;
      margin-top: 0px;
      min-height: 45px;
      ${(props) => `
                padding: ${props.mode === "normal" ? "7px 8px" : "10px"};
                border-bottom: ${
                  props.mode === "normal" ? "" : "1px solid #BFCAD0"
                };
                border: ${props.mode === "normal" ? "1px solid #BFCAD0" : ""};
                border-radius: ${props.mode === "normal" ? "8px" : "0px"};
                &:focus {
                    box-shadow: none;
                    ${
                      props.mode === "normal" ? "border: 1px solid #0f9af0" : ""
                    };
                }
            `}

      .ant-select-selection-search {
        left: ${(props) =>
          props.mode === "normal" ? (props.multiple ? "3px" : "7px") : "0px"};
        display: flex;
        align-items: center;
        margin: 0px;
        padding: 0px 0px;
      }
    }
  }
  .ant-form-item {
    margin-bottom: ${(props) =>
      `${!isNaN(props.marginBottom) ? props.marginBottom : 24}px`};
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-family: "DM Sans";
    font-weight: normal;
    font-size: 13px;
    text-align: left;
  }
`;

export { SelectWrapper };
