import React, { useEffect, useState } from "react";
import { Col, Row, Form, message as AntdMessage } from "antd";
import Button from "../Button";
import { TextArea, StyleModal } from "./style";
import { useUpdateNoteMutation } from "redux/queries/notes";
import { NoteType } from "../../constants/types";
type CreateNote = {
  isEditModalOpen: boolean;
  onClose: () => void;
  patientNotes: NoteType;
  patientId: string;
};

const EditNoteModal = ({
  isEditModalOpen,
  onClose,
  patientNotes,
  patientId,
}: CreateNote) => {
  const { note, id } = patientNotes;
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [noteVal, setNoteVal] = useState("");
  const [updateNote] = useUpdateNoteMutation();
  const [sendMsgForm] = Form.useForm();

  async function editNote() {
    setIsUpdateLoading(true);
    try {
      await updateNote({
        noteId: id,
        note: noteVal,
        patientId,
      });

      onClose();
      AntdMessage.success("Note is update");
      sendMsgForm.resetFields();
    } catch (err) {
      AntdMessage.error("An error occurred. Please try again...");
    } finally {
      setIsUpdateLoading(false);
    }
  }

  useEffect(() => {
    sendMsgForm.setFieldsValue({
      note,
    });
  }, [sendMsgForm, note]);

  return (
    <StyleModal
      title="update note"
      okText="Submit"
      footer={null}
      visible={isEditModalOpen}
      onCancel={onClose}
      style={{ borderRadius: "120px" }}
      forceRender
    >
      <Form
        name="basicForm"
        layout="vertical"
        form={sendMsgForm}
        onFinish={editNote}
      >
        <Row gutter={24}>
          <Col span={24}>
            <TextArea
              formItem={{
                name: "note",
                rules: [
                  {
                    required: true,
                    message: "Please ........",
                  },
                ],
              }}
              mode="normal"
              autoSize={{ minRows: 6, maxRows: 5 }}
              placeholder={"Write note here..."}
              onChange={(e: any) => {
                setNoteVal(e.target.value);
              }}
              value={noteVal}
            />
          </Col>
        </Row>

        <Row
          justify="space-between"
          style={{
            marginTop: "20px",

            width: "100%",
            display: "flex",
          }}
        >
          <Button
            type="secondary"
            style={{
              width: 198,
              height: 48,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            htmlType="submit"
            type="primary"
            disabled={isUpdateLoading}
            style={{ width: 198, height: 48 }}
          >
            {isUpdateLoading ? "Updating..." : "Update note"}
          </Button>
        </Row>
      </Form>
    </StyleModal>
  );
};

export default EditNoteModal;
