import React, { useEffect, useState } from "react";
import { CardContainer, SearchWrapper, Wrapper } from "./style";
import { DatePicker, Input, Spin, Timeline } from "antd";
import Button from "component/Button";
//svg
import { ReactComponent as TimelineIcon } from "assets/icons/Timeline.svg";
import { EditOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";

import CreateNoteModal from "../CreateNoteModal";
import BottomBar from "../BottonBar";
import { NoteType } from "../../constants/types";
import { ReactComponent as ServiceIcon } from "../../assets/icons/healthcare.svg";
import moment from "moment";
import PatientAppointment from "../PatientAppointment";
import EditNoteModal from "../EditNoteModal";
import { ReactComponent as Calendar } from "../../assets/icons/calendar-empty.svg";

type NoteProps = {
  handleOpen: () => void;
  notes: any;
};
const Notes = ({ handleOpen, notes }: NoteProps) => {
  const { patientId }: { patientId: string } = useParams();
  const [filterNote, setFilterNote] = useState<any>();
  const [selectedMonth, setSelectedMonth] = useState("");
  const AntdSearch = Input.Search;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState<NoteType>({
    id: "",
    note: "",
    created_at: "",
    updated_at: ""
  });

  function handelOpen() {
    setIsModalOpen(true);
  }
  function onClick(note: NoteType) {
    setSelectedNote(note);
    setIsEditModalOpen(true);
  }

  useEffect(() => {
    if (notes) {
      setFilterNote([...notes]);
    }
  }, [notes]);
  const onChange = (date: any, dateString: string) => {
    if (date && dateString) {
      const _notes = [...notes];
      setSelectedMonth(dateString);
      setFilterNote(
        _notes.filter((item: NoteType) => {
          const { created_at } = item;

          const newDate = created_at
            ? moment(created_at).format("MMMM Do YYYY").split("/")
            : new Date().toLocaleDateString();
          const newDateStr = newDate[1] + "/" + newDate[0] + "/" + newDate[2];

          return moment(newDateStr).isSame(moment(date), "day");
        })
      );
    }
    if (date === null && dateString === "") {
      setFilterNote([...notes]);
    }
  };

  const handleSearch = (e: any) => {
    const keyword = e.target.value;

    const _followUps = [...notes];

    setFilterNote(() =>
      _followUps?.filter((item: NoteType) =>
        item.note.toLowerCase().includes(keyword.toLowerCase())
      )
    );
  };
  return (
    <>
      <Wrapper>
        {notes?.length ? (
          <SearchWrapper>
            <AntdSearch
              size='large'
              placeholder='Search notes'
              onChange={handleSearch}
            />
          </SearchWrapper>
        ) : null}

        <div className={"grid"}>
          <CardContainer>
            <Timeline>
              {filterNote?.length ? (
                filterNote?.map((note: any) => (
                  <Timeline.Item dot={<TimelineIcon />} key={note.id}>
                    <p className={"time"}>
                      {" "}
                      Created {""}
                      {moment(note.created_at).format("MMMM Do YYYY, h:mm a")}
                    </p>
                    <div className='card'>
                      <p>{note.note}</p>

                      <Button onClick={() => onClick(note)}>
                        <EditOutlined style={{ fontSize: "20px" }} />
                        <span>Update Note</span>
                      </Button>
                    </div>
                  </Timeline.Item>
                ))
              ) : (
                <div className='empty-bookings'>
                  <Calendar />
                  <h4>No Notes </h4>
                  <p>All notes will show here</p>
                </div>
              )}
            </Timeline>
          </CardContainer>

          {/*<PatientAppointment handleOpen={handleOpen} />*/}
        </div>

        <BottomBar text={"Add note"} onClick={handelOpen} />

        <CreateNoteModal
          isModalOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
      </Wrapper>
      {isEditModalOpen && (
        <EditNoteModal
          isEditModalOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          patientNotes={selectedNote}
          patientId={patientId}
        />
      )}
    </>
  );
};

export default Notes;
