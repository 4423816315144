import styled from "styled-components";

export const SearchAndCreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  max-width: 100%;

  @media (min-width: 768px) {
    gap: 8px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 2px;
  }

  & > .ant-col:first-of-type {
    width: 500px;
  }
`;

export const PractitionersTableWrapper = styled.div`
  .input-pane {
    padding: 10px 10px;
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    input {
      height: 45px;
      border-radius: 6px;
    }
  }
`;

export const PractitionersTableBody = styled.div`
  border-radius: 8px;
  border-radius: 8px;
  .ant-dropdown-link.pending {
    text-transform: capitalize;
    color: #ff9359;
  }

  .ant-dropdown-link.dispatched {
    text-transform: capitalize;
    color: #59ca7f;
  }

  .ant-dropdown-link.processing {
    text-transform: capitalize;
    color: #6db8d6;
  }

  .ant-card {
    box-shadow: none !important;
    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }
  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }
  .ant-table-wrapper {
    border-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table {
    border-radius: 8px;
    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }
    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }
    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }
    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }
    tr {
      td:first-child,
      th:first-child {
        padding: 10px 20px;
      }
    }
    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
    }
    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;
      .flex {
        display: flex;
        img {
          margin-right: 10px;
          border: 0.8px solid #0f9af0;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }
      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }
      .action {
        img {
          margin-left: 10px;
        }
      }
      p {
        margin-bottom: 5px;
      }
      span.subtext {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }
`;

export const EmptyStateWrapper = styled.div`
  height: calc(100vh - 200px);
  background: transparent;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans";

    h2 {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      color: #222222;
      margin: 0 0 12px;

      @media (min-width: 768px) {
        font-size: 36px;
      }

      @media (min-width: 768px) {
        font-size: 48px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #667085;
      margin: 0 0 24px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }

    div[type] {
      button.ant-btn {
        display: inline-flex;
        align-items: center;
        gap: 8px;

        &:hover {
          opacity: 0.92;
          background-color: #0f9af1;
          color: #fff;
        }

        @media (min-width: 768px) {
          font-size: 16px;
          height: 45px;
        }
      }
    }
  }
`;
