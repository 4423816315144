import { useEffect, useState } from "react";
import { Col, Row, Form as antDForm } from "antd";
import Form from "antd/lib/form/Form";
import { useHistory } from "react-router-dom";
import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import Input from "../../../component/Input";
import EmailLinkSent from "./EmailLinkSent";
import RecoverPasswordSvg from "../../../assets/icons/recover-password.svg";
import { authSelector, resetPassword, hasResetPassword as setPasswordResetStatus } from "../../../redux/reducers/auth";
import { useDispatch, useSelector } from "react-redux";

const RecoverPassword = () => {
    const history = useHistory();
    const [resetForm] = antDForm.useForm();
    const dispatch = useDispatch();
    const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);
    const { isResettingPassword, hasResetPassword } = useSelector(authSelector);

    const handleReset = () => {
        const email = resetForm.getFieldValue('email');
        dispatch(resetPassword(email))
    };

    useEffect(() => {
        if (hasResetPassword) {
            dispatch(setPasswordResetStatus(false));
            setIsLoginSuccessful(true)
        }
    }, [hasResetPassword])

    return isLoginSuccessful
        ? <EmailLinkSent
            setIsLoginSuccessful={setIsLoginSuccessful}
        />
        : (
            <>
                <Col span={12}>
                    <div className="left-pane">
                        <img className="app-logo" src={AppIcon} alt="logo" />
                        <div className="align-center">
                            <img src={RecoverPasswordSvg} alt="icon" />
                            <h3>Forgot your password?</h3>
                            <Row justify="center">
                                <Col span={18}>
                                    <p>Enter your email and we will send you a link to reset your password</p>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="right-pane">
                        <span className="close-icon" onClick={() => history.goBack()}>
                            <i className="lni lni-arrow-left" /> Back
                        </span>
                        <Form
                            name="basic"
                            layout="vertical"
                            form={resetForm}
                            requiredMark={false}
                            onFinish={handleReset}
                        >
                            <Row gutter={24} align="middle">
                                <Col span={24}>
                                    <Input
                                        formItem={{
                                            label: "Email",
                                            name: "email",
                                            rules: [
                                                { required: true, message: 'Please input your email' }
                                            ]
                                        }}
                                        placeholder="name@mail.com"
                                        type="text"
                                        marginBottom={0}
                                        label="Email"
                                    />
                                </Col>
                                <Col span={24} className="align-center">
                                    <Button disabled={isResettingPassword} htmlType="submit" type="primary" className="auth-button recover-password-button">{isResettingPassword ? 'Submitting...' : 'Submit'}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </>
        )
}

export default RecoverPassword;