import styled from "styled-components";

const LabRequestsDrawerWrapper = styled.div``;

const LabRequestsItemWrapper = styled.div`
  padding: 20px;
  border-bottom: 0.5px solid rgba(78, 102, 126, 0.35);
  &:last-child {
    border-bottom: none;
  }
  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 0px;
  }
  p {
    margin-top: 8px;
    font-size: 13px;
    margin-bottom: 0px;
  }
  i {
    font-size: 13px;
    font-style: italic;
    margin-bottom: 0px;
  }
`;

const LabRequestsDrawerHeader = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  span.title {
    font-size: 13px;
    opacity: 0.7;
    margin-right: 20px;
  }
  .ant-tag-checkable {
    border-radius: 6px;
    padding: 4px 15px;
    opacity: 1;
  }
  ul {
    list-style-type: none;
    list-style: none;
    margin: 0;
    padding: 0px 40px;
    li {
      display: block;
      float: left;
      padding: 20px 10px 10px 10px;
      margin-right: 44px;
      cursor: pointer;
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      text-align: left;
      color: #002e46;
      &.active {
        color: #0f9af0;
        border-bottom: 3px solid #0f9af0;
      }
    }
  }
`;

const LabRequestsDrawerBody = styled.div`
  .investigation-item {
    margin: 0 2px 20px 2px;
    box-shadow: 0px 1px 2px 2px #f2f2f2;
    border-radius: 6px;
    padding: 15px;

    h2 {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #344054;
      margin-bottom: 24px;
    }

    h3 {
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 16px;
      line-height: 120%;
      color: #344054;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 12px;

      > span,
      > small {
        background: transparent;
        border: 1px solid #0faff0;
        border-radius: 4px;
        color: #0faff0;
        min-width: 70px;
        padding: 4px 12px;
        font-weight: 400;
        font-size: 12px;
        cursor: pointer;

        &:hover {
          background: #0faff0;
          color: #fff;
        }
      }

      > small {
        border: none;
        margin-right: 18px;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &:hover {
          background: none;
          color: #0faff0;
        }
      }
    }

    .additional-services {
      margin: 32px 0 16px;

      h4 {
        margin-bottom: 8px;
        display: flex;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        color: #667085;
        padding-right: 32px;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #efefef;
        padding-bottom: 12px;

        span {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #0faff0;
          cursor: pointer;
        }
      }

      .additional-services-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        padding-top: 8px;

        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #0faff0;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 32px;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 120%;
          color: #344054;

          > span:first-of-type {
            font-size: 16px;
            flex: 2;
            padding-right: 16px;
          }

          > span:nth-of-type(2) {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #ff0000;
            cursor: pointer;
          }
        }
      }
    }

    p {
      margin-bottom: 5px;
      font-size: 14px;

      b {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }

      &.name {
        font-size: 16px;
        margin-bottom: 0px;
      }
      &.description {
        font-size: 13px;
        margin-bottom: 10px;
        opacity: 0.8;
      }
      &.price {
        float: right;
      }
    }
    .additional-services {
    }

    .practitioner-name {
      margin: 13px 0;
      padding-top: 24px;
      border-top: 1px solid #e4e7ec;
    }

    span {
      font-size: 13px;
    }

    .tag {
      background: #f2f2f2;
      padding: 5px 10px;
      display: inline-block;
      margin-right: 5px;
      margin-bottom: 5px;
      font-family: "DM Sans";
      word-wrap: break-all;
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      text-align: center;
      color: #096dd9;
      opacity: 0.75;
      border-radius: 10px;
    }

    .details-wrapper {
      display: flex;
      width: 100%;

      p {
        display: flex;
        flex-direction: column;
        gap: 8px;

        span:first-of-type {
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #667085;
          min-width: 140px;
        }

        span:nth-of-type(2) {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
          display: flex;
          align-items: center;
          gap: 4px;
        }
      }
    }

    p.note {
      margin-top: 12px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      span:first-of-type {
        font-weight: 400;
        font-size: 12px;
        line-height: 120%;
        color: #667085;
        min-width: 140px;
      }

      span:nth-of-type(2) {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        display: flex;
        align-items: center;
        gap: 4px;
      }
    }
  }

  .ant-tabs-nav {
    max-width: max-content;
    margin: 0 auto 16px;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
  }

  .actions-wrapper {
    display: flex;
    justify-content: space-around;
    margin: 18px 0 26px;
    padding-bottom: 36px;
    border-bottom: 1px solid #f0f0f0;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      transition: all 0.25s ease;

      button {
        width: 60px;
        height: 60px;
        background: #c8e8fb;
        border-radius: 54px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          font-size: 18px;
        }
      }

      span {
        font-size: 14px;
        line-height: 103.4%;
        text-align: center;
        color: #1b2d4a;
      }

      &:hover {
        button {
          svg {
            transform: scale(1.04);
          }
        }

        span {
          text-decoration: underline;
        }
      }
    }
  }

  .empty-followups,
  .followups-loading,
  .followups-error {
    margin-top: 36px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h4 {
      max-width: 280px;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
      color: #222222;
      margin: 24px auto;
    }
  }

  h4.followups-header {
    font-weight: 700;
    font-size: 20px;
    margin: 0 0 24px;
    line-height: 20px;
    color: #344054;
  }

  .single-followup {
    border: 1.5px solid #e4e7ec;
    padding: 24px;
    border-radius: 12px;
    margin-bottom: 20px;

    h4 {
      font-size: 18px;

      span {
        font-size: 12px;
        font-weight: 500;
        line-height: 100%;
        color: #08873d;
        width: 97px;
        height: 25px;
        background: #98f1be;
        border-radius: 34px;
        padding: 4px 10px;

        &.cancelled {
          background: #eb5757;
          color: #ffb8b8;
        }
      }
    }

    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 8px;

      span {
        color: #344054;
        font-weight: 500;
      }
    }

    .btn-wrapper {
      margin-top: 32px;
      display: flex;
      gap: 16px;
    }
  }

  .actions {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px dashed #e4e7ec;

    .btns {
      margin-top: 15px;
      display: flex;
      gap: 20px;
    }
  }

  .response {
    display: inline-flex;
    padding: 18px 23px 37px 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    border-radius: 8px;
    border: 1.5px solid #e4e7ec;
    background: #fff;
    width: 100%;

    &__form-name {
      color: #344054;
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 0;
    }
    span {
      color: #344054;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }

    &__body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;

      &-single {
        max-width: 280px;
        display: flex;
        align-items: flex-start;
        gap: 9px;

        .number {
          display: flex;
          width: 30px;
          height: 30px;
          justify-content: center;
          align-items: center;
          gap: 12px;
          border-radius: 68.4px;
          background: #0d97cd;
          padding: 5px 10px;
          flex: 1;

          span {
            color: #fff;
            font-family: DM Sans;
            font-size: 14.4px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
          }
        }

        .question {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 7px;
          h5 {
            color: #101828;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 22.465px;
            margin: 0;
          }

          .answer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 7px;
            div {
              display: flex;
              align-items: flex-start;
              gap: 10px;
              p {
                color: #344054;
                font-family: DM Sans;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

export {
  LabRequestsDrawerWrapper,
  LabRequestsDrawerHeader,
  LabRequestsDrawerBody,
  LabRequestsItemWrapper,
};
