import { useState } from "react";
import {
  Row,
  Col,
  Select as AntdSelect,
  Form,
  message as AntdMessage,
  message,
} from "antd";
import firebase from "firebase";
import moment from "moment";
import OtpInput from "react-otp-input";

import { RegisterUserValues } from "constants/types";
import { db } from "utils/firebase";

import Button from "component/Button";
import Input from "component/Input";
import Select from "component/Select";
import { DateOfBirthPicker } from "component/DatePicker/styles";
import { handlePhoneNumberFormat } from "utils/utils";

import { ModalWrapper, FormItem } from "layouts/DashboardLayout/styles";
import {
  createUser,
  setSelectedUser,
  getPatients,
  requestPatientAccess,
  approvePatientAccessRequest,
} from "redux/reducers/users";
import { useAppDispatch } from "redux/store";

type CreatePatientModalTypes = {
  isModalVisible: boolean;
  handleCancel: () => void;
};

function CreatePatientModal({
  handleCancel,
  isModalVisible,
}: CreatePatientModalTypes) {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [noEmail, setNoEmail] = useState(false);
  const [noDOB, setNoDOB] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [formPage, setFormPage] = useState<
    "newUser" | "requestAccess" | "approveAccess"
  >("newUser");

  const [createPatientForm] = Form.useForm();

  function formatPhoneNumber(
    number: string,
    numberDetails: { dialCode: string }
  ) {
    const formattedPhone = handlePhoneNumberFormat(
      number,
      numberDetails.dialCode
    );

    setFormattedPhone(formattedPhone);
  }

  function handleNoEmailChange() {
    setNoEmail((prev) => !prev);
    if (!noEmail) {
      createPatientForm.setFieldsValue({ email: "" });
    }
  }

  function handleNoDOBChange() {
    setNoDOB((prev) => !prev);
    if (!noEmail) {
      createPatientForm.setFieldsValue({ dob: moment("01/01/2004") });
    }
  }

  async function handleCreatePatient(values: any) {
    setIsLoading(true);

    if (formPage === "newUser") {
      const _values = values as RegisterUserValues;

      _values.email = noEmail
        ? `frontdesk${formattedPhone}@pneuma.care`
        : _values.email;

      _values.phone = formattedPhone;

      _values.dob = noDOB
        ? moment("01/01/2004")
        : moment(_values.dob, "DD/MM/YYYY");

      const localStoreProviderID = window.localStorage.getItem("providerID")!;

      //check provider's patient collection for user's email - if it exists, throw an appropriate error, else continue with createUser flow.
      let doesExist = false,
        doesExistInGeneral = false;

      try {
        const patientsRef = db
          .collection("providerInfo")
          .doc(localStoreProviderID)
          .collection("patients")
          .where("email", "==", _values.email);

        doesExist = !!(await patientsRef.get()).docs.length;

        const generalPatientsRef = db
          .collection("patients")
          .where("email", "==", _values.email);

        doesExistInGeneral = !!(await generalPatientsRef.get()).docs.length;
        const generalPatientInfo = (await generalPatientsRef.get()).docs[0];

        //If not in provider patient collection, but in general patient collection.. add to provider patient collection & move on as existing user.
        if (doesExist === false) {
          if (doesExistInGeneral) {
            // if patient exist in general collection, add to local provider collection
            await db
              .collection("providerInfo")
              .doc(localStoreProviderID)
              .collection("patients")
              .doc(generalPatientInfo.data().id)
              .set({
                ...generalPatientInfo.data(),
                created_at: firebase.firestore.FieldValue.serverTimestamp(),
              });

            // update selected user state to newly created user.

            // dispatch(
            //   setSelectedUser({
            //     dob: generalPatientInfo.data().dob,
            //     //   email: generalPatientInfo.data().email,
            //     //   first_name: generalPatientInfo.data().first_name,
            //     //   gender: generalPatientInfo.data().gender,
            //     //   id: generalPatientInfo.data().id,
            //     //   last_name: generalPatientInfo.data().last_name,
            //     //   phone: generalPatientInfo.data().phone,
            //     //   patientId: generalPatientInfo.data().patientId,
            //   })
            // );
          } else {
            // else..create fresh user
            await dispatch(createUser(_values));
          }

          await dispatch(getPatients());
          AntdMessage.success("Patient created successfully!");
          createPatientForm.resetFields();
          handleCancel();
        } else {
          AntdMessage.error("Patient already exists!");
        }
      } catch (err) {
        const _err = err as Error;
        setFormPage("requestAccess");
        AntdMessage.error(_err.message);
      } finally {
        setIsLoading(false);
      }
    } else if (formPage === "requestAccess") {
      const _values = values as { email: string };
      try {
        await dispatch(requestPatientAccess(_values.email));
        message.success("Code sent! Check your email for your OTP code.");
        setFormPage("approveAccess");
      } catch (err: any) {
        message.error(err.message);
      } finally {
        setIsLoading(false);
      }
    } else if (formPage === "approveAccess") {
      try {
        await dispatch(approvePatientAccessRequest(otp));
        message.success("User account setup successfully!");
        createPatientForm.resetFields();
        handleCancel();
      } catch (err: any) {
        message.error(err.message);
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <ModalWrapper
      title={formPage === "newUser" ? "Create a new patient" : "Request Access"}
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className="dashboard-mode-modal"
    >
      <Form
        name="createPatientForm"
        layout="vertical"
        form={createPatientForm}
        onFinish={handleCreatePatient}
      >
        <Row gutter={24}>
          {formPage === "newUser" ? (
            <>
              <Col xs={24} sm={12}>
                <Input
                  formItem={{
                    name: "first_name",
                    label: "First name",
                    rules: [
                      {
                        required: true,
                        message: "Please enter first name",
                      },
                    ],
                  }}
                  label=""
                  mode="normal"
                />
              </Col>
              <Col xs={24} sm={12}>
                <Input
                  formItem={{
                    name: "last_name",
                    label: "Last name",
                    rules: [
                      {
                        required: true,
                        message: "Please enter last name",
                      },
                    ],
                  }}
                  mode="normal"
                />
              </Col>
              <Col span={24}>
                <Row gutter={24}>
                  <Col span={24} md={12} style={{ position: "relative" }}>
                    <Input
                      formItem={{
                        label: "Email address",
                        name: "email",
                        rules: [
                          {
                            required: !noEmail,
                            message: `${
                              noEmail ? "" : "Please input your email address"
                            }`,
                          },
                          {
                            type: "email",
                            message: noEmail
                              ? ""
                              : "Please input a valid email",
                          },
                        ],
                      }}
                      mode="normal"
                      type="email"
                      disabled={noEmail}
                      value={""}
                    />
                    <div
                      className="email-checkbox"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "120px",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <input
                        id="email-checkbox"
                        type="checkbox"
                        checked={noEmail}
                        onChange={() => handleNoEmailChange()}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        htmlFor="email-checkbox"
                        style={{ cursor: "pointer", fontSize: "12px" }}
                      >
                        Not available?
                      </label>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <FormItem
                      label="Date of Birth"
                      name="dob"
                      rules={[
                        {
                          required: !noDOB,
                          message: "Please enter date of birth",
                        },
                      ]}
                    >
                      <DateOfBirthPicker
                        clearIcon={null}
                        disableCalendar={true}
                        format="dd/MM/yyyy"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        disabled={noDOB}
                        maxDate={
                          new Date(
                            new Date().setFullYear(new Date().getFullYear())
                          )
                        }
                      />
                    </FormItem>

                    <div
                      className="dob-checkbox"
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "120px",
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                      }}
                    >
                      <input
                        id="dob-checkbox"
                        type="checkbox"
                        checked={noDOB}
                        onChange={() => handleNoDOBChange()}
                        style={{ cursor: "pointer" }}
                      />{" "}
                      <label
                        htmlFor="dob-checkbox"
                        style={{ cursor: "pointer", fontSize: "12px" }}
                      >
                        Not available?
                      </label>
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Select
                      formItem={{
                        name: "gender",
                        label: "Gender",
                        rules: [
                          {
                            required: true,
                            message: "Please select gender",
                          },
                        ],
                      }}
                      mode="normal"
                    >
                      {["male", "female"].map((option, index) => {
                        return (
                          <AntdSelect.Option key={index} value={option}>
                            <span style={{ textTransform: "capitalize" }}>
                              {option}
                            </span>
                          </AntdSelect.Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Input
                      formItem={{
                        name: "phone",
                        label: "Phone number",
                        rules: [
                          {
                            required: true,
                            message: "Please enter your phone number",
                          },
                          {
                            required: true,
                            pattern: /[0-9]{11}$/,
                            message: "Please enter a valid phone number",
                          },
                        ],
                      }}
                      type="phone"
                      mode="normal"
                      onChange={formatPhoneNumber}
                    />
                  </Col>
                  <Col xs={24} sm={12}>
                    <Input
                      formItem={{
                        name: "patientId",
                        label: "Patient ID",
                      }}
                      type="text"
                      mode="normal"
                      style={{ textTransform: "uppercase" }}
                    />
                  </Col>
                </Row>
              </Col>
            </>
          ) : formPage === "requestAccess" ? (
            <>
              <Col span={24}>
                <Input
                  formItem={{
                    label: "Email address",
                    name: "email",
                    rules: [
                      {
                        required: true,
                        message: "Please input your email address",
                      },
                      {
                        type: "email",
                        message: "Please input a valid email",
                      },
                    ],
                  }}
                  mode="normal"
                  type="email"
                />
              </Col>
            </>
          ) : formPage === "approveAccess" ? (
            <>
              <Col span={24}>
                <h4 className="otp-label">
                  <span>*</span> Enter your OTP
                </h4>
                <OtpInput
                  value={otp}
                  onChange={setOtp}
                  numInputs={8}
                  className="otp-input"
                  focusStyle={{ border: "2px solid #0f9af0" }}
                />
              </Col>
            </>
          ) : null}

          <Col
            span={24}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {formPage !== "newUser" ? (
              <Button
                type="secondary"
                onClick={() => setFormPage("newUser")}
                style={{ marginRight: "16px" }}
              >
                Go back
              </Button>
            ) : null}

            {formPage === "newUser" ? (
              <Button htmlType="submit" type="secondary" disabled={isLoading}>
                {isLoading ? "Creating..." : "Create Patient"}
              </Button>
            ) : (
              <Button htmlType="submit" type="primary" disabled={isLoading}>
                {isLoading ? "Requesting..." : "Request Access"}
              </Button>
            )}
          </Col>
        </Row>
      </Form>
    </ModalWrapper>
  );
}

export default CreatePatientModal;
