import styled from "styled-components";

const FollowUpsTableWrapper = styled.div`
  .input-pane {
    padding: 10px 10px;
    border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    input {
      height: 45px;
      border-radius: 6px;
    }
  }
`;

const FollowUpsTableBody = styled.div`
  border-radius: 8px;
  border-radius: 8px;
  .ant-dropdown-link.pending {
    text-transform: capitalize;
    color: #ff9359;
  }

  .ant-dropdown-link.dispatched {
    text-transform: capitalize;
    color: #59ca7f;
  }

  .ant-dropdown-link.processing {
    text-transform: capitalize;
    color: #6db8d6;
  }

  .ant-card {
    box-shadow: none !important;
    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }
  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }
  .ant-table-wrapper {
    border-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  .ant-table {
    border-radius: 8px;
    .odd-row td.ant-table-cell {
      background: #0f9af01a;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }
    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
    }
    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }
    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 16px;
      border-bottom: 0.5px solid #1890ff;
    }
    tr {
      td:first-child,
      th:first-child {
        padding: 10px 20px;
      }
    }
    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
    }
    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;
      .flex {
        display: flex;
        img {
          margin-right: 10px;
          border: 0.8px solid #0f9af0;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }
      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }
      .action {
        img {
          margin-left: 10px;
        }
      }
      p {
        margin-bottom: 5px;
      }
      span.subtext {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }
`;

export const SearchAndCreateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;

  @media (min-width: 768px) {
    gap: 8px;
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: 2px;
  }

  & > .ant-col:first-of-type {
    width: 500px;
  }
`;

export const DrawerHeader = styled.h5`
  margin-bottom: 0;
  font-weight: 700;
  font-size: 20px;
  line-height: 38px;
  color: "#333333";
`;

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 18px 0 26px;
  padding-bottom: 36px;
  border-bottom: 1px solid #f0f0f0;

  & > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    transition: all 0.25s ease;

    button {
      width: 60px;
      height: 60px;
      background: #c8e8fb;
      border-radius: 54px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        font-size: 18px;
      }
    }

    span {
      font-size: 14px;
      line-height: 103.4%;
      text-align: center;
      color: #1b2d4a;
    }

    &:hover {
      button {
        svg {
          transform: scale(1.04);
        }
      }

      span {
        text-decoration: underline;
      }
    }
  }
`;

export const FollowUpInfo = styled.div`
  padding: 24px 8px;
  border-radius: 12px;
  margin-bottom: 20px;

  h4 {
    font-size: 20px;

    span {
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
      color: #08873d;
      width: 97px;
      height: 25px;
      background: #98f1be;
      border-radius: 34px;
      padding: 4px 10px;

      &.cancelled {
        background: #eb5757;
        color: #ffb8b8;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 8px;

    span {
      color: #344054;
      font-weight: 500;
    }
  }

  .btn-wrapper {
    margin-top: 32px;
    display: flex;
    gap: 16px;
  }
`;

export const LoadingWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  min-height: 100%;
  width: 100%;
  display: grid;
  place-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;
export { FollowUpsTableWrapper, FollowUpsTableBody };
