import { Col, Drawer, Row, message as AntdMessage, Tabs, Spin } from "antd";
import axios from "axios";
import {
  LoadingOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { send } from "emailjs-com";

import {
  LabRequestsDrawerWrapper,
  LabRequestsDrawerBody,
  LoadingWrapper,
} from "./styles";
import { capitaliseFirstLetter } from "utils/utils";
import Button from "component/Button";
import { profileSelector } from "redux/reducers/profile";
import CreateAppointmentModal from "component/CreateAppointmentModal";
import { useSendSingleMessageMutation } from "redux/queries/messages";
import { useAppSelector, useAppDispatch } from "redux/store";
import {
  updateOrderMetadata,
  fetchAllServiceOrders,
} from "redux/reducers/requests";
import { MixPanel } from "utils/mixpanel";

// svg
import { ReactComponent as AlarmIcon } from "assets/icons/reminder-alarm.svg";
import { ReactComponent as FeedbackIcon } from "assets/icons/feedback-request.svg";
import { ReactComponent as AppointmentIcon } from "assets/icons/appointment-new.svg";
import { ReactComponent as CreateFollowUpIcon } from "assets/icons/create-follow-up.svg";
import { ReactComponent as SendMessageIcon } from "assets/icons/send-message.svg";
import { ReactComponent as NoFollowUpIcon } from "assets/icons/message-lg.svg";
import { ReactComponent as CalendarIcon } from "assets/icons/calendar.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as NotesIcon } from "assets/icons/notepad.svg";
import { ReactComponent as CheckIcon } from "assets/icons/check.svg";
import { UserType } from "constants/types";
import SendSMSModal from "component/SendSMSModal";
import AddFollowUpModal from "component/AddFollowUpModal";
import EditAdditionalServicesModal from "component/EditAdditonalService";
import UpdatePractitioner from "component/UpdatePractitioner";
import { fetchPractitioners } from "redux/slices/practitioners";
import {
  useGetBookingsFollowUpsQuery,
  useUpdateFollowUpMutation,
} from "redux/queries/follow-ups";
import { useGetSingleServiceFormsResponseQuery } from "../../../../redux/queries/service-forms";

const LabRequestsDrawer = (props: any) => {
  const dispatch = useAppDispatch();
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription,
  );
  const { practitioners } = useAppSelector((state) => state.practitioners);

  useEffect(() => {
    dispatch(fetchPractitioners());
  }, [dispatch]);

  const { profile: ProviderProfile } = useSelector(profileSelector);
  const { servicePlans } = useAppSelector((state) => state.servicePlans);
  const { selectedLabRequest, isVisible, onClose } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isServicesModalVisible, setIsServicesModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState<any>();
  const [updateServiceLoading, setUpdateServiceLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState<UserType>();
  const [selectedUserSMSId, setselectedUserSMSId] = useState<string>();
  const [isSMSModalVisible, setIsSMSModalVisible] = useState(false);
  const [isFollowUpEdit, setIsFollowUpEdit] = useState(false);
  const [selectedFollowUp, setSelectedFollowUp] = useState<any>();
  const [followUpModalVisible, setFollowUpModalVisible] = useState(false);
  const [isCreateAppointmentModalVisible, setIsCreateAppointmentModalVisible] =
    useState(false);

  const [isAssignPracModal, setIsAssignPracModal] = useState(false);
  const [selectedPracService, setSelectedPracService] = useState<any>();
  const [sendSingleMessage] = useSendSingleMessageMutation();
  let responseId = selectedLabRequest
    ? selectedLabRequest[0]?.metadata?.form_response
    : "";
  const {
    data,
    isLoading: isResponseLoading,
    error,
  } = useGetSingleServiceFormsResponseQuery({ responseId });

  let responses = data?.data[0];
  // follow-ups
  const {
    data: followUpData,
    isLoading: followUpLoading,
    error: followUpError,
  } = useGetBookingsFollowUpsQuery({
    bookingId: selectedLabRequest ? (selectedLabRequest[0]?.id as string) : "",
  });
  const [updateFollowUp] = useUpdateFollowUpMutation();

  async function completeFollowUp(id: string) {
    setIsLoading(true);
    try {
      await updateFollowUp({
        data: {
          status: "completed",
        },
        followUpId: id,
      }).unwrap();

      AntdMessage.success("Follow up completed successfully!");
      onClose();
    } catch (err) {
      AntdMessage.error("Unable to complete follow up. Please try again");
    } finally {
      setIsLoading(false);
    }
  }

  async function requestFeedback(
    requestId: string,
    requestName: string,
    user: { first_name: string; email: string; id: string },
  ) {
    // completed emailjs template
    const templateParams = {
      slug: ProviderProfile.slug,
      provider_id: ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      business_email: ProviderProfile.email,
      to_name: `${capitaliseFirstLetter(user?.first_name)}`,
      to_email: user.email,
      patient_id: user.id,
      service: requestName,
      service_id: requestId,
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };
    setIsLoading(true);
    try {
      await send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        "template_feedback",
        templateParams,
      );

      const bitRes = await axios.post(
        "https://api-ssl.bitly.com/v4/shorten",
        {
          long_url: `https://pneumapage.com/${ProviderProfile.id}/${user.id}/${requestId}/review`,
        },
        {
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
          },
        },
      );

      let messageText = `Hello, how would you rate your ${requestName} experience? Click the link below to let us know. ${bitRes.data.link}`;

      await sendSingleMessage([
        {
          message: messageText,
          user: user?.id,
        },
      ]);

      MixPanel.track("MessageSent", {
        messageType: "feedback request",
        wordCount: messageText.length,
        userId: user?.id!,
      });
      AntdMessage.success("Feedback request sent successfully!");
    } catch (err) {
      AntdMessage.error("Unable to send feedback request. Please try again!");
    } finally {
      setIsLoading(false);
    }
  }

  async function sendReminder(
    user: { email: string; first_name: string },
    preferred_date: string,
    planName: string,
    preferred_time?: string,
  ) {
    // reminder emailjs template
    const templateParams = {
      patient_email: user.email,
      first_name: capitaliseFirstLetter(user?.first_name),
      preferred_date: preferred_date,
      preferred_time: preferred_time,
      service: planName,
      business_name: ProviderProfile.business_name,
      business_email: ProviderProfile.email,
      business_phone: ProviderProfile.phone,
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };

    setIsLoading(true);
    try {
      await send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
        "template_reminder",
        templateParams,
      );
      AntdMessage.success("Reminder sent successfully!");
    } catch (err) {
      AntdMessage.error("Unable to send reminder. Please try again!");
    } finally {
      setIsLoading(false);
    }
  }

  async function handleDeleteAdditionalService(id: string) {
    if (selectedService) {
      setUpdateServiceLoading(true);
      try {
        await dispatch(
          updateOrderMetadata(selectedService.id, {
            metadata: {
              ...selectedService?.metadata,
              additionalServices:
                selectedService?.metadata?.additionalServices?.filter(
                  (item: any) => item !== id,
                ),
            },
          }),
        );

        await dispatch(fetchAllServiceOrders());
        AntdMessage.success("Appointment successfully updated");
        onClose();
        setSelectedService(null);
      } catch (err: any) {
        AntdMessage.error(
          err.message || "Unable to update appointment. Please try again.",
        );
      } finally {
        setUpdateServiceLoading(false);
      }
    }
  }

  const { TabPane } = Tabs;

  const LoadingIcon = <LoadingOutlined style={{ fontSize: 36 }} spin />;

  return (
    <Drawer
      title={
        <i>
          #
          {`${
            selectedLabRequest && selectedLabRequest.length
              ? selectedLabRequest[0].id
              : ""
          }`.toUpperCase()}
        </i>
      }
      width="450"
      className="lab-request-drawer"
      placement="right"
      closable={true}
      onClose={onClose}
      visible={isVisible}
    >
      <LabRequestsDrawerWrapper>
        <LabRequestsDrawerBody>
          {selectedLabRequest?.map((request: any, index: number) => {
            const {
              plan: { name },
              note,
              user,
              metadata,
              id,
              status,
            } = request;

            const additionalServices: any[] = [];
            servicePlans.map((plan) => {
              if (metadata?.additionalServices?.includes(plan.id)) {
                additionalServices.push(plan);
              }

              return plan;
            });

            const assignedPractitioner = practitioners.find(
              (item) => item.id === metadata?.assignedPractitioner,
            );

            return (
              <>
                <Tabs centered>
                  <TabPane tab="Booking details" key="1">
                    {status !== "pending" && (
                      <div className="actions-wrapper">
                        {status !== "completed" &&
                          moment(
                            request?.metadata.preferred_date,
                            "DD-MM-YYYY",
                          ).isSameOrAfter(moment(), "D") && (
                            <div
                              onClick={() =>
                                sendReminder(
                                  request?.user,
                                  request?.metadata.preferred_date,
                                  request?.plan.name,
                                  request?.metadata.preferred_time,
                                )
                              }
                            >
                              <button>
                                <AlarmIcon />
                              </button>
                              <span>Send reminder</span>
                            </div>
                          )}

                        {!request.metadata.feedback &&
                          request.status === "completed" && (
                            <div
                              onClick={() =>
                                requestFeedback(
                                  request.id,
                                  request?.plan.name,
                                  request.user,
                                )
                              }
                            >
                              <button>
                                <FeedbackIcon />
                              </button>
                              <span>Request Feedback</span>
                            </div>
                          )}

                        <div
                          onClick={() => {
                            setSelectedUser(request?.user);
                            setIsCreateAppointmentModalVisible(true);
                          }}
                        >
                          <button>
                            <AppointmentIcon />
                          </button>
                          <span>New appointment</span>
                        </div>
                      </div>
                    )}

                    <div className="investigation-item">
                      <h2>Patient details</h2>
                      <Row gutter={20}>
                        <Col span={24}>
                          <p
                            style={{
                              textTransform: "capitalize",
                            }}
                          >
                            <b>Name:</b>{" "}
                            {`${user?.first_name} ${user?.last_name}`}
                          </p>
                          <p style={{ margin: "10px 0" }}>
                            <b>Email Address:</b> {request?.user?.email}
                          </p>
                          <p style={{ margin: "10px 0" }}>
                            <b>Phone Number:</b> {request?.user?.phone}
                          </p>
                        </Col>
                      </Row>
                    </div>

                    <div className="investigation-item">
                      <h2 style={{ textTransform: "capitalize" }}>
                        Appointment
                      </h2>
                      <Row gutter={20}>
                        <Col span={24}>
                          <h3 className="name">
                            {capitaliseFirstLetter(name)}

                            {!additionalServices.length &&
                            status !== "completed" ? (
                              <span
                                onClick={() => {
                                  setSelectedService(request);
                                  setIsServicesModalVisible(true);
                                }}
                              >
                                Add additional services
                              </span>
                            ) : null}
                          </h3>

                          <div className="details-wrapper">
                            {metadata && metadata.preferred_time && (
                              <p>
                                <span>Time</span>
                                <span>
                                  <ClockIcon /> {metadata.preferred_time}
                                </span>
                              </p>
                            )}

                            {metadata && metadata.preferred_date && (
                              <p>
                                <span>Date</span>
                                <span>
                                  <CalendarIcon /> {metadata.preferred_date}
                                </span>
                              </p>
                            )}
                          </div>

                          {note ? (
                            <p className="note">
                              <span>Additional notes</span>
                              <span>
                                <NotesIcon /> {capitaliseFirstLetter(note)}
                              </span>
                            </p>
                          ) : null}

                          {additionalServices.length &&
                          status !== "completed" ? (
                            <div className="additional-services">
                              <h4>
                                Additional services{" "}
                                <span
                                  onClick={() => {
                                    setSelectedService(request);
                                    setIsServicesModalVisible(true);
                                  }}
                                >
                                  <EditOutlined /> Edit
                                </span>
                              </h4>

                              {updateServiceLoading ? (
                                <div
                                  style={{
                                    display: "grid",
                                    alignItems: "center",
                                    padding: "2em 0",
                                  }}
                                >
                                  <Spin />
                                </div>
                              ) : (
                                <div className="additional-services-wrapper">
                                  {additionalServices.map((item) => {
                                    return (
                                      <p key={item.id}>
                                        <span>
                                          {capitaliseFirstLetter(item?.name)}
                                        </span>{" "}
                                        <span
                                          onClick={() => {
                                            setSelectedService(() => request);
                                            handleDeleteAdditionalService(
                                              item.id,
                                            );
                                          }}
                                        >
                                          <DeleteOutlined /> Remove
                                        </span>
                                      </p>
                                    );
                                  })}
                                </div>
                              )}
                            </div>
                          ) : null}

                          {subscribedPlan !== "freemium" &&
                          status !== "completed" ? (
                            <>
                              <h3 className="practitioner-name">
                                Practitioner assigned
                                {!metadata?.assignedPractitioner ? (
                                  <span
                                    onClick={() => {
                                      setSelectedPracService(request);
                                      setIsAssignPracModal(true);
                                    }}
                                  >
                                    Assign practitioner
                                  </span>
                                ) : (
                                  <small
                                    onClick={() => {
                                      setSelectedPracService(request);
                                      setIsAssignPracModal(true);
                                    }}
                                  >
                                    <EditOutlined /> Edit
                                  </small>
                                )}
                              </h3>

                              <>
                                {assignedPractitioner ? (
                                  <p style={{ margin: 0 }}>
                                    <b>Practitioner: </b>
                                    {`${assignedPractitioner.title} ${assignedPractitioner.name}`}
                                  </p>
                                ) : null}
                              </>
                            </>
                          ) : null}
                        </Col>
                      </Row>
                    </div>

                    {request?.metadata &&
                      request?.metadata.payment_method === "insurance" && (
                        <div className="investigation-item">
                          <h2>Insurance Details</h2>

                          <Row gutter={20}>
                            <Col span={24}>
                              <p style={{ margin: "10px 0" }}>
                                <b>Provider:</b>{" "}
                                {request?.metadata.insurance_details.provider}
                              </p>

                              <p
                                style={{
                                  margin: "10px 0",
                                }}
                              >
                                <b>Enrollment ID:</b>{" "}
                                <span style={{ textTransform: "uppercase" }}>
                                  {request?.metadata.insurance_details.user_id}
                                </span>
                              </p>
                            </Col>
                          </Row>
                        </div>
                      )}

                    {request?.metadata && request?.metadata.feedback && (
                      <div className="investigation-item">
                        <h2>Patient Feedback</h2>

                        <Row gutter={20}>
                          <Col span={24}>
                            {request?.metadata.rating && (
                              <p
                                style={{
                                  margin: "10px 0",
                                }}
                              >
                                <b>Rating:</b>{" "}
                                <span style={{ textTransform: "uppercase" }}>
                                  {request?.metadata.rating} / 5
                                </span>
                              </p>
                            )}

                            <p style={{ margin: "10px 0" }}>
                              <b>Review:</b> {request?.metadata.feedback}
                            </p>
                          </Col>
                        </Row>
                      </div>
                    )}
                  </TabPane>

                  {subscribedPlan !== "freemium" ? (
                    <TabPane tab="Follow up" key="2">
                      <div className="actions-wrapper">
                        <div onClick={() => setFollowUpModalVisible(true)}>
                          <button>
                            <CreateFollowUpIcon />
                          </button>
                          <span>Schedule follow-up</span>
                        </div>

                        <div
                          onClick={() => {
                            setIsSMSModalVisible(true);
                            setselectedUserSMSId(user.id);
                          }}
                        >
                          <button>
                            <SendMessageIcon />
                          </button>
                          <span>Send Message (SMS)</span>
                        </div>
                      </div>

                      {followUpLoading && (
                        <div className="followups-loading">
                          <Spin indicator={LoadingIcon} />
                        </div>
                      )}

                      {followUpError && (
                        <div className="followups-error">
                          <h4>{followUpError}</h4>
                        </div>
                      )}

                      {!followUpLoading && !followUpData?.data?.length && (
                        <div className="empty-followups">
                          <NoFollowUpIcon />
                          <h4>
                            No follow up to {name} service for{" "}
                            {capitaliseFirstLetter(user?.first_name)}{" "}
                            {capitaliseFirstLetter(user?.last_name)}
                          </h4>

                          <Button
                            type="primary"
                            onClick={() => setFollowUpModalVisible(true)}
                          >
                            Schedule follow up
                          </Button>
                        </div>
                      )}

                      {!followUpLoading && followUpData?.data?.length
                        ? followUpData.data.map((followup) => (
                            <div className="single-followup">
                              <h4>
                                {capitaliseFirstLetter(followup?.goal)}{" "}
                                {followup.status === "completed" && (
                                  <span>Completed</span>
                                )}
                                {followup.status === "cancelled" && (
                                  <span className="cancelled">Cancelled</span>
                                )}
                              </h4>

                              <p>
                                {moment(followup.date).format(
                                  "ddd, MMMM Do, YYYY",
                                )}
                              </p>
                              {followup.note ? (
                                <p>{capitaliseFirstLetter(followup?.note)}</p>
                              ) : null}

                              {followup.status === "pending" && (
                                <div className="btn-wrapper">
                                  <Button
                                    type="secondary"
                                    onClick={() => {
                                      setIsFollowUpEdit(true);
                                      setSelectedFollowUp(followup);
                                      setFollowUpModalVisible(true);
                                    }}
                                  >
                                    Edit
                                  </Button>
                                  <Button
                                    type="primary"
                                    onClick={() =>
                                      completeFollowUp(followup.id)
                                    }
                                  >
                                    Complete follow up
                                  </Button>
                                </div>
                              )}
                            </div>
                          ))
                        : null}
                    </TabPane>
                  ) : null}

                  {responseId ? (
                    <TabPane tab="Response" key="3">
                      <div className={"response"}>
                        <h1 className="response__form-name">
                          {responses?.form?.name}
                        </h1>
                        <span>Responses</span>

                        <div className="response__body">
                          {responses?.responses?.map(
                            (item: any, index: number) => (
                              <div
                                className="response__body-single"
                                key={index}
                              >
                                <div className="number">
                                  <span>{item?.question}</span>
                                </div>
                                <div className="question">
                                  <h5>{item.question_text}</h5>
                                  {Array.isArray(item.value) ? (
                                    <div className="answer">
                                      {item.value.map(
                                        (response: any, index: number) => (
                                          <div key={index}>
                                            <CheckIcon />
                                            <p>{response}</p>
                                          </div>
                                        ),
                                      )}
                                    </div>
                                  ) : (
                                    <div className="answer">
                                      <div>
                                        {/*<CheckIcon />*/}
                                        <p>{item.value}</p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ),
                          )}
                        </div>
                      </div>
                    </TabPane>
                  ) : null}
                </Tabs>
              </>
            );
          })}
        </LabRequestsDrawerBody>

        {isLoading && (
          <LoadingWrapper>
            <Spin indicator={LoadingIcon} />
          </LoadingWrapper>
        )}
      </LabRequestsDrawerWrapper>

      <SendSMSModal
        isModalVisible={isSMSModalVisible}
        handleCancel={() => setIsSMSModalVisible(false)}
        selectedUserID={selectedUserSMSId!}
      />

      <CreateAppointmentModal
        isModalVisible={isCreateAppointmentModalVisible}
        handleCancel={() => setIsCreateAppointmentModalVisible(false)}
        selectedUser={selectedUser!}
      />

      <EditAdditionalServicesModal
        selectedAppointment={selectedService}
        isModalVisible={isServicesModalVisible}
        onClose={() => {
          setIsServicesModalVisible(false);
          onClose();
        }}
      />

      <UpdatePractitioner
        isModalVisible={isAssignPracModal}
        onClose={() => {
          setIsAssignPracModal(false);
          onClose();
        }}
        selectedBooking={selectedPracService}
      />

      <AddFollowUpModal
        isModalVisible={followUpModalVisible}
        handleCancel={() => {
          setIsFollowUpEdit(false);
          setFollowUpModalVisible(false);
        }}
        serviceOrder={selectedLabRequest}
        followUps={selectedFollowUp}
        isEdit={isFollowUpEdit}
      />
    </Drawer>
  );
};

export default LabRequestsDrawer;
