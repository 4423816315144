import styled from "styled-components";

interface TextAreaProps {
  marginBottom: number;
}

const TextAreaWrapper = styled.div<TextAreaProps>`
  label,
  .ant-form-item-label {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
    margin-bottom: 5px;
  }
  .ant-input {
    border-radius: 8px;
    background: #fff;
    border: 1px solid rgba(0, 46, 70, 0.25);
    padding: 10px;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      font-family: "DM Sans";
      font-weight: normal;
      font-size: 13px;
      text-align: left;
      color: #4e667e;
    }
  }
  .ant-form-item {
    margin-bottom: ${(props) =>
      `${!isNaN(props.marginBottom) ? props.marginBottom : 24}px`};
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-family: "DM Sans";
    font-weight: normal;
    font-size: 10px;
    text-align: left;
    color: rgba(0, 46, 70, 0.75);
  }
  .ant-input-textarea-show-count::after {
    font-family: "DM Sans";
    font-weight: normal;
    font-size: 10px;
    color: #0f9af0;
  }
`;

export { TextAreaWrapper };
