import { useState } from "react";
import { ContentWrapper, TabWrapper } from "./styles";
import { generatePath, NavLink } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import { ReactComponent as ArrowLeftIcon } from "assets/icons/arrow-left.svg";
import { Tabs } from "antd";
import Questions from "./questions";
import Preview from "./Preview";

const CreateForm = () => {
  const [activeTab, setActiveTab] = useState("questions");
  const [isPreviewQuestion, setIsPreviewQuestion] = useState<any>({
    name: "",
    questions: [],
    icon: "",
    description: ""
  });

  return (
    <ContentWrapper>
      <NavLink to={generatePath(ROUTES.FORMS)} className='return-btn'>
        <ArrowLeftIcon />
        <span>Back</span>
      </NavLink>

      <TabWrapper>
        <Tabs activeKey={activeTab} onChange={(val) => setActiveTab(val)}>
          <Tabs.TabPane
            key='questions'
            tab={<p className='label'>Questions</p>}
          >
            <Questions setIsPreviewQuestion={setIsPreviewQuestion} />
          </Tabs.TabPane>

          <Tabs.TabPane key='preview' tab={<p className='label'>Preview</p>}>
            <Preview isFormCreate={isPreviewQuestion} />
          </Tabs.TabPane>
        </Tabs>
      </TabWrapper>
    </ContentWrapper>
  );
};

export default CreateForm;
