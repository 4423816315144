import connectApi from "./connect-api";
import { providesList } from "utils/redux-toolkit-query";
import {
  AllFollowupsRes,
  CreateFollowUpInput,
  CreateFollowUpRes,
  FollowUpsRTKQueryEnum
} from "constants/types";

export const followUpsApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllFollowUps: builder.query<AllFollowupsRes, void>({
      query: () => ({
        url: `/follow_ups`
      }),
      providesTags: (data) =>
        providesList(FollowUpsRTKQueryEnum.FOLLOW_UPS, data?.data!) as any
    }),
    getSpecificPatientFollowUps: builder.query<
      AllFollowupsRes,
      { patientId: string }
    >({
      query: ({ patientId }) => ({
        url: `/follow_ups?user=${patientId}`
      })
    }),
    createFollowUp: builder.mutation<CreateFollowUpRes, CreateFollowUpInput>({
      query: (data) => ({
        url: `/follow_ups`,
        data,
        method: "POST"
      }),
      invalidatesTags: [FollowUpsRTKQueryEnum.FOLLOW_UPS as any]
    }),
    getBookingsFollowUps: builder.query<AllFollowupsRes, { bookingId: string }>(
      {
        query: ({ bookingId }) => ({
          url: `/follow_ups?service_order=${bookingId}`
        }),
        providesTags: (data) =>
          providesList(FollowUpsRTKQueryEnum.FOLLOW_UPS, data?.data!) as any
      }
    ),
    updateFollowUp: builder.mutation<
      CreateFollowUpRes,
      {
        data: {
          goal?: string;
          date?: string | Date;
          note?: string;
          status?: string;
        };
        followUpId: string;
      }
    >({
      query: ({ followUpId, data }) => ({
        url: `/follow_ups/${followUpId}`,
        data,
        method: "PUT"
      }),
      invalidatesTags: [FollowUpsRTKQueryEnum.FOLLOW_UPS as any]
    })
  }),
  overrideExisting: true
});
followUpsApi.enhanceEndpoints({
  addTagTypes: [...Object.values(FollowUpsRTKQueryEnum)]
});
export const {
  useGetAllFollowUpsQuery,
  useUpdateFollowUpMutation,
  useGetSpecificPatientFollowUpsQuery,
  useCreateFollowUpMutation,
  useGetBookingsFollowUpsQuery
} = followUpsApi;
