import styled from "styled-components";

export const StartPageWrapper = styled.div`
  padding-left: 10px;
  padding-bottom: 0px;

  .flex {
    display: flex;
    flex-direction: column;
    gap: 90px;
  }

  .content {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
    }

    .text_wrapper {
      h2 {
        color: #101828;
        font-family: DM Sans;
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      span {
        color: #667085;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
`;

export const QuestionsFormWrapper = styled.div`
  .question {
    &__header {
      width: 336px;
      display: inline-flex;
      padding: 10px 0px;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 10px;

      h1 {
        margin: 0;
        color: #101828;
        font-family: DM Sans;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
      }
    }
    &__progress {
      width: 334px;
    }
    &__form-body {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-top: 16px;
      h3 {
        color: #101828;
        font-family: DM Sans;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin: 0;
        @media (min-width: 1024px) {
          font-size: 20px;
        }
      }

      span {
        color: #667085;
        font-family: DM Sans;
        font-size: 14px;
        font-style: italic;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
`;

export const FieldContainer = styled.div<{ active?: boolean }>`
  width: 100%;
  max-width: 513px;
  margin-bottom: 8px;

  > * {
    width: 100%;
    padding: 12px;
  }

  @media (min-width: 768px) {
    width: 500px;
  }

  .ant-input {
    border-radius: 8px;
  }
`;

export const ChoiceFieldContainer = styled(FieldContainer)`
  border-radius: 5px;
  border: 1px solid #8a8a8a;

  > label {
    color: inherit;
  }
`;
