import styled from "styled-components";
import ReactDateOfBirthPicker from "react-date-picker";

interface DatePickerWrapperProps {
  mode?: string;
  marginBottom: number;
}

const DatePickerWrapper = styled.div<DatePickerWrapperProps>`
  label,
  .ant-form-item-label {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
    margin-bottom: 5px;
  }
  .ant-picker {
    border: none;
    padding: ${(props) => (props.mode === "normal" ? "10px" : "5px 0px")};
    border-bottom: ${(props) =>
      props.mode === "normal" ? "" : "1px solid #BFCAD0"};
    border: ${(props) => (props.mode === "normal" ? "1px solid #BFCAD0" : "")};
    border-radius: ${(props) => (props.mode === "normal" ? "8px" : "0px")};
    width: 100%;
    &:focus,
    &.ant-picker-focused {
      box-shadow: none;
      border: 1px solid #0f9af0;
    }
  }
  .ant-form-item-explain,
  .ant-form-item-extra {
    font-family: "DM Sans";
    font-weight: normal;
    font-size: 13px;
    text-align: left;
    /* color: rgba(0, 46, 70, 0.75); */
  }
  .ant-form-item {
    margin-bottom: ${(props) =>
      `${!isNaN(props.marginBottom) ? props.marginBottom : 24}px`};
  }
`;

export const DateOfBirthPicker = styled(ReactDateOfBirthPicker)`
  &.react-date-picker {
    width: 100%;
    border-radius: 8px;
  }

  .react-date-picker__wrapper {
    height: 44px;
    padding: 10px;
    border: 1px solid #bfcad0;
    border-radius: 8px;

    .react-date-picker__inputGroup__leadingZero {
      margin-bottom: 2px;
    }
    .react-date-picker__inputGroup__input {
      font-family: "DM Sans";
    }
    .react-date-picker__inputGroup__input:focus {
      outline: none;
    }
    .react-date-picker__inputGroup__day::placeholder,
    .react-date-picker__inputGroup__month::placeholder,
    .react-date-picker__inputGroup__year::placeholder {
      color: #b1adad;
    }
  }
`;

export { DatePickerWrapper };
