import styled from "styled-components";

export const ResponseWrapper = styled.div`
  width: calc(100%);
  min-height: calc(100vh - 57px);

  h3 {
    color: #333;
    font-family: DM Sans;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 38px;
  }
`;

export const ContentWrapper = styled.div`
  padding: 25px 25px 100px;
  background: #f3f8fb;
  min-height: calc(100vh - 60px);

  .return-btn {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-bottom: 27px;

    span {
      color: #0d0d0d;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export const TextWrapper = styled.div`
  display: flex;
  gap: 10px;
  //align-items: center;
  span {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 57px;
    background: #0d97cd;
    color: #fff;
    font-family: DM Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }
`;

export const ResponseTableBody = styled.div`
  margin-top: 51px;
  border-radius: 8px;

  .ant-card {
    box-shadow: none !important;

    .ant-card-body {
      box-shadow: none;
      border: 13px solid red;
    }
  }

  .ant-table-column-sorters {
    padding: 6px 0px !important;
  }

  .ant-table-wrapper {
    border-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }

  .ant-table-container table > thead > tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }

  .ant-table {
    border-radius: 8px;

    .odd-row td.ant-table-cell {
      background: #e7f5fd;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
      opacity: 1 !important;
    }

    .even-row td.ant-table-cell {
      background: #fff;
      border-bottom: 0.5px solid rgba(78, 102, 126, 0.15);
      opacity: 1;
    }

    .odd-row:last-child td.ant-table-cell,
    .even-row:last-child td.ant-table-cell {
      border-bottom: none;
    }

    .ant-table-thead > tr > th {
      background: #f2f8fe;
      padding: 6px 10px;
      border-bottom: 0.5px solid #1890ff;
      opacity: 1;
    }

    tr {
      td:first-child,
      th:first-child {
        padding: 10px 10px;
      }
    }

    th.ant-table-cell {
      font-family: "DM Sans";
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      text-align: left;
    }

    .ant-table-cell-fix-left:nth-child(1) {
      position: relative !important;
      z-index: 0;
      left: 0px !important;
      @media (min-width: 1024px) {
        position: sticky !important;
        left: 0px !important;
        z-index: 2;
      }
    }
    .ant-table-cell-fix-left:nth-child(2) {
      position: relative !important;
      z-index: 0;
      left: 0px !important;
      @media (min-width: 1024px) {
        position: sticky !important;
        left: 160px !important;
        z-index: 2;
        border-right: 1px solid #0faff0;
      }
    }

    .ant-table-cell-fix-right {
      border-left: 1px solid #0faff0;
    }
    td.ant-table-cell {
      background: #fff;
      font-family: "DM Sans";
      font-weight: 400;
      font-size: 13px;
      line-height: 14px;
      text-align: left;
      color: #002e46;

      .flex {
        display: flex;

        img {
          margin-right: 10px;
          border: 0.8px solid #0f9af0;
          border-radius: 50%;
          height: 100%;
          padding: 2px;
        }
      }

      .tag {
        background: #c1eee4;
        padding: 5px 10px;
        font-family: "DM Sans";
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        text-align: center;
        color: #0abb94;
        opacity: 0.75;
        border-radius: 10px;
      }

      .action {
        img {
          margin-left: 10px;
        }
      }

      p {
        margin-bottom: 5px;
      }

      span.subtext {
        font-family: "DM Sans";
        font-weight: normal;
        font-size: 13px;
        text-align: left;
        color: #0f9af0;
        opacity: 0.75;
      }
    }
  }

  .preferred-date {
    display: flex;
    flex-direction: column;
    gap: 6px;

    > span:nth-of-type(2) {
      color: #0d97cd;
    }
  }

  .answer {
    display: flex;
    gap: 10px;
  }

  .custom-pagination {
    display: flex;
    gap: 24px;
    justify-content: flex-end;
    align-items: center;
    padding: 12px 0 12px 12px;
  }
`;

export const DrawerHeader = styled.h2`
  color: #333;
  font-family: DM Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
`;

export const DrawerBody = styled.div`
  display: inline-flex;
  padding: 18px 23px 37px 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 23px;
  border-radius: 8px;
  border: 1.5px solid #e4e7ec;
  background: #fff;
  width: 100%;

  h3 {
    color: #344054;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .responses {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .single-response {
      max-width: 280px;
      display: flex;
      align-items: flex-start;
      gap: 9px;

      .number {
        display: flex;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        gap: 12px;
        border-radius: 68.4px;
        background: #0d97cd;
        padding: 5px 10px;
        flex: 1;

        span {
          color: #fff;
          font-family: DM Sans;
          font-size: 14.4px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
        }
      }

      .question {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 7px;
        h5 {
          color: #101828;
          font-family: DM Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 22.465px;
          margin: 0;
        }

        .answer {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 7px;
          div {
            display: flex;
            align-items: flex-start;
            gap: 10px;
            p {
              color: #344054;
              font-family: DM Sans;
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 120%;
              margin: 0;
            }
          }
        }
      }
    }
  }
`;

export const ResponseHeader = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
export const SearchBarWrapper = styled.div`
  display: inline-flex;
  gap: 18px;

  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
  }
  .ant-input-group-wrapper {
    width: 320px;
    @media (min-width: 768px) {
      width: 400px;
    }
  }
`;

export const EmptyStateWrapper = styled.div`
  margin-top: 200px;
  background: transparent;
  width: 100%;
  display: grid;
  place-content: center;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    font-family: "DM Sans";
    align-items: center;
    width: 369px;

    svg {
      height: 106px;
      width: 99px;
    }

    h2 {
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #222222;
      margin: 0 0 12px;

      @media (min-width: 768px) {
        font-size: 20px;
      }

      @media (min-width: 768px) {
        font-size: 24px;
      }
    }

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #667085;
      margin: 0 0 24px;

      @media (min-width: 768px) {
        font-size: 16px;
      }
    }
    button {
      display: flex;
      width: 227px;
      padding: 14px;
      justify-content: center;
      align-items: center;
      gap: 8px;
    }
  }
`;
