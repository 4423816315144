import {
  GetAllEmailsRes,
  GetAllMessagesRes,
  GetSpecificPatientSMSRes,
  MessagesRTKQueryTagEnum,
  SendMultipleEmailsInput,
  SendMultipleMessagesInput,
  SendSingleEmailInput,
  SendSingleMessageInput,
  SpecificMessagesRTKQueryTagEnum,
} from "constants/types";
import { providesList } from "utils/redux-toolkit-query";
import connectApi from "./connect-api";

export const messagesApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    sendSingleMessage: builder.mutation<
      { message: string },
      SendSingleMessageInput
    >({
      query: (data) => ({
        url: `/messages/sms`,
        data: JSON.stringify({ sms: data }),
        method: "POST",
      }),
      invalidatesTags: [
        MessagesRTKQueryTagEnum.MESSAGES as any,
        SpecificMessagesRTKQueryTagEnum.SPECIFIC_MESSAGES as any,
      ],
    }),
    sendSingleEmail: builder.mutation<
      { message: string },
      SendSingleEmailInput
    >({
      query: (data) => ({
        url: `/messages/emails`,
        data: JSON.stringify({ emails: data }),
        method: "POST",
      }),
    }),
    sendBulkMessages: builder.mutation<any, SendMultipleMessagesInput>({
      query: (data) => ({
        url: `/messages/sms/bulk`,
        data,
        method: "POST",
      }),
      invalidatesTags: [
        MessagesRTKQueryTagEnum.MESSAGES as any,
        SpecificMessagesRTKQueryTagEnum.SPECIFIC_MESSAGES as any,
      ],
    }),
    sendBulkEmails: builder.mutation<any, SendMultipleEmailsInput>({
      query: (data) => ({
        url: `/messages/emails/bulk`,
        data,
        method: "POST",
      }),
    }),
    getAllMessages: builder.query<GetAllMessagesRes, void>({
      query: () => ({
        url: `/messages/sms`,
      }),
      providesTags: (data) =>
        providesList(MessagesRTKQueryTagEnum.MESSAGES, data?.data!) as any,
    }),

    getAllEmailMessages: builder.query<GetAllEmailsRes, void>({
      query: () => ({
        url: `/messages/emails`,
      }),
    }),
    getSpecificPatientSMS: builder.query<
      GetSpecificPatientSMSRes,
      { patientId: string }
    >({
      query: ({ patientId }) => ({
        url: `/messages/sms?user=${patientId}`,
      }),
      providesTags: (data) =>
        providesList(
          SpecificMessagesRTKQueryTagEnum.SPECIFIC_MESSAGES,
          data?.data!
        ) as any,
    }),
  }),
  overrideExisting: true,
});

messagesApi.enhanceEndpoints({
  addTagTypes: [
    ...Object.values(MessagesRTKQueryTagEnum),
    ...Object.values(SpecificMessagesRTKQueryTagEnum),
  ],
});

export const {
  useSendSingleMessageMutation,
  useSendBulkMessagesMutation,
  useGetAllMessagesQuery,
  useGetAllEmailMessagesQuery,
  useSendSingleEmailMutation,
  useSendBulkEmailsMutation,
  useGetSpecificPatientSMSQuery,
} = messagesApi;
