import { Form, TimePicker as AntdTimePicker } from "antd";
import { TimePickerWrapper } from "./styles";

const TimePicker = (props: any) => {
  const { formItem, mode, ...rest } = props;

  return (
    <TimePickerWrapper>
      <Form.Item {...formItem}>
        <AntdTimePicker {...rest} />
      </Form.Item>
    </TimePickerWrapper>
  );
};

export default TimePicker;
