import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { message as AntdMessage } from "antd";

import { AppThunk } from "../store";
import http from "utils/api";
import { storage } from "utils/firebase";

export interface EncounterError {
  message: string;
}

export interface ProfileState {
  profile: object | any;
  isProfileLoading: boolean;
  isUpdatingLoading: boolean;
  profileError: EncounterError;
  iconURL: string | any;
  isUpdatingIconLoading: boolean;
  isUploadCoverLoading: boolean;
}

export const initialState: ProfileState = {
  profile: {},
  isProfileLoading: false,
  isUpdatingLoading: false,
  profileError: { message: "" },
  iconURL: "",
  isUpdatingIconLoading: false,
  isUploadCoverLoading: false
};

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchProfileLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isProfileLoading = payload;
    },
    fetchProfileSuccess: (state, { payload }: PayloadAction<Object>) => {
      state.profile = payload;
    },
    fetchProfileFailed: (state, { payload }: PayloadAction<EncounterError>) => {
      state.profileError = payload;
    },
    updateProfileSuccess: (state, { payload }: PayloadAction<Object>) => {
      state.profile = payload;
    },
    setIconURL: (state, { payload }: PayloadAction<string>) => {
      state.iconURL = payload;
    },
    updateIconURLStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdatingIconLoading = payload;
    },
    updateCoverUploadStatus: (state, { payload }: PayloadAction<boolean>) => {
      state.isUploadCoverLoading = payload;
    }
  }
});

export const {
  fetchProfileLoading,
  fetchProfileSuccess,
  fetchProfileFailed,
  updateProfileSuccess,
  updateIconURLStatus,
  updateCoverUploadStatus,
  setIconURL
} = profileSlice.actions;
export const profileSelector = (state: { profile: ProfileState }) =>
  state.profile;
export default profileSlice.reducer;

/** Actions */

export const fetchProviderProfile = (): AppThunk => async (dispatch) => {
  dispatch(fetchProfileLoading(true));
  await http
    .get("/services/providers/profile")
    .then((res) => {
      const profileData = res?.data.data || {};
      dispatch(fetchProfileSuccess(profileData));
    })
    .catch((err) => {
      const message = { message: err?.response?.data?.message };
      dispatch(fetchProfileFailed(message));
    });
  dispatch(fetchProfileLoading(false));
};

export const updateProviderProfile =
  (payload: object | any): AppThunk =>
  async (dispatch) => {
    await http
      .patch("/services/providers/profile", payload)
      .then((res) => {
        const currentUser = res?.data?.data || {};
        dispatch(updateProfileSuccess(currentUser));
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || err?.response?.data?.error
        };
        throw new Error(message.message);
      });
  };

export const setProviderProfile =
  (payload: Object): AppThunk =>
  async (dispatch) => {
    dispatch(fetchProfileSuccess(payload));
  };

export const uploadBusinessIcon =
  (payload: Object | any): AppThunk =>
  async (dispatch, getState) => {
    const storageRef = storage.ref();
    const currentFolderRef = storageRef.child(getState().profile.profile.email);
    const currentImageRef = storageRef.child(
      `${currentFolderRef}/${payload.name}`
    );

    dispatch(updateIconURLStatus(true));
    currentImageRef
      .put(payload)
      .then(() => {
        currentImageRef
          .getDownloadURL()
          .then((url: string | any) => dispatch(setIconURL(url)));

        dispatch(updateIconURLStatus(false));
      })
      .then(() =>
        AntdMessage.success(
          "Image uploaded successfully. Submit to confirm changes."
        )
      )
      .catch((err: object | any) => {
        console.log(err);
        dispatch(updateIconURLStatus(false));
      });
  };
