import styled from "styled-components";
import { Modal } from "antd";
import { ReactComponent as IconNew } from "assets/icons/user-circle.svg";
import { ReactComponent as IconOld } from "assets/icons/user-circle-plus.svg";
export const ModalWrapper = styled(Modal)`
  width: 895px !important;
  .ant-modal-content,
  .ant-modal-header {
    border-radius: 12px;
  }
  .ant-modal-header {
    position: static;
  }

  .ant-modal-body {
    padding: 0;
  }

  .btn__wrapper {
    display: inline-flex;
    padding: 24px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-top: 1px solid #d0d5dd;
    width: 100%;

    @media (min-width: 1024px) {
      padding: 24px 168px 23px 256px;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      border-radius: 4px;
      &:first-of-type {
        background: transparent;
        width: 90px;
        border: 1px solid #0d0d0d;

        span {
          color: #0d0d0d;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.986px;
        }
      }
      &:last-of-type {
        background: #0faff0;
        max-width: 302px;
        gap: 6.394px;
        padding: 10px;
        border: 1px solid #0faff0;

        span {
          color: #fff;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 15.986px;
        }
      }
    }
  }

  header {
    border-bottom: 1px solid #d0d5dd;
    display: inline-flex;
    padding: 12px 22px 7px 32px;
    align-items: center;
    //gap: 392px;
    width: 100%;

    h1 {
      margin: 0;
      color: #101828;
      font-family: DM Sans;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      flex: 1;
    }
    button {
      background: transparent;
      border: none;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.45);
      transition: color 0.3s;
      &:focus,
      &:hover {
        color: rgba(0, 0, 0, 0.75);
        text-decoration: none;
      }
    }
  }
`;

export const ModalContent = styled.main`
  display: flex;
  gap: 38px;
  padding: 0px 20px 20px;
  max-height: 650px;
  overflow-y: auto;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
    padding: 25px 20px 20px;
  }

  .step {
    position: sticky;
    top: 0rem;
    z-index: 100;
    background: white;
    padding: 10px;
    @media (min-width: 768px) {
      width: 30%;
    }
    .ant-steps-vertical {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .ant-steps-item-tail {
        height: 130%;
        @media (min-width: 768px) {
          height: 110%;
        }
      }
    }
  }
  .content {
    @media (min-width: 768px) {
      width: 70%;
    }
  }
  .row-icons {
    margin-bottom: 25px;
    overflow: auto;
    h3 {
      width: 100%;
      margin-left: 11px;
      color: #002e46;
      font-size: 13px;
      span {
        color: #ff4d4f;
        position: relative;
        top: 3px;
      }
    }
    .ant-card-bordered {
      border: 1px solid #f0f0f0;
      box-shadow: none;
      transition: 0.3s;

      &:hover {
        color: #0f9af0;
      }
    }

    .ant-card-body {
      padding: 10px !important;
      cursor: pointer;
      box-shadow: none !important;
      display: flex;
      align-items: center;
      justify-content: start;
    }

    svg {
      height: 30px;
      width: 30px;
      margin-right: 6px;
    }

    .icon-2 {
      margin-right: 12px;
    }

    .isCardActive {
      color: #0f9af0;
      border: 1px solid #f0f0f0 !important;
      border-radius: 8px;

      .ant-card-bordered {
        border: none !important;
        box-shadow: none !important;
      }

      .ant-card-body {
        border-radius: 8px;
        border: 1px solid #0f9af0 !important;
      }
    }
  }

  .disclaimer {
    display: flex;
    align-items: flex-start;
    gap: 4px;
    padding-bottom: 20px;

    svg {
      flex: 1;
    }
    span {
      color: #1e293b;
      font-family: DM Sans;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      max-width: 411px;
    }
  }
`;

export const NewPatient = styled(IconNew)<{ stroke: string }>`
  path {
    stroke: ${(props) => props.stroke};
  }
`;
export const OldPatient = styled(IconOld)<{ stroke: string }>`
  path {
    stroke: ${(props) => props.stroke};
  }
`;
