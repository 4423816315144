import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

export const Header = styled.div`
  position: relative;
  background-color: #fff;
  padding: 20px 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: 768px) {
    padding: 10px 20px;
    margin-bottom: 20px;
    margin-top: 32px;
    padding-bottom: 12px;
  }

  & > button {
    position: absolute;
    top: 0;
    font-size: 13px;
    display: none;

    @media (max-width: 750px) {
      display: block;
      top: -42px;
      left: 0;
      background: gray;
      color: #fff;
      border: none;
      border-radius: 2px;
      margin-top: 4px;
      padding: 4px 6px;
      cursor: pointer;
    }
  }

  & > div {
    display: flex;
    flex-direction: column;
    gap: 8px;

    h2 {
      text-transform: capitalize;
      margin: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #101828;

      @media (max-width: 768px) {
        font-size: 18px;
      }
    }

    p {
      margin: 0;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }
  }
`;

export const MessagesList = styled.div`
  display: flex;
  flex-direction: column-reverse;
  height: calc(100% - 160px);
  overflow: scroll;
  padding-bottom: 40px;

  @media (max-width: 768px) {
    height: calc(100% - 120px);
  }
`;

export const NoPatientSelected = styled.div`
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;

  h3 {
    font-weight: 700;
    font-size: 36px;
    text-align: center;
  }
`;

export const MessageItem = styled.div`
  max-width: 450px;
  position: relative;
  padding: 8px 30px 8px 13px;
  height: auto;
  background: #0c8abc;
  border-radius: 6px 6px 6px 0px;
  margin: 0 0 40px 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;

  @media (max-width: 768px) {
    margin-left: 20px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    color: #ffffff;
  }

  small {
    text-align: end;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #efefef;
  }

  &::after {
    content: "";
    position: absolute;
    left: -12px;
    bottom: -0.2px;
    border-top: 18px solid transparent;
    border-right: 18px solid #0c8abc;
    border-left: none;
  }
`;

export const MessageInputWrapper = styled.form`
  position: absolute;
  bottom: 00px;
  left: 0;
  right: 0;
  padding: 0 40px;
  background: #fff;
  display: flex;
  border-top: 2px solid #f3f3f3;

  @media (max-width: 768px) {
    padding: 0 20px;
  }

  img {
    cursor: pointer;
  }

  textarea {
    flex: 1;
    width: 100%;
    height: 50px;
    padding: 15px 10px;
    border: none;
    resize: none;
    font-size: 13px;
    background: transparent;

    &:focus {
      outline: none;
    }

    @media (max-width: 768px) {
      height: 60px;
    }
  }
`;

export const DrawerWrapper = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
`;

export const DrawerHeader = styled.div`
  border-bottom: 1px dashed #c0c0c0;
  padding-bottom: 32px;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 12px;
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0;

    &:first-of-type {
      text-transform: capitalize;
    }

    &:not(:first-of-type) {
      margin-top: 6px;
    }
  }
`;

export const DrawerBookings = styled.div`
  margin-top: 30px;

  h3 {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 24px;
  }

  .single-booking {
    border: 1px solid #c0c0c0;
    padding: 12px 16px;
    border-radius: 6px;

    &:not(:first-of-type) {
      margin-top: 12px;
    }

    h4 {
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      color: #344054;
      margin: 0 0 12px;
    }

    & > div {
      display: flex;
      gap: 22px;

      @media (max-width: 425px) {
        flex-direction: column;
        gap: 6px;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 4px;

        small {
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #667085;
        }

        p {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
          margin: 0;
        }

        &:nth-of-type(3) {
          border-left: 1px solid #344054;
          padding-left: 12px;

          @media (max-width: 425px) {
            border-left: none;
            padding-left: 0;
            border-top: 1px solid #344054;
            padding-top: 12px;
          }
        }
      }
    }
  }

  .empty-bookings {
    margin-top: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      height: 70px;
    }

    h4 {
      max-width: 280px;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      line-height: 150%;
      text-align: center;
      color: #222222;
      margin: 24px auto;

      span {
        text-transform: capitalize;
        font-weight: 600;
      }
    }
  }
`;
