import styled from "styled-components";
import { Form, Input as AntdInput } from "antd";

const TextAreaWrapper = styled.div`
  label,
  .ant-form-item-label {
    font-weight: 500;
    font-size: 13px;
    text-align: left;
    color: #002e46;
    padding: 0px;
    margin-bottom: 5px;
  }
  .ant-input {
    border-radius: 8px;
    background: #fff;
    border: 1px solid rgba(0, 46, 70, 0.25);
    padding: 10px;
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      font-family: "DM Sans";
      font-weight: normal;
      font-size: 13px;
      text-align: left;
      color: #4e667e;
    }
  }
`;

export const TextArea = (props: any) => {
  const { formItem, marginBottom, ...rest } = props;
  return (
    <TextAreaWrapper>
      <Form.Item {...formItem}>
        <AntdInput.TextArea {...rest} />
      </Form.Item>
    </TextAreaWrapper>
  );
};
