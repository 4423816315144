import { Col, Modal, Form, Select as AntdSelect, Row, message } from "antd";
import { useEffect, useState } from "react";

import Select from "component/Select";
import Button from "component/Button";
import {
  fetchAllServiceOrders,
  updateOrderMetadata
} from "redux/reducers/requests";
import { useAppSelector, useAppDispatch } from "redux/store";

type ModalProps = {
  selectedBooking: any;
  isModalVisible: boolean;
  onClose: () => void;
};

function UpdatePractitioner({
  selectedBooking,
  isModalVisible,
  onClose
}: ModalProps) {
  const dispatch = useAppDispatch();
  const { practitioners } = useAppSelector((state) => state.practitioners);
  const [updateForm] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  // auto-fill selected practitioner
  useEffect(() => {
    updateForm.setFieldsValue({
      assignedPractitioner: selectedBooking?.metadata?.assignedPractitioner
    });
  }, [selectedBooking, updateForm]);

  async function handleAssignPractitioner(values: any) {
    const _values = values as { assignedPractitioner: string };

    setIsLoading(true);
    try {
      await dispatch(
        updateOrderMetadata(selectedBooking.id, {
          metadata: {
            ...selectedBooking.metadata,
            assignedPractitioner:
              _values.assignedPractitioner && _values.assignedPractitioner
          }
        })
      );

      await dispatch(fetchAllServiceOrders());
      message.success("Practitioner assigned successfully!");
      onClose();
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal
      title='Update selected practitioners'
      okText='Submit'
      footer={null}
      visible={isModalVisible}
      onCancel={onClose}
      onOk={handleAssignPractitioner}
    >
      <Form
        name='basicForm'
        form={updateForm}
        layout='vertical'
        onFinish={handleAssignPractitioner}
      >
        <Col xs={24}>
          <Select
            mode='normal'
            placeholder='Assign practitioner'
            formItem={{
              label: "Add practitioner to this booking",
              name: "assignedPractitioner",
              rules: [
                { required: true, message: "Please select a practitioner" }
              ]
            }}
          >
            {practitioners.map((item) => (
              <AntdSelect.Option key={item.id} value={item.id}>
                {`${item.title} ${item.name}`}
              </AntdSelect.Option>
            ))}
          </Select>
        </Col>

        <Row justify='end' style={{ marginTop: "20px" }}>
          <Button
            type='secondary'
            style={{ marginRight: 10 }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button htmlType='submit' type='primary' disabled={isLoading}>
            {isLoading ? "Updating" : "Update"}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
}

export default UpdatePractitioner;
