import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 70px 0px 0px;

  .medical-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding-bottom: 50px;

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }

    .vital--info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      .top,
      .bottom {
        display: flex;
        gap: 70px;
        flex-direction: row;
        align-items: center;
        h3 {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 700;
          font-size: 14px;
          line-height: 18px;
          color: #344054;
        }
        p {
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
        }
      }
    }
  }

  .personal-record {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px 0px 50px;
    .content {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    .allergies,
    .medications {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      h1 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 13.4977px;
        line-height: 18px;
        color: #344054;
      }
      div {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 15px;
        span {
          padding: 6px 19px;
          background: #e7f5fd;
          border-radius: 57px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #0faff0;
        }
      }
    }
  }

  .family--history {
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px 0px 50px;
    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }
    .conditions--1,
    .conditions--2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;

      h1 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 13.4977px;
        line-height: 18px;
        color: #344054;
      }
      p {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
      .conditions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
      }
      .family--members {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
        div {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          gap: 15px;
        }
        span {
          padding: 6px 19px;
          background: #e7f5fd;
          border-radius: 57px;
          font-family: "DM Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 120%;
          color: #0faff0;
        }
      }
    }
    .conditions--2 {
      .family--members {
        margin-right: 78px;
      }
    }
  }

  .surgery-history {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px 0px 50px;

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }

    .surgeries {
      display: flex;
      gap: 70px;
      flex-direction: row;
      align-items: center;

      h3 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #344054;
      }
      p {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }
  .transfusion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px 0px 50px;

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }

    .procedure {
      display: flex;
      gap: 70px;
      flex-direction: row;
      align-items: center;

      h3 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #344054;
      }
      p {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }
  .vaccination {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    padding: 20px 0px 50px;

    h1 {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 120%;
      color: #333333;
    }

    .vaccine {
      display: flex;
      gap: 80px;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      h3 {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 18px;
        color: #344054;
      }
      p {
        font-family: "DM Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }
`;
