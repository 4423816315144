import React, { useMemo, useState } from "react";
import {
  ModalContent,
  ModalWrapper,
  OldPatient as IconOld,
  NewPatient as IconNew,
} from "./styles";
import {
  Button,
  Col,
  Form,
  message,
  Row,
  Select as AntdSelect,
  Steps,
} from "antd";
import Card from "../Card";

//svg
import { ReactComponent as CaretLeft } from "assets/icons/caret-left.svg";
import { ReactComponent as CaretRight } from "assets/icons/caret-right.svg";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";
import WalkInIcon from "../../assets/icons/walk-in-icon.png";
import CallInIcon from "../../assets/icons/call-in-icon.png";
import Select from "../Select";
import Input from "../Input";
import DatePicker from "../DatePicker";
import moment from "moment";
import TimePicker from "../TimePicker";
import {
  capitaliseFirstLetter,
  getDisabledHours,
  getDisabledMinutes,
  handlePhoneNumberFormat,
} from "../../utils/utils";
import TextArea from "../TextArea";
import { FormItem } from "../../layouts/DashboardLayout/styles";
import { DateOfBirthPicker } from "../DatePicker/styles";
import OtpInput from "react-otp-input";
import {
  BookValuesType,
  RegisterUserValues,
  RequestValueType,
  UserType,
  UserValueType,
} from "../../constants/types";
import {
  approvePatientAccessRequest,
  createUser,
  getPatients,
  requestPatientAccess,
  setSelectedUser,
  userSelector,
} from "../../redux/reducers/users";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import {
  bookService,
  bookServiceWithNorthKey,
  servicePlansSelector,
  setBookingModalState,
} from "../../redux/reducers/servicePlans";
import { useSelector } from "react-redux";
import { profileSelector } from "../../redux/reducers/profile";
import { CloseOutlined } from "@ant-design/icons";
import {
  useGetSingleServiceFormQuery,
  useHandleSubmitFormMutation,
} from "../../redux/queries/service-forms";
import { db } from "../../utils/firebase";
import firebase from "firebase";
import { send } from "emailjs-com";
import { MixPanel } from "../../utils/mixpanel";
import { fetchAllServiceOrders } from "../../redux/reducers/requests";
import Questions from "../Questions";
import { QuestionResponse } from "../Questions/QuestionsForm";

const { Step } = Steps;
const CreateConsultationModal = () => {
  const dispatch = useAppDispatch();
  const { isBookingModalVisible, servicePlans } =
    useAppSelector(servicePlansSelector);
  const { practitioners } = useAppSelector((state) => state.practitioners);
  const { profile: ProviderProfile } = useSelector(profileSelector);
  const { users, user } = useAppSelector(userSelector);
  const [pageIndex, setPageIndex] = useState<number>(0);
  const [formPage, setFormPage] = useState<
    "newUser" | "existingUser" | "requestAccess"
  >("newUser");
  const [appointmentType, setAppointmentType] = useState<"walkIn" | "callIn">(
    "walkIn",
  );
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [noEmail, setNoEmail] = useState(false);
  const [noDOB, setNoDOB] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");
  const [isTodaySelected, setIsTodaySelected] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedServiceId, setSelectedServiceId] = useState<string>();
  const [getAppointmentInfo, setGetAppointmentInfo] = useState<
    BookValuesType | any
  >(null);
  const [currentFormStep, setCurrentFormStep] = useState<number>(0);
  const [questionIds, setQuestionIds] = useState([1]);
  const currentQuestionId = questionIds[questionIds.length - 1];
  const [responses, setResponses] = useState(
    {} as { [key: string]: QuestionResponse },
  );

  const [isSubmittingResponse, setIsSubmittingResponse] =
    useState<boolean>(false);
  const [isCreatingAppointment, setIsCreatingAppointment] =
    useState<boolean>(false);

  const [submitFormMutation] = useHandleSubmitFormMutation();
  const handleFormStepChange = (step: "start" | "question") => {
    switch (step) {
      case "start":
        setCurrentFormStep(0);
        break;
      case "question":
        setCurrentFormStep(1);
        break;
    }
  };

  const [addBookForm] = Form.useForm();
  const serviceform = servicePlans?.find(
    (plan) => plan?.id === selectedServiceId,
  );

  const questionId = serviceform?.metadata?.service_form;

  const {
    data,
    isLoading: isQuestionLoading,
    error,
  } = useGetSingleServiceFormQuery({
    questionId,
  }) as any;

  const forms = data?.data[0] || {};
  const normalizedQuestions = useMemo(
    () =>
      forms?.questions?.reduce((acc: any, curr: any) => {
        acc[curr.id] = curr;
        return acc;
      }, {}),
    [forms.questions],
  );

  function handleQuestionFormChange(response: QuestionResponse) {
    setResponses((p) => ({ ...p, [currentQuestionId]: response }));
  }

  function handleNextQuestion() {
    const currentQuestion = normalizedQuestions[currentQuestionId];

    if (
      responses &&
      !responses[currentQuestionId]?.value &&
      currentQuestion?.required === false
    ) {
      handleQuestionFormChange({
        question: currentQuestion.id,
        question_text: currentQuestion.text,
        response: currentQuestion.response?.id,
        value: "null",
        next_question: currentQuestion?.next_question,
      });

      setQuestionIds((p) => {
        return [...p, currentQuestion.next_question];
      });
    } else {
      setQuestionIds((p) => {
        return [...p, responses[currentQuestionId].next_question];
      });
    }
  }

  const ButtonTexts = () => {
    if (formPage === "newUser" || "existingUser") {
      if (!pageIndex) {
        return isLoading ? "processing...." : "Proceed to appointment details";
      } else if (serviceform?.metadata?.service_form) {
        return "Proceed to questionnaire";
      } else {
        return isLoading ? "Processing..." : "Book Appointment";
      }
    } else if (formPage === "requestAccess") {
      if (!pageIndex) {
        return isLoading ? "Requesting..." : "Request access";
      } else {
        return isLoading ? "Processing..." : "Create appointment";
      }
    }
  };

  function formatPhoneNumber(
    number: string,
    numberDetails: { dialCode: string },
  ) {
    const formattedPhone = handlePhoneNumberFormat(
      number,
      numberDetails.dialCode,
    );

    setFormattedPhone(formattedPhone);
  }

  function handleNoEmailChange() {
    setNoEmail((prev) => !prev);
    if (!noEmail) {
      addBookForm.setFieldsValue({ email: "" });
    }
  }

  function handleNoDOBChange() {
    setNoDOB((prev) => !prev);
    if (!noEmail) {
      addBookForm.setFieldsValue({ dob: moment("01/01/2004") });
    }
  }

  const page2SimilarData = () => (
    <>
      <Col xs={24}>
        <Select
          onChange={(val: string) => setSelectedServiceId(val)}
          formItem={{
            name: "plan",
            label: "Main service",
            rules: [
              {
                required: true,
                message: "Please select a service",
              },
            ],
          }}
          mode="normal"
          showSearch
          optionFilterProp="children"
          filterOption={(_input: string, option: any) => {
            const input = _input.toLowerCase();
            const singleService = servicePlans.find(
              (plan: any) => plan.id === option.value,
            )!;
            const booleanValue = singleService.name
              .toLowerCase()
              .includes(input);
            return booleanValue;
          }}
          filterSort={(optionA: any, optionB: any) =>
            optionA.value
              .toLowerCase()
              .localeCompare(optionB.value.toLowerCase())
          }
        >
          {servicePlans?.map((option, index) => {
            return (
              <AntdSelect.Option key={index} value={option.id}>
                <span style={{ textTransform: "capitalize" }}>
                  {option.name}
                </span>
              </AntdSelect.Option>
            );
          })}
        </Select>
      </Col>

      <Col xs={24}>
        <Select
          mode="normal"
          multiple
          placeholder="Add other services to this appointment.."
          formItem={{
            label: "Add other services to this appointment",
            name: "additionalServices",
          }}
          filterOption={(_input: string, option: any) => {
            const input = _input.toLowerCase();
            const singleService = servicePlans.find(
              (plan: any) => plan.id === option.value,
            )!;
            const booleanValue = singleService.name
              .toLowerCase()
              .includes(input);
            return booleanValue;
          }}
          filterSort={(optionA: any, optionB: any) =>
            optionA.value
              .toLowerCase()
              .localeCompare(optionB.value.toLowerCase())
          }
        >
          {servicePlans
            ?.filter((val) => val.id !== selectedServiceId)
            ?.map((option, index) => {
              return (
                <AntdSelect.Option key={index} value={option.id}>
                  <span style={{ textTransform: "capitalize" }}>
                    {option.name}
                  </span>
                </AntdSelect.Option>
              );
            })}
        </Select>
      </Col>

      <Col xs={24}>
        <Select
          mode="normal"
          placeholder="Assign practitioner"
          formItem={{
            label: "Add practitioner to this booking",
            name: "assignedPractitioner",
          }}
        >
          {practitioners.map((item) => (
            <AntdSelect.Option key={item.id} value={item.id}>
              {`${item.title} ${item.name}`}
            </AntdSelect.Option>
          ))}
        </Select>
      </Col>
      <Col xs={24}>
        <Select
          formItem={{
            name: "payment_method",
            label: "Payment method",
            rules: [
              {
                required: true,
                message: "Please select payment method",
              },
            ],
          }}
          onChange={(value: string) => setSelectedPaymentMethod(value)}
          mode="normal"
        >
          <AntdSelect.Option value="cash">Cash</AntdSelect.Option>

          {ProviderProfile?.metadata?.payment_options?.insurance && (
            <AntdSelect.Option value="insurance">Insurance</AntdSelect.Option>
          )}
        </Select>
      </Col>
      {selectedPaymentMethod === "insurance" && (
        <>
          <Col span={16} style={{ marginBottom: "1em" }}>
            <Select
              formItem={{
                name: "insurance_provider",
                label: "Insurance provider",
                rules: [
                  {
                    required: true,
                    message: "Please select an insurance provider",
                  },
                ],
              }}
              showSearch={true}
              mode="normal"
            >
              {ProviderProfile?.metadata?.payment_options?.selectedProviders?.map(
                (item: any) => (
                  <AntdSelect.Option value={item} key={item}>
                    {item}
                  </AntdSelect.Option>
                ),
              )}
            </Select>
          </Col>

          <Col span={8}>
            <Input
              formItem={{
                name: "insurance_id",
                label: "Insurance ID",
                rules: [
                  {
                    required: true,
                    message: "Please input your insurance id",
                  },
                ],
              }}
              mode="normal"
              style={{ textTransform: "uppercase" }}
            />
          </Col>
        </>
      )}
      <Col xs={24} sm={12}>
        <DatePicker
          formItem={{
            name: "preferred_date",
            label: "Select preferred appointment date",
            rules: [
              {
                required: true,
                message: "Please select preferred appointment date",
              },
            ],
          }}
          disabledDate={(d: any) => {
            return !d || d.isBefore(new Date().getTime() - 24 * 60 * 60 * 1000);
          }}
          placeholder="Preferred appointment date"
          inputReadOnly={true}
          mode="normal"
          format="DD/MM/YYYY"
          popupStyle={{
            maxHeight: "400px !important",
          }}
          onChange={(e: any) => {
            setIsDateSelected(true);
            if (moment(e) > moment()) {
              setIsTodaySelected(false);
            } else {
              setIsTodaySelected(true);
            }
          }}
        />
      </Col>

      <Col span={12}>
        <TimePicker
          formItem={{
            label: "Preferred Time",
            name: "preferred_time",
            rules: [
              {
                required: true,
                message: "Please input preferred time",
              },
            ],
          }}
          format="hh:mm"
          mode="normal"
          inputReadOnly={true}
          minuteStep={30}
          disabledHours={() => getDisabledHours(isTodaySelected)}
          disabledMinutes={(selectedHour: number) =>
            getDisabledMinutes(selectedHour, isTodaySelected)
          }
        />
      </Col>

      <Col span={24}>
        <TextArea
          formItem={{
            name: "note",
            label: "Additional notes/comments",
          }}
          label=""
          mode="normal"
          maxLength={300}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </Col>

      {serviceform?.metadata?.service_form && (
        <div className="disclaimer">
          <InfoIcon />
          <span>
            The service selected has an intake form, a patient is required to
            fill in a questionnaire with the help of a healthcare provider
            before appointment will be booked.
          </span>
        </div>
      )}
    </>
  );
  const bookingPage = {
    new: [
      <>
        <Col xs={24} sm={12}>
          <Input
            formItem={{
              name: "first_name",
              label: "First name",
              rules: [
                {
                  required: true,
                  message: "Please enter first name",
                },
              ],
            }}
            label=""
            mode="normal"
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            formItem={{
              name: "last_name",
              label: "Last name",
              rules: [
                {
                  required: true,
                  message: "Please enter last name",
                },
              ],
            }}
            mode="normal"
          />
        </Col>
        <Col span={24} sm={12} style={{ position: "relative" }}>
          <Input
            formItem={{
              label: "Email address",
              name: "email",
              rules: [
                {
                  required: !noEmail,
                  message: `${
                    noEmail ? "" : "Please input your email address"
                  }`,
                },
                {
                  type: "email",
                  message: noEmail ? "" : "Please input a valid email",
                },
              ],
            }}
            mode="normal"
            type="email"
            disabled={noEmail}
            value={""}
          />
          <div
            className="email-checkbox"
            style={{
              position: "absolute",
              top: "0",
              left: "120px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <input
              id="email-checkbox"
              type="checkbox"
              checked={noEmail}
              onChange={() => handleNoEmailChange()}
              style={{ cursor: "pointer" }}
            />{" "}
            <label
              htmlFor="email-checkbox"
              style={{ cursor: "pointer", fontSize: "12px" }}
            >
              Not available?
            </label>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <Input
            formItem={{
              name: "phone",
              label: "Phone number",
              rules: [
                {
                  required: true,
                  message: "Please enter your phone number",
                },
                {
                  required: true,
                  pattern: /[0-9]{11}$/,
                  message: "Please enter a valid phone number",
                },
              ],
            }}
            type="phone"
            mode="normal"
            onChange={formatPhoneNumber}
          />
        </Col>
        <Col xs={24} sm={12}>
          <FormItem
            label="Date of Birth"
            name="dob"
            rules={[
              {
                required: !noDOB,
                message: "Please enter date of birth",
              },
            ]}
          >
            <DateOfBirthPicker
              clearIcon={null}
              disableCalendar={true}
              format="dd/MM/yyyy"
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              disabled={noDOB}
              maxDate={
                new Date(new Date().setFullYear(new Date().getFullYear()))
              }
            />
          </FormItem>

          <div
            className="dob-checkbox"
            style={{
              position: "absolute",
              top: "0",
              left: "120px",
              display: "flex",
              alignItems: "center",
              gap: "4px",
            }}
          >
            <input
              id="dob-checkbox"
              type="checkbox"
              checked={noDOB}
              onChange={() => handleNoDOBChange()}
              style={{ cursor: "pointer" }}
            />{" "}
            <label
              htmlFor="dob-checkbox"
              style={{ cursor: "pointer", fontSize: "12px" }}
            >
              Not available?
            </label>
          </div>
        </Col>
        <Col xs={24} sm={12}>
          <Select
            formItem={{
              name: "gender",
              label: "Gender",
              rules: [
                {
                  required: true,
                  message: "Please select gender",
                },
              ],
            }}
            mode="normal"
          >
            {["male", "female"].map((option, index) => {
              return (
                <AntdSelect.Option key={index} value={option}>
                  <span style={{ textTransform: "capitalize" }}>{option}</span>
                </AntdSelect.Option>
              );
            })}
          </Select>
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col xs={24}>
              <Input
                formItem={{
                  name: "patientId",
                  label: "Patient ID",
                }}
                type="text"
                mode="normal"
                style={{ textTransform: "uppercase" }}
              />
            </Col>
          </Row>
        </Col>
      </>,
      <>{page2SimilarData()}</>,
    ],
    request: [
      <Col span={23} style={{ margin: "0 auto" }}>
        <Input
          formItem={{
            label: "Email address",
            name: "email",
            rules: [
              {
                required: true,
                message: "Please input your email address",
              },
              {
                type: "email",
                message: "Please input a valid email",
              },
            ],
          }}
          placeholder="Check your email for your OTP code"
          mode="normal"
          type="email"
        />
      </Col>,
      <>
        <Col span={24}>
          <h4 className="otp-label">
            <span>*</span> Enter your OTP
          </h4>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={8}
            className="otp-input"
            focusStyle={{ border: "2px solid #0f9af0" }}
          />
        </Col>
        <>{page2SimilarData()}</>
      </>,
    ],
    existing: [
      <Col span={24}>
        <Select
          formItem={{
            name: "user",
            label: "Select or search patient",
            rules: [
              {
                required: true,
                message: "Please select an existing patient",
              },
            ],
          }}
          placeholder="Search patient by name, email or phone"
          mode="normal"
          onChange={(val: any) => {
            const singleUser = users.find(
              (user: UserType) => user.id === JSON.parse(val).id,
            )!;
            dispatch(setSelectedUser(singleUser as any));
          }}
          showSearch
          optionFilterProp="children"
          filterOption={(_input: string, option: any) => {
            const input = _input.toLowerCase();

            const singleUser = JSON.parse(option.value);

            const doesExist =
              singleUser.email.toLowerCase().includes(input) ||
              singleUser.first_name?.toLowerCase().includes(input) ||
              singleUser.last_name?.toLowerCase().includes(input) ||
              String(singleUser.patientId)?.toLowerCase()?.includes(input) ||
              (
                singleUser.first_name?.toLowerCase() +
                " " +
                singleUser.last_name?.toLowerCase()
              ).includes(input) ||
              (
                singleUser.last_name?.toLowerCase() +
                " " +
                singleUser.first_name?.toLowerCase()
              ).includes(input);

            return doesExist;
          }}
          // filterSort={(optionA: any, optionB: any) =>
          //   optionA.value
          //     .toLowerCase()
          //     .localeCompare(optionB.value.toLowerCase())
          // }
        >
          {users?.map((user, index) => {
            return (
              <AntdSelect.Option key={index} value={JSON.stringify(user)}>
                <span style={{ textTransform: "capitalize" }}>
                  {user.first_name} {user.last_name}
                </span>
              </AntdSelect.Option>
            );
          })}
        </Select>
      </Col>,
      <>{page2SimilarData()}</>,
    ],
  };

  const resetFormModal = () => {
    dispatch(setBookingModalState(false));
    setResponses({});
    handleFormStepChange("start");
    setQuestionIds([1]);
    addBookForm.resetFields();
    setFormPage("newUser");
    setPageIndex(0);
    setGetAppointmentInfo(null);
    setSelectedServiceId("");
  };

  const createAppointment = async (values: any) => {
    setIsLoading(true);

    // accepted emailjs template
    const acceptedTemplateParams = {
      slug: ProviderProfile.slug || ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      to_name: `${capitaliseFirstLetter(user?.first_name || "")}`,
      to_email: user?.email,
      business_phone: ProviderProfile.phone,
      business_email: ProviderProfile.email,
      business_address: `${capitaliseFirstLetter(
        ProviderProfile.address?.street_line_one,
      )},  ${capitaliseFirstLetter(
        ProviderProfile.address?.city,
      )}, ${capitaliseFirstLetter(ProviderProfile.address?.state)}.`,
      service: ProviderProfile.service_plans.find(
        (plan: any) => plan.id === values?.plan,
      )?.name,
      preferred_date: moment(values?.preferred_date).format("LL"),
      preferred_time: moment(values?.preferred_time).format("hh:mm a"),
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };

    try {
      if (formPage === "newUser" && !pageIndex) {
        const _values = values as RegisterUserValues;
        _values.email = noEmail
          ? `frontdesk${formattedPhone}@pneuma.care`
          : _values.email;
        _values.phone = formattedPhone;
        _values.dob = noDOB
          ? moment("01/01/2004")
          : moment(_values.dob, "DD/MM/YYYY");

        const localStoreProviderID = window.localStorage.getItem("providerID")!;
        //check provider's patient collection for user's email - if it exists, push to the existingUser page, else continue with createUser flow.
        let doesExist = false,
          doesExistInGeneral = false;

        try {
          const patientsRef = db
            .collection("providerInfo")
            .doc(localStoreProviderID)
            .collection("patients")
            .where("email", "==", _values.email);

          doesExist = !!(await patientsRef.get()).docs.length;

          const generalPatientsRef = db
            .collection("patients")
            .where("email", "==", _values.email);

          doesExistInGeneral = Boolean(
            (await generalPatientsRef.get()).docs.length,
          );

          const generalPatientInfo = (await generalPatientsRef.get()).docs[0];
          if (doesExist === false) {
            if (doesExistInGeneral) {
              // if patient exist in general collection, add to local provider collection
              await db
                .collection("providerInfo")
                .doc(localStoreProviderID)
                .collection("patients")
                .doc(generalPatientInfo.data().id)
                .set({
                  ...generalPatientInfo.data(),
                  created_at: firebase.firestore.FieldValue.serverTimestamp(),
                });

              // update selected user state to newly created user.
              // dispatch(
              //   setSelectedUser({
              //     dob: generalPatientInfo.data().dob,
              //     email: generalPatientInfo.data().email,
              //     first_name: generalPatientInfo.data().first_name,
              //     gender: generalPatientInfo.data().gender,
              //     id: generalPatientInfo.data().id,
              //     last_name: generalPatientInfo.data().last_name,
              //     phone: generalPatientInfo.data().phone,
              //   })
              // );
            } else {
              // else..at this point it doesn't exist anywhere..create fresh user.
              await dispatch(createUser(_values));
            }
          } else {
            // doesExist is true at this branch
            setFormPage("existingUser");
            message.success(
              "User already exists. Please select or search user",
            );
          }
          setPageIndex(1);
          await dispatch(getPatients());
        } catch (err: any) {
          if (err.message.includes("User account already exists")) {
            message.error(err.message);
            setFormPage("requestAccess");
          }
        }
      } else if (formPage === "existingUser" && !pageIndex) {
        const { user } = values as UserValueType;
        const _user = JSON.parse(user);
        const userID = _user.id;
        const selectedUser: UserType | any = users?.find(
          (user: UserType) => user.id === userID,
        )!;
        dispatch(setSelectedUser(selectedUser));
        setPageIndex(1);
      } else if (formPage === "requestAccess" && !pageIndex) {
        try {
          const { email } = values as RequestValueType;
          await dispatch(requestPatientAccess(email));
          message.success("Code sent! check your email for your OTP code.");
          setPageIndex(1);
        } catch (err: any) {
          console.log(err);
          message.error(err.message);
          setFormPage("newUser");
        }
      } else {
        const _values = values as BookValuesType;
        if (!appointmentType) {
          throw new Error("Please choose an appointment type!");
        } else {
          if (formPage === "requestAccess" && pageIndex) {
            await dispatch(approvePatientAccessRequest(otp));
          }
          if (serviceform?.metadata?.service_form) {
            setPageIndex(pageIndex + 1);
            setGetAppointmentInfo({ ..._values });
          } else {
            await dispatch(
              bookService({
                ..._values,
                source: appointmentType,
                patientId: user?.patientId,
              }),
            );
            if (appointmentType === "callIn") {
              // send acceptance email
              await send(
                process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
                "temp_accepted_booking",
                acceptedTemplateParams,
              );
            }
            MixPanel.track("CreateAppointment", {
              service: ProviderProfile.service_plans.find(
                (plan: any) => plan.id === _values?.plan,
              )?.name,
              preferred_date: moment(_values.preferred_date).format("LL"),
              preferred_time: _values.preferred_time
                ? moment(_values.preferred_time).format("hh:mm a")
                : "Unavailable",
            });

            await dispatch(fetchAllServiceOrders());
            message.success("Appointment booked successfully!");
            resetFormModal();
          }
        }
      }
    } catch (error: any) {
      const err = error as Error;
      if (err.message.includes("User not found")) {
        // TODO -> remember to fix ASAP.
        // if user is not found (this means that the selected user does not exist on the current integration - FrontDesk/PneumaPage). We would make a hacky workaround to account for erroneous Divine dentals mass record upload (the data was mistakenly uploaded to the wrong integration - Telehealth/North) and use that integration's API key instead to make the booking.
        try {
          const _values = values as BookValuesType;

          await dispatch(
            bookServiceWithNorthKey({
              ..._values,
              source: appointmentType!,
              patientId: user?.patientId,
            }),
          );

          MixPanel.track("CreateAppointment", {
            service: ProviderProfile.service_plans.find(
              (plan: any) => plan.id === _values?.plan,
            )?.name,
            preferred_date: moment(_values.preferred_date).format("LL"),
            preferred_time: _values.preferred_time
              ? moment(_values.preferred_time).format("hh:mm a")
              : "Unavailable",
          });

          if (appointmentType === "callIn") {
            // send acceptance email
            await send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
              "temp_accepted_booking",
              acceptedTemplateParams,
            );
          }

          await dispatch(fetchAllServiceOrders());
          message.success("Appointment booked successfully!");
        } catch (err: any) {
          console.log(err);
          message.error(err.message);
        }
      } else if (err.message.includes("User account already exists")) {
        message.error(err.message);
        setFormPage("requestAccess");
      } else {
        message.error(err.message);
      }
    }
    setIsLoading(false);
  };

  // handling the consultation after submitting form response

  async function handleConsult(answers: any) {
    try {
      dispatch(
        bookService({
          ...getAppointmentInfo,
          source: appointmentType!,
          patientId: user?.id,
          form_response: answers,
        }),
      );
      message.success("Appointment booked successfully!");
      setIsCreatingAppointment(false);
      resetFormModal();
    } catch (e) {
      console.log(error);
      message.error(error?.data?.error);
    }
  }

  async function handleResponses() {
    setIsSubmittingResponse(true);
    try {
      const answerResponseData = await submitFormMutation({
        service_order: getAppointmentInfo?.plan,
        user: user?.id,
        form: questionId,
        responses: Object.values(responses).map((response: any) => {
          const newResponse = { ...response };
          delete newResponse.next_question;
          delete newResponse.question_text;
          return newResponse;
        }),
      }).unwrap();
      message.success(answerResponseData.message);
      if (answerResponseData.message === "Service form answered") {
        await handleConsult(answerResponseData?.data?.id);
      }
    } catch (err: any) {
      message.error(err.message || "Something went wrong. Please try again.");
      console.log(err.message);
    } finally {
      setIsSubmittingResponse(false);
    }
  }

  return (
    <ModalWrapper
      visible={isBookingModalVisible}
      onCancel={resetFormModal}
      closable={false}
      footer={false}
      destroyOnClose
    >
      <header>
        <h1>Create a new appointment</h1>
        <button onClick={resetFormModal}>
          <CloseOutlined style={{ fontSize: "1.2rem" }} />
        </button>
      </header>
      <Form
        name="basicForm"
        layout="vertical"
        form={addBookForm}
        className="form"
        onFinish={createAppointment}
      >
        <ModalContent>
          <div className="step">
            <Steps size="default" current={pageIndex} direction={"vertical"}>
              <Step title="Add patient or select existing patient" />
              <Step title="Add appointment details" />
              {questionId && <Step title="Fill intake form" />}
            </Steps>
          </div>
          <div className="content">
            {!pageIndex && formPage !== "requestAccess" ? (
              <Row gutter={18} className="row-icons">
                <Col span={12}>
                  <Card
                    className={formPage === "newUser" ? "isCardActive" : ""}
                    onClick={() => setFormPage("newUser")}
                  >
                    <IconNew
                      stroke={formPage === "newUser" ? "#0FAFF0" : "#344054"}
                    />{" "}
                    New patient
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className={
                      formPage === "existingUser" ? "isCardActive" : ""
                    }
                    onClick={() => setFormPage("existingUser")}
                  >
                    <IconOld
                      stroke={
                        formPage === "existingUser" ? "#0FAFF0" : "#344054"
                      }
                      className="icon-2"
                    />{" "}
                    Existing patient
                  </Card>
                </Col>
              </Row>
            ) : pageIndex === 1 ? (
              <Row gutter={18} className="row-icons">
                <h3>
                  <span>*</span> Choose appointment method
                </h3>
                <Col span={12}>
                  <Card
                    className={
                      appointmentType === "walkIn" ? "isCardActive" : ""
                    }
                    onClick={() => setAppointmentType("walkIn")}
                  >
                    <img
                      src={WalkInIcon}
                      alt="walk in"
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />{" "}
                    Walk in
                  </Card>
                </Col>
                <Col span={12}>
                  <Card
                    className={
                      appointmentType === "callIn" ? "isCardActive" : ""
                    }
                    onClick={() => setAppointmentType("callIn")}
                  >
                    <img
                      src={CallInIcon}
                      alt="call in"
                      style={{
                        width: "30px",
                        height: "30px",
                        objectFit: "cover",
                        marginRight: "10px",
                      }}
                    />{" "}
                    Call in
                  </Card>
                </Col>
              </Row>
            ) : (
              pageIndex === 2 && (
                <Questions
                  form={data?.data[0]}
                  currentFormStep={currentFormStep}
                  isLoading={isQuestionLoading}
                  handleQuestionFormChange={handleQuestionFormChange}
                  currentQuestionId={currentQuestionId}
                  normalizedQuestions={normalizedQuestions}
                  responses={responses}
                />
              )
            )}

            <Row gutter={24}>
              {formPage === "newUser"
                ? bookingPage.new[pageIndex]
                : formPage === "existingUser"
                ? bookingPage.existing[pageIndex]
                : bookingPage.request[pageIndex]}
            </Row>
          </div>
        </ModalContent>

        <div className="btn__wrapper">
          {pageIndex === 2 ? (
            <>
              <Button
                onClick={() => {
                  if (currentFormStep === 0) {
                    setPageIndex(1);
                  } else if (currentQuestionId === 1) {
                    handleFormStepChange("start");
                  } else {
                    setQuestionIds((p) => {
                      const result = [...p];
                      result.pop();
                      return result;
                    });
                  }
                }}
              >
                <CaretLeft />
                Back
              </Button>

              {normalizedQuestions[currentQuestionId]?.id ===
              forms.number_of_questions ? (
                <Button
                  disabled={
                    (normalizedQuestions[currentQuestionId]?.required &&
                      !responses[currentQuestionId]?.value) ||
                    isSubmittingResponse ||
                    isCreatingAppointment
                  }
                  onClick={async () => {
                    setIsCreatingAppointment(true);
                    try {
                      await handleResponses();
                    } catch (err) {
                      console.log(err);
                    }
                  }}
                >
                  {isCreatingAppointment
                    ? "processing...."
                    : "Book appointment"}{" "}
                  <CaretRight />
                </Button>
              ) : (
                <Button
                  disabled={
                    currentFormStep === 0
                      ? false
                      : normalizedQuestions[currentQuestionId]?.required &&
                        !responses[currentQuestionId]?.value
                  }
                  onClick={() => {
                    if (currentFormStep === 0) {
                      handleFormStepChange("question");
                    } else {
                      handleNextQuestion();
                    }
                  }}
                >
                  {currentFormStep === 0
                    ? "Start questionnaire"
                    : "Next Question"}
                  <CaretRight />
                </Button>
              )}
            </>
          ) : (
            <>
              <Button
                disabled={pageIndex === 0}
                onClick={() => setPageIndex(pageIndex - 1)}
                style={{
                  display: pageIndex === 0 ? "none" : "flex",
                }}
              >
                <CaretLeft />
                Back
              </Button>

              <Button type="primary" className={"right"} htmlType={"submit"}>
                {ButtonTexts()}{" "}
                <CaretRight
                  style={{
                    display: !pageIndex || questionId ? "block" : "none",
                  }}
                />
              </Button>
            </>
          )}
        </div>
      </Form>
    </ModalWrapper>
  );
};

export default CreateConsultationModal;
