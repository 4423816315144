import { Moment } from "moment";

interface ErrorType {
  message: string;
}

interface ErrorTypeAPI extends ErrorType {
  response: {
    data: {
      error?: string;
      message: string;
    };
    status: number;
  };
}

interface RegisterUserValues {
  first_name: string;
  last_name: string;
  email: string;
  gender: string;
  dob: Moment;
  phone: string;
  patientId?: string;
}

interface UserType {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  phone: string;
  gender: string;
  dob: string;
  patientId?: string;
}

interface RegisteredReturnType {
  data: {
    data: UserType;
  };
}

interface BookValuesType {
  plan: string;
  preferred_date: Moment;
  preferred_time?: Moment;
  note: string;
  source: "walkIn" | "callIn" | "leads";
  payment_method: "cash" | "insurance";
  insurance_id?: string;
  insurance_provider?: string;
  user?: string;
  patientId?: string;
  additionalServices?: string[];
  assignedPractitioner?: string;
  form_response: string;
}

export interface ServicePlan {}

interface UserValueType {
  user: string;
}

interface RequestValueType {
  email: string;
}

export type SingleFollowUpType = {
  created_at: Date;

  date: Date;

  goal: string;
  id: string;
  metadata: any;

  note: string;
  provider: string;
  service_order: {
    id: string;
    service_plan: string;
  };
  status: string;
  user: {
    email: string;
    first_name: string;
    last_name: string;
    id: string;
  };
};
export type NoteType = {
  id: string;
  note: string;
  created_at: string;
  updated_at: string;
};

export type GetSpecificNoteRes = {
  message: string;
  data: NoteType[];
};
export type SendSingleMessageInput = [
  {
    user: string;
    message: string;
  }
];

export type SendSingleEmailInput = [
  {
    user: string;
    subject: string;
    body: string;
  }
];

export type SendMultipleMessagesInput = {
  users: string[];
  message: string;
};

export type SendMultipleEmailsInput = {
  users: string[];
  subject: string;
  body: string;
  reply_to: string;
};

export type SingleMessageType = {
  created_at: string;
  id: string;
  message: string;
  provider: string;
  sent_at: string;
  status: string;
  to: string;
  updated_at: Date;
  user: {
    first_name: string;
    id: string;
    last_name: string;
  };
};

export type GetAllMessagesRes = {
  message: string;
  data: SingleMessageType[];
};

export type GetAllEmailsRes = {
  id: number;
  from: string;
  to: string;
  subject: string;
  body: string;
  provider: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
  };
  status: string;
  sent_at: Date;
  created_at: Date;
  updated_at: Date;
};

export type GetSpecificPatientSMSRes = {
  message: string;
  data: SingleMessageType[];
};

export type SingleBooking = {
  created_at: Date;
  id: string;
  status: FollowUpStatus;
  note: string;
  metadata: {
    preferred_date: string;
    preferred_time: string;
    rating: number;
  };
  plan: {
    id: string;
    name: string;
  };
  user: {
    email: string;
    first_name: string;
    id: string;
    last_name: string;
    phone: string;
  };
};

export type FollowUpStatus = "pending" | "completed" | "cancelled" | "canceled";

export type AllFollowupsRes = {
  message: string;
  data: {
    id: string;
    goal: string;
    service: {
      id: string;
      plan: string;
    };
    date: Date;
    user: string;
    status: FollowUpStatus;
    note: string;
    provider: string;
    created_at: Date;
  }[];
};

export type CreateFollowUpInput = {
  goal: string;
  date: string | Date;
  service_order: string;
  note: string;
};
export type CreateFinancialRequestInput = {
  service_plan: string;
  user: string;
};

export type CreateFollowUpRes = {
  message: string;
  data: {
    id: string;
    goal: string;
    service: {
      id: string;
      plan: string;
    };
    date: Date;
    user: string;
    status: FollowUpStatus;
    note: string;
    provider: string;
    created_at: Date;
  };
};
export type CreateFormInput = {
  name: string;
  category: string;
  description: string;
  specialty: string;
  icon?: string | null;
  required?: boolean;
  questions?: QuestionsInput[];
};

export type QuestionsInput = {
  id: number;
  text: string;
  hint: string;
  type: string;
  response: ResponseInput[] | ResponseInput;
};

export type ResponseInput = {
  id: number;
  value: string;
};

export type UnsplashRes = {
  results: {
    id: string;
    blur_hash: string;
    width: number;
    height: number;
    alt_description: string;
    urls: {
      full: string;
      raw: string;
      regular: string;
      small: string;
      small_s3: string;
      thumb: string;
    };
    user: {
      id: string;
      name: string;
      links: {
        self: string;
        html: string;
      };
    };
  }[];
};

export type SingleLead = {
  id: string;
  reason: string;
  type: string;
  status: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
  };
  preferred_date: Date;
  preferred_time: string;
  has_insurance: boolean;
  insurance?: string;
  insurance_id?: string;
  location: string;
  created_at: Date;
};
export type SingleFinancialRequest = {
  id: string;
  status: string;
  user: {
    id: string;
    first_name: string;
    last_name: string;
  };
  service_plan: {
    id: string;
    name: string;
    price: number;
  };

  created_at: Date;
};

export type SingleUnlockedLead = {
  id: string;
  service_provider: string;
  lead: {
    id: string;
    user: {
      id: string;
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
    };
    reason: string;
    type: string;
    status: string;
    preferred_date: Date;
    preferred_time: string;
    has_insurance: boolean;
    insurance?: string;
    insurance_id: string;
    location: string;
    created_at: Date;
  };
  status: string;
  created_at: Date;
};

export type SingleFormType = {
  id: string;
  category: string;
  created_at: string;
  name: string;
  specialty: string;
  icon: string;
  questions: QuestionType[];
  description: string;
  number_of_questions?: number;
};

export interface QuestionType {
  id: number;
  text: string;
  response: Responses[];
  hint: string;
  required?: boolean;
  type: string;
}

export interface Responses {
  id: number;
  value: string;
}

export type ResponseType = {
  created_at: string;
  id: string;
  form: {
    id: string;
    name: string;
    description: string;
  };
  metadata: string;
  responses: IResponses[];
  user: {
    id: string;
    first_name: string;
    last_name: string;
  };
  total_score: string;
};

export interface IResponses {
  question: number;
  question_text: string;
  response: number;
  score: number;
  value: string;
}
export type GetLeadsRes = {
  data: {
    data: Array<SingleLead>;
    pagination: { size: number; next: string | null; previous: string | null };
  };
  message: string;
};

export type GetFinancialRequestRes = {
  data: {
    data: Array<SingleFormType> | any;
    pagination: { size: number; next: string | null; previous: string | null };
  };
  message: string;
};

export type GetServiceFormsRes = {
  data: SingleFormType[];
  message: string;
};

export type GetServiceFormAnswer = {
  data: ResponseType[];
  message: string;
};

export type ViewLeadRes = {
  message: string;
  data: SingleUnlockedLead;
};

export type GetProviderLeadsRes = {
  data: {
    data: Array<SingleUnlockedLead>;
    pagination: { size: number; next: string | null; previous: string | null };
  };
  message: string;
};

export type UpdateLeadInput = {
  id: string;
  status: string;
};

export type SubmitServiceFormResponseInput = {
  service_order: string;
  user: string;
  form: string;
  responses: FormResponse[];
};

export interface FormResponse {
  question: number;
  response: number;
  value: any;
}
export type UpdateLeadRes = {
  message: string;
  data: SingleUnlockedLead;
};

export type LeadUser = SingleUnlockedLead["lead"]["user"];

export {
  type RegisterUserValues,
  type ErrorTypeAPI,
  type RegisteredReturnType,
  type BookValuesType,
  type UserType,
  type UserValueType,
  type RequestValueType,
};

export const BookingsRTKQueryTagEnum = {
  BOOKINGS: "BOOKINGS",
};

export const MessagesRTKQueryTagEnum = {
  MESSAGES: "MESSAGES",
};

export const NOTERTKQueryTagEnum = {
  NOTES: "NOTES",
};

export const FINANCIALREQUESTRTKQueryTagEnum = {
  FINACIAL_REQUEST: "FINACIAL_REQUEST",
};
export const SERVICE_FORMSRTKQueryTagEnum = {
  SERVICE_FORMS: "SERVICE_FORMS",
};

export const SpecificMessagesRTKQueryTagEnum = {
  SPECIFIC_MESSAGES: "SPECIFIC_MESSAGES",
};

export const FollowUpsRTKQueryEnum = {
  FOLLOW_UPS: "FOLLOW_UPS",
};

export const LeadsRTKQueryEnum = {
  LEADS: "LEADS",
};

export const ProviderLeadsRTKQueryEnum = {
  PROVIDER_LEADS: "PROVIDER_LEADS",
};
