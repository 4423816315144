import React from "react";
import { Col, Form, Row, Select as AntdSelect, Space } from "antd";
import { Row as Rows } from "./style";
import Button from "component/Button";
import Input from "component/Input";
import Select from "../Select";
import TextArea from "../TextArea";
import { FormItem } from "layouts/DashboardLayout/styles";
import { DateOfBirthPicker } from "component/DatePicker/styles";
import DatePicker from "component/DatePicker";
const BioForm = () => {
  const [form] = Form.useForm();

  return (
    <Form name="addFollowUpForm" layout="vertical">
      <Rows>
        <h1>Patient Name</h1>
        <Row gutter={24}>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "First Name",
                name: "first_name",
                rules: [
                  {
                    required: true,
                    message: "Please input first name",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "Last Name",
                name: "last_name",
                rules: [
                  {
                    required: true,
                    message: "Please input last name",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col span={24} md={10}>
            <FormItem
              label="Date of birth"
              name="dob"
              rules={[
                {
                  required: true,
                  message: "Input Patient Date of birth",
                },
              ]}
            >
              <DatePicker
                marginBottom={0}
                mode="normal"
                width="auto"
                format={"YYYY-MM-DD"}
                // onChange={onChangeDate}
                inputReadOnly={true}
              />
            </FormItem>
          </Col>
          <Col span={24} md={10}>
            <Select
              formItem={{
                label: "Gender",
                name: "gender",
                rules: [
                  {
                    required: true,
                    message: "Please select patient gender",
                  },
                ],
              }}
              mode="normal"
              placeholder="Select patient gender"
            >
              {["Male", "Female"].map((gender: string, index: number) => (
                <AntdSelect.Option key={index} value={gender}>
                  <span>{gender}</span>
                </AntdSelect.Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Rows>
      <Rows>
        <h1>Contact information</h1>
        <Row gutter={24}>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "Email address",
                name: "email_address",
                rules: [
                  {
                    required: true,
                    message: "Please input patient Email address",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col xs={24} md={10}>
            <Input
              formItem={{
                label: "Phone number",
                name: "phone",
                rules: [
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                  {
                    pattern: /^([+]?)\d+$/,
                    message: "Phone number can only contain digits",
                  },
                ],
              }}
              mode="normal"
              type="text"
              label="Phone number"
            />
          </Col>
        </Row>
      </Rows>
      <Rows>
        <h1>Contact address</h1>
        <Row gutter={24}>
          <Col xs={24}>
            <TextArea
              formItem={{
                name: "home_address",
                label: "Home address",
                rules: [
                  {
                    required: true,
                    message: "Please enter home address",
                  },
                ],
              }}
              placeholder="Enter home address"
              maxLength={300}
              label=""
              mode="normal"
              autoSize={{ minRows: 4, maxRows: 4 }}
            />
          </Col>
          <Col xs={24} md={8}>
            <Input
              formItem={{
                label: "City",
                name: "city",
                rules: [
                  {
                    required: true,
                    message: "Please input City",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col xs={24} md={8}>
            <Input
              formItem={{
                label: "State",
                name: "state",
                rules: [
                  {
                    required: true,
                    message: "Please input City",
                  },
                ],
              }}
              mode="normal"
              type="text"
            />
          </Col>
          <Col span={24} md={8}>
            <Input
              formItem={{
                label: "Country",
                name: "country",
                rules: [
                  {
                    required: true,
                    message: "Please input Country",
                  },
                ],
              }}
              mode="normal"
              type="email"
            />
          </Col>
        </Row>
      </Rows>

      <Button
        type={"primary"}
        style={{ marginTop: "40px", width: "207px", height: "48px" }}
      >
        Update profile
      </Button>
    </Form>
  );
};

export default BioForm;
