import connectApi from "./connect-api";
import { GetSpecificNoteRes, NOTERTKQueryTagEnum } from "../../constants/types";
import { providesList } from "../../utils/redux-toolkit-query";

export const notesApi = connectApi.injectEndpoints({
  endpoints: (builder) => ({
    createNote: builder.mutation<{ user: string; note: string }, any>({
      query: ({ user, note }) => ({
        url: `/users/${user}/notes`,
        data: JSON.stringify({ note }),
        method: "POST",
      }),
      invalidatesTags: [NOTERTKQueryTagEnum.NOTES as any],
    }),

    updateNote: builder.mutation<
      any,
      { patientId: string; noteId: string; note: string }
    >({
      query: ({ noteId, note, patientId }) => ({
        url: `/users/${patientId}/notes/${noteId}`,
        data: JSON.stringify({ note }),
        method: "POST",
      }),
      invalidatesTags: [NOTERTKQueryTagEnum.NOTES as any],
    }),
    getSpecificPatientNote: builder.query<
      GetSpecificNoteRes,
      { patientId: string }
    >({
      query: ({ patientId }) => ({
        url: `/users/${patientId}/notes`,
      }),
      providesTags: (data) =>
        providesList(NOTERTKQueryTagEnum.NOTES, data?.data!) as any,
    }),
  }),
  overrideExisting: true,
});

notesApi.enhanceEndpoints({
  addTagTypes: [...Object.values(NOTERTKQueryTagEnum)],
});
export const {
  useCreateNoteMutation,
  useGetSpecificPatientNoteQuery,
  useUpdateNoteMutation,
} = notesApi;
