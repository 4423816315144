import { Table, Row, Col, Dropdown, Menu, message, Tooltip } from "antd";
import { EditOutlined, SettingOutlined } from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { send } from "emailjs-com";
import StarRatings from "react-star-ratings";
import firebase from "firebase";
import { ReactComponent as ViewMoreIcon } from "assets/icons/details-icon.svg";
import Card from "component/Card";
import {
  LabRequestsTableWrapper,
  LabRequestsTableBody,
  SearchAndCreateWrapper,
} from "./styles";
import Button from "component/Button";
import DatePicker from "component/DatePicker";
import LabRequestsDrawer from "../LabRequestsDrawer";
import {
  fetchAllServiceOrders,
  requestsSelector,
  updateOrderStatus,
} from "redux/reducers/requests";
import { profileSelector } from "redux/reducers/profile";
import Search from "component/Search";
import DropdownIcon from "assets/icons/dropdown.svg";
import InsuranceImg from "assets/images/insurance.png";
import CashImg from "assets/images/banknotes.png";
import { db } from "utils/firebase";
import { fetchSingleUser } from "redux/reducers/users";
import EditAppointmentModal from "component/EditAppointmentModal";
import { capitaliseFirstLetter } from "utils/utils";
import { bookingsApi } from "redux/queries/bookings";
import { setBookingModalState } from "redux/reducers/servicePlans";
import { useSendSingleMessageMutation } from "redux/queries/messages";
import { useAppSelector } from "redux/store";
import { ReactComponent as NoteIcon } from "assets/icons/note-bl.svg";
import { useHistory } from "react-router-dom";
import { ROUTES } from "../../../../constants/routes";
import CreateConsultationModal from "../../../../component/CreateConsultationModal";

const Statuses: any = {
  Pending: "pending",
  Accepted: "accepted",
  Completed: "completed",
  Cancelled: "canceled",
};

interface LabRequestsTableProps {
  count?: number;
  hasPagination?: boolean;
  marginTop?: number;
  dashboardMode?: string;
  status?: string;
}

const LabRequestsTable = ({
  count,
  dashboardMode,
  status,
}: LabRequestsTableProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState("");
  const { investigations, isInvestgationsLoading } =
    useSelector(requestsSelector);
  const { profile: ProviderProfile } = useSelector(profileSelector);
  const [labRequestsState, setLabRequestsState] = useState<any[]>([]);
  const [selectedMonth, setSelectedMonth] = useState<any>();
  const [selectedLabRequest, setSelectedLabRequests] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [isLabRequestsDrawerOpen, setIsLabRequestsDrawerOpen] = useState(false);
  const [isAppointmentModalOpen, setIsAppointmentModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState();
  const [isConsultModalVisible, setIsConsultModalVisible] =
    useState<boolean>(false);

  const [sendSingleMessage] = useSendSingleMessageMutation();
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription,
  );

  // setup bookings live updates connection
  bookingsApi.useGetBookingsQuery(
    useMemo(
      () => ({ path: { profileId: ProviderProfile?.id } }),
      [ProviderProfile?.id],
    ),
    { skip: !ProviderProfile?.id },
  );

  useEffect(() => {
    if (investigations.length) {
      setLabRequestsState(
        investigations.filter((item: any) =>
          status ? item.status === status : item,
        ),
      );
    }
  }, [investigations, status]);

  const handleOpenDrawer = (id: string) => {
    const item = labRequestsState.filter((data) => data.id === id);
    setSelectedLabRequests(item);
    setIsLabRequestsDrawerOpen(true);
  };

  const handleOpenModal = (id: string) => {
    const item = labRequestsState.filter((data) => data.id === id)[0];
    setIsAppointmentModalOpen(true);
    setSelectedAppointment(item);
  };

  const changeStatusText = (text: string) => {
    switch (text) {
      case "Accepted":
        return "Accept";
      case "Started":
        return "Check in";
      case "Completed":
        return "Complete";
      case "Cancelled":
        return "Cancel";
      case "Pending":
        return "Pending";
    }
  };

  const statusMenu = (record: any, status: string) => (
    <Menu
      className="status-menu"
      onClick={(e: any) => {
        handleStatusUpdate(record, e.key);
      }}
    >
      {Object.keys(Statuses).map((key: string) => (
        <Menu.Item
          key={Statuses[key]}
          className={status === Statuses[key] ? "active" : ""}
        >
          <span style={{ textTransform: "capitalize" }}>
            {changeStatusText(key)}
          </span>
        </Menu.Item>
      ))}
    </Menu>
  );

  // checks if user exists on firebase providerID patients (if it does, it'll exist on the generic patients). if user does not exist, get user details using id, add updated user info to generic patients and specific provider >patient collection
  const checkUser = async (userId: string, email: string) => {
    const localStoreProviderID = window.localStorage.getItem("providerID")!;
    let doesExist = false;

    try {
      const patientsRef = db
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("patients")
        .where("email", "==", email);

      doesExist = !!(await patientsRef.get()).docs.length;
    } catch (err) {
      console.log(err);
    }

    if (doesExist === false) {
      try {
        // get User
        const user: any = await dispatch(fetchSingleUser(userId));

        const userObject = {
          id: user.id,
          email: user.email,
          first_name: user.first_name,
          last_name: user.last_name,
          phone: user.phone,
          gender: user.gender,
          dob: user.dob,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        };

        // add to patients
        await db.collection("patients").doc(user.id).set(userObject);

        // add to providerInfo > patients
        await db
          .collection("providerInfo")
          .doc(localStoreProviderID)
          .collection("patients")
          .doc(user.id)
          .set(userObject);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const handleStatusUpdate = async (record: any, status: string) => {
    const payload = { status };

    // accepted emailjs template
    const acceptedTemplateParams = {
      slug: ProviderProfile.slug || ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      to_name: `${capitaliseFirstLetter(record.user?.first_name)}`,
      to_email: record.user.email,
      business_phone: ProviderProfile.phone,
      business_email: ProviderProfile.email,
      business_address: `${ProviderProfile.address?.street_line_one}, ${ProviderProfile.address?.street_line_two}, ${ProviderProfile.address.city}, ${ProviderProfile.address.state}.`,
      service: record.plan.name,
      preferred_date: record.metadata?.preferred_date as string,
      preferred_time: record.metadata?.preferred_time as string,
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };

    // rejected emailjs template
    const rejectedTemplateParams = {
      slug: ProviderProfile.slug || ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      to_name: `${capitaliseFirstLetter(record.user?.first_name)}`,
      to_email: record.user.email,
      business_email: ProviderProfile.email,
      business_phone: ProviderProfile.phone,
      preferred_date: record.metadata?.preferred_date as string,
      service: record.plan.name,
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };

    // completed emailjs template
    const completedTemplateParams = {
      slug: ProviderProfile.slug,
      provider_id: ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      business_email: ProviderProfile.email,
      to_name: `${capitaliseFirstLetter(record.user?.first_name)}`,
      to_email: record.user.email,
      patient_id: record.user.id,
      service: record.plan.name,
      service_id: record.id,
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };

    setLoading(true);

    if (
      (record.metadata?.payment_method?.includes("cash") ||
        record.metadata?.payment_method?.includes("insurance")) &&
      status === "completed"
    ) {
      Swal.fire({
        title: "Are you sure?",
        text: `${
          record.metadata?.payment_method?.includes("insurance")
            ? "The payment method for this booking is Insurance. Kindly authorize with the insurance provider before completing."
            : "The payment method for this booking is Cash. Please confirm you have collected cash from the patient."
        }`,
        confirmButtonColor: "#0f9af0",
        confirmButtonText: "Yes, complete booking",
        showCancelButton: true,
        iconHtml: `<img className="payment_method_swal_image" src=${
          record.metadata?.payment_method?.includes("insurance")
            ? InsuranceImg
            : CashImg
        } />`,
        customClass: {
          title: "payment_modal__title",
          icon: "payment_modal__icon",
        },
      }).then(async (res) => {
        if (!res.isConfirmed) {
          return null;
        }

        try {
          await dispatch(updateOrderStatus(record.id, payload));

          // run check user here
          await checkUser(record.user.id, record.user.email);

          await send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
            "template_feedback",
            completedTemplateParams,
          );

          const bitRes = await axios.post(
            "https://api-ssl.bitly.com/v4/shorten",
            {
              long_url: `https://pneumapage.com/${ProviderProfile.id}/${record.user.id}/${record.id}/review`,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
              },
            },
          );
          await sendSingleMessage([
            {
              message: `Hello, how would you rate your ${record.plan.name} experience? Click the link below to let us know. ${bitRes.data.link}`,
              user: record.user?.id,
            },
          ]);

          message.success("Status updated successfully!");

          dispatch(fetchAllServiceOrders());
        } catch (error) {
          const err = error as Error;
          message.error(err.message);
        } finally {
          setLoading(false);
        }
      });
    } else {
      try {
        await dispatch(updateOrderStatus(record.id, payload));

        if (status === "accepted") {
          await checkUser(record.user.id, record.user.email);
        }

        if (
          status === "accepted" &&
          record.metadata?.payment_source !== "walkIn"
        ) {
          await send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
            "temp_accepted_booking",
            acceptedTemplateParams,
          );
        }

        if (status === "completed") {
          await send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
            "template_feedback",
            completedTemplateParams,
          );

          const bitRes = await axios.post(
            "https://api-ssl.bitly.com/v4/shorten",
            {
              long_url: `https://pneumapage.com/${ProviderProfile.id}/${record.user.id}/${record.id}/review`,
            },
            {
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_BITLY_TOKEN}`,
              },
            },
          );
          await sendSingleMessage([
            {
              message: `Hello, how would you rate your ${record.plan.name} experience? Click the link below to let us know. ${bitRes.data.link}`,
              user: record.user?.id,
            },
          ]);
        }

        if (status === "canceled") {
          await send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
            "template_tuhfyiq",
            rejectedTemplateParams,
          );
        }

        dispatch(fetchAllServiceOrders());
        message.success("Status updated successfully!");
      } catch (error) {
        console.log(error);
        const err = error as Error;
        message.error(err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const capitaliseWords = (words: string): string => {
    return words
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const columns = [
    {
      title: "Patient ID",
      width: 200,
      render: (_: string, record: any) => <p>{record?.metadata?.patientId}</p>,
    },
    {
      title: "Patient",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      sorter: (a: any, b: any) =>
        a?.user?.first_name.toLowerCase() < b?.user?.first_name.toLowerCase()
          ? -1
          : a?.user?.first_name.toLowerCase() >
            b?.user?.first_name.toLowerCase()
          ? 1
          : 0,
      render: (_: string, record: any) => {
        const { first_name, last_name } = record?.user || {};
        return (
          <div>
            <p
              style={{
                margin: 0,
                maxWidth: "max-content",
                minWidth: "120px",
                lineHeight: "1.7",
                textTransform: "capitalize",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {first_name} {last_name}
              {String(record?.metadata?.existingPatient) &&
              record?.metadata?.existingPatient === false ? (
                <span
                  style={{
                    backgroundColor: "rgb(152, 241, 190)",
                    padding: "2px 6px",
                    borderRadius: "14px",
                    color: "#08873D",
                    fontSize: "12px",
                    maxWidth: "fit-content",
                  }}
                >
                  New patient
                </span>
              ) : null}
            </p>
          </div>
        );
      },
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      render: (_: any, record: any) => {
        return (
          <div
            style={{
              display: "flex",
            }}
          >
            <p style={{ width: "180px" }}>
              {record?.plan?.name}
              {record?.metadata?.additionalServices?.length ? (
                <span
                  style={{
                    display: "block",
                    fontWeight: 400,
                    fontSize: "12px",
                    lineHeight: "120%",
                    color: "#0D97CD",
                    marginTop: "5px",
                  }}
                >
                  + {record?.metadata?.additionalServices?.length} services
                </span>
              ) : null}
            </p>
            {record?.metadata?.form_response && (
              <Tooltip title="This service booking has an intake form">
                <Button
                  style={{
                    background: "transparent",
                    width: 0,
                  }}
                >
                  <NoteIcon
                    style={{
                      fontSize: ".75rem",
                    }}
                  />
                </Button>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      title: "Schedule",
      dataIndex: "schedule",
      key: "schedule",
      render: (_: Date, record: any) => {
        const dateData = record?.metadata?.preferred_date;
        const timeData = record?.metadata?.preferred_time;
        return (
          <>
            <p
              style={{
                maxWidth: "max-content",
                minWidth: "100px",
                lineHeight: "1.7",
              }}
            >
              {dateData && moment(dateData, "DD/MM/YYYY").isValid()
                ? moment(dateData, "DD/MM/YYYY").format("ll")
                : "Unavailable"}
            </p>
            <span className="subtext">{timeData ? timeData : ""}</span>
          </>
        );
      },
    },
    {
      title: "Payment method",
      dataIndex: "payment_method",
      key: "payment_method",
      render: (_: any, record: any) => {
        const { payment_method } = record?.metadata || {};

        let paymentMethodFormatted = "";

        if (payment_method === "insurance") {
          paymentMethodFormatted = "Insurance";
        } else if (payment_method === "search") {
          paymentMethodFormatted = "Marketplace";
        } else if (
          payment_method === "pneumapay" ||
          payment_method === "pneuma-pay"
        ) {
          paymentMethodFormatted = "PneumaCare";
        } else if (
          payment_method === "cash at check-in" ||
          payment_method === "cash"
        ) {
          paymentMethodFormatted = "Cash";
        }
        return <p style={{ width: "max-content" }}>{paymentMethodFormatted}</p>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: labRequestsState
        .map(({ status }) => ({
          text: capitaliseWords(status === "canceled" ? "cancelled" : status),
          value: capitaliseWords(status),
        }))
        .filter(function (item, pos, self) {
          return pos === self.findIndex((t) => t.text === item.text);
        }),
      onFilter: (value: any, record: any) =>
        record.status.toLowerCase().startsWith(value.toLowerCase()),
      filterSearch: true,
      render: (status: string, record: any) => {
        return (
          <div style={{ textAlign: "left", minWidth: "100px" }}>
            <span
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Dropdown
                overlay={statusMenu(record, status)}
                trigger={["click"]}
                placement="bottomLeft"
                className="status-changer"
                disabled={status === "canceled" || status === "completed"}
              >
                <a
                  href="#!"
                  className={`ant-dropdown-link ${status} text-capitalize`}
                  style={{ textTransform: "capitalize" }}
                >
                  <b style={{ marginRight: 5 }}>
                    {status === "canceled" ? "Cancelled" : status}
                  </b>{" "}
                  <img
                    width="10px"
                    className="ml-1"
                    alt="icon"
                    src={DropdownIcon}
                  />
                </a>
              </Dropdown>
            </span>
          </div>
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
      filters: labRequestsState
        .map(({ metadata }) => {
          const sourceFormat =
            metadata?.source && metadata?.source === "pneumapage"
              ? "PneumaPage"
              : metadata?.source === "walkIn"
              ? "Walk in"
              : metadata?.source === "callIn"
              ? "Call in"
              : metadata?.source === "leads"
              ? "Leads"
              : "";

          return (
            metadata?.source && {
              text: capitaliseWords(sourceFormat),
              value: capitaliseWords(metadata?.source),
            }
          );
        })
        .filter(function (item, pos, self) {
          return (
            item && pos === self.findIndex((t) => t && t.text === item.text)
          );
        }),
      overlayStyle: { textTransform: "capitalize" },
      onFilter: (value: any, record: any) =>
        record.metadata.source
          ? record.metadata.source.toLowerCase().startsWith(value.toLowerCase())
          : false,
      filterSearch: true,
      render: (_: any, record: any) => {
        const { source } = record?.metadata || {};
        const sourceFormat =
          source === "pneumapage"
            ? "PneumaPage"
            : source === "walkIn"
            ? "Walk in"
            : source === "callIn"
            ? "Call in"
            : source === "leads"
            ? "Leads"
            : "";
        return (
          <p
            style={{
              minWidth: "120px",
              maxWidth: "max-content",
              lineHeight: "1.7",
            }}
          >
            {sourceFormat}
          </p>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "action",
      align: "center" as "center",
      render: (id: string, record: any) => {
        return (
          <p style={{ display: "flex", gap: "4px" }}>
            <Tooltip placement="top" title="Edit booking">
              <Button
                style={{ background: "none" }}
                onClick={() => handleOpenModal(id)}
                disabled={
                  record.status === "completed" || record.status === "canceled"
                }
              >
                <EditOutlined style={{ fontSize: "1rem" }} />
              </Button>
            </Tooltip>

            <Tooltip placement="top" title="View details">
              <Button
                style={{ background: "none" }}
                onClick={() => handleOpenDrawer(id)}
              >
                <ViewMoreIcon style={{ fontSize: "1rem", height: "16" }} />
              </Button>
            </Tooltip>
          </p>
        );
      },
    },
  ];

  const handleSearch = (e: any) => {
    const keyword = e.target.value;
    setKeyword(keyword);
    const _labRequests = [...investigations];
    setLabRequestsState(
      _labRequests
        .filter((item) => (status ? item.status === status : item))
        .filter((item) => {
          const { user, metadata } = item;
          return (
            user.first_name.toLowerCase().includes(keyword.toLowerCase()) ||
            user.last_name.toLowerCase().includes(keyword.toLowerCase()) ||
            metadata?.patientId?.toLowerCase().includes(keyword.toLowerCase())
          );
        }),
    );
  };

  const onChange = (date: any, dateString: string) => {
    if (date && dateString) {
      const _labRequests = [...investigations];
      setSelectedMonth(dateString);
      setLabRequestsState(
        _labRequests.filter((item) => {
          // preferred date comes in dd/mm/yy format..so first we change it to mm/dd/yy format, and then check if that matches the selected date.
          const newDate = item.metadata.preferred_date
            ? item.metadata.preferred_date.split("/")
            : new Date().toLocaleDateString();
          const newDateStr = newDate[1] + "/" + newDate[0] + "/" + newDate[2];

          return moment(newDateStr).isSame(moment(date), "day");
        }),
      );
    } else {
      setLabRequestsState([...investigations]);
    }
  };

  const totalCount =
    labRequestsState.length === investigations.length
      ? `${labRequestsState.length}`
      : `${labRequestsState.length}/${investigations.length}`;

  const dataSource = count
    ? labRequestsState.slice(0, count)
    : labRequestsState;
  return (
    <>
      {!count && (
        <Row justify="space-between" align="top">
          <Col xs={24} md={12}>
            <h3 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              <span> {labRequestsState.length}</span>{" "}
              {Number(labRequestsState.length) > 1 ? "Bookings" : "Booking"}{" "}
            </h3>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <SearchAndCreateWrapper>
              <Col>
                <Search
                  placeholder="Search by patient"
                  onChange={handleSearch}
                />
              </Col>

              {/*{subscribedPlan !== "freemium" ? (*/}
              <Col>
                <Button
                  className="plan-add"
                  type="primary"
                  pAlign
                  onClick={() => {
                    dispatch(setBookingModalState(true));
                  }}
                >
                  Create a new appointment
                </Button>
              </Col>
              {/*// ) : null}*/}
            </SearchAndCreateWrapper>
          </Col>
          <Col xs={24} md={4} style={{ margin: "16px 0 10px" }}>
            <DatePicker
              marginBottom={0}
              mode="normal"
              width="auto"
              format="LL"
              onChange={onChange}
              placeholder="Filter by date"
              inputReadOnly={true}
            />
          </Col>
        </Row>
      )}
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <LabRequestsTableWrapper>
          <LabRequestsTableBody>
            <Table
              dataSource={labRequestsState}
              columns={
                subscribedPlan !== "freemium"
                  ? columns
                  : columns.filter((item) => item.title !== "Rating")
              }
              rowKey={(dataRow) => dataRow.id}
              style={{ pointerEvents: loading ? "none" : "all" }}
              scroll={
                !isInvestgationsLoading && dataSource.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 650px)").matches
                  ? { x: 700 }
                  : {}
              }
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              loading={isInvestgationsLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true,
              }}
            />
          </LabRequestsTableBody>
        </LabRequestsTableWrapper>
        <LabRequestsDrawer
          isVisible={isLabRequestsDrawerOpen}
          selectedLabRequest={selectedLabRequest}
          onClose={() => setIsLabRequestsDrawerOpen(false)}
        />

        <EditAppointmentModal
          isModalVisible={isAppointmentModalOpen}
          selectedAppointment={selectedAppointment}
          onClose={() => setIsAppointmentModalOpen(false)}
        />
      </Card>
    </>
  );
};

export default LabRequestsTable;
