import styled from "styled-components";
import { Upload } from "antd";

export const Wrapper = styled.div``;

export const ContentWrapper = styled.div`
  padding: 10px 25px 100px;
  background: #f3f8fb;
  //min-height: calc(100vh - 60px);
  width: 100%;
  overflow: hidden;

  @media (min-width: 1400px) {
    padding: 25px 25px 100px;
  }
  a {
    display: inline-flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;

    span {
      color: #0d0d0d;
      font-family: DM Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
  }
`;

export const TabWrapper = styled.div`
  display: grid;
  place-content: center;
  width: 100%;
  background: #f3f8fb;
  top: 70%;
  justify-content: center;
  overflow: hidden;
  margin-top: 90px;
  @media (min-width: 768px) {
    margin-top: 60px;
  }
  @media (min-width: 1400px) {
    margin-top: 90px;
  }

  .ant-tabs {
    position: fixed;
    overflow: auto;
    padding-bottom: 200px;
    max-width: 995px;
    max-height: 34rem;
    @media (min-width: 30rem) {
      max-height: 38rem;
    }
    @media (min-width: 1400px) {
      max-height: 38rem;
      left: 30%;
      right: 10%;
    }
    @media (min-width: 1900px) {
      max-height: 40rem;
    }

    .ant-tabs-nav-wrap {
      position: fixed;
      z-index: 1000;
      max-height: 37rem;
      top: 15%;
      @media (min-width: 1024px) {
        top: 15%;
      }
      @media (min-width: 1400px) {
        top: 20%;
      }

      @media (min-width: 1600px) {
        top: 15%;
      }
    }
    .ant-tabs-nav::before {
      border: none;
    }
    .ant-tabs-tab {
      p.label {
        font-family: "DM Sans";
        font-size: 16px;
        line-height: 24px;
        display: flex;
        margin: 0;
        gap: 6px;
        color: rgba(0, 0, 0, 0.85);

        span {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 0px 8px;
          border-radius: 100px;
          font-size: 12px;
          line-height: 20px;
          font-weight: 500;
          text-align: center;
          background: #f0f0f0;
          color: rgba(0, 0, 0, 0.45);
        }
      }

      &-active {
        p.label {
          color: #1890ff;

          span {
            background: #e6f7ff;
            color: #1890ff;
          }
        }
      }
    }
  }
`;

export const QuestionsWrapper = styled.div`
  h1 {
    color: #344054;
    font-family: DM Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px; /* 100% */
  }
`;
export const FormDetailsWrapper = styled.div`
  padding-top: 24px;
  padding-bottom: 25px;
  padding-right: 20px;
  .profile-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 5px;
    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 100%;
    }
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  .warning {
    font-size: 12px;
    @media (min-width: 768px) {
      margin-top: -10px;
    }
  }
  .btn-wrapper {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #e4e7ec;
    padding-top: 18px;
    align-items: flex-end;
    gap: 41px;
    div:nth-child(1) {
      button {
        background: transparent;
        width: 94px;
        height: 40px !important;

        span {
          color: #0d0d0d;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    div:nth-child(2) {
      button {
        background: #0fba84;
        width: 81px;
        height: 40px !important;
        span {
          color: #fff;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
        }
      }
    }

    .edit {
      button {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }

  .ant-upload-list {
    max-width: 400px;
    height: 32px;
    padding: 5px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    //justify-content: center;
  }
`;

export const UploadWrapper = styled(Upload)`
  cursor: pointer;
  > .ant-upload .ant-btn {
    border: none;
    background: transparent;
    box-shadow: none;
    border-radius: 8px;
    padding: 5px;
    width: 40%;
  }

  span {
    color: #344054;
    font-family: DM Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
  }
`;

export const QuestionFieldsWrapper = styled.div`
  border-top: 1px solid #e4e7ec;
  padding-top: 25px;
  padding-right: 20px;
  padding-bottom: 50px;
  @media (min-width: 50rem) {
    padding-bottom: 100px;
  }
  .add-question {
    display: flex;
    justify-content: center;
    button {
      display: flex;
      width: 234px;
      padding: 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      height: 72px !important;
      border-radius: 8px;
      background: #d9efff;

      span {
        color: #0faff0;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
      }
    }
  }

  .ant-form-item {
    margin-bottom: 10px !important;
  }

  .btn-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 30px;

    .branch,
    .delete {
      button {
        height: 52px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 15px;
        gap: 10px;

        svg {
          width: 40px;
          height: 40px;
        }
        span {
          color: #0d0d0d;
          font-family: DM Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
        }
      }
    }

    .branch {
      button {
        border-radius: 8px;
        border: 1px solid #000;
      }
    }

    .toggle {
      display: none;
      gap: 10px;
      justify-content: center;
      align-items: center;
      height: 52px;
      span {
        color: #0d0d0d;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 36px;
      }
    }
  }

  .add-options {
    button {
      display: inline-flex;
      padding: 10px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      background: #ebf7fc;
      width: 137px;
      height: 44px;

      span {
        color: #0faff0;
        font-family: DM Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px; /* 150% */
      }
    }
  }

  .save-btn {
    position: fixed;
    top: 17%;
    right: 3%;
    z-index: 1100;

    @media (min-width: 1400px) {
      top: 20%;
      right: 6%;
    }
    @media (min-width: 1800px) {
      top: 17%;
      right: 20%;
    }
  }

  .radio-wrapper {
    display: flex;

    gap: 10px;
  }
`;

export const PreviewWrapper = styled.div`
  padding-top: 24px;
  padding-right: 25px;
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    h1 {
      color: #101828;
      font-family: DM Sans;
      font-size: 32px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      margin-bottom: 0;
    }
    span {
      color: #667085;
      font-family: DM Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }
  .ant-card-body {
    width: 394px;
    @media (min-width: 768px) {
      width: 711px;
    }
    @media (min-width: 1024px) {
      width: 955px;
    }
    @media (min-width: 1400px) {
      max-width: 1115px;
    }
  }
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 25px;

  .question {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    div {
      display: flex;
      gap: 15px;
      align-items: center;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin: 0 auto 0;
        gap: 12px;
        border-radius: 68.4px;
        background: #0d97cd;
        color: #fff;
        font-family: DM Sans;
        font-size: 14.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }

      h1 {
        color: #101828;
        font-family: DM Sans;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        margin: 0;
      }
    }

    span {
      color: #667085;
      font-family: DM Sans;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      line-height: 22.465px;
    }
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    .ant-radio-group {
      border-radius: 4px;
      border: 1px solid #d0d5dd;
      width: 311px;
      @media (min-width: 768px) {
        width: 400px;
      }
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      @media (min-width: 1024px) {
        width: 700px;
      }
      .ant-radio-wrapper-disabled {
        cursor: pointer;
        .ant-radio-disabled,
        .ant-radio-disabled,
        .ant-radio-input,
        .ant-radio-disabled,
        .ant-radio-inner {
          cursor: pointer;
          background: transparent;
          border-color: #d0d5dd;
          + span {
            cursor: pointer;
            color: #64748b;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
          }
        }
      }
    }

    .tag {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #e9f8e4;
      padding: 6px 10px;
      border-radius: 50px;
      color: #219653;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    .ant-checkbox-group {
      border-radius: 4px;
      border: 1px solid #d0d5dd;
      height: 48px;
      display: flex;
      align-items: center;
      padding-left: 25px;
      width: 311px;
      @media (min-width: 768px) {
        width: 500px;
      }
      @media (min-width: 1024px) {
        width: 700px;
      }
      .ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
        cursor: pointer;
        .ant-checkbox-disabled {
          cursor: pointer;
          + span {
            color: #64748b;
            font-family: DM Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            cursor: pointer;
          }
        }
      }
      .ant-checkbox-inner {
        background: transparent;
      }
    }
    .tag {
      width: auto;
      height: 32px;
      display: flex;
      align-items: center;
      gap: 10px;
      background: #e9f8e4;
      padding: 6px 10px;
      border-radius: 50px;
      color: #219653;
      font-family: DM Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
    }
  }
  .ant-input {
    width: 311px;
    @media (min-width: 768px) {
      width: 500px;
    }
    @media (min-width: 1024px) {
      width: 700px;
    }
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 18px;

  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .ant-form-item {
    margin-bottom: 0 !important;
  }

  .ant-input {
    min-width: 290px;
    @media (min-width: 768px) {
      width: 340px;
    }
    @media (min-width: 1024px) {
      width: 490px;
    }
  }

  .options-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    button {
      svg {
        width: 32px;
        height: 32px;
      }
    }

    .next_question {
      width: 190px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background: #e9f8e4;
      padding: 6px 10px;
      border-radius: 50px;
      span {
        color: #219653;
        font-family: DM Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }
`;
