import { useEffect } from "react";

import { InvestigationWrapper, ContentWrapper } from "./styles";
import InvestigationTable from "./containers/InvestigationTable";

const Investigations = () => {
  useEffect(() => {
    document.title = "FrontDesk | Service Plans";
  }, []);

  return (
    <InvestigationWrapper>
      <ContentWrapper>
        <InvestigationTable />
      </ContentWrapper>
    </InvestigationWrapper>
  );
};

export default Investigations;
