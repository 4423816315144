import React from "react";
import { Wrapper } from "./style";
import { useParams } from "react-router-dom";
import { useAppSelector } from "redux/store";
import { userSelector } from "redux/reducers/users";
import moment from "moment";
import { EmptyStateWrapper } from "screens/Messages/styles";
import Loader from "../Loader";

type Props = {
  user: any;
};

const BioData = ({ user }: Props) => {
  const { patientId }: { patientId: string } = useParams();
  const { users, usersError, isUsersLoading } = useAppSelector(userSelector);
  const selectUser = users.find((user) => user.id === patientId);

  if (!usersError) {
    return (
      <EmptyStateWrapper>
        <h2>Error Loading page...</h2>
        <p>Please try again!!</p>
      </EmptyStateWrapper>
    );
  }
  return (
    <>
      {isUsersLoading ? (
        <Loader />
      ) : (
        <Wrapper>
          <div className="patient">
            <h1>Patient Name</h1>
            <div className="patient--name">
              <div>
                <h3>First Name</h3>
                <p>{user?.first_name}</p>
              </div>
              <div>
                <h3>Last Name</h3>
                <p>{user?.last_name}</p>
              </div>
              <div>
                <h3>Date of Birth</h3>

                <p> {moment(user?.dob, "DD/MM/YYYY").format("LL")}</p>
              </div>
              <div>
                <h3>Gender</h3>
                <p>{user?.gender}</p>
              </div>
            </div>
          </div>
          <div className="contact">
            <h1>Contact Information</h1>
            <div className="contact--information">
              <div>
                <h3>Email Address</h3>
                <p>{user?.email}</p>
              </div>
              <div>
                <h3>Phone Number</h3>
                <p>{user?.phone}</p>
              </div>
            </div>
          </div>

          <div className="address">
            <h1>Contact Address</h1>
            <div className="information">
              <div>
                <h3>Home Address</h3>
                {user?.address?.street_line_one ? (
                  <p>{user?.address?.street_line_one}</p>
                ) : (
                  <p>N/A</p>
                )}
              </div>

              <div>
                <h3>City</h3>
                {user?.address?.city ? (
                  <p>{user?.address?.city}</p>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <div>
                <h3>State</h3>
                {user?.address?.state ? (
                  <p>{user?.address?.state}</p>
                ) : (
                  <p>N/A</p>
                )}
              </div>
              <div>
                <h3>Country</h3>
                {user?.address?.country ? (
                  <p>{user?.address?.country}</p>
                ) : (
                  <p>N/A</p>
                )}
              </div>
            </div>
          </div>
        </Wrapper>
      )}
    </>
  );
};

export default BioData;
