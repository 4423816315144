import { useEffect } from "react";

import LabRequestsTable from "./containers/LabRequestsTable";
import { LabRequestsWrapper, ContentWrapper } from "./styles";

const LabRequests = ({ dashboardMode }: any) => {
  useEffect(() => {
    document.title = "FrontDesk | Bookings";
  }, []);

  return (
    <LabRequestsWrapper>
      <ContentWrapper>
        <LabRequestsTable dashboardMode={dashboardMode} />
      </ContentWrapper>
    </LabRequestsWrapper>
  );
};

export default LabRequests;
