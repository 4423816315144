import styled from "styled-components";

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #ffffff;
  border-bottom: 1px solid #e4e7ec;

  @media (min-width: 1024px) {
    padding: 10px 80px;
  }

  .logo-wrapper {
    svg {
      height: 40px;
      width: 100px;

      @media (min-width: 768px) {
        height: 60px;
        width: 120px;
      }
    }
  }

  .links-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    @media (min-width: 768px) {
      gap: 32px;
    }

    button {
      color: #0faff0;
      background: transparent;
    }
  }
`;

export const Wrapper = styled.main`
  padding: 45px 20px;

  h2 {
    font-family: "DM Sans";
    font-style: normal;
    font-weight: 700;
    max-width: 520px;
    margin: 0 auto 6px;
    font-size: 20px;
    line-height: 120%;
    text-align: center;
    color: #101828;

    @media (min-width: 1024px) {
      font-size: 32px;
    }
  }

  p {
    font-weight: 400;
    font-size: 15px;
    line-height: 120%;
    text-align: center;
    color: #667085;
    max-width: 350px;
    margin: 0 auto 24px;

    @media (min-width: 1024px) {
      font-size: 18px;
      margin: 0 auto 32px;
    }
  }

  .plans-wrapper {
    .ant-radio-group {
      gap: 4px;
      margin: 0 auto 42px;
      background-color: #d9efff;
      padding: 10px 18px;
      border-radius: 8px;

      label {
        padding: 6px 12px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.85);
        margin: 0;
      }

      @media (min-width: 1024px) {
        padding: 10px 12px;

        label {
          padding: 10px 24px;
          font-size: 14px;
        }
      }
    }

    .plans {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: center;

      @media (min-width: 1024px) {
        flex-direction: row;
      }

      .single-plan {
        background: #ffffff;
        border: 0.787312px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 40px 20px;
        display: flex;
        flex-direction: column;

        @media (min-width: 1024px) {
          padding-top: 60px;
        }

        h5 {
          display: flex;
          align-items: center;
          gap: 8px;
          font-weight: 500;
          font-size: 24px;
          line-height: 140%;
          color: #0faff0;
          margin: 0 0 6px;
        }

        h4 {
          font-weight: 700;
          font-size: 24px;
          line-height: 120%;
          color: #0d0d0d;
          margin: 0 0 6px;

          span {
            font-size: 14px;
          }
        }

        p {
          font-weight: 400;
          font-size: 14px;
          line-height: 120%;
          text-align: left;
          color: #000000;
        }

        .btn-wrapper {
          margin: 24px 0;

          button {
            background-color: #0faff0;
            width: 100%;
            padding-top: 18px;
            padding-bottom: 18px;
            height: auto;
            font-size: 16px;

            &:hover {
              color: #fff;
            }
          }
        }

        .benefits {
          border-top: 0.787312px solid #dcdfe5;
          padding-top: 24px;

          h6 {
            font-weight: 500;
            font-size: 14px;
            line-height: 150%;
            color: #000000;
            margin: 0 0 12px;
          }

          ul {
            margin: 0;
            padding: 0;
            list-style: none;

            li {
              display: flex;
              align-items: center;
              gap: 6px;

              &:not(:first-of-type) {
                margin-top: 16px;
              }
            }
          }
        }
      }

      .single-plan.is-active {
        border: 2px solid #0faff0;
        position: relative;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

        .btn-wrapper {
          button {
            background-color: #f5f7f8;
            border: none;
            color: #bbbbbb;

            &:hover {
              background-color: #f5f7f8 !important;
              color: #bbbbbb;
            }
          }
        }

        &::before {
          position: absolute;
          content: "Your current plan";
          background: #0faff0;
          border-radius: 4px 4px 0px 0px;
          top: 0;
          left: 0;
          width: 100%;
          height: 31px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
        }
      }
    }
  }
`;
