import { Table, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { useState, useMemo, useContext } from "react";
import { EditOutlined } from "@ant-design/icons";

import Card from "component/Card";
import Search from "component/Search";
import Button from "component/Button";
import EditUserModal from "component/EditUserModal";
import { UpdateSubContext } from "context/UpdateSubscriptionContext";
import { userSelector } from "redux/reducers/users";
import { UserType } from "constants/types";
import {
  PatientsTableWrapper,
  PatientsTableBody,
  SearchAndCreateWrapper
} from "./styles";
import CreatePatientModal from "component/CreatePatient";
import { generatePath, NavLink } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useAppSelector } from "redux/store";

const PatientsTable = ({}) => {
  const { handleShowModal } = useContext(UpdateSubContext);
  const { users, isUsersLoading } = useSelector(userSelector);
  const { plan: subscribedPlan } = useAppSelector(
    (state) => state.subscription
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [patientModalVisible, setPatientModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserType>({} as UserType);

  const columns = [
    {
      title: "ID",
      dataIndex: "patientId",
      render: (id: string, record: any) => {
        return (
          <p style={{ width: "max-content", textTransform: "uppercase" }}>
            {record.patientId}
          </p>
        );
      },
      sorter: (a: any, b: any) => {
        return a?.patientId?.toLowerCase() < b?.patientId?.toLowerCase()
          ? -1
          : a?.patientId?.toLowerCase() > b?.patientId?.toLowerCase()
          ? 1
          : 0;
      }
    },
    {
      title: "Patient",
      dataIndex: "name",
      key: "first_name",
      width: 200,
      sorter: (a: any, b: any) => {
        return a?.first_name?.toLowerCase() < b?.first_name?.toLowerCase()
          ? -1
          : a?.first_name?.toLowerCase() > b?.first_name?.toLowerCase()
          ? 1
          : 0;
      },
      render: (_: string, record: any) => {
        const { first_name, last_name } = record;
        return (
          <div>
            <p
              style={{
                margin: 0,
                maxWidth: "max-content",
                minWidth: "120px",
                lineHeight: "1.7",
                textTransform: "capitalize"
              }}
            >
              {first_name} {last_name}
            </p>
          </div>
        );
      }
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (email: string, record: any) => {
        return <p style={{ width: "max-content" }}>{email}</p>;
      }
    },
    {
      title: "Phone number",
      dataIndex: "phone",
      key: "phone",
      render: (phone: string, record: any) => {
        return <p style={{ width: "max-content" }}>{phone}</p>;
      }
    },
    {
      title: "Age",
      dataIndex: "dob",
      key: "dob",
      render: (dob: string, record: any) => {
        return (
          <p style={{ width: "max-content", textAlign: "left" }}>
            {dob} <br />{" "}
            {dob &&
              Number(new Date().getFullYear()) -
                Number(dob.split("/")[2]) +
                ` years`}
            {dob &&
            Number(new Date().getMonth()) + 1 - Number(dob.split("/")[1]) > 0
              ? `, ${
                  Number(new Date().getMonth()) + 1 - Number(dob.split("/")[1])
                } months`
              : ""}
          </p>
        );
      }
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender: string, record: any) => {
        return (
          <p style={{ width: "max-content", textTransform: "capitalize" }}>
            {gender}
          </p>
        );
      }
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "id",
      align: "left" as "left",
      render: (id: string, record: UserType) => (
        <div style={{ minWidth: "120px" }}>
          <button
            style={{
              color: "#1890ff",
              textDecoration: "none",
              backgroundColor: "transparent",
              outline: "none",
              border: "none",
              cursor: "pointer",
              padding: ".2em .4em"
            }}
            onClick={() => {
              setSelectedUser(record);
              setIsEditModalVisible(true);
            }}
          >
            <EditOutlined />
          </button>
          {subscribedPlan === "start" || subscribedPlan === "freemium" ? (
            <button
              style={{
                marginLeft: "1em",
                border: "none",
                background: "none",
                color: "rgb(24, 144, 255)",
                cursor: "pointer"
              }}
              onClick={() => handleShowModal(true, "Patient details")}
            >
              View patient
            </button>
          ) : (
            <NavLink
              style={{ marginLeft: "1em" }}
              to={generatePath(ROUTES.PATIENTS_DETAILS, {
                patientId: id
              })}
            >
              View patient
            </NavLink>
          )}
        </div>
      )
    }
  ];

  const filteredUsers = useMemo(
    () =>
      users.filter(
        (user) =>
          user.first_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase().trim()) ||
          user.last_name
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase().trim()) ||
          String(user.patientId)
            ?.toLowerCase()
            .includes(searchTerm.toLowerCase().trim()) ||
          (
            user.first_name?.toLowerCase() +
            " " +
            user.last_name?.toLowerCase()
          ).includes(searchTerm.toLowerCase().trim()) ||
          (
            user.last_name?.toLowerCase() +
            " " +
            user.first_name?.toLowerCase()
          ).includes(searchTerm.toLowerCase().trim())
      ),
    [users, searchTerm]
  );

  return (
    <>
      {
        <Row justify='space-between' align='middle'>
          <Col xs={24} md={12}>
            <h3 style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              {users.length} Patient{users.length > 1 ? "s" : ""}
            </h3>
          </Col>
          <Col
            xs={24}
            md={12}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <SearchAndCreateWrapper>
              <Col>
                <Search
                  placeholder='Search by patient'
                  onChange={(e: any) => setSearchTerm(e.target.value)}
                />
              </Col>

              <Col>
                <Button
                  className='plan-add'
                  type='primary'
                  pAlign
                  onClick={() => setPatientModalVisible(true)}
                >
                  Create a new patient
                </Button>
              </Col>
            </SearchAndCreateWrapper>
          </Col>
        </Row>
      }
      <Card marginBottom={15} padding={0} style={{ marginTop: "20px" }}>
        <PatientsTableWrapper>
          <PatientsTableBody>
            <Table
              dataSource={filteredUsers}
              columns={columns}
              rowKey={(dataRow) => dataRow.id}
              style={{ pointerEvents: isUsersLoading ? "none" : "all" }}
              rowClassName={(_, index) =>
                index % 2 === 0 ? "even-row" : "odd-row"
              }
              scroll={
                !isUsersLoading && filteredUsers.length > 0
                  ? { x: 700 }
                  : window.matchMedia("(max-width: 650xp").matches
                  ? { x: 700 }
                  : {}
              }
              loading={isUsersLoading}
              pagination={{
                defaultCurrent: 1,
                position: ["bottomRight"],
                showLessItems: true
              }}
            />
          </PatientsTableBody>

          <EditUserModal
            handleCancel={() => setIsEditModalVisible(false)}
            isModalVisible={isEditModalVisible}
            selectedUser={selectedUser}
          />

          <CreatePatientModal
            isModalVisible={patientModalVisible}
            handleCancel={() => setPatientModalVisible(false)}
          />
        </PatientsTableWrapper>
      </Card>
    </>
  );
};

export default PatientsTable;
