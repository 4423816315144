import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { AppThunk } from "../store";
import http, {
  httpWithIntegration,
  httpWithJWT,
  httpWithNorthIntegration,
} from "utils/api";
import { message } from "antd";
import { requestAccountActivationFailed } from "./auth";
import {
  ErrorTypeAPI,
  RegisteredReturnType,
  RegisterUserValues,
  UserType,
} from "constants/types";
import { db } from "utils/firebase";
import firebase from "firebase";
import moment from "moment";

export interface UserError {
  message: string;
}

export interface UserState {
  user: any;
  users: Array<UserType>;
  isUserLoading: boolean;
  isUsersLoading: boolean;
  isCreateUserLoading: boolean;
  isVerifyUserLoading: boolean;
  isUpdateUserLoading: boolean;
  isRequestUserAccessLoading: boolean;
  hasCreatedUser: boolean;
  hasVerifiedUser: boolean | null;
  hasRequestedUserAccess: boolean;
  userError: UserError;
  usersError: UserError;
  createUserError: UserError;
  verifyUserError: UserError;
  requestUserAccessError: UserError;
  updateUserError: UserError;
}

export const initialState: UserState = {
  user: {},
  users: [],
  isUserLoading: true,
  isUsersLoading: true,
  isCreateUserLoading: false,
  isVerifyUserLoading: false,
  isRequestUserAccessLoading: false,
  isUpdateUserLoading: false,
  hasCreatedUser: false,
  hasVerifiedUser: null,
  hasRequestedUserAccess: false,
  userError: { message: "" },
  usersError: { message: "" },
  createUserError: { message: "" },
  verifyUserError: { message: "" },
  requestUserAccessError: { message: "" },
  updateUserError: { message: "" },
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }: any) => {
      state.user = payload;
    },
    createUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isCreateUserLoading = payload;
      state.hasCreatedUser = false;
    },
    createUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.user = payload;
      state.hasCreatedUser = true;
    },
    createUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.createUserError = payload;
      state.user = null;
      state.hasCreatedUser = false;
    },
    // VERIFY USER
    resetHasVerifiedUser: (state) => {
      state.hasVerifiedUser = null;
    },
    verifyUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isVerifyUserLoading = payload;
    },
    verifyUserSuccess: (state, { payload }: PayloadAction<Array<any>>) => {
      state.hasVerifiedUser = true;
    },
    verifyUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.verifyUserError = payload;
      state.hasVerifiedUser = false;
    },
    // REQUEST USER ACCESS
    requestUserAccessLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isRequestUserAccessLoading = payload;
      state.hasRequestedUserAccess = false;
    },
    requestUserAccessSuccess: (
      state,
      { payload }: PayloadAction<Array<any>>,
    ) => {
      state.hasRequestedUserAccess = true;
    },
    requestUserAccessFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.requestUserAccessError = payload;
      state.hasRequestedUserAccess = false;
    },
    // FETCH SINGLE USER
    fetchUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUserLoading = payload;
    },
    fetchUserSuccess: (state, { payload }: PayloadAction<UserType>) => {
      state.user = payload;
    },
    fetchUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.userError = payload;
      state.user = null;
    },
    // FETCH ALL USERS
    fetchUsersLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUsersLoading = payload;
    },
    fetchUsersSuccess: (state, { payload }: PayloadAction<Array<UserType>>) => {
      state.users = payload;
      state.isUsersLoading = false;
    },
    fetchUsersFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.usersError = payload;
      state.isUsersLoading = false;
    },
    updateUserLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isUpdateUserLoading = payload;
    },
    updateUserSuccess: (state, { payload }: PayloadAction<UserType>) => {
      state.users = state.users.map((user: any) => {
        if (user.id === payload.id) {
          return { ...payload };
        }
        return user;
      });
      state.isUpdateUserLoading = false;
    },
    updateUserFailed: (state, { payload }: PayloadAction<UserError>) => {
      state.updateUserError = payload;
      state.isUpdateUserLoading = false;
    },
  },
});

export const {
  createUserLoading,
  createUserSuccess,
  createUserFailed,
  resetHasVerifiedUser,
  verifyUserLoading,
  verifyUserSuccess,
  verifyUserFailed,
  requestUserAccessLoading,
  requestUserAccessSuccess,
  requestUserAccessFailed,
  fetchUserLoading,
  fetchUserSuccess,
  fetchUserFailed,
  fetchUsersLoading,
  fetchUsersSuccess,
  fetchUsersFailed,
  setSelectedUser,
  updateUserSuccess,
} = usersSlice.actions;
export const userSelector = (state: { users: UserState }) => state.users;
export default usersSlice.reducer;
/** Actions */

export const createUser =
  (payload: RegisterUserValues): AppThunk =>
  async (dispatch) => {
    const localStoreProviderID = window.localStorage.getItem("providerID")!;

    try {
      const res: RegisteredReturnType = await httpWithIntegration.post(
        "/users",
        {
          first_name: payload.first_name.toLowerCase(),
          last_name: payload.last_name.toLowerCase(),
          email: payload.email.toLowerCase(),
          dob: payload.dob.format("DD/MM/YYYY"),
          gender: payload.gender,
          phone: payload.phone,
        },
      );

      const user = res.data.data;
      const userObject: any = {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        gender: user.gender,
        dob: user.dob,
        patientId: payload.patientId,
      };
      dispatch(setSelectedUser(userObject));

      await db
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("patients")
        .doc(user.id)
        .set({
          ...userObject,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          patientId: payload.patientId || "",
        });

      await db
        .collection("patients")
        .doc(user.id)
        .set({
          ...userObject,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
          patientId: payload.patientId || "",
        });
      dispatch(fetchUserSuccess(userObject));
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response?.data?.message ||
        err.message ||
        err.response?.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };

export const updateUserDetails =
  (id: string, payload: UserType): AppThunk =>
  async (dispatch) => {
    const localStoreProviderID = window.localStorage.getItem("providerID")!;

    try {
      const res = await httpWithIntegration.put(`users/${id}`, {
        dob: payload.dob,
        first_name: payload.first_name,
        gender: payload.gender,
        last_name: payload.last_name,
        phone: payload.phone,
      });
      const user = res.data.data;

      const updatedUser: UserType = {
        dob: user.dob,
        email: user.email,
        first_name: user.first_name,
        gender: user.gender,
        last_name: user.last_name,
        phone: user.phone,
        id: user.id,
        patientId: payload.patientId,
      };

      await db
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("patients")
        .doc(id)
        .update({ ...updatedUser, patientId: payload.patientId });

      await db
        .collection("patients")
        .doc(id)
        .update({
          ...updatedUser,
          patientId: payload.patientId,
        });

      dispatch(updateUserSuccess(updatedUser));
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response.data.message ||
        err.message ||
        err.response.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };

export const updateUserDetailsWithNorthKey =
  (id: string, payload: UserType): AppThunk =>
  async (dispatch) => {
    const localStoreProviderID = window.localStorage.getItem("providerID")!;

    try {
      const res = await httpWithNorthIntegration.put(`users/${id}`, {
        dob: payload.dob,
        first_name: payload.first_name,
        gender: payload.gender,
        last_name: payload.last_name,
        phone: payload.phone,
      });
      const user = res.data.data;

      const updatedUser: UserType = {
        dob: user.dob,
        email: user.email,
        first_name: user.first_name,
        gender: user.gender,
        last_name: user.last_name,
        phone: user.phone,
        id: user.id,
        patientId: payload.patientId,
      };

      await db
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("patients")
        .doc(id)
        .update({ ...updatedUser, patientId: payload.patientId });

      await db
        .collection("patients")
        .doc(id)
        .update({
          ...updatedUser,
          patientId: payload.patientId,
        });

      dispatch(updateUserSuccess(updatedUser));
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response.data.message ||
        err.message ||
        err.response.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };
export const requestPatientAccess =
  (email: string): AppThunk =>
  async () => {
    try {
      await httpWithIntegration.post(`/users/request_access`, { email });
    } catch (error) {
      const err = error as ErrorTypeAPI;
      const message =
        err.response.data.message ||
        err.response.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };

export const getPatients = (): AppThunk => async (dispatch) => {
  const localStoreProviderID = window.localStorage.getItem("providerID")!;

  try {
    dispatch(fetchUsersLoading(true));
    const allPatientsRef = await db
      .collection("providerInfo")
      .doc(localStoreProviderID)
      .collection("patients");

    const _patientsList = await allPatientsRef
      .orderBy("created_at", "desc")
      .get();
    const patientsList = await _patientsList.docs;
    const _allPatientsList: Array<UserType> = patientsList.map((doc: any) => {
      return {
        ...doc.data(),
        created_at: moment
          .unix(doc.data().created_at.seconds)
          .format("DD/MM/YYYY"),
      };
    });

    dispatch(fetchUsersSuccess(_allPatientsList));
  } catch (error) {
    const err = error as ErrorTypeAPI;
    dispatch(fetchUsersFailed(err));
    console.log(error);
  }
};

export const approvePatientAccessRequest =
  (otp: string, patientId?: string): AppThunk =>
  async (dispatch) => {
    const localStoreProviderID = window.localStorage.getItem("providerID")!;
    try {
      const res = await httpWithIntegration.post(
        `/users/approve_request/${otp}`,
      );

      const user = res.data.data;
      const userObject = {
        id: user.id,
        email: user.email,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        gender: user.gender,
        dob: user.dob,
        patientId: patientId ?? "",
      };

      await db
        .collection("providerInfo")
        .doc(localStoreProviderID)
        .collection("patients")
        .doc(user.id)
        .set({
          ...userObject,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        });

      await db
        .collection("patients")
        .doc(user.id)
        .set({
          ...userObject,
          created_at: firebase.firestore.FieldValue.serverTimestamp(),
        });
      dispatch(fetchUserSuccess(userObject));
    } catch (error) {
      const err = error as ErrorTypeAPI;
      console.log(error);
      const message =
        err.response.data.message ||
        err.response.data.error ||
        "An error occurred, please try again.";
      throw new Error(message);
    }
  };

// UNUSED

export const verifyUser =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(verifyUserLoading(true));
    await http
      .post(`/users/verify`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(verifyUserSuccess(user));
        // message.success("User verified successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(verifyUserFailed(message));
      });
    dispatch(verifyUserLoading(false));
  };

export const requestUserAccess =
  (payload: any): AppThunk =>
  async (dispatch) => {
    dispatch(requestUserAccessLoading(true));
    await http
      .post(`/users/request_access`, payload)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(requestUserAccessSuccess(user));
        message.success("User access requested successfully");
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(requestAccountActivationFailed(message));
      });
    dispatch(requestUserAccessLoading(false));
  };

export const approveUserAccessRequest =
  (otp: string): AppThunk =>
  async (dispatch) => {
    dispatch(createUserLoading(true));
    await http
      .post(`/users/approve_request/${otp}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(createUserSuccess(user));
        message.success("User successfully onboarded");
      })
      .catch((err) => {
        const messages = {
          message: err?.response?.data?.message || "An error occurred",
        };
        message.error("Wrong OTP! Please go back and request OTP again");
        dispatch(createUserFailed(messages));
      });
    dispatch(createUserLoading(false));
  };

export const fetchSingleUser =
  (id: any): AppThunk =>
  async (dispatch) => {
    dispatch(fetchUserLoading(true));
    const res = await httpWithIntegration
      .get(`/users/${id}`)
      .then((res) => {
        const user = res?.data?.data;
        dispatch(fetchUserSuccess(user));
        return user;
      })
      .catch((err) => {
        const message = {
          message: err?.response?.data?.message || "An error occurred",
        };
        dispatch(fetchUserFailed(message));
      })
      .finally(() => {
        dispatch(fetchUserLoading(false));
      });

    return res;
  };

export const fetchAllUsers = (): AppThunk => async (dispatch) => {
  dispatch(fetchUsersLoading(true));
  await httpWithJWT
    .get(`/users`)
    .then((res) => {
      const users = res?.data?.data;

      // dispatch(fetchUsersSuccess(users));
      // message.success("Users Fetched Successfully");
    })
    .catch((err) => {
      const _message = {
        message: err?.response?.data?.message || "An error occurred",
      };
      dispatch(fetchUsersFailed(_message));
      message.error(err?.response?.data?.message || "An error occurred");
    });
  dispatch(fetchUsersLoading(false));
};
