import {
  Col,
  Drawer,
  Form,
  message,
  Row,
  Select as AntdSelect,
  Steps,
} from "antd";
import { cloneElement, useState, useEffect } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { CloseCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { send } from "emailjs-com";
import firebase from "firebase";

import TimePicker from "component/TimePicker";
import {
  getDisabledHours,
  getDisabledMinutes,
  handlePhoneNumberFormat,
} from "utils/utils";
import Navbar from "component/Navbar";
import Sidebar from "component/Sidebar";
import {
  DashboardWrapper,
  Container,
  MainWrapper,
  ModalWrapper,
  FormItem,
} from "./styles";
import AppIcon from "assets/images/icon.svg";
import { fetchProviderProfile } from "redux/reducers/profile";
import {
  bookService,
  bookServiceWithNorthKey,
  fetchAllServicePlans,
  servicePlansSelector,
  setBookingModalState,
} from "redux/reducers/servicePlans";
import { fetchAllServiceOrders } from "redux/reducers/requests";
import Button from "component/Button";
import Input from "component/Input";
import { useAppDispatch, useAppSelector } from "redux/store";
import Card from "component/Card";
import { ReactComponent as IconNew } from "assets/images/profile-3.svg";
import { ReactComponent as IconOld } from "assets/images/profile-4.svg";
import Select from "component/Select";
import DatePicker from "component/DatePicker";
import TextArea from "component/TextArea";
import { DateOfBirthPicker } from "component/DatePicker/styles";
import {
  approvePatientAccessRequest,
  createUser,
  getPatients,
  requestPatientAccess,
  setSelectedUser,
  userSelector,
} from "redux/reducers/users";
import { profileSelector } from "redux/reducers/profile";
import {
  BookValuesType,
  RegisterUserValues,
  UserType,
  UserValueType,
  RequestValueType,
} from "constants/types";
import CallInIcon from "assets/icons/call-in-icon.png";
import WalkInIcon from "assets/icons/walk-in-icon.png";
import { MixPanel } from "utils/mixpanel";

import { db } from "utils/firebase";
import { capitaliseFirstLetter } from "utils/utils";
import { fetchPractitioners } from "redux/slices/practitioners";
import CreateConsultationModal from "../../component/CreateConsultationModal";

const { Step } = Steps;

const DashboardLayout = (props: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isChatPaneVisible, setIsChatPaneVisible] = useState(false);
  const [isEncounterFormVisible, setIsEncounterFormVisible] = useState(false);
  const [openedMenu, setOpenedMenu] = useState(false);
  const [isDashboardModeModalVisible, setIsDashboardModeModalVisible] =
    useState(false);
  const dashboardMode =
    window.localStorage.getItem("pneumaDashboardMode") || "integration";

  useEffect(() => {
    dispatch(fetchProviderProfile());
    dispatch(fetchAllServiceOrders());
    dispatch(fetchAllServicePlans());
    dispatch(getPatients());
  }, []);

  useEffect(() => {
    setOpenedMenu(false);
  }, [location]);

  return (
    <DashboardWrapper>
      <div>
        <Navbar setOpenedMenu={setOpenedMenu} />
      </div>
      <Container>
        <Sidebar
          dashboardMode={dashboardMode}
          setIsDashboardModeModalVisible={setIsDashboardModeModalVisible}
        />
        <Drawer
          className="sidebar-drawer"
          title={
            <div>
              <img src={AppIcon} alt="icon" />
            </div>
          }
          placement={"left"}
          closable={true}
          width={"auto"}
          onClose={() => setOpenedMenu(false)}
          visible={openedMenu}
          key={"left"}
        >
          <Sidebar
            dashboardMode={dashboardMode}
            setIsDashboardModeModalVisible={setIsDashboardModeModalVisible}
          />
        </Drawer>
        <MainWrapper>
          {cloneElement(props.children, {
            isChatPaneVisible,
            setIsChatPaneVisible,
            isEncounterFormVisible,
            setIsEncounterFormVisible,
          })}
        </MainWrapper>
      </Container>
    </DashboardWrapper>
  );
};

const DashboardLayoutRoute = (props: any) => {
  const { component: Component, ...rest } = props;
  const { isBookingModalVisible, servicePlans } =
    useAppSelector(servicePlansSelector);
  const { practitioners } = useAppSelector((state) => state.practitioners);
  const { users, user } = useAppSelector(userSelector);
  const [pageIndex, setPageIndex] = useState<0 | 1>(0);
  const [formPage, setFormPage] = useState<
    "newUser" | "existingUser" | "requestAccess"
  >("newUser");
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [appointmentType, setAppointmentType] = useState<"walkIn" | "callIn">(
    "walkIn",
  );
  const dispatch = useAppDispatch();
  const [addBookForm] = Form.useForm();
  const hasbeenAuthorized = window.localStorage.getItem("isAuthorized");
  const dashboardMode =
    window.localStorage.getItem("pneumaDashboardMode") || "integration";
  const { profile: ProviderProfile } = useSelector(profileSelector);

  const [noEmail, setNoEmail] = useState(false);
  const [noDOB, setNoDOB] = useState(false);
  const [formattedPhone, setFormattedPhone] = useState("");
  const [isTodaySelected, setIsTodaySelected] = useState(false);
  const [isDateSelected, setIsDateSelected] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("cash");
  const [selectedServiceId, setSelectedServiceId] = useState<string>();

  useEffect(() => {
    dispatch(fetchPractitioners());
  }, [dispatch]);

  const ButtonTexts = () => {
    if (formPage === "newUser") {
      if (!pageIndex) {
        return loading ? "Adding..." : "Add patient";
      } else {
        return loading ? "Processing..." : "Create appointment";
      }
    } else if (formPage === "existingUser") {
      if (!pageIndex) {
        return "Create appointment";
      } else {
        return loading ? "Processing..." : "Create appointment";
      }
    } else if (formPage === "requestAccess") {
      if (!pageIndex) {
        return loading ? "Requesting..." : "Request access";
      } else {
        return loading ? "Processing..." : "Create appointment";
      }
    }
  };

  const page2SimilarData = () => (
    <>
      <Col xs={24}>
        <Select
          onChange={(val: string) => setSelectedServiceId(val)}
          formItem={{
            name: "plan",
            label: "Select service",
            rules: [
              {
                required: true,
                message: "Please select a service",
              },
            ],
          }}
          mode="normal"
          showSearch
          optionFilterProp="children"
          filterOption={(_input: string, option: any) => {
            const input = _input.toLowerCase();
            const singleService = servicePlans.find(
              (plan: any) => plan.id === option.value,
            )!;
            const booleanValue = singleService.name
              .toLowerCase()
              .includes(input);
            return booleanValue;
          }}
          filterSort={(optionA: any, optionB: any) =>
            optionA.value
              .toLowerCase()
              .localeCompare(optionB.value.toLowerCase())
          }
        >
          {servicePlans?.map((option, index) => {
            return (
              <AntdSelect.Option key={index} value={option.id}>
                <span style={{ textTransform: "capitalize" }}>
                  {option.name}
                </span>
              </AntdSelect.Option>
            );
          })}
        </Select>
      </Col>

      <Col xs={24}>
        <Select
          mode="normal"
          multiple
          placeholder="Add other services to this appointment.."
          formItem={{
            label: "Add other services to this appointment",
            name: "additionalServices",
          }}
          filterOption={(_input: string, option: any) => {
            const input = _input.toLowerCase();
            const singleService = servicePlans.find(
              (plan: any) => plan.id === option.value,
            )!;
            const booleanValue = singleService.name
              .toLowerCase()
              .includes(input);
            return booleanValue;
          }}
          filterSort={(optionA: any, optionB: any) =>
            optionA.value
              .toLowerCase()
              .localeCompare(optionB.value.toLowerCase())
          }
        >
          {servicePlans
            ?.filter((val) => val.id !== selectedServiceId)
            ?.map((option, index) => {
              return (
                <AntdSelect.Option key={index} value={option.id}>
                  <span style={{ textTransform: "capitalize" }}>
                    {option.name}
                  </span>
                </AntdSelect.Option>
              );
            })}
        </Select>
      </Col>

      <Col xs={24}>
        <Select
          mode="normal"
          placeholder="Assign practitioner"
          formItem={{
            label: "Add practitioner to this booking",
            name: "assignedPractitioner",
          }}
        >
          {practitioners.map((item) => (
            <AntdSelect.Option key={item.id} value={item.id}>
              {`${item.title} ${item.name}`}
            </AntdSelect.Option>
          ))}
        </Select>
      </Col>

      <Col xs={24} sm={12}>
        <DatePicker
          formItem={{
            name: "preferred_date",
            label: "Select preferred appointment date",
            rules: [
              {
                required: true,
                message: "Please select preferred appointment date",
              },
            ],
          }}
          disabledDate={(d: any) => {
            return !d || d.isBefore(new Date().getTime() - 24 * 60 * 60 * 1000);
          }}
          placeholder="Preferred appointment date"
          inputReadOnly={true}
          mode="normal"
          format="DD/MM/YYYY"
          popupStyle={{
            maxHeight: "400px !important",
          }}
          onChange={(e: any) => {
            setIsDateSelected(true);
            if (moment(e) > moment()) {
              setIsTodaySelected(false);
            } else {
              setIsTodaySelected(true);
            }
          }}
        />
      </Col>

      <Col span={12}>
        <TimePicker
          formItem={{
            label: "Preferred Time",
            name: "preferred_time",
            rules: [
              {
                required: true,
                message: "Please input preferred time",
              },
            ],
          }}
          format="hh:mm"
          mode="normal"
          inputReadOnly={true}
          minuteStep={30}
          disabledHours={() => getDisabledHours(isTodaySelected)}
          disabledMinutes={(selectedHour: number) =>
            getDisabledMinutes(selectedHour, isTodaySelected)
          }
        />
      </Col>

      <Col xs={24} sm={12}>
        <Select
          formItem={{
            name: "payment_method",
            label: "Payment method",
            rules: [
              {
                required: true,
                message: "Please select payment method",
              },
            ],
          }}
          onChange={(value: string) => setSelectedPaymentMethod(value)}
          mode="normal"
        >
          <AntdSelect.Option value="cash">Cash</AntdSelect.Option>

          {ProviderProfile?.metadata?.payment_options?.insurance && (
            <AntdSelect.Option value="insurance">Insurance</AntdSelect.Option>
          )}
        </Select>
      </Col>
      {selectedPaymentMethod === "insurance" && (
        <>
          <Col span={16} style={{ marginBottom: "1em" }}>
            <Select
              formItem={{
                name: "insurance_provider",
                label: "Insurance provider",
                rules: [
                  {
                    required: true,
                    message: "Please select an insurance provider",
                  },
                ],
              }}
              showSearch={true}
              mode="normal"
            >
              {ProviderProfile?.metadata?.payment_options?.selectedProviders?.map(
                (item: any) => (
                  <AntdSelect.Option value={item} key={item}>
                    {item}
                  </AntdSelect.Option>
                ),
              )}
            </Select>
          </Col>

          <Col span={8}>
            <Input
              formItem={{
                name: "insurance_id",
                label: "Insurance ID",
                rules: [
                  {
                    required: true,
                    message: "Please input your insurance id",
                  },
                ],
              }}
              mode="normal"
              style={{ textTransform: "uppercase" }}
            />
          </Col>
        </>
      )}

      <Col span={24}>
        <TextArea
          formItem={{
            name: "note",
            label: "Additional notes/comments",
          }}
          label=""
          mode="normal"
          maxLength={300}
          autoSize={{ minRows: 4, maxRows: 4 }}
        />
      </Col>
    </>
  );

  function formatPhoneNumber(
    number: string,
    numberDetails: { dialCode: string },
  ) {
    const formattedPhone = handlePhoneNumberFormat(
      number,
      numberDetails.dialCode,
    );

    setFormattedPhone(formattedPhone);
  }

  function handleNoEmailChange() {
    setNoEmail((prev) => !prev);
    if (!noEmail) {
      addBookForm.setFieldsValue({ email: "" });
    }
  }

  function handleNoDOBChange() {
    setNoDOB((prev) => !prev);
    if (!noEmail) {
      addBookForm.setFieldsValue({ dob: moment("01/01/2004") });
    }
  }

  const bookingPage = {
    new: [
      <>
        <Col xs={24} sm={12}>
          <Input
            formItem={{
              name: "first_name",
              label: "First name",
              rules: [
                {
                  required: true,
                  message: "Please enter first name",
                },
              ],
            }}
            label=""
            mode="normal"
          />
        </Col>
        <Col xs={24} sm={12}>
          <Input
            formItem={{
              name: "last_name",
              label: "Last name",
              rules: [
                {
                  required: true,
                  message: "Please enter last name",
                },
              ],
            }}
            mode="normal"
          />
        </Col>
        <Col span={24}>
          <Row gutter={24}>
            <Col span={24} md={12} style={{ position: "relative" }}>
              <Input
                formItem={{
                  label: "Email address",
                  name: "email",
                  rules: [
                    {
                      required: !noEmail,
                      message: `${
                        noEmail ? "" : "Please input your email address"
                      }`,
                    },
                    {
                      type: "email",
                      message: noEmail ? "" : "Please input a valid email",
                    },
                  ],
                }}
                mode="normal"
                type="email"
                disabled={noEmail}
                value={""}
              />
              <div
                className="email-checkbox"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "120px",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <input
                  id="email-checkbox"
                  type="checkbox"
                  checked={noEmail}
                  onChange={() => handleNoEmailChange()}
                  style={{ cursor: "pointer" }}
                />{" "}
                <label
                  htmlFor="email-checkbox"
                  style={{ cursor: "pointer", fontSize: "12px" }}
                >
                  Not available?
                </label>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <FormItem
                label="Date of Birth"
                name="dob"
                rules={[
                  {
                    required: !noDOB,
                    message: "Please enter date of birth",
                  },
                ]}
              >
                <DateOfBirthPicker
                  clearIcon={null}
                  disableCalendar={true}
                  format="dd/MM/yyyy"
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  disabled={noDOB}
                  maxDate={
                    new Date(new Date().setFullYear(new Date().getFullYear()))
                  }
                />
              </FormItem>

              <div
                className="dob-checkbox"
                style={{
                  position: "absolute",
                  top: "0",
                  left: "120px",
                  display: "flex",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                <input
                  id="dob-checkbox"
                  type="checkbox"
                  checked={noDOB}
                  onChange={() => handleNoDOBChange()}
                  style={{ cursor: "pointer" }}
                />{" "}
                <label
                  htmlFor="dob-checkbox"
                  style={{ cursor: "pointer", fontSize: "12px" }}
                >
                  Not available?
                </label>
              </div>
            </Col>
            <Col xs={24} sm={12}>
              <Select
                formItem={{
                  name: "gender",
                  label: "Gender",
                  rules: [
                    {
                      required: true,
                      message: "Please select gender",
                    },
                  ],
                }}
                mode="normal"
              >
                {["male", "female"].map((option, index) => {
                  return (
                    <AntdSelect.Option key={index} value={option}>
                      <span style={{ textTransform: "capitalize" }}>
                        {option}
                      </span>
                    </AntdSelect.Option>
                  );
                })}
              </Select>
            </Col>
            <Col xs={24} sm={12}>
              <Input
                formItem={{
                  name: "phone",
                  label: "Phone number",
                  rules: [
                    {
                      required: true,
                      message: "Please enter your phone number",
                    },
                    {
                      required: true,
                      pattern: /[0-9]{11}$/,
                      message: "Please enter a valid phone number",
                    },
                  ],
                }}
                type="phone"
                mode="normal"
                onChange={formatPhoneNumber}
              />
            </Col>

            <Col xs={24} sm={12}>
              <Input
                formItem={{
                  name: "patientId",
                  label: "Patient ID",
                }}
                type="text"
                mode="normal"
                style={{ textTransform: "uppercase" }}
              />
            </Col>
          </Row>
        </Col>
      </>,
      <>{page2SimilarData()}</>,
    ],
    request: [
      <Col span={23} style={{ margin: "0 auto" }}>
        <Input
          formItem={{
            label: "Email address",
            name: "email",
            rules: [
              {
                required: true,
                message: "Please input your email address",
              },
              {
                type: "email",
                message: "Please input a valid email",
              },
            ],
          }}
          placeholder="Check your email for your OTP code"
          mode="normal"
          type="email"
        />
      </Col>,
      <>
        <Col span={24}>
          <h4 className="otp-label">
            <span>*</span> Enter your OTP
          </h4>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={8}
            className="otp-input"
            focusStyle={{ border: "2px solid #0f9af0" }}
          />
        </Col>
        <>{page2SimilarData()}</>
      </>,
    ],
    existing: [
      <Col span={24}>
        <Select
          formItem={{
            name: "user",
            label: "Select or search patient",
            rules: [
              {
                required: true,
                message: "Please select an existing patient",
              },
            ],
          }}
          placeholder="Search patient by name, email or phone"
          mode="normal"
          onChange={(val: any) => {
            const singleUser = users.find(
              (user: UserType) => user.id === JSON.parse(val).id,
            )!;
            dispatch(setSelectedUser(singleUser as any));
          }}
          showSearch
          optionFilterProp="children"
          filterOption={(_input: string, option: any) => {
            const input = _input.toLowerCase();

            const singleUser = JSON.parse(option.value);

            const doesExist =
              singleUser.email.toLowerCase().includes(input) ||
              singleUser.first_name?.toLowerCase().includes(input) ||
              singleUser.last_name?.toLowerCase().includes(input) ||
              String(singleUser.patientId)?.toLowerCase()?.includes(input) ||
              (
                singleUser.first_name?.toLowerCase() +
                " " +
                singleUser.last_name?.toLowerCase()
              ).includes(input) ||
              (
                singleUser.last_name?.toLowerCase() +
                " " +
                singleUser.first_name?.toLowerCase()
              ).includes(input);

            return doesExist;
          }}
          // filterSort={(optionA: any, optionB: any) =>
          //   optionA.value
          //     .toLowerCase()
          //     .localeCompare(optionB.value.toLowerCase())
          // }
        >
          {users?.map((user, index) => {
            return (
              <AntdSelect.Option key={index} value={JSON.stringify(user)}>
                <span style={{ textTransform: "capitalize" }}>
                  {user.first_name} {user.last_name}
                </span>
              </AntdSelect.Option>
            );
          })}
        </Select>
      </Col>,
      <>{page2SimilarData()}</>,
    ],
  };

  const resetFormModal = () => {
    dispatch(setBookingModalState(false));
    addBookForm.resetFields();
    setFormPage("newUser");
    setPageIndex(0);
  };

  const handleBooking = async (values: any) => {
    setLoading(true);

    // accepted emailjs template
    const acceptedTemplateParams = {
      slug: ProviderProfile.slug || ProviderProfile.id,
      business_name: ProviderProfile.business_name,
      to_name: `${capitaliseFirstLetter(user?.first_name || "")}`,
      to_email: user?.email,
      business_phone: ProviderProfile.phone,
      business_email: ProviderProfile.email,
      business_address: `${capitaliseFirstLetter(
        ProviderProfile.address?.street_line_one,
      )},  ${capitaliseFirstLetter(
        ProviderProfile.address?.city,
      )}, ${capitaliseFirstLetter(ProviderProfile.address?.state)}.`,
      service: ProviderProfile.service_plans.find(
        (plan: any) => plan.id === values?.plan,
      )?.name,
      preferred_date: moment(values?.preferred_date).format("LL"),
      preferred_time: moment(values?.preferred_time).format("hh:mm a"),
      logo:
        ProviderProfile.logo_path ||
        "https://cdn.jsdelivr.net/gh/PneumaCareHQ/symptoms-icon/Pneuma%E2%80%94Coloured.png",
    };

    try {
      if (formPage === "newUser" && !pageIndex) {
        const _values = values as RegisterUserValues;
        _values.email = noEmail
          ? `frontdesk${formattedPhone}@pneuma.care`
          : _values.email;
        _values.phone = formattedPhone;
        _values.dob = noDOB
          ? moment("01/01/2004")
          : moment(_values.dob, "DD/MM/YYYY");

        const localStoreProviderID = window.localStorage.getItem("providerID")!;

        //check provider's patient collection for user's email - if it exists, push to the existingUser page, else continue with createUser flow.
        let doesExist = false,
          doesExistInGeneral = false;

        try {
          const patientsRef = db
            .collection("providerInfo")
            .doc(localStoreProviderID)
            .collection("patients")
            .where("email", "==", _values.email);

          doesExist = !!(await patientsRef.get()).docs.length;

          const generalPatientsRef = db
            .collection("patients")
            .where("email", "==", _values.email);

          doesExistInGeneral = Boolean(
            (await generalPatientsRef.get()).docs.length,
          );

          const generalPatientInfo = (await generalPatientsRef.get()).docs[0];

          //If not in provider patient collection, but in general patient collection.. add to provider patient collection & move on as existing user.
          if (doesExist === false) {
            if (doesExistInGeneral) {
              // if patient exist in general collection, add to local provider collection
              await db
                .collection("providerInfo")
                .doc(localStoreProviderID)
                .collection("patients")
                .doc(generalPatientInfo.data().id)
                .set({
                  ...generalPatientInfo.data(),
                  created_at: firebase.firestore.FieldValue.serverTimestamp(),
                });

              // update selected user state to newly created user.
              // dispatch(
              //   setSelectedUser({
              //     dob: generalPatientInfo.data().dob,
              //     email: generalPatientInfo.data().email,
              //     first_name: generalPatientInfo.data().first_name,
              //     gender: generalPatientInfo.data().gender,
              //     id: generalPatientInfo.data().id,
              //     last_name: generalPatientInfo.data().last_name,
              //     phone: generalPatientInfo.data().phone,
              //   })
              // );
            } else {
              // else..at this point it doesn't exist anywhere..create fresh user.
              await dispatch(createUser(_values));
            }
          } else {
            // doesExist is true at this branch
            setFormPage("existingUser");
            message.success(
              "User already exists. Please select or search user",
            );
          }

          setPageIndex(1);
          await dispatch(getPatients());
        } catch (err: any) {
          if (err.message.includes("User account already exists")) {
            message.error(err.message);
            setFormPage("requestAccess");
          }
        }
      } else if (formPage === "existingUser" && !pageIndex) {
        const { user } = values as UserValueType;
        const _user = JSON.parse(user);
        const userID = _user.id;

        const selectedUser: UserType | any = users?.find(
          (user: UserType) => user.id === userID,
        )!;
        dispatch(setSelectedUser(selectedUser));
        setPageIndex(1);
      } else if (formPage === "requestAccess" && !pageIndex) {
        try {
          const { email } = values as RequestValueType;
          await dispatch(requestPatientAccess(email));
          message.success("Code sent! check your email for your OTP code.");
          setPageIndex(1);
        } catch (err: any) {
          console.log(err);
          message.error(err.message);
          setFormPage("newUser");
        }
      } else {
        const _values = values as BookValuesType;
        if (!appointmentType) {
          throw new Error("Please choose an appointment type!");
        } else {
          if (formPage === "requestAccess" && pageIndex) {
            await dispatch(approvePatientAccessRequest(otp));
          }
          await dispatch(
            bookService({
              ..._values,
              source: appointmentType,
              patientId: user?.patientId,
            }),
          );
          MixPanel.track("CreateAppointment", {
            service: ProviderProfile.service_plans.find(
              (plan: any) => plan.id === _values?.plan,
            )?.name,
            preferred_date: moment(_values.preferred_date).format("LL"),
            preferred_time: _values.preferred_time
              ? moment(_values.preferred_time).format("hh:mm a")
              : "Unavailable",
          });

          if (appointmentType === "callIn") {
            // send acceptance email
            await send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
              "temp_accepted_booking",
              acceptedTemplateParams,
            );
          }

          await dispatch(fetchAllServiceOrders());
          message.success("Appointment booked successfully!");
          resetFormModal();
        }
      }
    } catch (error) {
      const err = error as Error;
      if (err.message.includes("User not found")) {
        // TODO -> remember to fix ASAP.
        // if user is not found (this means that the selected user does not exist on the current integration - FrontDesk/PneumaPage). We would make a hacky workaround to account for erroneous Divine dentals mass record upload (the data was mistakenly uploaded to the wrong integration - Telehealth/North) and use that integration's API key instead to make the booking.
        try {
          const _values = values as BookValuesType;

          await dispatch(
            bookServiceWithNorthKey({
              ..._values,
              source: appointmentType!,
              patientId: user?.patientId,
            }),
          );

          MixPanel.track("CreateAppointment", {
            service: ProviderProfile.service_plans.find(
              (plan: any) => plan.id === _values?.plan,
            )?.name,
            preferred_date: moment(_values.preferred_date).format("LL"),
            preferred_time: _values.preferred_time
              ? moment(_values.preferred_time).format("hh:mm a")
              : "Unavailable",
          });

          if (appointmentType === "callIn") {
            // send acceptance email
            await send(
              process.env.REACT_APP_EMAILJS_SERVICE_ID as string,
              "temp_accepted_booking",
              acceptedTemplateParams,
            );
          }

          await dispatch(fetchAllServiceOrders());
          message.success("Appointment booked successfully!");
          resetFormModal();
        } catch (err: any) {
          console.log(err);
          message.error(err.message);
        }
      } else if (err.message.includes("User account already exists")) {
        message.error(err.message);
        setFormPage("requestAccess");
      } else {
        message.error(err.message);
      }
    }
    setLoading(false);
  };

  const headingText = () => {
    if (formPage === "newUser" && !pageIndex) {
      return "Add patient";
    } else if (formPage === "existingUser" && !pageIndex) {
      return "Existing patient";
    } else if (formPage === "requestAccess" && !pageIndex) {
      return "Request access";
    }
  };

  let isModal;

  return hasbeenAuthorized ? (
    <>
      <>
        <ModalWrapper
          visible={isModal}
          onCancel={resetFormModal}
          centered
          footer={false}
          width={550}
          destroyOnClose
          bodyStyle={{
            padding: "25px 20px 20px",
            maxHeight: "650px",
            overflowY: "scroll",
          }}
          closeIcon={
            <CloseCircleOutlined
              style={{ fontSize: "1.2rem", color: "#1a90ff" }}
            />
          }
          style={{ borderRadius: "80px !important" }}
        >
          <Steps
            current={pageIndex}
            style={{ margin: "20px auto 25px", width: "85%" }}
          >
            <Step title={headingText()} />
            <Step title="Create appointment" />
          </Steps>
          <Form
            name="basicForm"
            layout="vertical"
            form={addBookForm}
            onFinish={handleBooking}
          >
            <Row gutter={18} className="row-icons">
              {!pageIndex && formPage !== "requestAccess" ? (
                <>
                  <Col span={12}>
                    <Card
                      className={formPage === "newUser" ? "isCardActive" : ""}
                      onClick={() => setFormPage("newUser")}
                    >
                      <IconNew /> New patient
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      className={
                        formPage === "existingUser" ? "isCardActive" : ""
                      }
                      onClick={() => setFormPage("existingUser")}
                    >
                      <IconOld className="icon-2" /> Existing patient
                    </Card>
                  </Col>
                </>
              ) : pageIndex === 1 ? (
                <>
                  <h3>
                    <span>*</span> Choose appointment method
                  </h3>
                  <Col span={12}>
                    <Card
                      className={
                        appointmentType === "walkIn" ? "isCardActive" : ""
                      }
                      onClick={() => setAppointmentType("walkIn")}
                    >
                      <img
                        src={WalkInIcon}
                        alt="walk in"
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />{" "}
                      Walk in
                    </Card>
                  </Col>
                  <Col span={12}>
                    <Card
                      className={
                        appointmentType === "callIn" ? "isCardActive" : ""
                      }
                      onClick={() => setAppointmentType("callIn")}
                    >
                      <img
                        src={CallInIcon}
                        alt="call in"
                        style={{
                          width: "30px",
                          height: "30px",
                          objectFit: "cover",
                          marginRight: "10px",
                        }}
                      />{" "}
                      Call in
                    </Card>
                  </Col>
                </>
              ) : null}
            </Row>
            <Row gutter={24}>
              {formPage === "newUser"
                ? bookingPage.new[pageIndex]
                : formPage === "existingUser"
                ? bookingPage.existing[pageIndex]
                : bookingPage.request[pageIndex]}
              {/* {bookingPage.request[1]} */}

              <Col
                span={24}
                style={{ display: "flex", justifyContent: "flex-end" }}
              >
                {pageIndex === 1 || formPage === "requestAccess" ? (
                  <div style={{ marginRight: "15px" }}>
                    <Button
                      onClick={() => {
                        if (formPage === "requestAccess" && pageIndex === 0) {
                          setFormPage("newUser");
                        } else {
                          setPageIndex(0);
                        }
                      }}
                      type="secondary"
                      disabled={loading}
                    >
                      Previous
                    </Button>
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <Button htmlType="submit" type="primary" disabled={loading}>
                    {ButtonTexts()}
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalWrapper>
      </>
      <Route
        {...rest}
        render={(matchProps) => (
          <DashboardLayout>
            <Component {...matchProps} dashboardMode={dashboardMode} />
          </DashboardLayout>
        )}
      />

      <CreateConsultationModal />
    </>
  ) : (
    <Redirect to="/" />
  );
};

export default DashboardLayoutRoute;
