import { useState } from "react";
import { Col, Row } from "antd";

import Form from "antd/lib/form/Form";
import { Link, useHistory } from "react-router-dom";
import AppIcon from "../../../assets/images/icon.svg";
import Button from "../../../component/Button";
import Input from "../../../component/Input";
import EmailLinkSent from "./EmailLinkSent";
import LoginSvg from "../../../assets/icons/login-icon.svg";
import { authSelector, loginProvider } from "../../../redux/reducers/auth";
import { useSelector, useDispatch } from "react-redux";
import { ErrorMsg } from "../../../layouts/OnboardingLayout/styles";
import { ROUTES } from "../../../constants/routes";
import { MixPanel } from "utils/mixpanel";

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoading, error } = useSelector(authSelector);
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  const handleLogin = async (values: any) => {
    await dispatch(loginProvider(values));

    MixPanel.track("SignIn", { email: values.email });
  };

  return isLoginSuccessful ? (
    <EmailLinkSent setIsLoginSuccessful={setIsLoginSuccessful} />
  ) : (
    <>
      <Col xs={0} md={12}>
        <div className='left-pane'>
          <img className='app-logo' src={AppIcon} alt='logo' />
          <div className='align-center'>
            <img src={LoginSvg} alt='icon' />
            <h3>Welcome back</h3>
            <p>Enter your details to log into your account</p>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='right-pane'>
          <span className='close-icon' onClick={() => history.goBack()}>
            <i className='lni lni-arrow-left' /> Back
          </span>
          <Form
            name='basic'
            layout='vertical'
            requiredMark={false}
            onFinish={handleLogin}
          >
            <Row gutter={24} align='middle'>
              <Col span={24}>
                <Input
                  formItem={{
                    label: "Email",
                    name: "email",
                    rules: [
                      {
                        required: true,
                        message: "Please input your Email address"
                      },
                      {
                        type: "email",
                        message: "Please input a valid email"
                      }
                    ]
                  }}
                  type='email'
                  label='Email address'
                  placeholder='name@mail.com'
                />
              </Col>
              <Col span={24}>
                <Input
                  formItem={{
                    label: "Password",
                    name: "password",
                    rules: [
                      { required: true, message: "Please input your password" }
                    ]
                  }}
                  placeholder='Password'
                  marginBottom={0}
                  type='password'
                  label='Password'
                />
                <Row>
                  <Col span={24}>
                    <ErrorMsg className='error-msg'>{error?.message}</ErrorMsg>
                  </Col>

                  <Link
                    to={ROUTES.RECOVER_PASSWORD}
                    className='register-link'
                    style={{ marginTop: "22px", marginLeft: "auto" }}
                  >
                    Reset password
                  </Link>
                </Row>
              </Col>
              <Col span={24} className='align-center'>
                <Button
                  htmlType='submit'
                  type='primary'
                  className='auth-button login-button'
                  disabled={isLoading}
                >
                  {isLoading ? "Signing in..." : "Sign in"}
                </Button>
                <Link to={ROUTES.REGISTER} className='register-link'>
                  Or create a profile
                </Link>
              </Col>
            </Row>
          </Form>
        </div>
      </Col>
    </>
  );
};

export default Login;
