import React from "react";
import { PreviewWrapper, Question } from "./styles";
import Card from "../../../../component/Card";
import { Checkbox, Form, Input as AntdInput, Radio } from "antd";

const { TextArea } = AntdInput;

type Props = {
  isFormCreate: any;
};

const Preview = ({ isFormCreate }: Props) => {
  const previewData = isFormCreate;
  return (
    <PreviewWrapper style={{ maxHeight: "1000rem" }}>
      <Card style={{ marginBottom: 24 }}>
        <div className="header">
          {previewData?.icon && (
            <img src={previewData?.icon} alt={`${previewData?.name} icon`} />
          )}
          <h1>{previewData?.name}</h1>
          <span>{previewData?.description}</span>
        </div>
      </Card>

      {previewData?.questions?.map((item: any, index: number) => (
        <Card key={index} style={{ marginBottom: 12 }}>
          <Form>
            <Question>
              <div className="question">
                <div>
                  <span>{item?.id}</span>
                  <h1>{item?.text}</h1>
                </div>
                <span>{item?.hint}</span>
              </div>

              {item?.type === "text" && (
                <TextArea placeholder={"Write your answer here..."} />
              )}

              {item?.type === "select_one" &&
                Array.isArray(item?.response) &&
                item?.response?.map((item: any, index: number) => (
                  <div className="radio-wrapper" key={index}>
                    <Radio.Group value={""}>
                      <Radio
                        key={item?.id}
                        style={{ textTransform: "capitalize" }}
                      >
                        {item?.value}
                      </Radio>
                    </Radio.Group>
                    {item?.next_question && (
                      <span className="tag">
                        {" "}
                        Jump to question {item?.next_question}
                      </span>
                    )}
                  </div>
                ))}

              {item?.type === "select_multiple" && (
                <>
                  {item?.response?.map((item: any, index: number) => (
                    <div className="checkbox-wrapper" key={index}>
                      <Checkbox.Group>
                        <Checkbox
                          key={item?.id}
                          style={{ textTransform: "capitalize" }}
                        >
                          {item?.value}
                        </Checkbox>
                      </Checkbox.Group>
                      {item?.next_question && (
                        <span className="tag">
                          {" "}
                          Jump to question {item?.next_question}
                        </span>
                      )}
                    </div>
                  ))}
                </>
              )}
            </Question>
          </Form>
        </Card>
      ))}
    </PreviewWrapper>
  );
};

export default Preview;
