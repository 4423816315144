import React, { useEffect, useState } from "react";
import { ModalWrapper } from "./styles";
import {
  Col,
  Form,
  Row,
  Button,
  Select as AntdSelect,
  message,
  Checkbox
} from "antd";
import Select from "component/Select";
import { useSelector } from "react-redux";
import { requestsSelector } from "redux/reducers/requests";
import { userSelector } from "redux/reducers/users";
import { UserType } from "constants/types";
import { useCreateFinancialRequestMutation } from "redux/queries/financial-request";
import { servicePlansSelector } from "redux/reducers/servicePlans";
import { capitaliseFirstLetter } from "utils/utils";
import { MixPanel } from "utils/mixpanel";
import { useAppSelector } from "redux/store";

type FinanceProps = {
  isModalVisible: boolean;
  handleCancel: any;
};

const FinanceRequestModal = ({
  handleCancel,
  isModalVisible
}: FinanceProps) => {
  const { servicePlans } = useSelector(servicePlansSelector);
  const { profile: providerProfile } = useAppSelector((state) => state.profile);
  const [financeForm] = Form.useForm();
  const { users, isUsersLoading } = useSelector(userSelector);
  const [getServicePlanId, setGetServicePlanId] = useState<string>("");
  const [selectedUsers, setSelectedUsers] = useState<UserType[]>([]);
  const [getUserId, setGetUserId] = useState<string>("");
  const [selectedService, setSelectedService] = useState<any>();
  const [createRequest] = useCreateFinancialRequestMutation();
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [selectedUser, setSelectUser] = useState<UserType>();
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  useEffect(() => {
    setSelectedUsers(users);
  }, [users]);

  const onChange = (event: string) => {
    setGetServicePlanId(event);
    const services = servicePlans.find((plan) => plan.id === event);
    setSelectedService(services);
  };

  const handleUsersChange = (event: string) => {
    const user = users.find((user) => user.id === event);
    setGetUserId(event);
    setSelectUser(user);
  };

  const onSubmit = async (event: any) => {
    setIsRequestLoading(true);
    try {
      await createRequest({
        user: getUserId,
        service_plan: getServicePlanId
      }).unwrap();
      message.success(
        `Financing request has been created for ${selectedUser?.first_name} ${selectedUser?.last_name}`
      );
      MixPanel.track("finance_request", {
        amount: selectedService?.price / 100,
        patientId: getUserId,
        service: selectedService?.name
      });
      financeForm.resetFields();
      handleCancel();
    } catch (err: any) {
      message.error(err?.message || "An error occurred. Please try again...");
    } finally {
      setIsRequestLoading(false);
    }
  };

  return (
    <ModalWrapper
      title={"Create a finance request"}
      visible={isModalVisible}
      footer={null}
      onCancel={handleCancel}
      className='dashboard-mode-modal'
    >
      <Form
        name='financeForm'
        layout='vertical'
        form={financeForm}
        onFinish={onSubmit}
      >
        <Row gutter={18}>
          <Col xs={24}>
            <Select
              formItem={{
                name: "patient",
                label: "Patient",

                rules: [
                  {
                    required: true,
                    message: "Please select a patient"
                  }
                ]
              }}
              onChange={handleUsersChange}
              mode='normal'
              width='auto'
              size='large'
              placeholder='Select a patient'
              showSearch
              filterOption={(_input: string, option: any) => {
                const input = _input.toLowerCase();
                const singleUser = users.find(
                  (user: any) => user.id === option.value
                )!;
                const booleanValue =
                  `${singleUser.first_name.toLowerCase()} ${singleUser.last_name.toLowerCase()}`
                    .toLowerCase()
                    .includes(input) ||
                  `${singleUser.last_name.toLowerCase()} ${singleUser.first_name.toLowerCase()}`
                    .toLowerCase()
                    .includes(input);
                return booleanValue;
              }}
            >
              {selectedUsers.map((user) => (
                <AntdSelect.Option key={user.id} value={user?.id}>
                  {user?.first_name?.charAt(0).toUpperCase() +
                    user?.first_name?.slice(1)}{" "}
                  {user?.last_name?.charAt(0).toUpperCase() +
                    user?.last_name?.slice(1)}
                </AntdSelect.Option>
              ))}
            </Select>
          </Col>
          <Col xs={24}>
            <Select
              formItem={{
                name: "service",
                label: "What service does the patient need?",

                rules: [
                  {
                    required: true,
                    message: "Please select a service"
                  }
                ]
              }}
              onChange={onChange}
              mode='normal'
              width='auto'
              size='large'
              placeholder='Select a service'
            >
              {servicePlans
                .filter((plan) => Number(plan.price) / 100 >= 2000)
                .map((plan: any, index: number) => (
                  <AntdSelect.Option key={index} value={plan?.id}>
                    {plan?.name ? capitaliseFirstLetter(plan?.name) : ""}
                  </AntdSelect.Option>
                ))}
            </Select>
          </Col>
          <Col xs={24}>
            {selectedService && (
              <div className='select'>
                <div className='service'>
                  <h3>Service</h3>
                  <h1>{capitaliseFirstLetter(selectedService?.name)}</h1>
                </div>
                <span />
                <div className='fee'>
                  <h3>Fee</h3>
                  <h1>{`${new Intl.NumberFormat("en-NG", {
                    style: "currency",
                    currency: "NGN",
                    maximumFractionDigits: 0
                  }).format(selectedService?.price / 100)}`}</h1>
                </div>
              </div>
            )}
          </Col>

          <Col xs={24}>
            <Form.Item name='expires' initialValue={true} required>
              <Checkbox
                checked={acceptedTerms}
                onChange={() => setAcceptedTerms((prev) => !prev)}
              >
                I understand a finance request attracts a 10% service charge
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={24}>
            <div className='btn-wrapper'>
              <Button
                htmlType='submit'
                disabled={isRequestLoading || !acceptedTerms}
              >
                {isRequestLoading ? "Creating" : "Create finance request"}
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </ModalWrapper>
  );
};

export default FinanceRequestModal;
